import { useEffect } from 'react';

const QualtricsCustoms = (): JSX.Element => {
  const handleClick = (e: Event) => {
    const closeButtonQueryIdentifier =
      ".QSIWebResponsive .QSIWebResponsive-creative-container-fade [class^='QSIWebResponsiveDialog'][class*='_close-btn'] button";
    const closeButton = document.querySelector(closeButtonQueryIdentifier) as HTMLElement;

    const fadeBackgroundIdentifier = '.QSIWebResponsive .QSIWebResponsive-creative-container-fade';
    const fadeBackground = document.querySelector(fadeBackgroundIdentifier) as HTMLElement;

    const clickElement = e.target as HTMLElement;

    let isClickWithinDialog = false;
    if (fadeBackground) {
      isClickWithinDialog = fadeBackground.contains(clickElement);
    }

    const isClickWithinFadeBackground = fadeBackground === clickElement;

    //clicked outside the dialog so fire off the closebutton event
    if (isClickWithinFadeBackground) {
      e.preventDefault();
      closeButton.click();
    }
    //clicked within dialog
    else if (isClickWithinDialog && !isClickWithinFadeBackground) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  //functional component only
  return <></>;
};

export default QualtricsCustoms;
