// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';
import CircleIcon from 'components/helpers/Icons/CircleIcon/CircleIcon';
import { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { getThemeClasses, Theme } from 'lib/get-theme';
import { useRef } from 'react';
import { getEnum } from 'lib/get-enum';

export type PromoProps = Sitecore.Override.ComponentBase & Feature.Components.Promo.Fields.Promo;
export type PromoAlignment = 'center' | 'space-between';

const Promo = ({ fields, rendering }: PromoProps): JSX.Element => {
  const promoRef = useRef<HTMLDivElement>(null);
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const componentAnchorId = rendering?.uid;
  const themeValue = getEnum<Theme>(fields?.promoBgColor);
  const alignment = getEnum<PromoAlignment>(fields?.promoAlignment);
  const fullWidth = fields?.promoFullWidth?.value;

  return (
    <Container
      dataComponent="authorable/promo/promo"
      id={componentAnchorId}
      className="anchorIdIndentifier"
      fullWidth={fullWidth}
    >
      <div
        ref={promoRef}
        className={classNames(
          'flex',
          'flex-col',
          'sm:flex-row',
          'items-center',
          'justify-center',
          'p-5',
          'text-center',
          { 'rounded-lg': !fullWidth },
          { 'sm:justify-between': alignment === 'space-between' },
          { 'sm:justify-center': alignment === 'center' },
          'sm:p-4',
          'sm:text-left',
          getThemeClasses(themeValue),
          'text-theme-text'
        )}
      >
        <div
          className={classNames(
            'flex',
            'flex-col',
            'sm:flex-row',
            'items-center',
            'md:max-w-[80%]'
          )}
        >
          <CircleIcon
            field={fields.iconImage as SizedImageField}
            size={'xs'}
            theme={themeValue === 'theme-blue--peacock' ? 'theme-white' : 'theme-blue'}
          />
          <Text
            tag="div"
            className={classNames(
              'mb-3',
              'sm:mb-0',
              {
                'sm:ml-4': fields.iconImage.value,
                'sm:mr-6': fields.iconImage.value,
                'sm:mt-0': fields.iconImage.value,
                'mt-3': fields.iconImage.value,
              },
              'text-md',
              'md:text-lg'
            )}
            field={fields.headline}
          />
        </div>
        <CtaGroup {...fields} />
      </div>
    </Container>
  );
};

export default Promo;
