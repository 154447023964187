/**
 * Used for finding if the link is sc edge cdn link
 * @param linkHref
 * @returns true if edge.sitecloud.io is found in the href else false
 */

import IsDevelopmentOrLower from 'components/helpers/Utility/IsDevelopmentOrLower/IsDevelopmentOrLower';

export const isEdgeSitecoreCdnLink = (linkHref: string | undefined) => {
  try {
    if (linkHref != null && linkHref != undefined && linkHref != '') {
      if (linkHref?.indexOf('edge.sitecorecloud.io') > 0) {
        return true;
      } else return false;
    } else return false;
  } catch (error) {
    if (IsDevelopmentOrLower()) {
      console.log(`isEdgeSitecoreCdnLink err: ${error}`);
    }
    return false;
  }
};
