// Global
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';

// Local
import PlaceholderWrapper, {
  usePlaceholderContext,
} from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { useEffect, useRef, useState } from 'react';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { getEnum } from 'lib/get-enum';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';
import { Cookies } from 'react-cookie';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/ConditionalWrapper';
import React from 'react';

export type HeroShowRule = 'always' | 'members' | 'non-members';

export type HeroStandardProps = Sitecore.Override.ComponentBase &
  Feature.Components.Heros.Fields.Hero;

type ContentAlignment = 'left' | 'right';

export type HeroStandardContentProps = HeroStandardProps & {
  showPlaceholder: boolean;
  showAdditionalResourcesPlaceholder: boolean;
  contentAlignment: ContentAlignment;
  heroShowRule: HeroShowRule;
};

const HeroContent = ({
  fields,
  rendering,
  showPlaceholder,
  showAdditionalResourcesPlaceholder,
  contentAlignment,
  heroShowRule,
}: HeroStandardContentProps) => {
  const headingWrapperData = {
    headline: fields?.heroHeading,
    headlineBold: fields?.heroHeadingBold,
    headlineLevel: fields?.headlineLevel,
    description: fields?.heroDescription,
    descriptionRTE: fields?.heroDescriptionRTE,
    eyebrows: fields?.heroEyebrowText,
    headlinePosition: fields?.heroContentPlacement,
  };

  return (
    <div
      className={classNames('mx-auto', 'container-content-padding-x', {
        'pb-20': showPlaceholder,
        'md:pb-0': showPlaceholder,
      })}
    >
      <div
        className={classNames(
          'flex',
          'flex-col',
          'justify-center',
          'relative',
          'z-1',
          'md:h-full',
          'md:min-h-[480px]',
          'md:w-1/2',
          {
            'pt-7': showPlaceholder,
            'pb-1': showPlaceholder,
            'sm:pt-20': showPlaceholder,
            'sm:pb-28': showPlaceholder,
            'py-10': !showPlaceholder,
          },
          {
            'md:ml-auto': contentAlignment === 'right',
            'md:items-start': contentAlignment === 'right',
          }
        )}
      >
        <div
          className={classNames('max-w-lg', 'z-10', {
            'md:pr-8': contentAlignment !== 'right',
          })}
        >
          <EditingHelpText
            text="This will be hidden for users who have visited pages under /members"
            condition={heroShowRule === 'non-members'}
          />
          <EditingHelpText
            text="This will be only visible for users who have visited pages under /members"
            condition={heroShowRule === 'members'}
          />
          {fields?.iconImage.value?.src && (
            <div className={classNames('mb-6', 'text-teal', 'w-fit', 'hidden', 'md:block')}>
              <ImageWrapper
                imageClass={classNames('max-w-8')}
                field={fields.iconImage as SizedImageField}
              />
            </div>
          )}
          <HeadingWrapper
            headingClassName={classNames({ 'mb-0': !fields?.heroDescription.value })}
            {...headingWrapperData}
          />
          {showAdditionalResourcesPlaceholder ? (
            <div className={classNames('my-8')}>
              <PlaceholderWrapper
                wrapperSize="half"
                name="BCBSAZ-Additional-Content"
                rendering={rendering}
              />
            </div>
          ) : null}
          <ConditionalWrapper
            condition={!!showPlaceholder && !!fields?.primaryCta.value.text}
            wrapper={(children: JSX.Element) => (
              <div className={classNames('mb-12')}>{children}</div>
            )}
          >
            <CtaGroup className={'mt-8'} {...fields} />
          </ConditionalWrapper>
        </div>
      </div>
    </div>
  );
};

const HeroStandard = ({ fields, rendering }: HeroStandardProps): JSX.Element => {
  const [nextSectionTheme, setNextSectionTheme] = useState<string>();

  const heroShowRule = getEnum<HeroShowRule>(fields?.heroShowRule) || 'always';

  const cookies = new Cookies();

  const [hideHero, setHideHero] = useState<boolean | undefined>();

  const ref = useRef<HTMLDivElement>(null);

  const placeholders = rendering?.placeholders;

  const isEE = useExperienceEditor();

  const parentPlaceholderName = usePlaceholderContext();

  const showPlaceholder =
    isEE || (placeholders && placeholders['BCBSAZ-Hero-Health-Sherpa']?.length);

  const showAdditionalResourcesPlaceholder =
    isEE || (placeholders && placeholders['BCBSAZ-Additional-Content']?.length);

  const contentAlignment = getEnum<ContentAlignment>(fields?.heroContentPlacement) ?? 'left';

  useEffect(() => {
    // setting values causes elements to be re-rendering including placeholders
    // and the placeholder settings don't work correctly in EE in that case
    // so skip these adjustments when in EE
    if (isEE) {
      return;
    }

    const visitedMembers = cookies.get('visited-member') === 'true';

    setHideHero(shouldHideHero(visitedMembers, heroShowRule));
  }, []);

  useEffect(() => {
    // setting values causes elements to be re-rendering including placeholders
    // and the placeholder settings don't work correctly in EE in that case
    // so skip these adjustments when in EE
    if (isEE) {
      return;
    }

    const thisComponent = ref.current; //document.querySelector('[data-component="authorable/hero/herostandard"]');

    const nextTheme = thisComponent?.nextElementSibling?.getAttribute('data-current-theme');

    if (nextTheme !== undefined && nextTheme !== null) {
      setNextSectionTheme(nextTheme);
    }
  }, [ref.current]);

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  if (!isEE && hideHero) {
    return <React.Fragment key={rendering?.uid}></React.Fragment>;
  }
  const overlapClass =
    rendering.placeholders && rendering?.placeholders['BCBSAZ-Hero-Health-Sherpa']?.length
      ? '-mt-20'
      : '';

  const bgposition =
    rendering.placeholders && rendering?.placeholders['BCBSAZ-Hero-Health-Sherpa']?.length
      ? '80px'
      : '';

  const componentAnchorId = rendering?.uid;
  return (
    <div
      key={rendering?.uid}
      className={classNames(
        'w-full',
        'relative',
        'flex',
        'flex-col',
        'h-full',
        {
          // Before we figure out whether to show or hide, set it to invisible so it looks more natural
          invisible: !isEE && hideHero === undefined && heroShowRule === 'members',
        },
        'anchorIdIndentifier'
      )}
      data-component="authorable/hero/herostandard"
      ref={ref}
      id={componentAnchorId}
    >
      <div
        className={classNames(
          'bg-cover',
          'bg-no-repeat',
          'flex',
          'flex-col',
          'flex-grow',
          'shrink-0',
          'justify-end',
          'md:justify-center',
          'md:min-h-[565px]',
          'overflow-hidden',
          'relative',
          {
            'bg-right-top': contentAlignment !== 'right',
            'bg-left-top': contentAlignment === 'right',
          },
          {
            'after:from-white': fields.heroFadeOverlay.value,
            'before:bg-white': fields.heroFadeOverlay.value,
          },
          // gradient
          'after:absolute',
          'after:md:block',
          'after:hidden',
          'after:w-2/12',
          'after:h-full',
          'after:top-0',
          // copy-overlay
          'before:absolute',
          'before:z-10',
          'before:md:block',
          'before:hidden',
          'before:w-full',
          'before:h-full',
          'before:top-0',
          // gradient-left
          {
            'after:bg-gradient-to-r': contentAlignment !== 'right',
            'after:left-1/3': contentAlignment !== 'right',
          },
          // copy-overlay-left
          {
            'before:right-2/3': contentAlignment !== 'right',
          },
          // gradient-right
          {
            'after:bg-gradient-to-l': contentAlignment === 'right',
            'after:right-1/3': contentAlignment === 'right',
          },
          // copy-overlay-right
          {
            'before:left-2/3': contentAlignment === 'right',
          }
        )}
      >
        <div className={classNames('hidden', 'md:block', 'select-none')}>
          <ImageWrapper
            layout="fill"
            priority={parentPlaceholderName === 'BCBSAZ-Hero'}
            objectPosition="top"
            field={fields?.heroDesktopImage as SizedImageField}
            imageClass={classNames('w-full', '!h-full', 'object-cover', 'absolute')}
          />
        </div>
        <div className={classNames('block', 'md:hidden', 'select-none')}>
          <ImageWrapper
            layout="responsive"
            priority={parentPlaceholderName === 'BCBSAZ-Hero'}
            field={fields?.heroMobileImage as SizedImageField}
            imageClass={classNames('w-full', '!h-full', 'object-cover')}
          />
        </div>
        <Container className={classNames('')}>
          <HeroContent
            fields={fields}
            rendering={rendering}
            showPlaceholder={!!showPlaceholder}
            showAdditionalResourcesPlaceholder={!!showAdditionalResourcesPlaceholder}
            contentAlignment={contentAlignment}
            heroShowRule={heroShowRule}
          />
        </Container>
      </div>
      {showPlaceholder ? (
        <div
          className={classNames(
            'flex',
            'flex-col',
            'items-center',
            'mx-auto',
            'overlay-wrapper',
            'relative',
            'space-y-16',
            'relative',
            'w-full',
            'z-10',
            nextSectionTheme,
            overlapClass
          )}
          style={{
            backgroundImage: `linear-gradient(transparent ${bgposition}, ${
              showPlaceholder && 'var(--theme-bg)'
            } ${bgposition}, ${showPlaceholder && 'var(--theme-bg)'} 100%)`,
          }}
        >
          <PlaceholderWrapper
            wrapperSize="two-thirds"
            displayName="BCBSAZ-Hero-Overlay"
            name="BCBSAZ-Hero-Health-Sherpa"
            rendering={rendering}
          />
        </div>
      ) : null}
    </div>
  );
};

export default HeroStandard;

function shouldHideHero(visitedMembers: boolean, heroShowRule: string) {
  return (
    (visitedMembers && heroShowRule === 'non-members') ||
    (!visitedMembers && heroShowRule === 'members')
  );
}
