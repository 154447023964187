import { AtomicLayoutSection } from '@coveo/atomic-react';
import classNames from 'classnames';
import AzBlueFacet from './AzBlueFacet';
import { SpecifiedArticleListingProps } from 'components/authorable/article/SpecifiedArticleListing/SpecifiedArticleListing';

const AzBlueAtomicSpecifiedArticleFacets = ({
  fields,
}: SpecifiedArticleListingProps): JSX.Element => {
  const feed = fields?.browseAllText.value ?? '';

  return (
    <div
      className={classNames(
        'md:border-b',
        'border-gray',
        'flex',
        'flex-col',
        'md:flex-row',
        'md:items-end',
        'justify-between',
        'mb-3',
        'py-3',
        'relative'
      )}
      id="facet"
    >
      <h2
        className={classNames(
          'text-lg',
          'pb-5',
          'mb-5',
          'w-full md:w-1/4 lg:2/4',
          'border-b',
          'border-gray',
          'md:border-transparent',
          'md:mb-0',
          'md:pb-0'
        )}
      >
        {/* TODO use dictionary */}
        Browse All {feed} Articles
      </h2>
      <AtomicLayoutSection
        className={classNames(
          'w-full md:w-3/4 lg:w-2/4 flex flex-row justify-end flex-wrap space-x-2 mt-4'
        )}
        section="facets"
      >
        {fields?.feedFilters.map((x) => {
          return <AzBlueFacet key={x.id} facetItem={x} />;
        })}
      </AtomicLayoutSection>
    </div>
  );
};

export default AzBlueAtomicSpecifiedArticleFacets;
