import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import Button from '../../Button/Button';

export type HeaderLanguageToggleProps = {
  fields: {
    languageText: Field<string>;
  };
};

const HeaderLanguageToggle = ({ fields }: HeaderLanguageToggleProps): JSX.Element => {
  const sitecoreContext = useSitecoreContext();

  // Fail out if we don't have any fields
  if (!fields || !sitecoreContext.sitecoreContext.otherLangUrl) {
    return <></>;
  }

  return (
    <Button
      className={classNames('header-navList-menuItem')}
      icon="GlobeAmericasIcon"
      iconSize="xxs"
      iconPosition="left"
      size="small"
      field={{
        value: {
          href: sitecoreContext.sitecoreContext.otherLangUrl,
          text: fields?.languageText?.value,
        },
      }}
      type="button"
      variant="link"
    />
  );
};

export default HeaderLanguageToggle;
