import classNames from 'classnames';

export type VideoItemProps = {
  videoSrc?: string;
  title?: string;
};
const VideoItem = ({ videoSrc, title }: VideoItemProps): JSX.Element => {
  if (!videoSrc) {
    return <></>;
  }
  const [url, query] = videoSrc.split('?');
  const params = new URLSearchParams(query);
  if (url.includes('youtube.com')) {
    params.set('rel', '0');
  }

  const src = `${url}?${params}`;

  return (
    <div
      className={classNames(
        'embed-responsive',
        'embed-responsive-16by9',
        'relative',
        'w-full',
        'overflow-hidden'
      )}
      style={{ paddingTop: ' 56.25%' }}
      data-component="components/helpers/videoitem"
    >
      <iframe
        className={classNames(
          'embed-responsive-item',
          'absolute',
          'top-0',
          'right-0',
          'bottom-0',
          'left-0',
          'w-full',
          'h-full'
        )}
        src={src}
        allowFullScreen
        title={title || `Video from: ${src}`}
      ></iframe>
    </div>
  );
};

export default VideoItem;
