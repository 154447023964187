// Global
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';

// Local
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';

export type LayoutWideLeftProps = Sitecore.Override.ComponentBase;

export type LayoutWideLeftShellProps = {
  left: JSX.Element;
  right: JSX.Element;
  dataComponent?: string;
};

export const LayoutWideLeftShell = ({
  left,
  right,
  dataComponent,
}: LayoutWideLeftShellProps): JSX.Element => {
  return (
    <div
      className={classNames('md:grid', 'grid-cols-3', 'md:gap-5', 'lg:gap-7')}
      data-component={dataComponent}
    >
      <div className={classNames('col-span-2', 'mb-10', 'md:mb-0', 'space-y-6')}>{left}</div>
      <div className={classNames('col-span-1', 'space-y-6')}> {right}</div>
    </div>
  );
};

const LayoutWideLeft = ({ rendering }: LayoutWideLeftProps): JSX.Element => {
  return (
    <LayoutWideLeftShell
      dataComponent="authorable/layout/layout-components/layout-wide-left"
      left={
        <PlaceholderWrapper
          wrapperSize="two-thirds"
          name="BCBSAZ-Two-Thirds"
          rendering={rendering}
        />
      }
      right={
        <PlaceholderWrapper
          wrapperSize="third"
          name="BCBSAZ-One-Third-Right"
          rendering={rendering}
        />
      }
    />
  );
};

export default LayoutWideLeft;
