import classNames from 'classnames';

export type FormGroupProps = {
  children?: React.ReactNode;
  formGroupClasses?: string;
};

const FormGroup = ({ children, formGroupClasses }: FormGroupProps) => {
  return <div className={classNames('mb-6', 'relative', formGroupClasses)}>{children}</div>;
};

export default FormGroup;
