// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import Heading, {
  HeadingLevels,
  HeadingPositionProps,
  HeadingSizes,
} from 'components/helpers/Heading/Heading';
import { getEnum } from 'lib/get-enum';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type HeadingWrapperProps = {
  defaultHeadlinePosition?: HeadingPositionProps;
  defaultHeadlineLevel?: HeadingLevels;
  headingClassName?: string;
  headingSizeOverride?: HeadingSizes;
  eyebrowClassName?: string;
  eyebrows?: Field<string>;
  headline?: Field<string>;
  headlineBold?: Field<string>;
  headlineLevel?: Sitecore.Override.ItemEx;
  headlinePosition?: Sitecore.Override.ItemEx;
  description?: Field<string>;
  descriptionRTE?: Field<string>;
};

const HeadingWrapper = (fields: HeadingWrapperProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const headlineLevelValue =
    getEnum<HeadingLevels>(fields.headlineLevel) || fields.defaultHeadlineLevel || 'h3';

  const headlineSplitPosition =
    getEnum<HeadingPositionProps>(fields.headlinePosition) ||
    fields.defaultHeadlinePosition ||
    'center';
  let headlineSizeValue;

  switch (headlineLevelValue) {
    case 'h1':
      headlineSizeValue = 'xl';
      break;
    case 'h2':
      headlineSizeValue = 'lg';
      break;
    case 'h3':
      headlineSizeValue = 'md';
      break;
    default:
      headlineSizeValue = 'md';
      break;
  }

  return (
    <Heading
      headingText={fields.headline}
      headingTextBold={fields.headlineBold}
      headingLevel={headlineLevelValue}
      headingSize={fields.headingSizeOverride || headlineSizeValue}
      description={fields.description}
      descriptionRTE={fields.descriptionRTE}
      headingSplitPostion={headlineSplitPosition}
      eyebrow={fields.eyebrows}
      headingClassName={fields.headingClassName}
      eyebrowClassName={fields.eyebrowClassName}
    />
  );
};

export default HeadingWrapper;
