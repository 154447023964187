import { getAppRootId } from '@sitecore-jss/sitecore-jss/graphql';
import { debug } from '@sitecore-jss/sitecore-jss';
import { GraphQLSitemapServiceConfig, ImageField } from '@sitecore-jss/sitecore-jss-nextjs';

import config from 'temp/config';
import { BaseGraphQLSitemapService } from './base-sitemap-service';
import { siteItemId } from '.';
import { ArticleResult } from './article-sitemap-service';

/** @private */
export const queryError =
  'Valid value for rootItemId not provided and failed to auto-resolve app root item.';

/** @private */
export const languageError = 'The list of languages cannot be empty';

const mediaArticleTemplateId = '{A9364A5A-1DD9-468A-9DBF-7A0AFCBB874B}';

const defaultQuery = /* GraphQL */ `
  query ArticleSitemapQuery(
    $rootItemId: String!
    $language: String!
    $pageSize: Int = 40
    $after: String
  ) {
    search(
      where: {
        AND: [
          {
            OR: [
              { name: "_templates", value: "${mediaArticleTemplateId}", operator: CONTAINS }
            ]
          }
          { name: "_path", value: $rootItemId, operator: CONTAINS }
          { name: "_language", value: $language }          
        ]
      }
      orderBy: { name: "publisheddate", direction: DESC }
      first: $pageSize
      after: $after
    ) {
      total
      pageInfo {
        endCursor
        hasNext
      }
      results {
        url {
          path
        }
        id
        ...MediaArticleFragment
      }
    }
  }
  
  fragment BaseArticleFragment on _BaseArticle {
    articleTitle {
      value
    }
    imageFeatured {
      jsonValue
    }
    imageFallback {
      jsonValue
    }
  }

  fragment MediaArticleFragment on NewsroomMediaArticle {
    ...BaseArticleFragment
    publishedDate {
      dateValue
    }
  }
`;

/**
 * The schema of data returned in response to a page list query request
 */
export type ArticleQueryResult = {
  id: string;
  url: { path: string };
  articleTitle: { value: string };
  imageFeatured: { jsonValue: ImageField };
  imageFallback: { jsonValue: ImageField };
  publishedDate: { dateValue: number };
};

/**
 * Service that fetches the list of site pages using Sitecore's GraphQL API.
 * This list is used for SSG and Export functionality.
 * @mixes SearchQueryService<PageListQueryResult>
 */
export class MediaArticleGraphQLSitemapService extends BaseGraphQLSitemapService<ArticleQueryResult> {
  /**
   * Gets the default query used for fetching the list of site pages
   */
  protected get query(): string {
    return defaultQuery;
  }

  /**
   * Creates an instance of graphQL sitemap service with the provided options
   * @param {GraphQLSitemapServiceConfig} options instance
   */
  constructor(options: GraphQLSitemapServiceConfig) {
    super(options);
  }

  /**
   * Fetch sitemap which could be used for generation of static pages using SSG mode
   * @param {string[]} locales locales which application supports
   * @returns an array of @see ArticleResult objects
   */
  async fetchArticles(locales: string[], rootId?: string): Promise<ArticleResult[]> {
    const formatPath = (
      path: string[],
      locale: string,
      item: ArticleQueryResult
    ): ArticleResult => ({
      params: {
        path,
      },
      locale,
      id: item.id,
      articleBody: '',
      articleTitle: item.articleTitle?.value ?? '',
      articleAuthor: '',
      articleCategory: '',
      imageFeatured: item.imageFeatured?.jsonValue ?? null,
      imageFallback: item.imageFallback?.jsonValue ?? null,
      publishedDate: item.publishedDate?.dateValue ?? null,
      lastUpdatedDate: null,
    });

    return this.fetchArticleSitemap(locales, formatPath, rootId);
  }

  /**
   * Fetch a flat list of all pages that are descendants of the specified root item and have a
   * version in the specified language(s).
   * @param {string[]} languages Fetch pages that have versions in this language(s).
   * @param {Function} formatStaticPath Function for transforming the raw search results into (@see StaticPath) types.
   * @returns list of pages
   * @throws {RangeError} if the list of languages is empty.
   * @throws {Error} if the app root was not found for the specified site and language.
   */
  protected async fetchArticleSitemap(
    languages: string[],
    formatStaticPath: (path: string[], language: string, item: ArticleQueryResult) => ArticleResult,
    rootId?: string
  ): Promise<ArticleResult[]> {
    if (!languages.length) {
      throw new RangeError(languageError);
    }

    // If the caller does not specify a root item ID, then we try to figure it out
    const rootItemId =
      rootId ||
      this.options.rootItemId ||
      (await getAppRootId(
        this.graphQLClient,
        this.options.siteName,
        languages[0],
        this.options.jssAppTemplateId
      ));

    if (!rootItemId) {
      throw new Error(queryError);
    }

    // Fetch paths using all locales
    const paths = await Promise.all(
      languages.map((language) => {
        debug.sitemap('fetching sitemap data for %s', language);
        return this.searchService
          .fetch(this.query, {
            rootItemId,
            language,
            pageSize: this.options.pageSize,
          })
          .then((results: ArticleQueryResult[]) => {
            return results.map((item) =>
              formatStaticPath(item.url.path.replace(/^\/|\/$/g, '').split('/'), language, item)
            );
          });
      })
    );

    // merge promises results into single result
    return ([] as ArticleResult[]).concat(...paths);
  }
}

export const mediaArticleSitemapFetcher = new MediaArticleGraphQLSitemapService({
  endpoint: config.graphQLEndpoint,
  apiKey: config.sitecoreApiKey,
  siteName: config.jssAppName,
  rootItemId: siteItemId,
});
