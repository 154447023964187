// Global
import { Link, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { LinkProps, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import NextLink from 'next/link';

// Lib
import useExperienceEditor from 'lib/sitecore/use-experience-editor';

// Components
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useEffect, useRef } from 'react';
import { isExternalLink } from 'lib/utils/is-external-link';
import { removeLandingFromUrl } from 'lib/utils/landing-page-utils';

export type LinkA11yWrapperProps = LinkProps & {
  linkClass?: string;
  srOnlyText?: string;
  suppressLinkText?: boolean | 'true' | 'false';
  suppressNewTabIcon?: boolean | 'true' | 'false';
  hideText?: boolean; // for when it shows up twice from button.tsx
  preventScroll?: boolean;
};

// Starts with '/' and does not contain '#'
// Hashes get escaped, which is not what we want
// Since the Sitecore Link implementation does not properly pass them as a separate
// Parameter to next/link.  This will treat links with hashs as regular links, not next/link.
const internalLinkMatcher = /^\/((?!(#)).)*$/;

const LinkA11yWrapper = ({
  field,
  linkClass,
  suppressNewTabIcon,
  srOnlyText,
  suppressLinkText,
  hideText,
  preventScroll,
  ...props
}: LinkA11yWrapperProps) => {
  const isExperienceEditor = useExperienceEditor();
  const context = useSitecoreContext();
  const span = useRef<HTMLAnchorElement>(null);

  // Normalize and make a copy so we aren't modifying the original object
  const asLinkField: LinkField = !field?.value
    ? { value: { ...field } }
    : ({ ...field, value: { ...field.value } } as LinkField);

  // Sitecore doesn't do tel: links correctly, it appends http to it.  Remove that.
  asLinkField.value.href = asLinkField.value.href?.replace('http://tel:', 'tel:');

  // Replace Landing.
  asLinkField.value.href = removeLandingFromUrl(asLinkField.value.href);

  asLinkField.value.href =
    asLinkField.value.anchor && asLinkField.value.linktype !== 'anchor'
      ? asLinkField.value.href + '#' + asLinkField.value.anchor
      : asLinkField.value.href;

  const showExternalIcon = suppressNewTabIcon ? false : true;
  const target = asLinkField?.value?.target;
  const text = suppressLinkText ? '' : asLinkField?.value?.text;
  const isExternal: boolean | undefined = isExternalLink(
    asLinkField.value.href,
    context.sitecoreContext?.internalUrlWhitelist
  );

  const isSitecoreExperienceEdge =
    asLinkField.value.href?.startsWith('http') &&
    asLinkField.value.href?.indexOf('edge.sitecorecloud.io') > 0;

  const handleModal = (e: Event) => {
    e.preventDefault();
    const href = e.target as HTMLAnchorElement;
    const modalId = decodeURIComponent(href?.getAttribute('data-modal-id') ?? '');

    const btnToBeClicked = document.querySelector(`[data-modal-target-id="${modalId}"]`);

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });

    btnToBeClicked?.dispatchEvent(clickEvent);
  };
  useEffect(() => {
    const link = span.current?.firstChild as HTMLAnchorElement;
    const modalId = link?.getAttribute('data-modal-id');
    if (modalId) {
      link?.addEventListener('click', handleModal);
      return () => {
        link?.removeEventListener('click', handleModal);
      };
    }
    return;
  }, [span.current]);

  // Don't print the link if not in experience editor and no data is present
  if (
    !asLinkField?.value?.text &&
    !asLinkField?.value?.href &&
    !isExperienceEditor &&
    !suppressLinkText
  ) {
    return <></>;
  }

  // EE sometimes strips out the anchor in links.  Ensure the link is correct in EE
  if (isExperienceEditor && asLinkField.value.href?.indexOf('http') === 0) {
    asLinkField.editableFirstPart = asLinkField.editableFirstPart?.replace(
      /href\=\"[^"]*\" ?/,
      `href="${asLinkField.value.href}" `
    );
  }

  const lang = asLinkField.value.href?.startsWith('/es') ? 'es' : 'en';

  // Gets any additional link props set on the back-end that aren't accounted for, e.g. data-modal-id
  const additionalLinkProps = getAdditionaLinkProps(asLinkField);

  return (
    <>
      {isExperienceEditor ? (
        <Link
          className={linkClass}
          field={asLinkField}
          internalLinkMatcher={internalLinkMatcher}
          showLinkTextWithChildrenPresent={false}
          {...props}
          {...additionalLinkProps}
        />
      ) : (
        <span ref={span}>
          <NextLink
            href={asLinkField.value.href ?? ''}
            scroll={!preventScroll}
            locale={lang}
            //https://developers.google.com/search/docs/crawling-indexing/qualify-outbound-links#:~:text=site%20and%20platform.-,rel%3D%22nofollow%22,txt%20disallow%20rule.
            rel={
              isSitecoreExperienceEdge ? 'nofollow' : isExternal ? 'noopener noreferrer' : undefined
            }
            className="next-link"
          >
            <Link
              className={linkClass}
              field={asLinkField}
              internalLinkMatcher={internalLinkMatcher}
              showLinkTextWithChildrenPresent={false}
              {...props}
              {...additionalLinkProps}
              //https://developers.google.com/search/docs/crawling-indexing/qualify-outbound-links#:~:text=site%20and%20platform.-,rel%3D%22nofollow%22,txt%20disallow%20rule.
              rel={
                isSitecoreExperienceEdge
                  ? 'nofollow'
                  : isExternal
                  ? 'noopener noreferrer'
                  : undefined
              }
            >
              {hideText ? '' : text}
              {props.children}
              {showExternalIcon && isExternal && (
                <>
                  <>&nbsp;</>
                  <HeroIcon
                    className={classNames('inline', 'ml-2', '-mt-1')}
                    icon="ArrowUpRightIcon"
                    size="em"
                  />
                </>
              )}
              {(target === '_blank' || srOnlyText) && (
                <span className="sr-only">
                  {srOnlyText && srOnlyText}
                  {target === '_blank' && ' (Opens in a new tab)'}
                </span>
              )}
            </Link>
          </NextLink>
        </span>
      )}
    </>
  );
};

export default LinkA11yWrapper;

function getAdditionaLinkProps(asLinkField: LinkField) {
  // Get the current props
  const additionalProps: Record<string, unknown> = { ...asLinkField.value };

  // Remove the known props
  delete additionalProps.anchor;
  delete additionalProps.class;
  delete additionalProps.href;
  delete additionalProps.id;
  delete additionalProps.linktype;
  delete additionalProps.linkDescriptionText;
  delete additionalProps.querystring;
  delete additionalProps.style;
  delete additionalProps.target;
  delete additionalProps.targetDisplayName;
  delete additionalProps.text;
  delete additionalProps.title;
  delete additionalProps.url;

  // Return any additional props
  return additionalProps;
}
