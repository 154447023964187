// Global
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

// Lib

// Local
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { getThemeClasses } from 'lib/get-theme';
import { Feature } from '.generated/templates/Feature.Items.model';
import { useRouter } from 'next/router';
import { NavigationMenuLink } from 'components/helpers/Header/header-types';
import MainNavMicrosite from 'components/helpers/Header/MainNavSection/MainNavMicrosite';
import HeaderLoginCta from 'components/helpers/Header/MainNavSection/HeaderLoginCta';
import SecondaryCta from 'components/helpers/Header/MainNavSection/SecondaryCta';
import TertiaryCta from 'components/helpers/Header/MainNavSection/TertiaryCta';
import HeaderLanguageToggle from 'components/helpers/Header/HeaderButtons/HeaderLanguageToggle';
import HeaderLogo from 'components/helpers/Header/MainNavSection/HeaderLogo';
import HeaderPhone from 'components/helpers/Header/HeaderButtons/HeaderPhone';
import HeaderHamburger from 'components/helpers/Header/HeaderButtons/HeaderHamburger';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export type HeaderBasicProps = Feature.Components.Header.Fields.HeaderBasic & {
  fields?: {
    mainNavLinks: NavigationMenuLink[];
    loginCta: NavigationMenuLink[];
    secondaryCta: NavigationMenuLink[];
    tertiaryCta: NavigationMenuLink[];
    secondaryCtaText: Field<string>;
    tertiaryCtaText: Field<string>;
  };
};

const HeaderBasic = ({ fields }: HeaderBasicProps): JSX.Element => {
  const [activeSecondaryNavItem, setActiveSecondaryNavItem] = useState('');
  const [isNavOpen, setIsNavOpen] = useState(false);

  const sitecoreContext = useSitecoreContext();

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const phoneNumber =
    sitecoreContext?.sitecoreContext?.phoneNumber?.fields?.phoneNumber?.value ||
    fields?.telephoneText?.value;
  const phoneNumberTTY = sitecoreContext?.sitecoreContext?.phoneNumber?.fields?.tty?.value;

  const route = useRouter();

  const onClickHamburgerButton = () => {
    const activePrimaryNavItemSubnav = document.querySelector('#navigation-primary button.isActive')
      ?.nextSibling as HTMLElement;
    const activeSecondaryNavItemSubnav = document.querySelector(
      '#navigation-secondary button.isActive'
    )?.nextSibling as HTMLElement;
    const body = document.querySelector('body') as HTMLElement;

    if (activePrimaryNavItemSubnav) activePrimaryNavItemSubnav.style.maxHeight = '';
    if (activeSecondaryNavItemSubnav) activeSecondaryNavItemSubnav.style.maxHeight = '';

    body.style.overflow = 'initial';
    setActiveSecondaryNavItem('');
    setIsNavOpen(!isNavOpen);
  };

  useEffect(() => {
    isNavOpen && onClickHamburgerButton();
  }, [route?.asPath]);

  return (
    <div>
      <div
        className={classNames(
          {
            'before:absolute': !fields.hideUtility?.value,
            'before:bg-blue-navy': !fields.hideUtility?.value,
            'before:content-[""]': !fields.hideUtility?.value,
            'before:block': !fields.hideUtility?.value,
            'before:h-12': !fields.hideUtility?.value,
            'before:left-0': !fields.hideUtility?.value,
            'before:top-0': !fields.hideUtility?.value,
            'before:w-full': !fields.hideUtility?.value,
            'pt-12': !fields.hideUtility?.value,
          },
          'relative',
          'md:flex'
        )}
        data-component="authorable/site/header"
      >
        {/*  Mobile version for Phone Number and  Login CTA */}
        {!fields.hideUtility?.value && (
          <div
            className={classNames(
              'flex',
              'md:hidden',
              'absolute',
              'bottom-[45%]',
              'items-center',
              'justify-between',
              'w-full',
              '-translate-y-1/4',
              'px-5',
              'h-12',
              'theme-blue--navy',
              'bg-theme-bg',
              'text-theme-text',
              'z-10'
            )}
          >
            <ConditionalRender condition={!!phoneNumber}>
              <HeaderPhone
                variant="mobile"
                phoneNumber={phoneNumber}
                phoneNumberTTY={phoneNumberTTY}
              />
            </ConditionalRender>
            <HeaderLoginCta fields={fields} variant="mobile" />
          </div>
        )}
        <div id="header-menubar" className={classNames('md:px-5', getThemeClasses('theme-white'))}>
          <HeaderLogo fields={fields} />

          {/*  Nav/Hamburger Button for Mobile Version  */}
          <HeaderHamburger onClick={() => onClickHamburgerButton()} isNavOpen={isNavOpen} />

          <div
            className={classNames('px-5', 'md:px-0', {
              isOpen: isNavOpen,
              isSlidOpen: activeSecondaryNavItem.length,
            })}
            id="header-flyout"
          >
            {/*  Main Navigation  */}
            <MainNavMicrosite fields={fields} />
            {!fields.hideUtility?.value && (
              <nav
                className={classNames('header-navList', 'md:px-12', 'md:flex', 'md:items-center')}
                id="navigation-tertiary"
                role="navigation"
              >
                <ul className={classNames('w-full', 'justify-end')}>
                  {/*  Phone Number Destktop Version  */}
                  <ConditionalRender condition={!!phoneNumber}>
                    <li className={classNames('hidden', 'md:list-item')}>
                      <HeaderPhone
                        variant="desktop"
                        phoneNumber={phoneNumber}
                        phoneNumberTTY={phoneNumberTTY}
                      />
                    </li>
                  </ConditionalRender>
                  <li>
                    <HeaderLanguageToggle fields={fields} />
                  </li>
                </ul>
              </nav>
            )}
            <nav id="navigation-footer" role="navigation" className={classNames('flex', 'gap-4')}>
              {/*  Login CTA for Desktop Version  */}
              <HeaderLoginCta fields={fields} variant="desktop" />

              {/*  Secondary CTA for Desktop Version  */}
              <SecondaryCta fields={fields} variant="desktop" />

              {/*  Tertiary CTA for Desktop Version  */}
              <TertiaryCta fields={fields} variant="desktop" />
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderBasic;
