import classNames from 'classnames';
import HeroIcon from '../../Icons/HeroIcon/HeroIcon';
import { ReactNode } from 'react';

export type FormCheckBoxProps = {
  disabled?: boolean;
  errorMessage?: string;
  isInvalid?: boolean;
  checked?: boolean;
  id?: string;
  name?: string;
  label?: string | ReactNode;
  labelClassName?: string;
  onChange?: (arg0: any) => void;
  preventLabelClick?: boolean;
};

const FormCheckBox = ({
  disabled,
  name,
  label,
  labelClassName,
  checked,
  onChange,
  id,
  isInvalid,
  errorMessage,
  preventLabelClick,
}: FormCheckBoxProps) => {
  const labelClasses = classNames(
    'block',
    'cursor-pointer',
    'ml-4',
    { 'pointer-events-none': preventLabelClick },
    'after:absolute',
    'after:cursor-pointer',
    'after:opacity-0',
    'after:left-0',
    'after:top-0',
    'after:transition-all',
    'after:w-6',
    'before:absolute',
    'before:bg-white',
    'before:border',
    'before:border-gray',
    'before:h-6',
    'before:left-0',
    'before:rounded-[6px]',
    'before:top-0',
    'before:transition-all',
    'before:w-6',
    'peer-checked/check-box:after:opacity-1',
    'peer-checked/check-box:before:bg-blue-navy',
    'peer-checked/check-box:before:border-blue-navy',
    {
      'peer-checked/check-box:after:opacity-1': isInvalid,
      'peer-checked/check-box:before:bg-error': isInvalid,
      'peer-checked/check-box:before:border-error': isInvalid,
      'before:border-error': isInvalid,
    },
    labelClassName
  );

  const inputClasses = classNames(
    'h-6',
    'w-6',
    'm-0',
    'appearance-none',
    'peer/check-box',
    'shrink-0',
    'cursor-pointer'
  );

  return (
    <div className={classNames('checkbox', 'relative', 'flex')}>
      <input
        className={inputClasses}
        disabled={disabled}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <div className={classNames('absolute', 'z-10', 'pointer-events-none', 'text-white')}>
        <HeroIcon size="xs" icon={'CheckIcon'} />
      </div>
      <label className={labelClasses} htmlFor={id}>
        {label}
      </label>
      <span
        className={classNames('text-xs', 'text-error', 'top-full', 'left-0', 'absolute', {
          hidden: !isInvalid,
          block: isInvalid,
        })}
      >
        {errorMessage}
      </span>
    </div>
  );
};

export default FormCheckBox;
