import { debug } from '@sitecore-jss/sitecore-jss';
import {
  GraphQLRequestClient,
  GraphQLSitemapServiceConfig,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { GraphQLClient, SearchQueryService } from '@sitecore-jss/sitecore-jss/graphql';

export abstract class BaseGraphQLSitemapService<TResult> {
  protected graphQLClient: GraphQLClient;
  protected searchService: SearchQueryService<TResult>;
  public options: GraphQLSitemapServiceConfig;

  /**
   * Gets the default query used for fetching the list of site pages
   */
  protected abstract get query(): string;

  /**
   * Creates an instance of graphQL sitemap service with the provided options
   * @param {GraphQLSitemapServiceConfig} options instance
   */
  constructor(options: GraphQLSitemapServiceConfig) {
    this.options = options;
    this.graphQLClient = this.getGraphQLClient();
    this.searchService = new SearchQueryService<TResult>(this.graphQLClient);
  }

  /**
   * Gets a GraphQL client that can make requests to the API. Uses graphql-request as the default
   * library for fetching graphql data (@see GraphQLRequestClient). Override this method if you
   * want to use something else.
   * @returns {GraphQLClient} implementation
   */
  protected getGraphQLClient(): GraphQLClient {
    return new GraphQLRequestClient(this.options.endpoint, {
      apiKey: this.options.apiKey,
      debugger: debug.sitemap,
    });
  }

  /**
   * Gets a service that can perform GraphQL "search" queries to fetch @see TResult
   * @returns {SearchQueryService<TResult>} the search query service
   */
  protected getSearchService(): SearchQueryService<TResult> {
    return new SearchQueryService<TResult>(this.graphQLClient);
  }
}
