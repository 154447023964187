// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Foundation } from '.generated/templates/Foundation.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import EditingHelpText from 'components/helpers/EditingHelpText';
import Heading from 'components/helpers/Heading/Heading';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';

export type ListingFormProps = Sitecore.Override.ComponentBase &
  Sitecore.Override.ItemEx & {
    fields: {
      root?: ListingFormMediaFolderType;
    };
  };

export type ListingFormMediaFolderType = Sitecore.Override.ItemEx &
  Feature.Data.DocumentListing.Fields.DocumentListingMediaFolder & {
    children: Sitecore.Override.ItemEx[];
  };

export type ListingFormDocumentDownload = {
  id: string;
  downloadText: string;
  url: string;
};

export type ListingFormDocument = Sitecore.Override.ItemEx &
  Foundation.Data.DocumentListing.Fields.DocumentListingMediaFields & {
    downloadList: {
      name: string;
      displayName: string;
      downloads: ListingFormDocumentDownload[];
    };
  };

const DocumentListing = ({ fields, rendering }: ListingFormProps): JSX.Element => {
  const listingFormMediaFolderTemplateId = '1649faa3-3946-4ae7-996a-6e7cdf2ee348';

  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const GetDocumentHtml = (folderItem: ListingFormMediaFolderType) => {
    return folderItem.children.map((element) => {
      const docItem = element as ListingFormDocument;
      return (
        <tr key={docItem?.id} className={classNames()}>
          <td className={classNames('font-bolder', 'py-5', 'align-top', 'space-y-1')}>
            {docItem?.downloadList?.name || docItem?.displayName}
          </td>
          <td className={classNames('py-5', 'space-y-1')}>
            {docItem?.downloadList?.downloads.map((e) => (
              <div key={e.id}>
                <LinkA11yWrapper
                  field={{ value: { href: e?.url, text: e.downloadText, target: '_blank' } }}
                />
              </div>
            ))}
          </td>
        </tr>
      );
    });
  };

  const GetFolderHtml = (folderItem: ListingFormMediaFolderType | undefined) => {
    if (!folderItem) {
      return null;
    }
    return (
      <>
        <Heading
          headingLevel="h2"
          headingSize="md"
          description={folderItem.fields?.description}
          headingText={folderItem.fields?.title}
          descriptionClassName={classNames('mb-6')}
        />
        <table className={classNames('table-fixed', 'w-full', 'text-left')}>
          <thead className={classNames('uppercase', 'text-gray-dark')}>
            <tr className={classNames('border-b-2', 'tracking-wider', 'border-blue-navy')}>
              <th scope="col" className={classNames('font-regular', 'py-3')}>
                {folderItem.fields?.column1Label?.value}
              </th>
              <th scope="col" className={classNames('font-regular', 'py-3')}>
                {folderItem.fields?.column2Label?.value}
              </th>
            </tr>
          </thead>
          <tbody className={classNames('divide-y', 'divide-gray')}>
            {GetDocumentHtml(folderItem)}
          </tbody>
        </table>
      </>
    );
  };

  const HasFolderChildren = (folderItem: ListingFormMediaFolderType | undefined) => {
    let returnVal = false;
    folderItem?.children.forEach((element) => {
      const scElement = element as Sitecore.Override.ItemEx;
      if (scElement?.templateId === listingFormMediaFolderTemplateId) {
        returnVal = true;
      }
    });
    return returnVal;
  };

  const hasFolderChildrenBool = HasFolderChildren(fields?.root);

  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/table/document-listing"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.root}
        text="Document Listing | No document folder selected . "
      />
      <EditingHelpText
        condition={!fields.root?.children?.length}
        text="Document Listing | No documents in folder. "
      />
      {!hasFolderChildrenBool ? GetFolderHtml(fields?.root) : null}

      {hasFolderChildrenBool
        ? fields?.root?.children.map((e) =>
            e.templateId === listingFormMediaFolderTemplateId ? (
              <div key={e.id}>{GetFolderHtml(e as ListingFormMediaFolderType)}</div>
            ) : null
          )
        : null}
    </Container>
  );
};

export default DocumentListing;
