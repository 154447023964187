// Components

import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ReactDOMClient from 'react-dom/client';
import Container from 'components/helpers/Container/Container';
import Heading from 'components/helpers/Heading/Heading';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { getArticleMetaData, getStoryImageUrls } from 'lib/utils/article-utils';
import Button from 'components/helpers/Button/Button';
import { useState } from 'react';

const ShowDescriptionButton = ({ desc }: { desc: HTMLDivElement }): JSX.Element => {
  const [descriptionHidden, setDescriptionHidden] = useState(true);
  return (
    <div aria-hidden="true" className={classNames('w-auto', 'my-8', 'text-center')}>
      <Button
        text={descriptionHidden ? 'Show Description' : 'Hide Description'}
        onClick={() => {
          desc.classList.toggle('sr-only', !descriptionHidden);
          setDescriptionHidden(!descriptionHidden);
        }}
        icon={descriptionHidden ? 'ChevronDownIcon' : 'ChevronUpIcon'}
        iconPosition="right"
        variant="secondary"
      />
    </div>
  );
};

export type HealthLibraryArticleProps = Sitecore.Override.ComponentBase;

const HealthLibraryArticle = ({ rendering }: HealthLibraryArticleProps): JSX.Element => {
  const context = useSitecoreContext();

  const story = context.sitecoreContext.healthLibraryArticle;

  if (!story) {
    return <></>;
  }

  const { category, publishedDate, updatedDate, publishedDateFormatted, updatedDateFormatted } =
    getArticleMetaData(story);

  const imgs = getStoryImageUrls(story);
  const storyBaseUrl = context.sitecoreContext.itemPath?.replace(/\/.+$/, '') || '';

  // We want to insert a show/hide description button around the description
  // We do this after the content renders
  const onContentChangeUseEffect = () => {
    // See if we have a description
    const desc = document.querySelector('.js-desc') as HTMLDivElement;
    if (!desc) {
      return;
    }

    // Create a div container for the button
    const container = document.createElement('div');
    // and insert it before our description element
    desc.parentElement && desc.parentElement.insertBefore(container, desc);

    // Create a render a react root inside the container div we just created
    const root = ReactDOMClient.createRoot(container);
    root.render(<ShowDescriptionButton desc={desc} />);

    // Cleanup
    return () => {
      root.unmount();
      container.remove();
    };
  };
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/article/health-library-article"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div className={classNames('pt-9', 'container-content-padding-x')}>
        {category && (
          <div
            className={classNames(
              'text-blue-navy',
              'text-sm',
              'flex',
              'gap-2',
              'items-center',
              'mb-3',
              'md:mb-5'
            )}
          >
            <SvgIcon size={'em'} icon="large-bullet" viewBox="0 0 12 13" />
            {category}
          </div>
        )}
        <h1 className={classNames('text-2xl', 'font-light', 'leading-11', 'mb-3')}>
          {story.Headline}
        </h1>
        <div
          className={classNames(
            'text-gray-dark',
            'text-sm',
            'flex',
            'gap-2',
            'mb-5',
            'items-center'
          )}
        >
          <span>{publishedDateFormatted}</span>
          {updatedDate && updatedDate > publishedDate ? (
            <>
              <span>|</span>
              <span className={classNames('italic')}> Updated:{` ${updatedDateFormatted}`}</span>
            </>
          ) : (
            ''
          )}
        </div>

        {/* For quizes and infographics we don't want to display an image because it's redundant
        So we check the regular image url.
        However we want to actually display the wide one because when it won't be stretched
        when we size it, and a size is required for NextImage which we don't know ahead of time
        for Health Library images. */}
        {imgs.imgUrl?.trim() ? (
          <div className={classNames('w-full', 'p-0', 'mb-8', 'text-center')}>
            <ImageWrapper
              field={{
                value: {
                  src: imgs.wideImgUrl,
                  alt: imgs.wideImgAlt,
                  width: '950px',
                  height: '500px',
                },
              }}
            />
          </div>
        ) : null}

        {story.Summary?.trim() ? (
          <Heading
            headingClassName="mb-4 italic"
            headingTextBold={{ value: story.Summary }}
            headingLevel="h2"
            headingSize="sm"
            descriptionClassName="mb-8"
            headingIsRichText
          />
        ) : null}

        <div className={classNames('p-0', 'mb-8')}>
          <RichTextA11yWrapper
            executeExternalScripts
            field={{
              value: story.Body,
            }}
            onContentChangeUseEffect={onContentChangeUseEffect}
            linkUrlReplace={[
              {
                regex: /\[BASEDOMAIN\]\/healthinfo\/Story\.aspx\?StoryId=/i,
                replacement: storyBaseUrl,
              },
              {
                regex: /\[BASEDOMAIN\]\/healthinfo\/\.\.\/interactives\/recipe\.aspx\?/i,
                replacement: storyBaseUrl + '?type=recipe&',
              },
              {
                regex: /\[BASEDOMAIN\]\/healthinfo\/\.\.\/interactives\//i,
                replacement: 'https://azblue.netreturns.biz/healthinfo/../interactives/',
              },
              // {
              //   regex: /\[BASEDOMAIN\]\//i,
              //   replacement: 'https://azblue.netreturns.biz/',
              // },
            ]}
            imageUrlReplace={[
              {
                regex: /\[BASEDOMAIN\]\//i,
                replacement: 'https://azblue.netreturns.biz/',
              },
            ]}
            iFrameUrlReplace={[
              {
                regex: /\[BASEDOMAIN\]\//i,
                replacement: 'https://azblue.netreturns.biz/',
              },
            ]}
            classNameReplace={[
              {
                regex: /nR_HL_igDesc/,
                replacement: 'js-desc sr-only bg-gray-light p-4',
              },
            ]}
          />
        </div>
      </div>
    </Container>
  );
};

export default HealthLibraryArticle;
