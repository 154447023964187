/**
 * Normalizes a guid format so they can be compared as strings.
 */
export const normalizeGuid = (guid?: string): string | undefined => {
  return guid?.toLowerCase().replace(/[\{\-\}]/g, '');
};

export const guidEquals = (guid1?: string, guid2?: string): boolean => {
  return normalizeGuid(guid1) === normalizeGuid(guid2);
};

export const guidIsOneOf = (guid: string | undefined, guidList: string[]): boolean => {
  return guidList.some((x) => normalizeGuid(x) === normalizeGuid(guid));
};

export const normalizeSitecoreDateString = (date: string): string => {
  // For fields that don't contain
  if (date.charAt(15) !== 'Z') {
    date = `${date}Z`;
  }

  const isValid = date.length === 16 && date.charAt(8) === 'T' && date.charAt(15) === 'Z';
  if (!isValid) {
    // If used with new Date, wil get 'Invalid Date'.
    console.warn(`Invalid date provided, ${date}. Valid Sitecore date string: 20211112T203919Z.`);
    return 'Invalid Date';
  }

  return date.replace(/(\w{4})(\w{2})(\w{5})(\w{2})(\w{2})/, '$1-$2-$3:$4:$5');
};

/**
 * Ensures that css number has a unit value (defaults to px if missing)
 * @param value numerical css value
 * @returns a valid css number string, or empty string
 */
export const addPxIfNeeded = (value: string) => {
  const number = parseFloat(value);

  if (isNaN(number)) {
    return '';
  }
  const unit = value.match(/%|em|px/)?.[0] ?? 'px';
  const output = number + unit;
  return output;
};
