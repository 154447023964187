import React, { useContext } from 'react';

export type SectionSize = 'wide' | 'normal' | 'half' | 'third' | 'two-thirds' | 'quarter';

export const SectionSizeContext = React.createContext<SectionSize>('normal');

const useSectionSizeContext = () => {
  const context = useContext(SectionSizeContext);

  return context;
};

export default useSectionSizeContext;
