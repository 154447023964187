// Components
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

const GTMScripts = (): JSX.Element => {
  const context = useSitecoreContext();
  const gtmDetails = context.sitecoreContext?.gtmDetails;

  // Fail out if we don't have any fields
  if (!gtmDetails?.id) {
    return <></>;
  }

  return (
    <>
      {/* Start: Google Tag Manager */}
      <div id="gtm">
        {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
        <Script
          id="gtag-base"
          strategy="beforeInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '${gtmDetails.parameters}';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${gtmDetails.id}');
            `,
          }}
        />

        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmDetails.id}${gtmDetails.parameters}`}
            height="0"
            width="0"
            style={{ display: 'none', visibility: 'hidden' }}
          />
        </noscript>
      </div>
      {/* End: Google Tag Manager */}
    </>
  );
};

export default GTMScripts;
