export const isExternalLinkForNewTab = (linkHref: string | undefined) => {
  //Rules for links
  //Links to external sites like azbluefacts.com or azbluefoundation.org are in a new tab

  //Links to AZBlue.com open in the same tab
  if (linkHref?.startsWith(process.env.PUBLIC_URL as string)) {
    return false;
  }
  //Links to AZBlue.com open in the same tab
  if (!linkHref?.startsWith('http')) {
    return false;
  }
  //PDFs open in a new tab also takes care of the PDF's that are in common.secure.azblue that dont end with .pdf
  return true;
};
