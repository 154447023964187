// Global
import {
  Image as JSSImage,
  ImageField,
  LayoutServicePageState,
  useSitecoreContext,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ImageFieldValue } from '@sitecore-jss/sitecore-jss-react';
import NextImage from 'next/image';
import ExternalSvgIcon from '../Icons/ExternalSvgIcon/ExternalSvgIcon';
import { nextConfigImages } from 'next.config.images';
import usePreviewData from 'lib/state/preview-context';

/**
 * JSS does not yet support Next Image in Exprience Editor
 * This component will switch between the two based on environment
 * which allows us to get the various performance benefits from Next Image
 *
 * Note that the images may display slightly differently in
 * Experience Editor as the JSS Image component doesn't have the same layout options
 */

export interface SizedImageField extends ImageField {
  value?: ImageFieldValue & {
    alt?: string;
    height: string;
    src?: string;
    width: string;
  };
}

type NextImageLayoutOption = 'intrinsic' | 'responsive' | 'fill';
type ObjectFitOption = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
type ObjectPositionOption = 'top' | 'bottom' | 'left' | 'right' | 'center';

interface AnyNextImage {
  alt: string;
  height?: string;
  layout: NextImageLayoutOption;
  objectFit?: ObjectFitOption;
  objectPosition?: ObjectPositionOption;
  priority?: boolean;
  src: string;
  width?: string;
}

export interface ImageWrapperProps {
  field?: SizedImageField;
  imageClass?: string;
  layout?: NextImageLayoutOption;
  objectPosition?: ObjectPositionOption;
  priority?: boolean;
  editable?: boolean;
  removeFill?: boolean;
  skipExternalSvg?: boolean;
}

export const resizeHeight = (field: ImageField, newHeight: number) => {
  return {
    value: {
      ...field.value,
      height: `${newHeight}px`,
      width:
        parseInt(field.value?.width ?? '0') * (newHeight / parseInt(field.value?.height ?? '1')) +
        'px',
    },
  } as SizedImageField;
};

export const resizeWidth = (field: ImageField, newWidth: number) => {
  return {
    value: {
      ...field.value,
      width: `${newWidth}px`,
      height:
        parseInt(field.value?.width ?? '0') * (newWidth / parseInt(field.value?.height ?? '1')) +
        'px',
    },
  } as SizedImageField;
};

const ImageWrapper = ({
  editable,
  field,
  imageClass,
  layout = 'intrinsic',
  priority,
  objectPosition,
  removeFill = false,
  skipExternalSvg,
}: ImageWrapperProps): JSX.Element => {
  const sitecoreContext = useSitecoreContext();

  const isPreview = usePreviewData();
  const pageState = sitecoreContext.sitecoreContext?.pageState ?? LayoutServicePageState.Normal;

  if (
    !skipExternalSvg &&
    field?.value?.extension === 'svg' &&
    pageState !== LayoutServicePageState.Edit
  ) {
    return <ExternalSvgIcon className={imageClass} field={field} removeFill={removeFill} />;
  }

  const validDomain =
    field?.value?.src?.startsWith('/') ||
    nextConfigImages?.domains?.some(
      (x) => (field?.value?.src?.toLowerCase().indexOf(x) ?? -1) >= 0
    );

  // Checking if we're in sitecore, even if we are not in experience editor
  // This supports EE and Preview
  const isInSitecore = /^https?:\/\/cm/.test(field?.value?.src ?? '');

  const publicUrl = new URL(process.env.PUBLIC_URL as string);

  // Adding a check to see if it's a local image, and if so we want to remove the domain
  // from the URL so it can work with Next Image
  // This comes up when we have images from Algolia which will be fully qualified
  // but should actually have be treated as an internal url
  let isLocalImage = false;
  let src = field?.value?.src || '';
  if (src) {
    const originalSrc = src;
    // If it's a fully qualified url, use it as is, otherwise include the public url
    const imageUrl = originalSrc.startsWith('http')
      ? new URL(originalSrc)
      : new URL(originalSrc, publicUrl);

    // If we're on localhost, treat external images as local.  Should work most of the time.
    // This is to handle images locally from Algolia since there is no local index
    isLocalImage =
      // publicUrl.origin === 'http://localhost:3000' ||
      publicUrl.origin === imageUrl.origin || isInSitecore;

    if (isLocalImage) {
      src = src.replace(imageUrl.origin, '');
    }
  }

  if (isPreview || isInSitecore || !validDomain) {
    return (
      <JSSImage
        className={imageClass}
        field={{ ...field, value: { ...field?.value, src: src } }}
        editable={editable}
      />
    );
  }

  // If the image has no value, return nothing
  if (!field?.value || !field?.value?.src) {
    return <></>;
  }

  const nextImageProps: AnyNextImage = {
    // Allowed domains for NextImage are case sensitive on Vercel even though it's not locally (at least on Windows)
    src: field.value.src.toLowerCase(),
    alt: field.value.alt || '',
    layout,
    priority,
  };

  if (layout === 'fill') {
    nextImageProps.objectFit = 'cover';
    nextImageProps.objectPosition = objectPosition;
  } else {
    nextImageProps.height = field.value.height;
    nextImageProps.width = field.value.width;
  }

  return <NextImage className={imageClass} {...nextImageProps} />;
};

export default ImageWrapper;
