import { Field } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import LinkA11yWrapper from '../../LinkA11yWrapper/LinkA11yWrapper';
import { NavigationMenuLink } from '../header-types';
import HeaderDropdown from '../HeaderDropdown';
import Button from 'components/helpers/Button/Button';

export type HeaderLoginCtaProps = {
  variant: 'mobile' | 'desktop';
  fields: {
    loginCtaText: Field<string>;
    loginCta: NavigationMenuLink[];
  };
};

const HeaderLoginCta = ({ fields, variant }: HeaderLoginCtaProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields?.loginCtaText?.value) {
    return <></>;
  }

  const additionalListClasses =
    variant === 'desktop'
      ? classNames('bottom-full', 'md:bottom-auto', 'md:mt-1', 'mb-10', 'md:mb-0')
      : classNames('-mt-1', 'text-white', 'text-sm');

  const triggerStyles =
    variant === 'mobile'
      ? classNames('header-navList-menuItem', 'text-sm', 'text-white', 'py-3', 'pl-2')
      : '';

  const dropdownStyles =
    variant === 'desktop' ? classNames('hidden', 'md:block') : classNames('block', 'md:hidden');

  return (
    <>
      {fields.loginCta.length < 2 ? (
        <Button
          field={fields.loginCta?.map((i) => i.fields?.Link)[0]}
          variant={variant === 'desktop' ? 'primary' : 'link'}
          size="small"
          text={fields.loginCtaText?.value}
          className={classNames({ 'text-sm': variant === 'mobile' })}
        />
      ) : (
        <HeaderDropdown
          className={dropdownStyles}
          label={fields.loginCtaText?.value}
          showButton={variant === 'desktop'}
          buttonVariant="primary"
          size="small"
          listStyles={classNames(
            'absolute',
            'login-menu-dropdown',
            'bg-white',
            'w-full',
            'py-4',
            'shadow-card',
            'rounded-lg',
            'border',
            'border-gray',
            additionalListClasses
          )}
          triggerStyles={triggerStyles}
        >
          {fields.loginCta?.map((i: NavigationMenuLink) => (
            <li key={i.id} className={classNames('hover:bg-gray', 'group')}>
              <LinkA11yWrapper
                className={classNames(
                  'header-navList-menuItem',
                  'flex',
                  'px-4',
                  'py-1',
                  'items-center',
                  'justify-between',
                  '!pointer-events-auto',
                  'text-blue-navy',
                  'group-hover:text-blue',
                  { 'text-sm': variant === 'mobile' }
                )}
                field={i.fields?.Link}
              />
            </li>
          ))}
        </HeaderDropdown>
      )}
    </>
  );
};

export default HeaderLoginCta;
