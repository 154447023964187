import Image from 'next/image';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { TestimonialWithLargeImageProps } from './TestimonialWithImage';
import classNames from 'classnames';

const LargeTestimonialImage = ({ fields }: TestimonialWithLargeImageProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <>
      {fields?.testimonialImage?.value?.src && (
        <span className="md:block hidden max-w-[824px]">
          <Image
            src={fields.testimonialImage.value.src}
            width="824"
            height="618"
            className={classNames('rounded-lg', 'max-w-[824px]')}
          ></Image>
        </span>
      )}
    </>
  );
};

export default LargeTestimonialImage;
