// Components
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import classNames from 'classnames';
import { HealthLibraryStory } from 'lib/server-utils/health-library';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getStoryImageUrls } from 'lib/utils/article-utils';
import { ArticleResult } from 'lib/sitecore/sitemap-fetcher/article-sitemap-service';

interface FeaturedArticleImageProps {
  featuredArticle: ArticleResult | HealthLibraryStory;
  sidebysideState: boolean;
}
const FeaturedArticleImage = ({
  featuredArticle,
  sidebysideState,
}: FeaturedArticleImageProps): JSX.Element => {
  const context = useSitecoreContext();

  const article = featuredArticle as ArticleResult;

  const { healthLibraryFeaturedArticle } = context.sitecoreContext;

  const images = healthLibraryFeaturedArticle
    ? getStoryImageUrls(healthLibraryFeaturedArticle)
    : undefined;
  const ogImgUrl: SizedImageField | null = images
    ? {
        value: {
          src: images.wideImgUrl,
          alt: images.wideImgAlt,
          width: '950px',
          height: '500px',
        },
      }
    : null;

  return (
    <>
      {sidebysideState ? (
        <>
          <div className={classNames('hidden', 'sm:block')}>
            <ImageWrapper
              layout="fill"
              field={ogImgUrl ?? (article?.imageFeatured as SizedImageField)}
              imageClass={classNames('w-full', '!h-full', 'object-cover')}
            />
          </div>
          <div className={classNames('block', 'sm:hidden')}>
            <ImageWrapper
              layout="responsive"
              field={ogImgUrl ?? (article?.imageFeatured as SizedImageField)}
              imageClass={classNames('w-full', '!h-full', 'object-cover')}
            />
          </div>
        </>
      ) : (
        <ImageWrapper
          layout="responsive"
          field={ogImgUrl ?? (article?.imageFeatured as SizedImageField)}
          imageClass={classNames('w-full', '!h-full', 'object-cover')}
        />
      )}
    </>
  );
};

export default FeaturedArticleImage;
