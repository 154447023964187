// data interface model
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';

export type MediaPromoPlacement = 'right' | 'left';

interface LargeMediaPromoProps extends Feature.Components.Promo.Fields.LargeMediaPromo {
  mediaPlacement: MediaPromoPlacement;
}

const LargeMediaPromoImage = ({ fields, mediaPlacement }: LargeMediaPromoProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <>
      <div className={classNames('hidden', 'md:block')}>
        <ImageWrapper
          layout="fill"
          field={fields.image as SizedImageField}
          imageClass={classNames('w-full', '!h-full', 'object-cover')}
        />
      </div>
      <div className={classNames('block', 'md:hidden')}>
        <ImageWrapper
          layout="responsive"
          field={fields.image as SizedImageField}
          imageClass={classNames('w-full', '!h-full', 'object-cover')}
        />
      </div>
      {(fields.overlayCircleImage?.value?.src || fields.overlayCircleText?.value) && (
        <div
          className={`w-[200px] h-[200px] overflow-hidden rounded-[100px] bg-btn-bg-primary z-20 py-10 px-6 absolute -bottom-[100px] md:-bottom-14 left-1/2 -translate-x-1/2 ${
            mediaPlacement === 'right' ? 'md:left-0' : 'md:left-full'
          } flex flex-col justify-evenly`}
        >
          {fields.overlayCircleImage?.value?.src && (
            <div className={`w-fit h-auto max-w-[84px] mx-auto`}>
              <ImageWrapper
                field={
                  {
                    ...fields.overlayCircleImage,
                    value: {
                      ...fields.overlayCircleImage.value,
                      width: fields?.overlayCircleImage?.value?.width
                        ? fields.overlayCircleImage.value.width
                        : '84px',
                      height: fields?.overlayCircleImage?.value?.height
                        ? fields.overlayCircleImage.value.height
                        : '84px',
                    },
                  } as SizedImageField
                }
                layout="intrinsic"
              />
            </div>
          )}
          {fields.overlayCircleText?.value && (
            <p className="text-center text-sm leading-tight">{fields.overlayCircleText?.value}</p>
          )}
        </div>
      )}
    </>
  );
};

export default LargeMediaPromoImage;
