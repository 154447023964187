import { buildSearchEngine } from '@coveo/headless';

const buildCoveoEngine = () => {
  return buildSearchEngine({
    configuration: {
      search: {
        searchHub: process.env.COVEO_SEARCH_HUB ?? '',
      },
      accessToken: process.env.COVEO_ACCESS_TOKEN ?? '',
      organizationId: process.env.COVEO_ORGANIZATION_ID ?? '',
    },
  });
};

let coveoEngine = buildCoveoEngine();

/** When we change pages, we want to create a new coveo engine to start fresh */
export const createNewCoveoEngine = () => {
  coveoEngine = buildCoveoEngine();
};

export const useCoveoEngine = () => {
  // Converted to a function in case we need to add custom logic here
  return coveoEngine;
};
