import classNames from 'classnames';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { withAtomicSearchInterface } from 'components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchInterface';

export type SearchContainerProps = Sitecore.Override.ComponentBase;

const SearchContainer = ({ rendering }: SearchContainerProps): JSX.Element => {
  const componentAnchorId = rendering?.uid;

  return (
    <div
      className="w-full relative anchorIdIndentifier"
      data-component="authorable/search/searchcontainer"
      id={componentAnchorId}
    >
      <div className={classNames('mx-auto', 'relative')}>
        <PlaceholderWrapper
          wrapperSize="normal"
          displayName="BCBSAZ-Search-Container"
          name="BCBSAZ-Search"
          rendering={rendering}
        />
      </div>
    </div>
  );
};

export default withAtomicSearchInterface(SearchContainer);
