// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import { getEnum } from 'lib/get-enum';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import IconBenefitsGridItem from 'components/authorable/general/IconBenefitsGrid/IconBenefitsGridItem';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';

export type IconBenefitsItemProps = Sitecore.Override.ItemEx &
  Feature.Components.General.Fields.IconBenefitsItem;

export type IconBenefitsGrid = Sitecore.Override.ItemEx &
  Feature.Components.General.Fields.IconBenefitsGrid & {
    children: IconBenefitsItemProps[];
  };

export type IconBenefitsGridProps = Sitecore.Override.ComponentBase & {
  fields: {
    root: IconBenefitsGrid;
  };
};

const IconBenefitsGrid = ({ fields, rendering }: IconBenefitsGridProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const iconBenefitGridData = fields.root.fields;
  const iconBenefitGridList = fields.root.children;
  const isEE = useExperienceEditor() && iconBenefitGridList.length < 0;
  const numberPerRow = getEnum(iconBenefitGridData.numberPerRow);
  const componentAnchorId = rendering?.uid;
  const iconAlign = getEnum(iconBenefitGridData.iconAlignment);
  return (
    <div
      data-component="authorable/general/iconbenefitsgrid"
      className={classNames('w-full icon-benefits-grid anchorIdIndentifier')}
      id={componentAnchorId}
    >
      <div className={classNames('p-0', 'mb-8')}>
        <RichTextA11yWrapper field={iconBenefitGridData?.description} editable />
      </div>
      <EditingHelpText
        block
        condition={isEE}
        text="IconBenefitsGrid: Add icon benefits as child items to datasource item"
      />
      <div
        className={classNames(
          'w-full max-w-5xl mb-6 mx-auto',
          'flex',
          `${numberPerRow === '2' ? 'flex-row' : 'flex-col'}`,
          'flex-wrap'
        )}
      >
        {iconBenefitGridList.length > 0 ? (
          iconBenefitGridList.map((icongridItem: IconBenefitsItemProps) => {
            return (
              <div
                className={classNames(
                  'w-full',
                  `${numberPerRow === '2' ? 'md:w-1/2' : ''}`,
                  'flex flex-row'
                )}
                key={icongridItem.id}
              >
                <IconBenefitsGridItem alignment={iconAlign} fields={icongridItem.fields} />
              </div>
            );
          })
        ) : (
          <></>
        )}
      </div>
      <CtaGroup {...iconBenefitGridData} />
    </div>
  );
};

export default IconBenefitsGrid;
