// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';
import EditingHelpText from 'components/helpers/EditingHelpText';
import Heading from 'components/helpers/Heading/Heading';
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import { getEnum } from 'lib/get-enum';
import { getThemeClasses } from 'lib/get-theme';

export type MultiColumnOverlayPromoProps = Sitecore.Override.ComponentBase &
  Feature.Components.Promo.Fields.MultiColumnOverlayPromo &
  Sitecore.Override.ItemEx;

const MultiColumnOverlayPromo = ({
  fields,
  rendering,
}: MultiColumnOverlayPromoProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const columns = fields.selectedCards.length.toString() as ColumnOptions;

  const renderPromo = () =>
    fields?.selectedCards.map((promo) => {
      const promoData = promo as Sitecore.Override.ItemEx & Feature.Data.Promo.Fields.ColumnPromo;
      return (
        <li
          className={classNames(
            'first:md:pl-0',
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-start',
            'md:py-0',
            'pt-5',
            'py-5',
            'shrink-0',
            { 'md:pl-9': columns === '2' },
            { 'md:pl-5': columns === '3' || columns === '4' }
          )}
          key={promoData.id}
        >
          <Heading
            headingText={promoData?.fields?.headline}
            headingClassName={classNames('!font-bold', 'mb-3')}
            headingLevel="h3"
            headingSize="sm"
            eyebrow={promoData?.fields?.eyebrowText}
            eyebrowClassName={classNames('mb-3', 'block')}
            description={promoData?.fields?.description}
            descriptionClassName={classNames('mb-4')}
          />
          <div className={classNames('mt-auto')}>
            <CtaGroup {...promoData.fields} />
          </div>
        </li>
      );
    });
  const componentAnchorId = rendering?.uid;
  return (
    <div className={classNames('w-full', 'container-content-padding-x', 'container-content-width')}>
      <div
        className={classNames(
          'md:px-0',
          'px-5',
          'py-3',
          'rounded-lg',
          {
            'md:py-12': columns === '2',
            'md:py-10': columns === '3' || columns === '4',
            'shadow-card': !fields.backgroundColor,
          },
          getThemeClasses(getEnum(fields.backgroundColor) || 'theme-white'),
          'anchorIdIndentifier'
        )}
        data-component="authorable/promo/multicolumnoverlay"
        id={componentAnchorId}
      >
        <EditingHelpText
          condition={!fields.selectedCards.length}
          text="Multi Column Overlap Promo | No Cards Selected. "
        />
        <Xup
          columnClasses={classNames(
            '!gap-y-0',
            'justify-center',
            'container-content-padding-x',
            {
              'divide-y': fields.showDivider.value,
              'divide-blue-sky': fields.showDivider.value,
              'md:divide-x': fields.showDivider.value,
              'md:divide-y-0': fields.showDivider.value,
            },
            {
              'mx-auto': columns === '2',
              'max-w-[1040px]': columns === '2',
              'md:-mx-2': columns === '3' || columns === '4',
              // '!px-0': columns === '3' || columns === '4',
            }
          )}
          columns={columns}
        >
          {renderPromo()}
        </Xup>
      </div>
    </div>
  );
};

export default MultiColumnOverlayPromo;
