import classNames from 'classnames';
import { UtilityDropDownMenu, NavigationMenuLink } from '../header-types';
import HeaderDropdown from '../HeaderDropdown';
import HeaderLink from '../HeaderLink';
import ConditionalRender from '../../ConditionalWrapper/ConditionalRender';
import Link from 'next/link';

export type HeaderUtilityDropDownMenuProps = {
  item?: UtilityDropDownMenu;
  link?: any;
  href?: URL;
};
const HeaderUtilityDropDownMenu = ({ item }: HeaderUtilityDropDownMenuProps) => {
  const isOneItem = item?.fields?.menuItems?.length;
  const isOneLink = isOneItem === 1;
  const oneLink = item?.fields?.heading?.value;
  const href = item?.fields?.menuItems[0]?.fields?.Link?.value?.href;

  return (
    <>
      {item?.fields?.menuItems?.length ? (
        <>
          {/* Desktop */}
          <ConditionalRender condition={!isOneLink}>
            <HeaderDropdown
              item={item}
              className={classNames('hidden', 'md:block')}
              triggerStyles={classNames(
                'font-semibold',
                'header-navList-menuItem',
                'hover:bg-btn-bg-secondary-hover',
                'hover:text-btn-text-secondary-hover',
                'text-btn-text-secondary',
                'px-8',
                'py-3'
              )}
              listStyles={classNames(
                // search blackout
                'after:opacity-0',
                'after:transition-opacity',
                'after:delay-75',
                'after:h-screen',
                'after:bg-black',
                'group-[.max-h-screen]:after:opacity-60',
                'after:w-full',
                'after:absolute',
                'after:left-0',
                'after:right-0',
                'after:top-full',
                'after:z-50',
                'after:pointer-events-none'
              )}
              label={item?.fields?.heading?.value}
            >
              {item?.fields?.menuItems?.map((subNavItem: NavigationMenuLink) => (
                <li key={subNavItem.id}>
                  <HeaderLink link={subNavItem?.fields?.Link} />
                </li>
              ))}
            </HeaderDropdown>
          </ConditionalRender>

          {/* Mobile */}

          {/* Is only one link in subnavigation */}
          <ConditionalRender condition={isOneLink}>
            <Link href={`${href}`}>
              <a
                className={classNames(
                  'text-gray-darkest',
                  'leading-12',
                  'mr-2.5',
                  'font-semibold',
                  'md:text-white',
                  'no-underline',
                  'md:text-sm',
                  'md:font-regular',
                  'md:my-0',
                  'md:leading-3'
                )}
              >
                {oneLink}
              </a>
            </Link>
          </ConditionalRender>

          {/* Is multipul links in sub navigation */}
          <ConditionalRender condition={!isOneLink}>
            <HeaderDropdown
              className={classNames('block', 'md:hidden')}
              triggerStyles={classNames(
                'font-semibold',
                'header-navList-menuItem',
                'hover:bg-btn-bg-secondary-hover',
                'hover:text-btn-text-secondary-hover',
                'text-btn-text-secondary',
                'px-8',
                'py-3'
              )}
              label={item?.fields?.heading?.value}
            >
              <>
                {item?.fields?.menuItems?.map((subNavItem: NavigationMenuLink) => (
                  <li key={subNavItem.id}>
                    <HeaderLink link={subNavItem?.fields?.Link} />
                  </li>
                ))}
              </>
            </HeaderDropdown>
          </ConditionalRender>
        </>
      ) : (
        <span className={classNames('header-navList-menuItem')}>
          {item?.fields?.heading?.value}
        </span>
      )}
    </>
  );
};

export default HeaderUtilityDropDownMenu;
