import { useRef, useEffect } from 'react';

export type DangerousHtmlProps = {
  html: string;
  triggerLoadEvent?: boolean;
  [htmlAttributes: string]: unknown;
};

/**
 * Use to render our HTML that can potentially contain external scripts
 * Be careful that the source of the html is trusted.
 * @returns
 */
export const DangerousHtml = ({ html, triggerLoadEvent, ...rest }: DangerousHtmlProps) => {
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!divRef.current || !html) return;

    // Create a 'tiny' document and parse the html string
    const slotHtml = document.createRange().createContextualFragment(html);

    const scripts = slotHtml.querySelectorAll('script');

    // Some scripts may depend on a "load" event which will have already happened.
    // If we want to re-trigger that event, do so on the last script.
    // Keep a list of all scripts so we can trigger after they all load
    const dynamicScriptPromises: Promise<void>[] = [];

    scripts.forEach((x, _index) => {
      // By default dynamically loaded scripts are async, which will break script order
      // Explicitly set it to false
      x.async = false;

      // We only want to do this for scripts with an 'src'.  Inline scripts don't have a load event
      if (triggerLoadEvent && x.src) {
        dynamicScriptPromises.push(
          // Wrap the onload in a promise so we can act after all scripts are loaded
          new Promise((resolve) => {
            x.onload = () => {
              resolve();
            };
          })
        );
      }
    });

    if (triggerLoadEvent) {
      const evt = new Event('load');

      if (dynamicScriptPromises.length) {
        // If we have dynamic external scripts, wait for them all to load
        Promise.all(dynamicScriptPromises).then(() => {
          window.dispatchEvent(evt);
        });
      } else {
        // Otherwise dispatch right away because Promise.all doesn't fire on an empty array
        window.dispatchEvent(evt);
      }
    }

    // Append the new content
    divRef.current.appendChild(slotHtml);

    return () => {
      if (divRef.current) {
        divRef.current.innerHTML = '';
      }
    };
  }, [html, divRef.current]);

  return <div {...rest} ref={divRef} />;
};
