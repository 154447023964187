// Components
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import Button from 'components/helpers/Button/Button';
import { useI18n } from 'next-localization';
import { getArticleMetaData } from 'lib/utils/article-utils';
import { guidEquals } from 'lib/string-utils';
import { TemplatesIds } from 'lib/sitecore/template-ids';
import { HealthLibraryStory } from 'lib/server-utils/health-library';
import { ArticleResult } from 'lib/sitecore/sitemap-fetcher/article-sitemap-service';

interface FeaturedArticleTextProps {
  featuredArticle: ArticleResult | HealthLibraryStory | undefined;
}

const FeaturedArticleText = ({ featuredArticle }: FeaturedArticleTextProps): JSX.Element => {
  const i18n = useI18n();
  const context = useSitecoreContext();
  const article = featuredArticle as ArticleResult;
  const story = featuredArticle as HealthLibraryStory;

  const { category, publishedDate, updatedDate, publishedDateFormatted, updatedDateFormatted } =
    getArticleMetaData(article);

  let articleUrl = '/' + article.params?.path.join('/');
  if (
    story.HIStoryID &&
    guidEquals(context.sitecoreContext.route?.templateId, TemplatesIds.HealthLibraryFeed)
  ) {
    articleUrl = `${context.sitecoreContext.itemPath}/${story.HIStoryID}`;
  }
  const readMoreCTA = {
    value: {
      href: articleUrl,
      text: i18n.t('Read More'),
      linktype: 'internal',
      class: 'w-auto',
      targetDisplayName: i18n.t('Read More'),
      linkDescriptionText: i18n.t('Read More'),
    },
  };

  return (
    <div className={classNames('w-full h-full', 'flex', 'flex-col', 'justify-center')}>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'md:flex-row',
          'md:flex-wrap',
          'md:justify-between',
          'mb-4'
        )}
      >
        {category && (
          <div
            className={classNames(
              'text-blue-navy',
              'text-sm',
              'flex',
              'gap-2',
              'items-center',
              'mb-3',
              'uppercase',
              'md:mb-5'
            )}
          >
            <SvgIcon size={'em'} icon="large-bullet" viewBox="0 0 12 13" />
            {category}
          </div>
        )}
        <div
          className={classNames(
            'text-gray-dark',
            'text-sm',
            'flex',
            'flex-wrap',
            'gap-2',
            'mb-3',
            'md:mb-5',
            'items-center'
          )}
        >
          <span>{publishedDateFormatted}</span>
          {updatedDate && updatedDate > publishedDate ? (
            <>
              <span>|</span>
              <span className={classNames('italic')}> Updated:{` ${updatedDateFormatted}`}</span>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <h2 className={classNames('text-xl md:text-2xl', 'font-light', 'leading-11', 'mb-6')}>
        <Text field={{ value: article?.articleTitle ?? story.Headline ?? '' }} />
      </h2>
      <div className={classNames('w-auto', 'mb-8')}>
        <Button
          field={readMoreCTA}
          icon="ChevronRightIcon"
          iconPosition="right"
          variant="primary"
          aria-label={`${i18n.t('Read More')}: ${article?.articleTitle}`}
        />
      </div>
    </div>
  );
};

export default FeaturedArticleText;
