import classNames from 'classnames';

import {
  AtomicResultDate,
  AtomicResultFieldsList,
  AtomicResultImage,
  AtomicResultLink,
  AtomicResultSectionBottomMetadata,
  AtomicResultSectionTitle,
  AtomicResultSectionVisual,
} from '@coveo/atomic-react';
import { Result, buildInteractiveResult } from '@coveo/headless';
import SvgIcon from '../../Icons/SvgIcon/SvgIcon';
import HeroIcon from '../../Icons/HeroIcon/HeroIcon';
import { theme } from '../../../../tailwind.config';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { i18nProps } from 'components/authorable/article/ArticleListing/ArticleListing';

const AzBlueAtomicSearchArticleResults = (result: Result, i18n: i18nProps): JSX.Element => {
  const articleResultsStyles = ` 
  atomic-result-image > img{
      height: 100% ! important;
    }
    .bg-white{
      background: ${theme.colors.white}
    }
    .result-root{
      border-radius: 8px;
      display: flex !important;
      flex-direction: column !important;
      height: 100%;
      width: 100%
    }
    .result-root.with-sections.display-list.density-normal atomic-result-section-visual {
      margin-right: 0 !important;
      width: 100% !important;
    }
  
    .result-root.with-sections.display-list.density-normal atomic-result-section-visual > div {
      padding-top: 40px;
    }
  
    .result-root.with-sections.display-list.density-normal atomic-result-section-badges{
      margin-bottom: 0 !important;
    }
    atomic-result-image img {
      object-fit: cover !important;
    }
    atomic-result-text{
      font-size: 24px !important;
      font-weight: 400 !important;
    }
    atomic-result-link a,
    atomic-result-link a:hover {
      color: ${theme.colors.gray.darkest} !important;
    }
    atomic-result-text {
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; 
    }
    .article-result-cta {
      align-items: center;
      align-self: flex-start;
      background: transparent;
      border-radius: 9999px;
      box-shadow: inset 0 0 0 1px ${theme.colors.blue.navy};
      color: ${theme.colors.blue.navy};
      display: inline-flex;
      font-size: 14px;
      font-weight: 600;
      justify-content: center;
      margin-bottom: 24px;
      margin-top: auto;
      padding: 16px 40px 16px 40px;
      text-align: center;
      transition: all .2s ease-in-out;
      vertical-align: middle;
      white-space: pre;
      width: auto;
    }
  
    .article-result-cta:hover {
      box-shadow: inset 0 0 0 2px ${theme.colors.blue.navyDark};
      color: ${theme.colors.blue.navyDark};
    }
    .hover-arrow-icon {
      fill: currentcolor;
      position: relative;
      stroke: currentcolor;
      stroke-width: 0;
    }
  
    .hover-arrow-icon g {
      transform: translateX(50%);
      transform-box: fill-box;
    }
  
    .hover-arrow-icon--stem,
    .hover-arrow-icon--tip {
      transform-origin: center center;
      transition: all 150ms ease-in;
    }
  
    .hover-arrow-icon--stem {
      clip-rule: evenodd;
      fill-rule: evenodd;
      opacity: 0;
      transform: translate(0, 45.5%);
      transform-box: view-box;
    }
  
    .hover-arrow-icon--tip {
      transform: translate(20%, 50%);
      transform-box: fill-box;
    }
  
    .hover-arrow-icon:hover .hover-arrow-icon--stem {
      opacity: 1;
    }
  
    .hover-arrow-icon:hover .hover-arrow-icon--tip {
      transform: translate(66%, 50%);
    }  
  `;
  const coveoEngine = useCoveoEngine();
  const interactiveResult = buildInteractiveResult(coveoEngine, {
    options: { result: result },
  });

  const articleCategory = result.raw.article_category?.join() || '';

  const tag = result.raw.source_outlet ? result.raw.source_outlet : articleCategory;

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'bg-white',
        'border',
        'h-full',
        'w-full',
        'border-gray',
        'overflow-hidden'
      )}
      style={{ borderRadius: '8px' }}
    >
      <style>{articleResultsStyles}</style>
      <AtomicResultSectionVisual className={classNames('w-full', '!mr-0')}>
        <AtomicResultImage className={classNames('w-full', '!mr-0')} field="image_url" />
      </AtomicResultSectionVisual>
      <div className={classNames('flex', 'flex-col', 'flex-grow', 'shrink-0', 'px-6')}>
        <>
          <div
            className={classNames(
              'flex',
              'items-center',
              'justify-between',
              'mb-2',
              'mt-10',
              'text-xs'
            )}
          >
            {tag ? (
              <div className={classNames('flex', 'items-center', 'justify-between')}>
                <span style={{ color: '#0B3B60' }}>
                  <SvgIcon size={'xxs'} icon="large-bullet" viewBox="0 0 12 13" />
                </span>
                <div className={classNames('ml-2')}>
                  <>{tag}</>
                </div>
              </div>
            ) : (
              <></>
            )}

            <AtomicResultDate
              field={
                result.raw.updated_date ?? -1 > (result.raw.published_date ?? 1)
                  ? 'updated_date'
                  : 'published_date'
              }
              className={classNames('text-sm')}
              format="ddd MMM D YYYY"
            />
          </div>
          <AtomicResultSectionTitle
            className={classNames('text-xl', 'text-gray-darkest', 'hover:text-gray-darkest')}
          >
            <AtomicResultLink />
          </AtomicResultSectionTitle>
          <a
            href={result.uri}
            className="article-result-cta hover-arrow-icon"
            onClick={() => interactiveResult.select()}
            onTouchStart={() => interactiveResult.beginDelayedSelect()}
            onTouchEnd={() => interactiveResult.cancelPendingSelect()}
            aria-label={`${i18n.ReadMore}: ${result.title}`}
          >
            Read More
            {!result.raw.source_outlet ? (
              <SvgIcon size="xs" viewBox={'0 0 16 16'} icon="hover-arrow" />
            ) : (
              <HeroIcon size="em" icon={'ArrowUpRightIcon'} />
            )}
          </a>
          <AtomicResultSectionBottomMetadata>
            <AtomicResultFieldsList></AtomicResultFieldsList>
          </AtomicResultSectionBottomMetadata>
        </>
      </div>
    </div>
  );
};

export default AzBlueAtomicSearchArticleResults;
