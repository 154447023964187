const nextConfigImages = {
  dangerouslyAllowSVG: true,
  domains: [
    'azblue.netreturns.biz',
    'assets.azblue.com',
    'stage.assets.azblue.com',
    'edge.sitecorecloud.io',
    'cm.azblue.localhost',
    'cm.development.azblue.com',
    'cm.staging.azblue.com',
    'cm.prod.azblue.com',
    'via.placeholder.com',
  ],
};

module.exports = {
  nextConfigImages,
};
