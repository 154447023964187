// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { getUrlPath } from 'lib/utils/string-utils';

export type ToggleNavigationItemProps = Sitecore.Override.ItemEx &
  Feature.Components.Navigation.Fields.ToggleItem;
export type ToggleNavigationProps = Sitecore.Override.ComponentBase & {
  fields?: {
    root?: Feature.Components.Navigation.Fields.ToggleNavigation & {
      children?: ToggleNavigationItemProps[];
    };
  };
};

const ToggleNavigation = ({ fields, rendering }: ToggleNavigationProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const router = useRouter();
  const componentAnchorId = rendering?.uid;

  return (
    <div
      data-component="authorable/navigation/togglenavigation"
      className={classNames('w-full px-5 md:py-16 anchorIdIndentifier')}
      id={componentAnchorId}
    >
      <div
        className={classNames(
          'w-full sm:w-fit',
          'mx-auto',
          'flex flex-col sm:flex-row text-center items-center',
          'space-x-0 sm:space-x-2',
          'space-y-2 sm:space-y-0'
        )}
      >
        <Text
          tag="div"
          field={fields?.root?.fields?.label}
          editable={true}
          className={classNames('w-full sm:w-fit', 'text-base leading-4 font-bold text-gray-dark')}
        />

        <div
          className={classNames(
            'w-full sm:w-fit',
            'p-0 sm:p-0.5',
            'bg-transparent sm:bg-gray-light',
            'sm:border border-gray rounded-full'
          )}
        >
          {fields?.root?.children && (
            <ul
              className={classNames(
                'w-full sm:w-fit',
                'flex flex-col sm:flex-row',
                'space-y-2 sm:space-x-2 sm:space-y-0'
              )}
            >
              {fields?.root?.children.map((item: ToggleNavigationItemProps) => {
                const lnkPath = getUrlPath(item.fields?.toggleLink?.value?.href);

                const activeButtonClass =
                  getUrlPath(router?.asPath) === lnkPath
                    ? 'bg-blue-navy text-white'
                    : 'bg-gray-light sm:bg-transparent text-gray-dark';
                return (
                  <li className={classNames('text-base font-bold')} key={item.id}>
                    <LinkA11yWrapper
                      field={item.fields.toggleLink}
                      suppressNewTabIcon
                      preventScroll
                      className={classNames(
                        'w-full h-full sm:w-fit',
                        'block px-6 py-3',
                        'rounded-full',
                        'border border-gray rounded-[100px] sm:border-0',
                        'no-underline',
                        activeButtonClass,
                        'hover:bg-blue-navy hover:text-white'
                      )}
                    />
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default ToggleNavigation;
