// Components
import classNames from 'classnames';
import { getEnum } from 'lib/get-enum';
import Button from 'components/helpers/Button/Button';
import {
  ButtonIconNames,
  ButtonIconPosition,
} from 'components/helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Feature } from '.generated/templates/Feature.Items.model';
import { DateTime } from 'luxon';
import React, { useLayoutEffect, useRef, useState } from 'react';
import useWindowDimensions from 'components/helpers/WindowsSize/WindowSize';

export type AlertMessageItemProps = Feature.Data.AlertMessage.Fields.AlertMessage;

const AlertMessageItem = ({ fields }: AlertMessageItemProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  const alertStartDate = +DateTime.fromISO(fields.startDateTime.value);
  const alertEndDate = +DateTime.fromISO(fields.endDateTime.value);
  const currentDate = +DateTime.fromISO(DateTime.local().toString());
  const isAlertVisible =
    currentDate >= alertStartDate && currentDate <= alertEndDate
      ? true
      : alertStartDate === -62135596800000 && currentDate < alertEndDate
      ? true
      : currentDate > alertStartDate && alertEndDate === -62135596800000
      ? true
      : alertEndDate === -62135596800000 && alertStartDate === -62135596800000
      ? true
      : false;

  if (!isAlertVisible) {
    return <></>;
  }

  const wrapperClasses =
    getEnum(fields?.typeStyling) === 'warning'
      ? 'bg-warning text-warning-color border-warning-dark alert-warning'
      : 'bg-info border-info-dark text-info-color alert-info';
  const messageClasses =
    getEnum(fields?.typeStyling) === 'warning' ? 'rte text-warning-color' : 'rte text-info-color';
  const alertIcon = getEnum(fields?.typeStyling) === 'warning' ? 'warning' : 'info';
  const iconPrimary = getEnum<ButtonIconNames>(fields?.primaryCtaIcon);
  const iconPositionPrimary = getEnum<ButtonIconPosition>(fields?.primaryCtaIconLocation);
  const centerAlign = fields?.centerAlignAlert?.value;
  const contentRef = useRef<HTMLSpanElement>(null);
  const [showOverflow, setShowOverflow] = useState(false);

  const [showOverflowButton, setShowOverflowButton] = useState(false);

  const [alertContentHeight, setAlertContentHeight] = useState(0);

  const { width } = useWindowDimensions();

  useLayoutEffect(() => {
    if (contentRef.current) {
      const handleResize = () => {
        const pastAlertContentHeight = alertContentHeight;
        const currentAlertContentHeight = contentRef?.current?.offsetHeight ?? 49;
        setAlertContentHeight(currentAlertContentHeight);

        if (pastAlertContentHeight > 48) {
          setShowOverflow(true);
          setShowOverflowButton(true);
        } else {
          setShowOverflowButton(false);
          setShowOverflow(false);
        }
      };
      handleResize();
      window.addEventListener('resize', handleResize);
    }
  }, [width]);

  return (
    <div className={classNames(wrapperClasses, 'w-full border-t-2 py-0 px-5')}>
      <div
        className={classNames(
          'mx-auto w-full container-content-width w-screen-content lg:px-5 flex flex-col lg:flex-row justify-items-start sm:justify-between items-start sm:items-center'
        )}
      >
        <div
          className={classNames(
            'w-full',
            'lg:w-[80.5%]',
            'min-h-[32px]',
            'pt-2',
            'pb-2',
            'max-w-6xl',
            'ml-8',
            'flex',
            'flex-row',
            'justify-items-start',
            'text-base',
            'items-center',
            { 'justify-center': centerAlign === true }
          )}
        >
          <div
            className={classNames('icon', {
              'text-warning-dark': getEnum(fields?.typeStyling) === 'warning',
              'text-info-dark': getEnum(fields?.typeStyling) === 'informational',
            })}
          >
            <SvgIcon
              size={'sm'}
              icon={alertIcon}
              viewBox="0 0 32 32"
              className="mr-4 w-[1.4rem] mt-[0.05rem]"
            />
          </div>

          <div className={classNames(messageClasses)}>
            {/* <h6> */}
            <span
              ref={contentRef}
              className={classNames('text-base', {
                'text-ellipsis line-clamp-2 ': showOverflow,
                'overflow-visible': !showOverflow,
              })}
            >
              <RichTextA11yWrapper field={fields?.message} editable={false} />
            </span>

            {showOverflowButton && (
              <button
                className={classNames('text-base', 'font-bold', 'text-blue', {})}
                onClick={() => setShowOverflow(!showOverflow)}
              >
                {showOverflow ? '...Show More' : '...Show Less'}
              </button>
            )}
            {/* </h6> */}
          </div>
        </div>
        {fields?.primaryCta?.value?.text && (
          <div
            className={classNames(
              'w-full',
              'lg:w-1/6',
              'flex',
              'justify-items-start',
              'sm:justify-center'
            )}
          >
            <div className={`w-fit ${iconPositionPrimary === 'right' ? 'pr-2' : ''}`}>
              <Button
                field={fields?.primaryCta}
                icon={iconPrimary}
                iconPosition={iconPositionPrimary}
                variant={getEnum(fields?.primaryCtaStyle)}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AlertMessageItem;
