import classNames from 'classnames';
import Button, { ButtonProps } from 'components/helpers/Button/Button';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';

interface HeaderDropdownProps {
  className?: string;
  label: string;
  listStyles?: string;
  triggerStyles?: string;
  children: React.ReactNode;
  showButton?: boolean;
  buttonVariant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  item?: any;
}

const HeaderDropdown = ({
  className,
  label,
  listStyles,
  triggerStyles,
  children,
  showButton,
  buttonVariant,
  size,
  item,
}: HeaderDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAnimate, setIsOpenAnimate] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const isOneItem = item?.fields.menuItems.length.toString();
  const isOneLink = isOneItem === 1;

  const setIsOpenHelper = (newValue: boolean) => {
    setIsOpen(newValue);
    setTimeout(() => setIsOpenAnimate(newValue), 0);
  };
  const onMouseDown = useCallback(
    (e: Event) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        closeDropdown();
      }
    },
    [containerRef, setIsOpen]
  );

  const toggleIsOpen = () => {
    setIsOpenHelper(!isOpen);
  };

  const closeDropdown = () => setIsOpenHelper(false);

  useEffect(() => {
    window.addEventListener('resize', closeDropdown);
    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('resize', closeDropdown);
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  useEffect(() => {
    closeDropdown();
  }, [router?.asPath]);
  return (
    <>
      {/* Only link in sub-navigation */}
      <ConditionalRender condition={isOneLink}>
        <div ref={containerRef} className={className}>
          {showButton ? (
            <Button
              variant={buttonVariant}
              text={label}
              size={size}
              onClick={toggleIsOpen}
              icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              iconSize="xs"
            />
          ) : (
            <button
              className={classNames(
                triggerStyles,
                'align-middle',
                'duration-200',
                'ease-in-out',
                'inline-flex',
                'items-center',
                'transition-all',
                'min-w-[72px]',
                'text-left',
                '!leading-tight',
                'align-center',
                { 'menu-open': isOpen }
              )}
              onClick={toggleIsOpen}
            >
              {label}
              <span className={classNames('ml-0', '!md:ml-0')}>
                <HeroIcon icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'} size={'em'} />
              </span>
            </button>
          )}
          <ConditionalRender condition={isOpen}>
            <div
              className={classNames('transition-maxHeight', 'duration-200', 'relative', 'group', {
                'overflow-hidden': !isOpenAnimate,
                'overflow-visible': isOpenAnimate,
                'max-h-0': !isOpenAnimate,
                'max-h-screen': isOpenAnimate,
              })}
            >
              <ul className={listStyles}>{children}</ul>
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
      {/* More than one link in sub-navigation */}
      <ConditionalRender condition={!isOneLink}>
        <div ref={containerRef} className={className}>
          {showButton ? (
            <Button
              variant={buttonVariant}
              text={label}
              size={size}
              onClick={toggleIsOpen}
              icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              iconSize="xs"
            />
          ) : (
            <button
              className={classNames(
                triggerStyles,
                'align-middle',
                'duration-200',
                'ease-in-out',
                'inline-flex',
                'items-center',
                'transition-all',
                '!min-w-[75px]',
                'md:min-w-full',
                'text-left',
                '!leading-tight',
                'align-center',
                { 'menu-open': isOpen }
              )}
              onClick={toggleIsOpen}
            >
              {label}
              <span className={classNames('ml-2')}>
                <HeroIcon icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'} size={'em'} />
              </span>
            </button>
          )}
          <ConditionalRender condition={isOpen}>
            <div
              className={classNames('transition-maxHeight', 'duration-200', 'relative', 'group', {
                'overflow-hidden': !isOpenAnimate,
                'overflow-visible': isOpenAnimate,
                'max-h-0': !isOpenAnimate,
                'max-h-screen': isOpenAnimate,
              })}
            >
              <ul className={listStyles}>{children}</ul>
            </div>
          </ConditionalRender>
        </div>
      </ConditionalRender>
    </>
  );
};

export default HeaderDropdown;
