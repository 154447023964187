// Global
import classnames from 'classnames';
import dynamic from 'next/dynamic';
import { IconSize, iconSizeClasses } from '../ContentMangedIcon/ContentMangedIcon';

/**
 * Standardize SVG icons on a 48x48 grid to allow
 * for consistent use across the project
 *
 * Icon contents should be stored in the icons subdirectory
 * using the naming scheme 'icon--[name].tsx'
 */

export const ALL_ICON_TYPES = [
  'call-us',
  'email',
  'hover-arrow',
  'info',
  'phone',
  'large-bullet',
  'play-button',
  'quote',
  'social-facebook',
  'social-instagram',
  'social-linkedin',
  'social-twitter',
  'social-youtube',
  'social-tiktok',
  'warning',
];

export type SvgIconsTuple = typeof ALL_ICON_TYPES;

export type SvgIconNames = SvgIconsTuple[number];

export type SvgIconProps = {
  className?: string;
  icon?: SvgIconNames;
  size?: IconSize;
  viewBox?: string;
  style?: React.CSSProperties;
};

export function isSvgIcon(icon: string) {
  return ALL_ICON_TYPES.includes(icon);
}

const SvgIcon = ({
  className,
  icon,
  size = 'em',
  viewBox = '0 0 48 48',
  style,
}: SvgIconProps): JSX.Element => {
  if (!icon || !isSvgIcon(icon)) return <></>;

  const IconContent = dynamic(() => import(`./icons/icon--${icon}`));

  return (
    <svg
      className={classnames(iconSizeClasses[size], className)}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <IconContent />
    </svg>
  );
};

export default SvgIcon;
