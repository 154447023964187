// Components
import Container from 'components/helpers/Container/Container';
import classNames from 'classnames';
import Xup from 'components/helpers/Xup/Xup';
import EditingHelpText from 'components/helpers/EditingHelpText';
import FAQCategoryCard, { FAQCategoryItem } from 'components/helpers/cards/FAQCategoryCard';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { buildFacet, FacetOptions } from '@coveo/headless';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { buildContext } from '@coveo/headless';
import { useEffect, useState } from 'react';

export type FAQCategoryListingProps = Sitecore.Override.ComponentBase &
  Feature.Components.Faqs.Fields.FaqCategoryListing & {
    fields?: {
      selectedItems: FAQCategoryItem[];
    };
  };

const FAQCategoryListing = ({ fields, rendering }: FAQCategoryListingProps): JSX.Element => {
  // Facet Manager
  const engine = useCoveoEngine();
  const options: FacetOptions = { facetId: 'taxonomy_audience_1', field: 'taxonomy_audience' };
  const controller = buildFacet(engine, { options });
  const context = buildContext(engine);
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  // Filter are applied in Coveo Query pipeline based on Context conditions
  useEffect(() => {
    context.set({ ...context.state.values, component: 'faq' });
  }, []);

  // Fail out if we don't have any fields

  if (!fields) {
    return <></>;
  }

  const renderList = () =>
    fields?.selectedItems.map((i) => {
      const card = i as FAQCategoryItem;
      const count = state?.values?.find(
        (x) => x?.value === card?.fields?.title?.value
      )?.numberOfResults;

      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'mb-5',
            'md:mb-0',
            'shrink-0'
          )}
          key={card.id}
        >
          <FAQCategoryCard {...card} url={fields.searchPage?.url} numReferrers={count ?? 0} />
        </li>
      );
    });
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/faqs/faqcategorylisting"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.selectedItems.length}
        text="FAQ Category Listing | No Categories Selected. "
      />
      <Xup columns={'3'}>{renderList()}</Xup>
    </Container>
  );
};
export default FAQCategoryListing;
