// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/ConditionalWrapper';
import Container from 'components/helpers/Container/Container';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { HeadingLevels } from 'components/helpers/Heading/Heading';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import { getEnum } from 'lib/get-enum';
import { useEffect, useState } from 'react';

export type HeadlineProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.Headline;

const Headline = ({ fields, rendering }: HeadlineProps): JSX.Element => {
  const [ispageSectionWrapper, setPageSectionWrapper] = useState(true);

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  useEffect(() => {
    const currentComponent = document.querySelector(
      '[data-component="authorable/general/headline"]'
    );

    setPageSectionWrapper(
      currentComponent?.closest('[data-component="authorable/layout/layout-page-section"]') !== null
        ? true
        : false
    );
  }, []);
  const headlineLevelValue = getEnum<HeadingLevels>(fields.headlineLevel) || 'h3';
  const headingSpaceClasses = ispageSectionWrapper
    ? classNames('py-12')
    : classNames('pt-6 sm:py-0');
  let headlineSizeValue;

  switch (headlineLevelValue) {
    case 'h1':
      headlineSizeValue = 'xl';
      break;
    case 'h2':
      headlineSizeValue = 'lg';
      break;
    case 'h3':
      headlineSizeValue = 'md';
      break;
    default:
      headlineSizeValue = 'md';
      break;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/headline"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <ConditionalWrapper
        condition={headlineSizeValue === 'xl'}
        wrapper={(children: JSX.Element) => {
          return (
            <Container className={headingSpaceClasses}>
              <div
                className={classNames(
                  'containter-content-width',
                  'container-content-padding-x',
                  'pt-9'
                )}
              >
                {children}
              </div>
            </Container>
          );
        }}
      >
        <HeadingWrapper {...fields} />
      </ConditionalWrapper>
    </div>
  );
};

export default Headline;
