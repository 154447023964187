/**
 * Returns the path portion of the url, without hash or querystring
 * @param url The url
 * @returns The path portion of the url, without hash or querystring
 */
export const getUrlPath = (url: string | undefined) => {
  if (!url) return url;

  try {
    const urlObj = new URL(url, process.env.PUBLIC_URL);
    return urlObj.pathname;
  } catch {
    return url;
  }
};
