import React, { useContext } from 'react';

export const PreviewDataContext = React.createContext<boolean>(false);

const usePreviewData = () => {
  const context = useContext(PreviewDataContext);

  return context;
};

export default usePreviewData;
