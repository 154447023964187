// Components
import { Project } from '.generated/templates/Project.Items.model';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

const ExternalMediaRedirect = (): JSX.Element => {
  const context = useSitecoreContext();

  const routeDate = context.sitecoreContext.route as Project.Pages.Fields.NewsroomMediaArticle;

  const redirectHref = routeDate?.fields?.externalArticleUrl?.value.href;

  // Fail out if we don't have any fields
  if (!redirectHref) {
    return <></>;
  }

  return (
    <Script id="external-media-redirect">{`window.location.href = '${redirectHref}';`}</Script>
  );
};

export default ExternalMediaRedirect;
