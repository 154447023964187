// Components

import { Project } from '.generated/templates/Project.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import useSectionSizeContext from 'lib/state/section-size-context';
import SpecifiedFeaturedArticleImage from './SpecifiedFeaturedArticleImage';
import SpecifiedFeaturedArticleText from './SpecifiedFeaturedArticleText';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import { Feature } from '.generated/templates/Feature.Items.model';
import { HealthLibraryStory, getHealthLibaryStory } from 'lib/server-utils/health-library';

export type ArticleItem = Sitecore.Override.ItemEx & Project.Pages.Fields.NewsroomArticle;
export type SpecifiedFeaturedArticleProps = Sitecore.Override.ComponentBase &
  Feature.Components.Articles.Fields.SpecifiedFeatureArticle &
  ArticleItem & {
    healthLibraryFeaturedArticle?: HealthLibraryStory;
  };

const SpecifiedFeaturedArticle = ({
  fields,
  rendering,
  healthLibraryFeaturedArticle,
}: SpecifiedFeaturedArticleProps): JSX.Element => {
  // const [sidebysideState, setSidebysideState] = useState(false);
  // Use Section Size Context variable to determine design sidebyside or stacked
  const containerSize = useSectionSizeContext();
  const sidebysideState = containerSize === 'normal' || containerSize === 'wide';

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  // wrapper CSS classes based on sideBySide condition  to determine design sidebyside or stacked
  const wrapperClassNames = sidebysideState
    ? 'flex flex-col md:flex-row md:items-stretch rounded-lg overflow-hidden border-0 md:border border-gray'
    : 'flex flex-col rounded-l-lg overflow-hidden border-0 md:border border-gray';
  const imgSectionClasses = sidebysideState
    ? 'w-full md:w-1/2 relative rounded-lg md:rounded-none overflow-hidden border-gray border md:border-0'
    : 'w-full h-full rounded-lg overflow-hidden';
  const textSectionClasses = sidebysideState
    ? 'w-full bg-white md:w-1/2 md:border-l border-gray p-6 md:p-20'
    : 'w-full bg-white border-0 rounded-lg pt-6 sm:px-7';

  const specifiedFeaturedArticle = fields.specifiedFeaturedArticle as ArticleItem;
  const currentDomain = new URL(process.env.PUBLIC_URL as string);
  const healthLibraryPath = '/newsroom/health-library/';

  let domainUrl = '';
  if (currentDomain.hostname == 'www.azblue.localhost' || currentDomain.hostname == 'localhost') {
    domainUrl = 'https://development.azblue.com';
  } else {
    domainUrl = currentDomain.origin;
  }
  let healthLibraryArticle = null;
  let healthLibraryArticleUrl = domainUrl + healthLibraryPath;

  if (healthLibraryFeaturedArticle) {
    healthLibraryArticleUrl += healthLibraryFeaturedArticle?.HIStoryID ?? '';
    healthLibraryArticle = healthLibraryFeaturedArticle;
    healthLibraryArticle.LinkUrl = healthLibraryArticleUrl;
  }

  const componentAnchorId = rendering?.uid;

  if (!healthLibraryArticle && !specifiedFeaturedArticle?.fields?.articleBody)
    return <EditingHelpText condition={!specifiedFeaturedArticle} text="No Featured Article" />;

  return (
    <div
      data-component="authorable/article/specifiedFeaturedarticle"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      {healthLibraryArticle ? (
        <div className={classNames(wrapperClassNames)}>
          {/* Health Library Article Image Section */}
          <div className={classNames(imgSectionClasses)}>
            <SpecifiedFeaturedArticleImage
              featuredArticle={healthLibraryArticle}
              sidebysideState={sidebysideState}
              healthLibraryUrl={healthLibraryArticleUrl}
            />
          </div>
          {/* Health Library Article Text Section */}
          <div className={classNames(textSectionClasses)}>
            <SpecifiedFeaturedArticleText
              featuredArticle={healthLibraryArticle}
              healthLibraryUrl={healthLibraryArticleUrl}
            />
          </div>
        </div>
      ) : (
        <div className={classNames(wrapperClassNames)}>
          {/* Featured Article Image Section */}
          <div className={classNames(imgSectionClasses)}>
            <SpecifiedFeaturedArticleImage
              featuredArticle={specifiedFeaturedArticle}
              sidebysideState={sidebysideState}
            />
          </div>
          {/* Featured Article Text Section */}
          <div className={classNames(textSectionClasses)}>
            <SpecifiedFeaturedArticleText featuredArticle={specifiedFeaturedArticle} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecifiedFeaturedArticle;

/**
 * Will be called during SSR
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 * @param {GetStaticComponentProps} context
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, _layoutData) => {
  try {
    const selectedHealthLibraryArticleGuid = (
      rendering as Feature.Components.Articles.Fields.SpecifiedFeatureArticle
    ).fields?.healthLibraryArticleGuid.value;

    let data = null;
    if (selectedHealthLibraryArticleGuid)
      data = await getHealthLibaryStory(selectedHealthLibraryArticleGuid);

    let result = null;
    if (data && data.Story[0]) {
      const jsonResult = JSON.stringify(data.Story[0]);
      result = JSON.parse(jsonResult) as HealthLibraryStory;
    }

    return {
      ...rendering,
      rendering: rendering,
      healthLibraryFeaturedArticle: result,
    };
  } catch (e) {
    console.error(e);
    return {
      ...rendering,
      rendering: rendering,
    };
  }
};
