// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { LinkField, LinkFieldValue } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/ConditionalWrapper';
import Container from 'components/helpers/Container/Container';
import Dropdown from 'components/helpers/Dropdown/Dropdown';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { getEnum } from 'lib/get-enum';
import { getUrlPath } from 'lib/utils/string-utils';
import { useRouter } from 'next/router';
import { SetStateAction, useEffect, useRef, useState } from 'react';
import { theme } from 'tailwind.config';

export type SubNavigationProps = Sitecore.Override.ComponentBase &
  Feature.Components.Navigation.Fields.SubNavigation;
export type LinkItem = Sitecore.Override.ItemEx & Feature.Data.Links.Fields.Link;
const SubNavigation = ({ fields, rendering }: SubNavigationProps): JSX.Element => {
  const route = useRouter();
  const subNavigationMenu = useRef<HTMLUListElement>(null);
  const activeScrollItem = useRef<HTMLLIElement>(null);
  const [navArray, setNavArray] = useState<Element[]>();
  const [dropdownArray, setDropdownArray] = useState<Element[]>([]);
  const isTabs = getEnum(fields?.tabsDesignVariant) === 'Tabs';

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;

  const path = getUrlPath(route?.asPath);

  const handleAddOverflow = () => {
    const overflow =
      navArray?.length &&
      navArray
        ?.filter((i) => {
          return i.classList.contains('hidden');
        })
        ?.map((x) => {
          return fields.subNavigationLinks.filter((item) => {
            return item.id === x.id;
          });
        });
    overflow && setDropdownArray([].concat(...(overflow as [])) as SetStateAction<Element[]>);
  };

  const handleRemoveNav = () => {
    navArray?.length && navArray?.forEach((o) => o.classList.remove('hidden'));
    navArray?.length &&
      navArray
        ?.filter(
          (i: Element) =>
            subNavigationMenu.current &&
            i.getBoundingClientRect().right >
              subNavigationMenu.current.getBoundingClientRect().right - 150
        )
        .map((x) => {
          if (
            x.getBoundingClientRect().right &&
            subNavigationMenu.current &&
            x.getBoundingClientRect().right >
              subNavigationMenu.current.getBoundingClientRect().right - 150
          ) {
            return x.classList.add('hidden');
          } else {
            return x.classList.remove('hidden');
          }
        });
  };

  const handleNavChange = () => {
    handleRemoveNav();
    handleAddOverflow();
  };

  useEffect(() => {
    const subNavItemsDOM = document.querySelectorAll('li.whitespace-pre');
    setNavArray(Array.from(subNavItemsDOM));
    if (subNavigationMenu.current != undefined) {
      subNavigationMenu.current.scrollLeft = (activeScrollItem.current?.offsetLeft || 0) - 40;
    }
  }, [subNavigationMenu.current, activeScrollItem.current]);

  useEffect(() => {
    if (!isTabs) {
      // This isn't currently working, but we have a request for alternate functionality
      // so leaving it broken for now.  Can either fix or remove in the future.
      const handleScreenSize = () => {
        handleNavChange();
        if (window.innerWidth < parseInt(theme.screens.md)) {
          setDropdownArray([]);
          navArray?.forEach((o) => o.classList.remove('hidden'));
        }
      };

      window.addEventListener('resize', handleScreenSize);
      return () => {
        window.removeEventListener('resize', handleScreenSize);
      };
    } else {
      return;
    }
  }, [navArray?.length]);

  return (
    <div
      data-coveo-no-index
      className={classNames(
        'relative',
        'overflow-x-hidden',
        'md:overflow-x-visible',
        'anchorIdIndentifier',
        {
          'border-b': !isTabs,
          'border-gray': !isTabs,
        }
      )}
      data-component="authorable/navigation/subnavigation"
      id={componentAnchorId}
    >
      <Container>
        <EditingHelpText
          condition={!fields.subNavigationLinks.length}
          text="Sub Navigation | No Links Selected. "
        />
        <ConditionalWrapper
          condition={isTabs}
          wrapper={(children: JSX.Element) => {
            return (
              <div
                className={classNames(
                  'container-content-padding-x',
                  'overflow-x-scroll',
                  'md:overflow-x-visible',
                  'hide-scroll-bar'
                )}
              >
                {children}
              </div>
            );
          }}
        >
          <ul
            ref={subNavigationMenu}
            className={classNames('pl-3', 'flex', {
              'overflow-x-scroll': !isTabs,
              'md:overflow-x-visible': !isTabs,
              'border-b': isTabs,
              'border-gray': isTabs,
              'container-content-padding-x': !isTabs,
              'hide-scroll-bar': !isTabs,
              'space-x-2': isTabs,
              'space-x-4': !isTabs,
              'md:space-x-2': isTabs,
              'md:space-x-6': !isTabs,
            })}
          >
            {fields?.subNavigationLinks?.map((i) => {
              const linkItem = i as LinkItem;
              const currentLoc = getUrlPath(linkItem.fields.Link?.value.href);
              return (
                <li
                  ref={currentLoc === path ? activeScrollItem : undefined}
                  id={linkItem.id}
                  key={linkItem.id}
                  className={classNames('group', 'whitespace-pre', {
                    // SUB-NAV VARIANT
                    'border-b-2': !isTabs || currentLoc === path,
                    '!border-blue': !isTabs && currentLoc === path,
                    'border-transparent': !isTabs,
                    'focus:border-b-2': !isTabs,
                    'focus:border-blue': !isTabs,
                    'hover:border-b-2': !isTabs,
                    'hover:border-blue': !isTabs,
                    // TAB VARIANT
                    'bg-gray-light': isTabs && currentLoc !== path,
                    'bg-white': isTabs && currentLoc === path,
                    'border-gray': isTabs,
                    'border-t': isTabs,
                    'border-l': isTabs,
                    'border-r': isTabs,
                    'font-bolder': isTabs && currentLoc === path,
                    'rounded-t-md': isTabs,
                  })}
                >
                  <LinkA11yWrapper
                    className={classNames(
                      'block',
                      'hover:!no-underline',
                      'group-hover:text-blue',
                      'h-full',
                      {
                        'shadow-underline': isTabs && currentLoc === path,
                        'px-4': isTabs,
                        'py-3': isTabs,
                        'py-4': !isTabs,
                        '!text-blue-navy': isTabs && currentLoc === path,
                        'text-gray-dark': currentLoc !== path,
                      }
                    )}
                    preventScroll
                    field={linkItem.fields.Link}
                  />
                </li>
              );
            })}
            <li
              className={classNames(
                'relative',
                'z-10',
                'height-full',
                'right-0',
                'top-0',
                'bg-white'
              )}
            >
              {dropdownArray && dropdownArray.length && !isTabs ? (
                <Dropdown
                  menuItem={dropdownArray}
                  menuItemClassName={classNames('py-2')}
                  menuTriggerClassName={classNames(
                    'bg-white',
                    'flex',
                    'gap-3',
                    'height-full',
                    'items-center',
                    'py-4'
                  )}
                  triggerCloseIcon={'ChevronUpIcon'}
                  triggerOpenIcon={'ChevronDownIcon'}
                >
                  {(menuItem: { fields: { Link: LinkField | LinkFieldValue } }) => (
                    <>
                      <LinkA11yWrapper
                        className={classNames('hover:!no-underline')}
                        field={menuItem.fields.Link}
                      />
                    </>
                  )}
                </Dropdown>
              ) : null}
            </li>
          </ul>
        </ConditionalWrapper>
      </Container>
    </div>
  );
};

export default SubNavigation;
