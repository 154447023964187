// Global

// Local
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import IconCard, { IconCardProps } from 'components/helpers/cards/IconCard';
import classNames from 'classnames';
import { getEnum } from 'lib/get-enum';
import { Feature } from '.generated/templates/Feature.Items.model';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type IconCardListingProps = Sitecore.Override.ComponentBase &
  Feature.Components.Cards.Fields.IconCardListing;

const IconCardListing = ({ fields, rendering }: IconCardListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const columns = getEnum<ColumnOptions>(fields.numberPerRow);

  const renderList = () =>
    fields?.selectedCards.map((i) => {
      const card = i as IconCardProps;
      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'mb-5',
            'md:mb-0',
            'shrink-0'
          )}
          key={i.id}
        >
          <IconCard
            {...card}
            border={fields.includeBlueTopBorder.value}
            alignment={getEnum(fields.cardTextAlignment)}
          />
        </li>
      );
    });
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/card-listings/icon-card-listing"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.selectedCards.length}
        text="Icon Card Listing | No Cards Selected. "
      />
      <Xup columns={columns}>{renderList()}</Xup>
    </div>
  );
};

export default IconCardListing;
