// Components
import classNames from 'classnames';
import { Feature } from '.generated/templates/Feature.Items.model';
import AlertMessageItem from './AlertMessageItem';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

type AlertMessage = Sitecore.Override.ItemEx & Feature.Data.AlertMessage.Fields.AlertMessage;

export type AlertMessagesProps = Sitecore.Override.ComponentBase & {
  fields?: {
    AlertMessages: AlertMessage[];
  };
};

const AlertMessage = ({ fields, rendering }: AlertMessagesProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields || !fields?.AlertMessages.length) {
    return <></>;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <>
      {fields?.AlertMessages.length && (
        <div
          data-component="authorable/general/alertmessage"
          className={classNames('w-full block anchorIdIndentifier')}
          id={componentAnchorId}
        >
          {fields?.AlertMessages.map((alertData) => {
            return <AlertMessageItem key={alertData.id} fields={alertData.fields} />;
          })}
        </div>
      )}
    </>
  );
};

export default AlertMessage;
