/**
 * Name of the landing page.  This is used for url replacement
 */
export const landingPageName = 'landing-pages';

/**
 * Returns the url without the landing page part of the path
 * @param url The current url
 * @returns The url without the landing page part of the path
 */
export const removeLandingFromUrl = (url: string | undefined | null) => {
  return url?.replace(new RegExp(`(${process.env.PUBLIC_URL})?\/${landingPageName}\/`), '$1/');
};
