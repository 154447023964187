import { useEffect } from 'react';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import useUtmContext from 'lib/state/utm-context';

const UtmParams = (): JSX.Element => {
  const context = useSitecoreContext();

  const utm = useUtmContext();

  const isUtmDomain = (x: string) => {
    return context.sitecoreContext.utmDomains?.some((domain) => x?.indexOf(domain) > -1);
  };

  const getUpdatedUtmHref = (hrefValue: string) => {
    if (!isUtmDomain(hrefValue) || !Object.keys(utm)?.length) {
      return hrefValue;
    }

    const strippedHref = hrefValue.split('?')[0];
    const url = new URL(hrefValue);
    const newParams = new URLSearchParams(url?.search);

    const filteredCookieParams = Object.keys(utm).filter((key) => key && utm[key]);

    filteredCookieParams.forEach((key) => newParams.set(key, utm[key]));

    return `${strippedHref}?${newParams}`;
  };

  const handleLinkClick = (e: Event) => {
    if ((e.target as HTMLAnchorElement)?.tagName !== 'A') {
      return;
    }

    const anchor = e.target as HTMLAnchorElement;

    // Prevent infinite loop
    if (anchor.getAttribute('data-click-ignore')) {
      return;
    }

    const href = anchor.getAttribute('href');

    const updatedHref = getUpdatedUtmHref(href || '');
    if (updatedHref !== href) {
      // Update href with UTM parameters
      // Still prevent default because we need to re-trigger a click after updating href
      e.preventDefault();

      // Update href
      anchor.href = updatedHref;

      // Prevent infinite loop
      anchor.setAttribute('data-click-ignore', 'true');

      // Set timeout needed because we are currently inside of a click handler so it can't trigger again
      setTimeout(() => anchor.click(), 0);
    }
  };

  useEffect(() => {
    const anchor = document.querySelectorAll('a');
    document.addEventListener('click', handleLinkClick);

    anchor.forEach((a) => (a.href = getUpdatedUtmHref(a.href ?? '')));

    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [utm]);
  return <></>;
};

export default UtmParams;
