// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { getEnum } from 'lib/get-enum';
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import Button from '../Button/Button';
import { ButtonIconNames, ButtonIconPosition } from '../Icons/ButtonLinkIcon/ButtonLinkIcon';
import classNames from 'classnames';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

export type CtaAlignment = 'center' | 'right' | 'left';
export type CtaGroupProps = {
  ctaAlignment?: Sitecore.Override.ItemEx;
  primaryCta?: LinkField;
  primaryCtaIcon?: Sitecore.Override.ItemEx;
  primaryCtaIconLocation?: Sitecore.Override.ItemEx;
  primaryCtaStyle?: Sitecore.Override.ItemEx;
  secondaryCta?: LinkField;
  secondaryCtaIcon?: Sitecore.Override.ItemEx;
  secondaryCtaIconLocation?: Sitecore.Override.ItemEx;
  secondaryCtaStyle?: Sitecore.Override.ItemEx;
  promoFullWidth?: Field<boolean>;
  className?: string;
};

const CtaGroup = (fields: CtaGroupProps): JSX.Element => {
  const isEE = useExperienceEditor();
  const wrap = fields?.promoFullWidth?.value;
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  if (!fields.primaryCta?.value.text && !fields.secondaryCta?.value.text && !isEE) {
    return <></>;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const alignment = getEnum<CtaAlignment>(fields?.ctaAlignment);
  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'sm:flex-row',
        { 'flex-wrap': wrap === undefined },
        'gap-x-4',
        'gap-y-4',
        'md:items-center',
        { 'justify-start': alignment === 'left' },
        { 'justify-center': alignment === 'center' },
        { 'justify-end': alignment === 'right' },
        'w-full',
        'sm:w-auto',
        fields.className
      )}
    >
      <ConditionalRender
        condition={!!fields.primaryCta && (isEE || !!fields.primaryCta.value.text)}
      >
        <Button
          field={fields?.primaryCta}
          icon={getEnum<ButtonIconNames>(fields?.primaryCtaIcon)}
          iconPosition={getEnum<ButtonIconPosition>(fields?.primaryCtaIconLocation)}
          variant={getEnum(fields.primaryCtaStyle)}
        />
      </ConditionalRender>
      <ConditionalRender
        condition={!!fields.secondaryCta && (isEE || !!fields.secondaryCta.value.text)}
      >
        <Button
          field={fields?.secondaryCta}
          icon={getEnum<ButtonIconNames>(fields?.secondaryCtaIcon)}
          iconPosition={getEnum<ButtonIconPosition>(fields?.secondaryCtaIconLocation)}
          variant={getEnum(fields.secondaryCtaStyle)}
        />
      </ConditionalRender>
    </div>
  );
};

export default CtaGroup;
