// data interface model
import { Feature } from '.generated/templates/Feature.Items.model';
// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import LargeMediaPromoImage, { MediaPromoPlacement } from './LargeMediaPromoImage';
import LargeMediaPromoText from './LargeMediaPromoText';
import { getEnum } from 'lib/get-enum';
import classNames from 'classnames';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useEffect, useState } from 'react';

export type LargeMediaPromoProps = Sitecore.Override.ComponentBase &
  Feature.Components.Promo.Fields.LargeMediaPromo;

export type MediaPromoProps = Feature.Components.Promo.Fields.MediaPromo;

const LargeMediaPromo = ({ fields, rendering }: LargeMediaPromoProps): JSX.Element => {
  const [afterHero, setAfterHero] = useState(false);
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  // Media placement right or left
  const mediaPlacement = getEnum<MediaPromoPlacement>(fields?.mediaPlacement) || 'right';

  const hasOverlay = fields.overlayCircleImage?.value?.src || fields.overlayCircleText?.value;

  const nextSpacing = hasOverlay
    ? mediaPlacement === 'right'
      ? classNames('pt-28 md:pt-14')
      : classNames('pt-0 md:pt-14')
    : '';

  useEffect(() => {
    const thisComponent = document.querySelector(
      '[data-component="authorable/promo/largemediapromo"]'
    );
    const overlayBefore = document.querySelector('.overlay-wrapper');
    if (thisComponent?.previousElementSibling?.contains(overlayBefore)) {
      setAfterHero(true);
    }
  }, []);
  const componentAnchorId = rendering?.uid;
  return (
    <div
      className={classNames(
        'mx-auto',
        'w-full',
        'block',
        'relative',
        'theme-blue--light',
        'bg-theme-bg',
        'text-theme-text',
        { 'pt-16': afterHero },
        'anchorIdIndentifier'
      )}
      data-current-theme="theme-blue--light"
      data-component="authorable/promo/largemediapromo"
      data-next-spacing={nextSpacing}
      id={componentAnchorId}
    >
      <div
        className={classNames(
          'mx-auto',
          'w-full',
          'container-content-width',
          'container-content-padding-x'
        )}
      >
        {
          <div
            className={`large-media-promo -mx-5 md:mx-0 flex ${
              mediaPlacement === 'right'
                ? 'flex-col md:flex-row'
                : 'flex-col-reverse md:flex-row-reverse'
            } md:justify-between md:items-stretch md:gap-5`}
          >
            <div
              className={`w-full px-5 py-9 md:px-0 md:w-1/2 mx-auto md:mx-0 flex flex-col justify-center`}
            >
              <LargeMediaPromoText fields={fields} rendering={rendering} />
            </div>
            <div
              className={`w-full h-auto ${
                mediaPlacement === 'right' && hasOverlay ? 'mb-0' : 'mb-28'
              } md:mb-0 md:w-1/2 relative`}
            >
              <LargeMediaPromoImage fields={fields} mediaPlacement={mediaPlacement} />
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default LargeMediaPromo;
