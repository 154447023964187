import { DateTime } from 'luxon';

export const SERVER_TIME_ZONE = 'America/Phoenix';
export const DATE_LOCALE = 'en-US';

const renderDate = (date: string | DateTime | undefined, format: Intl.DateTimeFormatOptions) => {
  if (!date || date === '0001-01-01T00:00:00Z') {
    return '';
  }

  const dateObj = typeof date === 'string' ? DateTime.fromISO(date) : date;
  return dateObj.toLocaleString(format, { locale: DATE_LOCALE });
};

export const renderFormattedDate = (date: string | DateTime | undefined) => {
  const format: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    timeZone: SERVER_TIME_ZONE,
  };
  return renderDate(date, format);
};

export const renderArticleFormattedDate = (
  date: string | DateTime | undefined,
  shortMonth = false
) => {
  const format: Intl.DateTimeFormatOptions = {
    weekday: undefined,
    year: 'numeric',
    month: shortMonth ? 'short' : 'long',
    day: 'numeric',
    timeZone: SERVER_TIME_ZONE,
  };
  return renderDate(date, format);
};
