import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type EnumField<T> = Sitecore.Override.ItemEx & {
  fields?: {
    Value?: {
      value: T;
    };
  };
};

export interface EnumObject<T> {
  name: string;
  value: T;
}

export const getEnum = <T = string>(item?: EnumField<T>): T | undefined =>
  item?.fields?.Value?.value;

export const getEnumArray = <T = string>(items?: EnumField<T>[]): (T | undefined)[] =>
  items?.map((x) => getEnum(x)).filter((x) => x) || [];

export const getEnumObject = <T>(field?: EnumField<T>): EnumObject<T> | undefined =>
  field?.fields?.Value
    ? { name: field?.displayName || field?.name || '', value: field?.fields?.Value?.value }
    : undefined;

export const getEnumValueOverRide = <T = string>(value: T): EnumField<string> => ({
  name: '',
  fields: { Value: { value: value as string } },
});
