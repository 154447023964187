import classNames from 'classnames';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { MainNavigationMenu, MainNavigationMenuSection } from '../header-types';
import HeaderDropdown from '../HeaderDropdown';
import HeaderMenuSection from '../HeaderMenuSection';

export type HeaderMainNavigationMenuProps = {
  closeText?: string;
  item?: MainNavigationMenu;
};

const HeaderMainNavigationMenu = ({ item, closeText }: HeaderMainNavigationMenuProps) => {
  return (
    <>
      {/* Render dropdown menu only if Is Static Link field is false */}
      {item?.fields?.isStaticLink?.value != true && item?.fields?.menuSections?.length ? (
        <>
          {/* Desktop Menu */}
          <HeaderDropdown
            className={classNames('hidden', 'md:block')}
            triggerStyles={classNames(
              'font-semibold',
              'header-navList-menuItem',
              'hover:bg-btn-bg-secondary-hover',
              'hover:text-btn-text-secondary-hover',
              'text-btn-text-secondary',
              'px-8',
              'py-3'
            )}
            listStyles={classNames(
              // search blackout
              'after:opacity-0',
              'after:transition-opacity',
              'after:delay-75',
              'after:h-screen',
              'after:bg-black',
              'group-[.max-h-screen]:after:opacity-60',
              'after:w-full',
              'after:absolute',
              'after:left-0',
              'after:right-0',
              'after:top-full',
              'after:z-50',
              'after:pointer-events-none'
            )}
            label={item?.fields?.heading?.value}
          >
            {item?.fields?.menuSections?.map((subNavItem: MainNavigationMenuSection) => (
              <li key={subNavItem.id}>
                <HeaderMenuSection
                  variant="desktop"
                  sectionLink={subNavItem}
                  closeText={closeText}
                />
              </li>
            ))}
          </HeaderDropdown>

          {/* Mobile Menu */}
          <HeaderDropdown
            className={classNames('block', 'md:hidden')}
            triggerStyles={classNames(
              'font-semibold',
              'header-navList-menuItem',
              'hover:bg-btn-bg-secondary-hover',
              'hover:text-btn-text-secondary-hover',
              'text-btn-text-secondary',
              'px-8',
              'py-3'
            )}
            label={item?.fields?.heading?.value}
          >
            {item?.fields?.menuSections?.map((subNavItem: MainNavigationMenuSection) => (
              <li key={subNavItem.id}>
                <HeaderMenuSection
                  variant="mobile"
                  sectionLink={subNavItem}
                  closeText={closeText}
                />
              </li>
            ))}
          </HeaderDropdown>
        </>
      ) : (
        <>
          <LinkA11yWrapper
            className={classNames(
              'font-semibold',
              'header-navList-menuItem',
              'hover:bg-btn-bg-secondary-hover',
              'hover:text-btn-text-secondary-hover',
              'text-btn-text-secondary',
              'px-8',
              'py-3'
            )}
            field={{
              href: item?.fields?.Link?.value.href,
              text: item?.fields?.heading?.value,
              linktype: item?.fields?.Link?.value?.type,
              target: item?.fields?.Link?.value?.target,
            }}
          />
        </>
      )}
    </>
  );
};

export default HeaderMainNavigationMenu;
