// Global

// Local
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import ImageCard, { ImageCardProps } from 'components/helpers/cards/ImageCard';
import classNames from 'classnames';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { getEnum } from 'lib/get-enum';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';

export type ImageCardListingProps = Sitecore.Override.ComponentBase &
  Feature.Components.Cards.Fields.ImageCardListing & {
    numberColumns?: ColumnOptions | undefined;
  };

const ImageCardListing = ({ fields, rendering }: ImageCardListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const numberColumns = getEnum<ColumnOptions>(fields.numberPerRow);

  const renderList = () =>
    fields?.selectedCards.map((i) => {
      const card = i as ImageCardProps;

      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'mb-5',
            'md:mb-0',
            'shrink-0'
          )}
          key={i.id}
        >
          <ImageCard
            {...card}
            theme={getEnum(fields.backgroundColor)}
            alignment={getEnum(fields.cardTextAlignment)}
            columns={numberColumns}
          />
        </li>
      );
    });
  const componentAnchorId = rendering?.uid;

  return (
    <div
      data-component="authorable/card-listings/image-card-listing"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.selectedCards.length}
        text="Image Card Listing | No Cards Selected. "
      />
      <Xup columns={numberColumns}>{renderList()}</Xup>
    </div>
  );
};

export default ImageCardListing;
