export type AzBlueAtomicScrollTargetProps = {
  offset?: string;
};
const AzBlueAtomicScrollTarget = ({
  offset = '-150px',
}: AzBlueAtomicScrollTargetProps): JSX.Element => {
  return <div style={{ top: offset, position: 'relative' }} id="scroll-target"></div>;
};

export default AzBlueAtomicScrollTarget;
