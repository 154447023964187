import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { TestimonialTextPosition, TestimonialWithLargeImageProps } from './TestimonialWithImage';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { getEnum } from 'lib/get-enum';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';

const LargeTestimonial = ({ fields }: TestimonialWithLargeImageProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const testimonialTextPosition =
    getEnum<TestimonialTextPosition>(fields?.testimonialTextPosition) ?? 'center';

  const hyphen = '— ';

  function getTestimonialTextPosition(alignment: string) {
    switch (alignment.toLowerCase()) {
      case 'left':
        return '!text-left';

      case 'right':
        return '!text-right';

      case 'center':
        return '!text-center';

      default:
        return 'text-center';
    }
  }

  return (
    <>
      <div className={`large-testimonial flex flex-col max-w-xl gap-3 py-12 px-6`}>
        <span className={classNames('mb-6', 'text-teal', 'fill-current')}>
          <ImageWrapper
            imageClass="flex justify-center"
            removeFill
            field={
              {
                ...fields.iconImage,
                value: { ...fields.iconImage.value, width: '60px', height: '60px' },
              } as SizedImageField
            }
          />
        </span>
        <div
          className={classNames(
            getTestimonialTextPosition(testimonialTextPosition.toString()),
            'large-testimonial-text',
            'font-serif',
            'italic'
          )}
        >
          <div dangerouslySetInnerHTML={{ __html: fields.testimonialText.value }}></div>
        </div>

        <div className={classNames('text-center')}>
          {fields?.testimonialFrom && (
            <>
              {fields?.testimonialFrom?.value?.length > 0 ? hyphen : ''}
              <Text field={fields?.testimonialFrom} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default LargeTestimonial;
