import { buildContext } from '@coveo/headless';
import { useEffect } from 'react';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { withAtomicSearchInterface } from '../atomic-wrappers/AzBlueAtomicSearchInterface';
import AzBlueAtomicStaticQuery from './AzBlueAtomicStaticQuery';

export type NewsFeed =
  | 'News Releases'
  | 'Viewpoints'
  | 'Blog'
  | 'Community'
  | 'Health Library'
  | string;
export type AzBlueAtomicStaticQueryProps = {
  feed: NewsFeed;
  additionalQuery: string;
};

const AzBlueAtomicNewsFeedContext = ({
  feed,
  additionalQuery,
}: AzBlueAtomicStaticQueryProps): JSX.Element => {
  const coveoEngine = useCoveoEngine();
  const controller = buildContext(coveoEngine);

  useEffect(() => {
    controller.set({ ...controller.state.values, newsfeed: feed, component: 'newsfeed' });
  }, [feed]);

  return <AzBlueAtomicStaticQuery coveoQuery={additionalQuery} />;
};

export default withAtomicSearchInterface(AzBlueAtomicNewsFeedContext);
