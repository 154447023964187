import classnames from 'classnames';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';

export const MissingDataSourceText = 'Component is missing Data Source';

export type EditingHelpTextProps = {
  condition: boolean;
  text: string;
  block?: boolean;
};

/**
 * Helper component that outputs "text" if "condition" is true, and we are in Experience Editor.  Otherwise outputs nothing.
 * @param block Whether to display as a "block" element instead of "inline-block"
 * @returns
 */
const EditingHelpText = ({ text, block, condition }: EditingHelpTextProps): JSX.Element => {
  const isExperienceEditor = useExperienceEditor();

  return isExperienceEditor && condition ? (
    <span
      className={classnames(
        block ? 'block' : 'inline-block',
        'mb-2',
        'ee-help-text-highlight',
        'text-sm'
      )}
      data-testid="editing-help-text"
    >
      <strong>{text}</strong>
    </span>
  ) : (
    <></>
  );
};

export const MissingDataSource = (): JSX.Element => (
  <EditingHelpText condition={true} text={MissingDataSourceText} block />
);

export default EditingHelpText;
