// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import { MissingDataSource } from '../EditingHelpText';

export type TestimonialProps = Sitecore.Override.ItemEx &
  Feature.Components.Testimonials.Fields.Testimonial;

const Testimonial = ({ fields }: TestimonialProps) => {
  if (!fields) {
    return <MissingDataSource />;
  }
  return (
    <div
      className={classNames(
        'bg-blue-light',
        'flex',
        'w-full',
        'flex-col',
        'items-center',
        'h-full',
        'p-8',
        'rounded-t-3xl',
        'rounded-b-none',
        'rounded-r-3xl'
      )}
      data-component="helpers/testimonial"
    >
      <div
        className={classNames(
          'text-center',
          'flex-grow',
          'font-serif',
          'italic',
          'text-lg',
          'max-w-[824px]',
          'mb-4'
        )}
      >
        <span className={classNames('font-semibold', 'mr-2', 'text-xl')}>“</span>
        <Text field={fields?.quote} />
        <span className={classNames('font-semibold', 'ml-2', 'text-xl')}>”</span>
      </div>
      {fields?.author.value ? (
        <div>
          — {<Text field={fields?.author} />}{' '}
          {fields?.location.value ? <>({<Text field={fields?.location} />})</> : null}
        </div>
      ) : null}
    </div>
  );
};

export default Testimonial;
