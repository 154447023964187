import { buildQuerySummary } from '@coveo/headless';
import classNames from 'classnames';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { useCoveoEngine } from 'lib/coveo/coveo';

import { useEffect, useState } from 'react';

export const AzBlueHeadlessQuerySummary = () => {
  const coveoEngine = useCoveoEngine();
  const controller = buildQuerySummary(coveoEngine);
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  const { hasResults, firstResult, lastResult, total, query, durationInSeconds } = state;

  if (!hasResults) {
    return null;
  }

  return (
    <div className={classNames('text-sm', 'text-gray-dark', 'mb-0')}>
      Results <span className={classNames('font-bold')}>{firstResult}</span>-
      <span className={classNames('font-bold')}>{lastResult}</span> of{' '}
      <span className={classNames('font-bold')}>{total}</span>
      <ConditionalRender condition={!!query}>
        <>
          {' '}
          for <span className={classNames('font-bold')}>{query}</span>
        </>
      </ConditionalRender>
      <span className={classNames('hidden')}>&nbsp;in {durationInSeconds} second</span>
    </div>
  );
};

export default AzBlueHeadlessQuerySummary;
