// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';

interface ExtendedCardGrid {
  alignment?: string;
}
export type IconGridItemProps = Feature.Components.General.Fields.IconBenefitsItem &
  ExtendedCardGrid;

const IconBenefitsGridItem = ({ fields, alignment }: IconGridItemProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const gridItemData = fields;
  const iconImageField = {
    value: {
      src: gridItemData?.iconImage?.value?.src,
      width: '40px',
      height: '40px',
      alt: gridItemData?.iconImage?.value?.alt,
      extension: gridItemData?.iconImage?.value?.extension,
      asset: gridItemData?.iconImage?.value?.asset,
    },
  };

  return (
    <div className={classNames('w-full pr-6')}>
      <div
        className={classNames('mb-8', 'flex', 'flex-row', 'gap-4', {
          'items-center': alignment === 'vertically-center',
        })}
      >
        {iconImageField.value?.src && (
          <div className={classNames('w-10', 'pt-1', 'flex-none')}>
            <ImageWrapper field={iconImageField as SizedImageField} layout="intrinsic" />
          </div>
        )}
        <div className={classNames('w-auto grow')}>
          <div className={classNames('w-full')}>
            {gridItemData?.primaryBenefitDescription?.value && (
              <Text
                field={gridItemData?.primaryBenefitDescription}
                tag="p"
                className={classNames('text-base leading-4 font-bold')}
              />
            )}
            {gridItemData?.supportBenefitDescription?.value && (
              <RichTextA11yWrapper field={gridItemData?.supportBenefitDescription} editable />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconBenefitsGridItem;
