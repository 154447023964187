// Global
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';

// Local
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';

export type LayoutTwoColumnProps = Sitecore.Override.ComponentBase;

export type LayoutTwoColumnShellProps = {
  className?: string;
  dataComponent?: string;
  left: JSX.Element;
  right: JSX.Element;
};

export const LayoutTwoColumnShell = ({
  className,
  dataComponent,
  left,
  right,
}: LayoutTwoColumnShellProps): JSX.Element => {
  return (
    <div
      className={classNames(
        className,
        'md:grid',
        'grid-cols-2',
        'md:gap-5',
        'lg:gap-12',
        'space-y-6',
        'md:space-y-0'
      )}
      data-component={dataComponent}
    >
      <div className={classNames('mb-10', 'md:mb-0', 'space-y-6')}>{left}</div>
      <div>{right}</div>
    </div>
  );
};

const LayoutTwoColumn = ({ rendering }: LayoutTwoColumnProps): JSX.Element => {
  return (
    <LayoutTwoColumnShell
      dataComponent="authorable/layout/layout-components/layout-two-column"
      left={<PlaceholderWrapper wrapperSize="half" name="BCBSAZ-Half-left" rendering={rendering} />}
      right={
        <PlaceholderWrapper wrapperSize="half" name="BCBSAZ-Half-Right" rendering={rendering} />
      }
    />
  );
};

export default LayoutTwoColumn;
