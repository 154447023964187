import { getThemeClasses, Theme } from 'lib/get-theme';
import classNames from 'classnames';
import { LegacyRef } from 'react';
interface ContainerProps {
  children: React.ReactNode | React.ReactNode[];
  dataComponent?: string;
  className?: string;
  theme?: Theme;
  dataOverlapClass?: string;
  id?: string;
  fullWidth?: boolean;
  innerRef?: LegacyRef<HTMLDivElement>;
}

const Container = ({
  children,
  dataComponent,
  className,
  theme,
  dataOverlapClass,
  id,
  fullWidth,
  innerRef,
}: ContainerProps): JSX.Element => {
  return (
    <div
      ref={innerRef}
      className={classNames('mx-auto', 'w-full', className, getThemeClasses(theme))}
      data-component={dataComponent}
      data-current-theme={theme}
      id={id}
    >
      <div
        className={classNames(
          'mx-auto',
          'w-full',
          { 'container-content-width': !fullWidth },
          dataOverlapClass
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
