// Components
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import classNames from 'classnames';
import { Feature } from '.generated/templates/Feature.Items.model';
import { getEnum } from 'lib/get-enum';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import Testimonial, { TestimonialProps } from 'components/helpers/Testimonial/Testimonial';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type TestimonialListingProps = Sitecore.Override.ComponentBase &
  Feature.Components.Testimonials.Fields.TestimonialListing;

const TestimonialListing = ({ fields, rendering }: TestimonialListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const columns = getEnum<ColumnOptions>(fields.numberPerRow);

  const renderList = () =>
    fields?.selectedTestimonials.map((i) => {
      const quote = i as TestimonialProps;
      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'mb-5',
            'last:mb-0',
            'md:mb-0',
            'shrink-0'
          )}
          key={quote.id}
        >
          <Testimonial {...quote} />
        </li>
      );
    });
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/testimoniallisting"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.selectedTestimonials.length}
        text="Testimonial Listing | No Testimonials Selected. "
      />
      <Xup columns={columns}>{renderList()}</Xup>
    </div>
  );
};

export default TestimonialListing;
