// Components

import { Feature } from '.generated/templates/Feature.Items.model';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';

export type CtaComponentProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.CtaComponent;

const CtaComponent = ({ fields, rendering }: CtaComponentProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/cta-component"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields.primaryCta?.value?.href && !fields.secondaryCta?.value?.href}
        text={'Add a Primary and/or Secondary CTA'}
      />
      <CtaGroup {...fields} />
    </div>
  );
};

export default CtaComponent;
