// Components
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Head from 'next/head';

/**
 * Will populate Favicon and App Icons for mobile devices
 */
const FavIcon = (): JSX.Element => {
  const context = useSitecoreContext();

  const favIconField = context.sitecoreContext?.favIcon;
  const favIcon16x16Field = context.sitecoreContext?.siteFavIcon16x16;
  const favIcon180x180Field = context.sitecoreContext?.siteFavIcon180x180;
  const favIcon196x196Field = context.sitecoreContext?.siteFavIcon196x196;

  // Fail out if we don't have any fields
  if (!favIconField && !favIcon16x16Field && !favIcon180x180Field && !favIcon196x196Field) {
    return <></>;
  }

  return (
    <Head>
      {favIconField && <link rel="icon" sizes="32x32" href={favIconField.value?.src} />}
      {favIcon16x16Field && <link rel="icon" sizes="16x16" href={favIcon16x16Field.value?.src} />}
      {favIcon180x180Field && (
        <link
          rel="apple-touch-icon-precomposed"
          sizes="180x180"
          href={favIcon180x180Field.value?.src}
        />
      )}
      {favIcon196x196Field && (
        <link rel="shortcut icon" sizes="196x196" href={favIcon196x196Field.value?.src} />
      )}
    </Head>
  );
};

export default FavIcon;
