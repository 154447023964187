import Container from 'components/helpers/Container/Container';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { getEnum } from 'lib/get-enum';
import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import FormCheckBox from 'components/helpers/FormElements/FormCheckBox/FormCheckBox';
import FormGroup from 'components/helpers/FormElements/FormGroup/FormGroup';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import { Foundation } from '.generated/templates/Foundation.Items.model';
import FindADoctorModal from 'components/helpers/Modals/FindADoctor/FindADoctorModal';

export type FindDoctorProps = Sitecore.Override.ComponentBase &
  Feature.Components.FindADoctor.Fields.FindADoctorComponent;
export type DropDownState = {
  ddValue: string;
  ddSetValue: Dispatch<SetStateAction<string>>;
  networks: FDNetwork[];
  dropDownOptions: FDDropDownOption[];
};
export type FDNetwork = Sitecore.Override.ItemEx & Feature.Data.FindADoctor.Fields.Network;
export type FDDropDown = Sitecore.Override.ItemEx &
  Feature.Data.FindADoctor.Fields.FindADoctorDropDown;
export type FDDropDownOption = Sitecore.Override.ItemEx &
  Feature.Data.FindADoctor.Fields.FindADoctorDropDownOption;

const FindDoctor = ({ fields, rendering }: FindDoctorProps): JSX.Element => {
  const [consentState, setConsentState] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const stateList: DropDownState[] = [];
  fields?.dropdowns?.forEach(() => {
    const [ddValue, ddSetValue] = useState('');
    stateList.push({
      ddValue,
      ddSetValue,
      networks: [],
      dropDownOptions: [],
    });
  });

  function networkHasSelected(items: Sitecore.Override.ItemEx[], selectedValue: string) {
    let returnValue = false;
    items.forEach((element) => {
      if (element?.id === selectedValue) {
        returnValue = true;
        return;
      }
    });
    return returnValue;
  }

  function getNetworks(networks: FDNetwork[], selectedValue: string, networkFieldType: string) {
    const filteredNetworks: FDNetwork[] = [];
    if (selectedValue === '') {
      return networks;
    }
    networks.forEach((element) => {
      if (networkFieldType === 'county') {
        if (
          networkHasSelected([element.fields.county] as Sitecore.Override.ItemEx[], selectedValue)
        ) {
          filteredNetworks.push(element);
        }
      } else if (networkFieldType === 'year') {
        if (
          networkHasSelected(
            element.fields.coverageYears as Sitecore.Override.ItemEx[],
            selectedValue
          )
        ) {
          filteredNetworks.push(element);
        }
      } else if (networkFieldType === 'network') {
        if (element.id === selectedValue) {
          filteredNetworks.push(element);
        }
      } else if (networkFieldType === 'coverage') {
        if (
          networkHasSelected(
            [element.fields.typeOfCoverage] as Sitecore.Override.ItemEx[],
            selectedValue
          )
        ) {
          filteredNetworks.push(element);
        }
      } else if (networkFieldType === 'provider') {
        if (
          networkHasSelected(
            [element.fields.typeOfProvider] as Sitecore.Override.ItemEx[],
            selectedValue
          )
        ) {
          filteredNetworks.push(element);
        }
      }
    });
    return filteredNetworks;
  }

  function getDropDownOptions(
    originalOptions: FDDropDownOption[],
    networks: FDNetwork[],
    networkFieldType: string
  ) {
    const availableOptions: FDDropDownOption[] = [];

    if (networkFieldType === 'network') {
      networks.forEach((element) => {
        if (element.fields.name.value !== '') {
          const newDropDownOption: FDDropDownOption = {
            id: element.id,
            name: element.name,
            fields: { value: { value: element.fields.name.value } },
          };
          availableOptions.push(newDropDownOption);
        }
      });
      return availableOptions;
    }

    originalOptions.forEach((element) => {
      if (
        networkFieldType === 'year' &&
        networks.some((n) => {
          return n.fields.coverageYears.some((x) => {
            return x.id === element.id;
          });
        })
      ) {
        availableOptions.push(element);
      } else if (
        networkFieldType === 'county' &&
        networks.some((n) => {
          return n.fields.county?.id === element.id;
        })
      ) {
        availableOptions.push(element);
      } else if (
        networkFieldType === 'coverage' &&
        networks.some((n) => {
          return n.fields.typeOfCoverage?.id === element.id;
        })
      ) {
        availableOptions.push(element);
      } else if (
        networkFieldType === 'provider' &&
        networks.some((n) => {
          return n.fields.typeOfProvider?.id === element.id;
        })
      ) {
        availableOptions.push(element);
      }
    });

    return availableOptions;
  }

  if (!fields) {
    return <></>;
  }

  let btnUrl = '#';
  let showConsent = false;
  let modal: Foundation.Data.Modals.Fields.BaseModal | undefined;
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/general/finddoctor"
      id={componentAnchorId}
      className={classNames(
        'max-w-[612px]',
        'pb-10',
        'pt-12',
        'px-6',
        'rounded-lg',
        'bg-white',
        'border',
        'border-gray',
        'anchorIdIndentifier'
      )}
    >
      {fields?.dropdowns?.map((i, index) => {
        const dd = i as FDDropDown;
        const currentState = stateList[index];

        if (index === 0 && currentState.ddValue === '' && dd.fields.selectedItems[0] != undefined) {
          const opt = dd.fields.selectedItems[0] as FDDropDownOption;
          currentState.ddSetValue(opt.id ? opt.id : '');
        }

        const networkFieldType = getEnum(dd.fields?.networkFieldType);

        let previousNetworks: FDNetwork[] = [];
        if (index === 0) {
          previousNetworks = fields?.networks as FDNetwork[];
        } else {
          previousNetworks = stateList[index - 1].networks;
        }

        currentState.networks = getNetworks(
          previousNetworks,
          currentState.ddValue,
          networkFieldType as string
        );

        const opts = dd.fields.selectedItems as FDDropDownOption[];
        currentState.dropDownOptions = getDropDownOptions(
          opts,
          previousNetworks,
          networkFieldType as string
        );

        let showDropDown = true;
        if (currentState.dropDownOptions.length === 0) {
          showDropDown = false;
        } else if (index > 0) {
          let num = 1;
          while (index - num > 0 && showDropDown) {
            const previousState = stateList[index - num];
            num++;
            if (previousState.dropDownOptions.length === 0) {
              continue;
            } else if (previousState.dropDownOptions.length === 1) {
              if (previousState.dropDownOptions[0].id == '') {
                continue;
              }
            } else {
              showDropDown = previousState.ddValue != '' && previousState.ddValue != '';
              if (showDropDown) break;
            }
          }
        }

        if (currentState.networks.length === 1) {
          const network = currentState.networks[0];
          btnUrl = network.fields.url.value.href as string;
          showConsent = network.fields.showConsentMessage.value;
          modal = network.fields.confirmModal as Sitecore.Override.ItemEx &
            Feature.Data.Modals.Fields.FindADoctorModal;
        } else {
          showConsent = false;
        }

        if (!showDropDown) {
          return <React.Fragment key={index}></React.Fragment>;
        }

        const optValue =
          currentState.ddValue === ''
            ? null
            : {
                value: `${currentState.ddValue}`,
                label: currentState.dropDownOptions
                  .filter((i) => i.id === currentState.ddValue)
                  .map((x) => x.fields.value.value),
              };

        return (
          <FormGroup key={index} formGroupClasses={classNames({ hidden: !showDropDown })}>
            <FormSelectList
              value={optValue}
              label={`${dd.fields.dropdownLabel.value}*`}
              placeholder="Select an Option"
              name={i.id}
              id={i.id}
              callBackChange={(e) => {
                currentState.ddSetValue(e.value);
                for (let c = index + 1; c < stateList.length; c++) {
                  stateList[c].ddSetValue('');
                  stateList[c].networks = [];
                  stateList[c].dropDownOptions = [];
                }
                setConsentState(false);

                // set select's value
              }}
              options={currentState.dropDownOptions.map((op) => {
                const option = op as FDDropDownOption;
                return {
                  value: option.id,
                  label: option.fields.value.value,
                };
              })}
            />
          </FormGroup>
        );
      })}
      {showConsent && (
        <FormGroup>
          <FormCheckBox
            id="checkboxDisabled"
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: fields?.consentMessage.value || '',
                }}
              />
            }
            labelClassName={classNames('text-sm')}
            onChange={(e) => {
              setConsentState(e.target.checked);
            }}
            checked={consentState}
          />
        </FormGroup>
      )}
      <Button
        field={{
          value: {
            href: btnUrl,
            target: '_blank',
            text: fields?.goToNetworkButtonText?.value,
          },
        }}
        iconSize="xs"
        iconPosition="right"
        ignoreExtLinkModal={true}
        disabled={!(btnUrl !== '#' && (!showConsent || (showConsent && consentState)))}
        onClick={(e) => {
          if (modal) {
            e.preventDefault();
            setShowModal(true);
          }
        }}
      />

      {modal ? (
        <FindADoctorModal
          href={btnUrl}
          externalLinkModal={modal}
          showModal={showModal}
          handleModal={setShowModal}
        />
      ) : (
        <></>
      )}
    </Container>
  );
};

export default FindDoctor;
