export interface WrapperProps {
  children: JSX.Element;
  condition: boolean;
  wrapper: (arg0: JSX.Element) => JSX.Element;
}

const ConditionalWrapper = ({ condition, wrapper, children }: WrapperProps) => {
  return <>{condition ? wrapper(children) : children}</>;
};
export default ConditionalWrapper;
