// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useState } from 'react';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import FormGroup from 'components/helpers/FormElements/FormGroup/FormGroup';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import {
  AxiosDataFetcher,
  // GetServerSideComponentProps,
  GetStaticComponentProps,
} from '@sitecore-jss/sitecore-jss-nextjs';
import axios from 'axios';
import FormInput from 'components/helpers/FormElements/FormInput/FormInput';
import Button from 'components/helpers/Button/Button';
import {
  ContractSpecialistSearchResponse,
  getNexusToken,
  getProviderSpecialties,
  getZipCodes,
  withRetry,
} from 'lib/server-utils/nexus-api';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import NoResults from 'components/helpers/Search/NoResults';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type ProviderLiaisonSearchProps = Sitecore.Override.ComponentBase &
  Feature.Components.Search.Fields.ProviderLiaisonSearch & {
    zipCodes: string[];
    specialties: string[];
    fields: {
      noResultsItem: Feature.Data.Search.Fields.SearchNoResult;
    };
  };

/**
 * Formats taxId as 00-0000000 format
 * @param taxId User entered tax id
 * @returns
 */
const formatTaxId = (taxId: string | undefined) => {
  if (!taxId) {
    return '';
  }
  const split = taxId.split('');
  // Splice happens in-place
  split.splice(2, 0, '-');
  const result = split.join('');
  return result;
};

const ProviderLiaisonSearch = (props: ProviderLiaisonSearchProps): JSX.Element => {
  // The initial state is undefined, which we do not display validation message for.
  const [zipCode, setZipCode] = useState<string>();
  const [specialty, setSpecialty] = useState<string>();
  const [taxId, setTaxId] = useState<string>();

  const [results, setResults] = useState<ContractSpecialistSearchResponse[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const isEE = useExperienceEditor();
  // Fail out if we don't have any fields
  const fields = props.fields;
  if (!fields) {
    return <MissingDataSource />;
  }

  const componentAnchorId = props?.rendering?.uid;

  return (
    <Container
      dataComponent="authorable/searc/providerliaisonsearch"
      id={componentAnchorId}
      className={classNames(
        'max-w-[795px]', // Not sure if we need this, check designs
        'md:py-12',
        'py-8',
        'md:px-28',
        'px-5',
        'rounded-lg',
        'bg-white',
        'border',
        'border-gray',
        'anchorIdIndentifier'
      )}
    >
      <HeadingWrapper {...fields} />

      <form
        onSubmit={async (e) => {
          // Don't actually submit anything
          e.preventDefault();

          // If any fields are not populated when submitting, replace them with empty string
          // this will trigger validation error
          if (!specialty || !zipCode || !taxId) {
            setSpecialty(specialty ?? '');
            setZipCode(zipCode ?? '');
            setTaxId(taxId ?? '');
            return;
          }

          // Clear the results and show loading indicator
          setResults(null);
          setIsLoading(true);

          // Do the search
          const ajaxResult = await axios.get<ContractSpecialistSearchResponse[]>(
            '/api/nexus/get-contract-specialists?' +
              new URLSearchParams([
                ['specialty', specialty],
                ['zipCode', zipCode],
                ['taxId', taxId],
              ])
          );

          // Empty array if there's no result data
          const data = ajaxResult?.data ?? [];

          // Clear loading status and set the results
          setIsLoading(false);
          setResults(data);
        }}
      >
        <FormGroup>
          <FormInput
            value={taxId}
            name="taxid"
            isInvalid={taxId !== undefined && !taxId.match(/^[0-9]{9}$/)}
            errorMessage={fields.taxIdValidationMessage.value}
            label={`${fields.taxIdLabel.value}*`}
            placeholder={fields.taxIdPlaceholder.value}
            callBackBlur={() => {
              setTaxId(taxId ?? '');
            }}
            callBackChange={(e) => {
              // Ensure only numbers and that there are only 9 digits
              setTaxId(e.target.value.replace(/[^0-9]/gi, '').slice(0, 9));
            }}
          />
        </FormGroup>

        <FormGroup>
          <FormSelectList
            value={specialty ? { value: specialty, label: specialty } : null}
            label={`${fields.specialtyLabel.value}*`}
            placeholder={fields.specialtyPlaceholder.value}
            name="specialty"
            isInvalid={!specialty}
            errorMessage={fields.specialtyValidationMessage.value}
            callBackChange={(e) => {
              setSpecialty(e.value);
            }}
            options={props.specialties?.map((x) => {
              return { value: x, label: x };
            })}
          />
        </FormGroup>

        <FormGroup>
          <FormSelectList
            value={zipCode ? { value: zipCode, label: zipCode } : null}
            label={`${fields.zipCodeLabel.value}*`}
            placeholder={fields.zipCodePlaceholder.value}
            name="zip"
            isInvalid={!zipCode}
            errorMessage={fields.zipCodeValidationMessage.value}
            callBackChange={(e) => {
              setZipCode(e.value);
            }}
            options={props.zipCodes?.map((x) => {
              return { value: x, label: x };
            })}
          />
        </FormGroup>
        <Button
          iconSize="xs"
          iconPosition="right"
          ignoreExtLinkModal={true}
          icon={isLoading ? 'loading' : 'MagnifyingGlassIcon'}
          text={fields.searchButtonText.value}
          disabled={isLoading || !taxId || !specialty || !zipCode}
          type="submit"
        />
      </form>
      <ConditionalRender condition={isEE || (!!results && results?.length > 0)}>
        <div className={classNames('mt-8')}>
          <h4 className={classNames('heading-xs', 'mb-6')}>{fields.contactInfoHeadline.value}</h4>
          <RichTextA11yWrapper
            field={fields.contactInfoSearchSummary}
            textReplace={[
              {
                regex: /\{taxId\}/,
                replacement: `<strong>${formatTaxId(taxId)}</strong>`,
              },
              {
                regex: /\{specialty\}/,
                replacement: `<strong>${specialty}</strong>`,
              },
              {
                regex: /\{zipCode\}/,
                replacement: `<strong>${zipCode}</strong>`,
              },
            ]}
          />
          <ul className={classNames('flex', 'flex-col', 'gap-6', 'mt-4')}>
            {results?.map((x, index) => (
              <li key={index} className={classNames('border', 'border-gray', 'rounded-lg', 'p-9')}>
                <p className={classNames('mb-2')}>
                  <span className={classNames('text-lg', 'font-bolder')}>
                    {fields.emailLabel.value}:
                  </span>{' '}
                  <a className={classNames('text-lg')} href={`mailto:${x.email}`}>
                    {x.email}
                  </a>
                </p>
                <p>
                  {fields.phoneLabel.value}:<a href={`tel:${x.phone}`}> {x.phone}</a>
                </p>
              </li>
            ))}
          </ul>
        </div>
      </ConditionalRender>
      <div
        className={classNames({
          'md:px-2': results && results.length === 0,
          'pt-8': results && results.length === 0,
        })}
      >
        <NoResults
          smallVariant
          whiteTheme
          fields={fields.noResultsItem?.fields}
          hasResults={results === null || results.length > 0}
        />
      </div>
    </Container>
  );
};

/**
 * Will be called during SSR
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 * @param {GetStaticComponentProps} context
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, _layoutData) => {
  const fetcher = new AxiosDataFetcher();

  const tokenResponse = await getNexusToken(fetcher);
  const zipCodes = await withRetry(getZipCodes)(fetcher, tokenResponse);
  const specialties = await withRetry(getProviderSpecialties)(fetcher, tokenResponse);

  return {
    ...rendering,
    rendering: rendering,
    zipCodes: zipCodes ?? [],
    specialties: specialties ?? [],
  };
};

// /**
//  * Will be called during SSR
//  * @param {ComponentRendering} rendering
//  * @param {LayoutServiceData} layoutData
//  * @param {GetServerSidePropsContext} context
//  */
// export const getServerSideProps: GetServerSideComponentProps = async (rendering, _layoutData) => {
//   const fetcher = new AxiosDataFetcher();

//   const tokenResponse = await getNexusToken(fetcher);
//   const zipCodes = await withRetry(getZipCodes)(fetcher, tokenResponse);
//   const specialties = await withRetry(getProviderSpecialties)(fetcher, tokenResponse);

//   return {
//     ...rendering,
//     rendering: rendering,
//     zipCodes: zipCodes ?? [],
//     specialties: specialties ?? [],
//   };
// };

export default ProviderLiaisonSearch;
