import { useContext, useEffect } from 'react';
import { ComponentPropsReactContext, RouteData } from '@sitecore-jss/sitecore-jss-nextjs';
import { deepSearchFound } from './object-utils';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export const useFindComponent = (components: string | string[]) => {
  const componentList = Array.isArray(components) ? components : [components];

  const context = useContext(ComponentPropsReactContext) as Record<
    string,
    Sitecore.Override.ComponentBase
  >;

  const results = Object.values(context).find(
    (x) => x.componentName && componentList.indexOf(x.componentName) >= 0
  );

  return results;
};

export const routeHasComponent = (
  route: RouteData | undefined | null,
  components: string | string[]
) => {
  const componentList = Array.isArray(components) ? components : [components];
  return deepSearchFound(route, (x) => componentList.indexOf(x.componentName) >= 0);
};

export const useClickAway = <T extends HTMLElement>(
  ref: React.RefObject<T>[],
  onClick: () => void
) => {
  const handleClick = (event: MouseEvent) => {
    const allRefs = ref;
    const found = allRefs.find((x) => x.current?.contains(event.target as Node));
    if (!found) {
      onClick();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, onClick]);
};

export const useGetCurrentUrl = () => {
  let currentUrl = '';
  if (typeof window !== 'undefined') {
    currentUrl = window.location.href;
  }

  useEffect(() => {
    currentUrl = window.location.href;
  }, []);

  return currentUrl;
};

export const getOffsetPositionOfElement = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + window.scrollX,
    top: rect.top + window.scrollY,
  };
};
