// Components
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { removeLandingFromUrl } from 'lib/utils/landing-page-utils';
import Head from 'next/head';
import React from 'react';

const HrefLang = (): JSX.Element => {
  const context = useSitecoreContext();

  const hrefLangs = context.sitecoreContext?.hrefLangs;

  // Fail out if we don't have any any languages
  if (!hrefLangs?.length) {
    return <></>;
  }

  return (
    <Head>
      {hrefLangs.map((x) =>
        x.url.endsWith('$') ? (
          <React.Fragment key={x.url} />
        ) : (
          <link key={x.url} rel="alternate" href={removeLandingFromUrl(x.url)} hrefLang={x.lang} />
        )
      )}
    </Head>
  );
};

export default HrefLang;
