import { useEffect } from 'react';
import { Cookies } from 'react-cookie';

export type CookieSetterProps = {
  cookieName: string;
  cookieValue: string;
  expiration?: Date;
};

const CookieSetter = ({ cookieValue, cookieName, expiration }: CookieSetterProps) => {
  const cookies = new Cookies();
  useEffect(() => {
    if (cookieName && cookieValue) {
      cookies.set(cookieName, cookieValue, {
        expires: expiration,
        path: '/',
      });
    }
  }, [cookieName, cookieName, expiration]);
  return null;
};

export default CookieSetter;
