// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import CtaGroup, { CtaAlignment } from 'components/helpers/CtaGroup/CtaGroup';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { HeadingPositionProps } from 'components/helpers/Heading/Heading';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { getEnum, getEnumValueOverRide } from 'lib/get-enum';

export type HeadlineWithCTAProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.HeadlineWithCta;

const HeadlineWithCTA = ({ fields, rendering }: HeadlineWithCTAProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const headlineSplitPosition = getEnum<HeadingPositionProps>(fields.headlinePosition) || 'center';
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/headline-with-cta"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div className="max-w-[200px] mx-auto relative overflow-hidden">
        <ImageWrapper field={fields.image as SizedImageField} layout="responsive" />
      </div>
      <div
        className={classNames('w-full', 'h-full', 'flex', 'flex-col', {
          'items-center': headlineSplitPosition !== 'left',
        })}
      >
        <HeadingWrapper {...fields} />
        <CtaGroup
          className={'mt-8'}
          {...fields}
          ctaAlignment={getEnumValueOverRide<CtaAlignment>(
            `${headlineSplitPosition as CtaAlignment}`
          )}
        />
      </div>
    </div>
  );
};

export default HeadlineWithCTA;
