import { useCoveoEngine } from 'lib/coveo/coveo';
import { withAtomicSearchInterface } from '../atomic-wrappers/AzBlueAtomicSearchInterface';
import { loadAdvancedSearchQueryActions } from '@coveo/headless';

export type AzBlueAtomicStaticQueryProps = {
  coveoQuery: string;
};

const AzBlueAtomicStaticQuery = ({ coveoQuery }: AzBlueAtomicStaticQueryProps): JSX.Element => {
  const coveoEngine = useCoveoEngine();

  const { registerAdvancedSearchQueries } = loadAdvancedSearchQueryActions(coveoEngine);

  coveoEngine.dispatch(
    registerAdvancedSearchQueries({
      aq: coveoQuery,
    })
  );
  return <></>;
};

export default withAtomicSearchInterface(AzBlueAtomicStaticQuery);
