import { buildSearchBox } from '@coveo/headless';
import classNames from 'classnames';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { AzBlueAtomicSearchBoxProps } from '../atomic-wrappers/AzBlueAtomicSearchBox';
import { useRouter } from 'next/router';

interface SearchBoxProps {
  fields: AzBlueAtomicSearchBoxProps['fields'];
}

export const AzBlueHeadlessSearchPageBar = ({ fields }: SearchBoxProps) => {
  const router = useRouter();
  const coveoEngine = useCoveoEngine();
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';

  const controller = buildSearchBox(coveoEngine, { options: { numberOfSuggestions: 5 } });
  const redirectUrl =
    router?.asPath.split('#')[0] === fields?.searchPage?.url ? undefined : fields?.searchPage?.url;

  const handleSubmit = () => {
    if (redirectUrl) {
      window.location.assign(`${redirectUrl}#q=${searchValue.trim()}`);
    } else {
      controller.updateText(searchValue);
      controller.submit();
    }
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target?.value);
    controller.updateText(e.target?.value);
  };

  const handleClear = () => {
    setSearchValue('');
    controller.clear();
    controller.submit();
  };

  useEffect(() => {
    return controller.subscribe(() => {
      inputRef.current?.focus();
      if (!controller.state.isLoading) {
        setSearchValue(controller.state.value);
      }
    });
  }, []);

  return (
    <div
      className={classNames(
        'hover:ring',
        'hover:ring-blue',
        'flex',
        'group',
        'items-center',
        'overflow-hidden',
        'relative',
        'rounded-lg',
        'transition-all',
        'w-full'
      )}
    >
      <input
        ref={inputRef}
        className={classNames(
          'border-2',
          'border-gray',
          'flex-grow',
          'focus:outline-none',
          'group-hover:border-transparent',
          'p-3',
          'rounded-l-lg',
          'text-gray-dark',
          'transition-all'
        )}
        value={searchValue}
        onChange={(e) => handleInput(e)}
        onKeyDown={(e) => isEnterKey(e) && searchValue && handleSubmit()}
        placeholder={fields?.searchBoxPlaceholder?.value || 'Search'}
        enterKeyHint="search"
      />
      {searchValue && (
        <button
          className={classNames('text-gray-medium', 'right-16', 'absolute')}
          onClick={handleClear}
        >
          <HeroIcon size="xs" icon={'XMarkIcon'} />
        </button>
      )}

      <button
        disabled={!searchValue}
        onClick={handleSubmit}
        className={classNames(
          'bg-blue',
          'border-2',
          'border-blue',
          'disabled:bg-btn-disabled-bg-primary',
          'disabled:border-transparent',
          'disabled:text-btn-disabled-text-primary',
          'disabled:cursor-not-allowed',
          'h-full',
          'hover:bg-blue-navy',
          'hover:border-blue-navy',
          '-ml-1',
          'p-3',
          'rounded-r-lg',
          'text-white',
          'transition-all'
        )}
      >
        <HeroIcon size="xs" icon={'MagnifyingGlassIcon'} />
      </button>
    </div>
  );
};
