// Global
import classNames from 'classnames';

// Generated
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
// Local
import Heading from 'components/helpers/Heading/Heading';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { useI18n } from 'next-localization';
import LinkA11yWrapper from '../LinkA11yWrapper/LinkA11yWrapper';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';

export type FAQCategoryItem = Sitecore.Override.ItemEx &
  Feature.Data.Faqs.Fields.FaqTaxonomyItem & {
    numReferrers: number;
  };

const FAQCategoryCard = ({ fields, numReferrers, url, name }: FAQCategoryItem): JSX.Element => {
  const isEE = useExperienceEditor();
  const i18n = useI18n();
  const faqText = i18n?.t('FAQs');
  const cardHelperText = i18n?.t('Card Link For');

  // Fail out if we don't have any fields
  if (!fields) return <></>;

  return (
    <div
      className={classNames(
        'bg-white',
        'hover:ring-2',
        'hover:ring-blue-navy',
        'ring-1',
        'ring-gray',
        'flex',
        'flex-col',
        'h-full',
        'relative',
        'rounded-lg',
        'text-center',
        'transition-all',
        'w-full',
        'group'
      )}
    >
      <div
        className={classNames(
          'flex',
          'flex-grow',
          'items-center',
          'flex-col',
          'p-6',
          'shrink-0',
          'text-gray-dark'
        )}
      >
        <span className={classNames('mb-5', 'w-fit')}>
          <ImageWrapper
            imageClass={classNames('w-fit', 'block')}
            field={
              {
                ...fields.iconImage,
                value: { ...fields.iconImage.value },
              } as SizedImageField
            }
          />
        </span>
        <span className={classNames('flex', 'flex-col', 'items-center', 'text-center')}>
          <Heading
            headingText={fields?.title}
            headingClassName={classNames('mb-5', 'font-regular', 'text-blue-navy')}
            headingLevel="h3"
            headingSize="xs"
          />
          {/* 
          className prop only works when editable and encode is false
          */}
          <Text
            tag="div"
            field={fields?.description}
            editable={true}
            className={classNames('text-gray-darkest')}
          />
          <div className={classNames('flex', 'gap-1', 'mt-4', 'text-gray-dark')}>
            <span>{numReferrers}</span>
            <span>{faqText}</span>
          </div>
        </span>
        <LinkA11yWrapper
          className={classNames({ 'stretched-link-hidden-text': !isEE })}
          field={{
            value: {
              href: `${url}#f-taxonomy_audience=${fields.title.value}`,
              text: `${cardHelperText} ${name}`,
            },
          }}
        />
      </div>
    </div>
  );
};

export default FAQCategoryCard;
