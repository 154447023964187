// data interface model
import { Feature } from '.generated/templates/Feature.Items.model';

// Components
import classNames from 'classnames';
import { getEnum } from 'lib/get-enum';
import { Theme, getThemeClasses } from 'lib/get-theme';
import React from 'react';
import ButtonLinkIcon from 'components/helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import { LinkField, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { isExternalLink } from 'lib/utils/is-external-link';
import EditingHelpText from 'components/helpers/EditingHelpText';

export type ResourceLinksProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.AdditionalResources;
type LinkItem = Sitecore.Override.ItemEx & Feature.Data.Links.Fields.Link;

const ResourceLinks = ({ fields, rendering }: ResourceLinksProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  const titleText = fields?.titleText?.value || 'undefined';
  const themeName = getEnum<Theme>(fields?.resourcesBackground) || '';
  const themeClasses = themeName !== '' ? getThemeClasses(themeName) : 'text-theme-text';
  const boxPadding = themeName !== '' ? 'p-6' : 'p-0';
  const componentAnchorId = rendering?.uid;
  const context = useSitecoreContext();
  return (
    <div
      data-component="authorable/general/resourcelinks"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div className={classNames('w-full', 'rounded-lg', themeClasses, boxPadding)}>
        {titleText !== 'undefined' && (
          <p className="mb-2 text-base uppercase tracking-[0.06em] font-regular">{titleText}</p>
        )}
        {fields.resourceLinks.length > 0 && (
          <ul>
            {fields.resourceLinks.map((l) => {
              const lnk = l as LinkItem;
              if (!lnk.fields.Link) {
                return (
                  <EditingHelpText
                    condition={true}
                    text={`Invalid resource link: ${lnk.id} (${lnk.url})`}
                  />
                );
              }
              const icotype = isExternalLink(
                lnk.fields.Link?.value.href,
                context.sitecoreContext?.internalUrlWhitelist
              )
                ? 'ArrowUpRightIcon'
                : 'hover-arrow';
              return (
                <li key={lnk.id} className="py-4 pr-1 border-b border-gray hover-arrow">
                  <LinkA11yWrapper
                    field={lnk?.fields?.Link as LinkField}
                    suppressNewTabIcon
                    className={classNames(
                      'flex',
                      'justify-between',
                      'items-center',
                      'text-base',
                      'text-theme-text',
                      'font-bolder',
                      'leading-4',
                      'px-0',
                      'py-0',
                      'w-full',
                      'btn',
                      'hover-arrow-icon',
                      'hover:text-theme-text'
                    )}
                    aria-label={lnk?.fields?.Link?.value?.text}
                  >
                    <ButtonLinkIcon
                      variant="link"
                      icon={icotype}
                      iconSize="xs"
                      iconPosition="right"
                      className="text-blue"
                    />
                  </LinkA11yWrapper>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
};

export default ResourceLinks;
