import { useEffect, useState } from 'react';
// import { GetServerSideProps } from 'next';
import { GetStaticPaths, GetStaticProps } from 'next';
import NotFound from 'components/layout/NotFound';
import PageLayout from 'components/layout/PageLayout';
import {
  SitecoreContext,
  ComponentPropsContext,
  handleEditorFastRefresh,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/sitecore/page-props';
import { sitecorePagePropsFactory } from 'lib/sitecore/page-props-factory';
import { componentFactory } from 'temp/componentFactory';
import { PreviewDataContext } from 'lib/state/preview-context';
import { SitecoreContextProps } from '@sitecore-jss/sitecore-jss-react/types/components/SitecoreContext';
import { UtmContext } from 'lib/state/utm-context';
import { useRouter } from 'next/router';
import { Cookies } from 'react-cookie';
// import { sitemapFetcher } from 'lib/sitecore/sitemap-fetcher';

export class FastSitecoreContext extends SitecoreContext {
  componentDidUpdate(prevProps: SitecoreContextProps) {
    // Taking out the deepEquals because it's slow for large objects
    if (this.props.layoutData && prevProps.layoutData !== this.props.layoutData) {
      this.setContext(this.props.layoutData);
      return;
    }

    // // In case if somebody will manage SitecoreContext state by passing fresh `layoutData` prop
    // // instead of using `updateSitecoreContext`
    // if (!deepEqual(prevProps.layoutData, this.props.layoutData)) {
    //   this.setContext(this.props.layoutData);

    //   return;
    // }
  }
}
const SitecorePage = ({
  notFound,
  componentProps,
  layoutData,
  preview,
}: SitecorePageProps): JSX.Element => {
  const router = useRouter();
  const [utmParams, setUtmParams] = useState<Record<string, string>>({});
  const cookies = new Cookies();
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh EE chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  useEffect(() => {
    const routeArray: { key: string; value: string }[] = [];

    const queryParams = new URLSearchParams(window.location.search);

    for (const [paramKey, paramValue] of queryParams.entries()) {
      routeArray.push({ key: paramKey, value: paramValue });
    }
    const allowedParams = ['utm_source', 'utm_campaign', 'utm_medium', 'call'];
    const userParams = allowedParams
      ?.map((x) => routeArray.filter((item) => item.key === x))
      .flat()
      .filter((x) => x)
      .reduce((obj, current) => {
        obj[current.key] = current.value;
        return obj;
      }, {} as Record<string, string>);

    // Use values from querystring if present
    if (Object.keys(userParams).length > 0) {
      // Add to cookie for next pages
      cookies.set('UTM Parameters', JSON.stringify(userParams));
      // And set value
      setUtmParams(userParams);
    } else {
      // Otherwise get from cookies
      const params = cookies.get('UTM Parameters');
      if (params) {
        try {
          // cookies.get looks like already parses to JSON, but adding this just in case.
          const paramsObj = typeof params === 'string' ? JSON.parse(params) : params;
          // And set the value
          setUtmParams(paramsObj);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [router.asPath]);

  if (notFound || !layoutData.sitecore?.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  return (
    <ComponentPropsContext value={componentProps}>
      <FastSitecoreContext componentFactory={componentFactory} layoutData={layoutData}>
        <PreviewDataContext.Provider value={preview}>
          <UtmContext.Provider value={utmParams}>
            <PageLayout layoutData={layoutData} />
          </UtmContext.Provider>
        </PreviewDataContext.Provider>
      </FastSitecoreContext>
    </ComponentPropsContext>
  );
};

// export const getServerSideProps: GetServerSideProps = async (context) => {
//   const props = await sitecorePagePropsFactory.create(context);

//   if (props.redirectPath) {
//     return {
//       props,
//       redirect: {
//         destination: props.redirectPath,
//         permanent: false,
//       },
//     };
//   } else {
//     return {
//       props,
//       notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
//     };
//   }
// };

export const getStaticProps: GetStaticProps = async (context) => {
  const props = await sitecorePagePropsFactory.create(context);

  props.preview = context.preview ?? false;

  // Revalidate based on environment variable or every 60 seconds
  const revalidate = !!process.env.ISR_REVALIDATE_TIMER
    ? parseInt(process.env.ISR_REVALIDATE_TIMER)
    : 60;

  if (props.redirectPath) {
    return {
      props,
      revalidate,
      redirect: {
        destination: props.redirectPath,
        permanent: false,
      },
    };
  } else {
    return {
      props,
      revalidate,
      notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
    };
  }
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (_context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration

  // if (process.env.NODE_ENV !== 'development') {
  //   // Note: Next.js runs export in production mode
  //   const paths = await sitemapFetcher.fetch(context);

  //   return {
  //     paths,
  //     fallback: process.env.EXPORT_MODE ? false : 'blocking',
  //   };
  // }

  return {
    paths: [],
    fallback: 'blocking',
  };
};

// // This function gets called at build time on server-side.
// // It may be called again, on a serverless function, if
// // revalidation (or fallback) is enabled and a new request comes in.
// export const getStaticProps: GetStaticProps = async (context) => {
//   const props = await sitecorePagePropsFactory.create(context);

//   return {
//     props,
//     // Next.js will attempt to re-generate the page:
//     // - When a request comes in
//     // - At most once every 5 seconds
//     revalidate: 5, // In seconds
//     notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
//   };
// };

export default SitecorePage;
