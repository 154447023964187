module.exports = {
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './lib/**/*.{js,ts,jsx,tsx}',
  ],
  important: '#azblue-body',
  theme: {
    colors: {
      // Theming
      'theme-bg': 'var(--theme-bg)',
      'theme-bg-alt': 'var(--theme-bg-alt)',
      'theme-heading-text': 'var(--theme-heading-text)',
      'theme-hr-bg': 'var(--theme-hr-bg)',
      'theme-text': 'var(--theme-text)',
      'theme-text-alt': 'var(--theme-text-alt)',
      // Primary Button
      'btn-bg-primary': 'var(--btn-bg-primary)',
      'btn-bg-primary-hover': 'var(--btn-bg-primary-hover)',
      'btn-border-primary-hover': 'var(--btn-border-primary-hover)',
      'btn-border-primary': 'var(--btn-border-primary)',
      'btn-text-primary': 'var(--btn-text-primary)',
      // Primary DISABLED
      'btn-disabled-border-primary': 'var(--btn-disabled-border-primary)',
      'btn-disabled-bg-primary': 'var(--btn-disabled-bg-primary)',
      'btn-disabled-text-primary': 'var(--btn-disabled-text-primary)',
      // Secondary Button
      'btn-bg-secondary': 'var(--btn-bg-secondary)',
      'btn-bg-secondary-hover': 'var(--btn-bg-secondary-hover)',
      'btn-border-secondary': 'var(--btn-border-secondary)',
      'btn-border-secondary-hover': 'var(--btn-border-secondary-hover)',
      'btn-text-secondary': 'var(--btn-text-secondary)',
      'btn-text-secondary-hover': 'var(--btn-text-secondary-hover)',
      // Primary DISABLED
      'btn-disabled-border-secondary': 'var(--btn-disabled-border-secondary)',
      'btn-disabled-bg-secondary': 'var(--btn-disabled-bg-secondary)',
      'btn-disabled-text-secondary': 'var(--btn-disabled-text-secondary)',
      // Tertiary Button
      'btn-bg-tertiary': 'var(--btn-bg-tertiary)',
      'btn-text-tertiary': 'var(--btn-text-tertiary)',
      // Tertiary Button
      'btn-disabled-bg-tertiary': 'var(--btn-disabled-bg-tertiary)',
      'btn-disabled-text-tertiary': 'var(--btn-disabled-text-tertiary)',
      // Link as Button
      'btn-link-text-hover': 'var(--btn-link-text-hover)',
      'btn-link-text': 'var(--btn-link-text)',
      current: 'currentColor',
      black: '#000000',
      white: '#FFFFFF',
      orange: '#ff8200',
      teal: '#28BFE0',
      transparent: 'transparent',
      gray: {
        DEFAULT: '#E6E6E6',
        light: '#F7F7F7',
        medium: '#AEAEAE',
        dark: '#595959',
        darkest: '#2D2926',
      },
      blue: {
        DEFAULT: '#2273bA',
        light: '#F1F9Fd',
        peacock: '#0099D8',
        sky: '#98E1FF',
        navy: '#0B3B60',
        navyDark: '#07253C',
      },
      gold: {
        DEFAULT: '#FFBE5F',
        light: '#FFD495',
      },
      error: {
        DEFAULT: '#DD524C',
        dark: '#7B302D',
        light: '#F0A9A7',
      },
      success: {
        DEFAULT: '#4CAF50',
        dark: '#1B5E20',
        light: '#A5D6A7',
      },
      warning: {
        DEFAULT: '#FFF6ED',
        dark: '#FF8200',
        color: '#0B3B60',
      },
      info: {
        DEFAULT: '#F1F9FD',
        dark: '#0099D8',
        color: '#0B3B60',
      },
    },
    fontFamily: {
      sans: ['"Roboto"', 'sans-serif'],
      serif: ['"Roboto Serif"', 'serif'],
    },
    fontSize: {
      xs: '0.75rem',
      sm: '0.875rem',
      base: '1rem',
      md: '1.125rem',
      lg: '1.25rem',
      xl: '1.5rem',
      '2xl': '2rem',
      '3xl': '2.25rem',
      '4xl': '3rem',
      '5xl': '3.5rem',
      '6xl': '4.5rem',
      '7xl': '6rem',
      '8xl': '8rem',
      '9xl': '9rem',
    },
    fontWeight: {
      black: 900,
      bold: 700,
      bolder: 600,
      light: 300,
      regular: 400,
      semibold: 500,
    },
    lineHeight: {
      20: '4.4rem', // 48
      16: '3.5rem', // 48
      12: '3rem', // 48
      11: '2.438rem', // 39
      10: '2.125rem', // 34
      9: '1.938rem', // 31
      8: '1.813rem', // 29
      7: '1.688rem', // 27
      6: '1.625rem', // 26
      5: '1.563rem', // 25
      4: '1.5rem', // 24
      3: '1.438rem', // 23
      2: '1.375rem', // 22
      1: '1.188rem', // 19
      none: '1', //
      tight: '1.25', //
    },
    screens: {
      sm: '576px',
      // => @media (min-width: 576px) { ... }
      md: '1080px',
      // => @media (min-width: 1080px) { ... }
      lg: '1440px',
      // => @media (min-width: 1440px) { ... }
    },
    extend: {
      boxShadow: {
        card: '0px 10px 26px rgba(0, 0, 0, 0.04)',
        underline: '0px 10px 0px 0px rgba(255,255,255,1)',
      },
      padding: {
        30: '7.5rem',
        50: '12.5rem',
        70: '17.5rem',
      },
      spacing: {
        em: '1em',
        xxl: '5rem',
        xl: '4rem',
        l: '2.5rem',
        m: '1.5rem',
        s: '1rem',
        xs: '.75rem',
        xxs: '.5rem',
        xxxs: '.25rem',
      },
      transitionProperty: {
        maxHeight: 'max-height',
        outline: 'outline',
        spacing: 'margin, padding',
        underline: 'text-decoration',
        background: 'background, background-color',
        boxShadow: 'box-shadow',
        translate: 'translate',
        textDecoration: 'text-decoration-line',
      },
    },
  },
  corePlugins: {
    aspectRatio: true,
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/container-queries'),
  ],
};
