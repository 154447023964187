// Components// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import Container from 'components/helpers/Container/Container';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useRef } from 'react';
import { getThemeClasses } from 'lib/get-theme';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';

export type HeroHeadlineProps = Feature.Components.General.Fields.Headline &
  Sitecore.Override.ComponentBase;

const HeroHeadline = ({ fields, rendering }: HeroHeadlineProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const overlapClass =
    rendering.placeholders && rendering?.placeholders['BCBSAZ-Hero-Health-Sherpa']?.length
      ? '-mt-20'
      : '';
  const overlapSpace =
    rendering.placeholders && rendering?.placeholders['BCBSAZ-Hero-Health-Sherpa']?.length
      ? 'pb-28 md:pb-32'
      : '';

  const componentAnchorId = rendering?.uid;

  return (
    <div className="w-full relative">
      <div
        className={classNames(getThemeClasses('theme-blue--light'), 'anchorIdIndentifier')}
        data-component="authorable/hero/heroheadline"
        id={componentAnchorId}
      >
        <Container className={classNames('py-9', 'md:py-12', overlapSpace)}>
          <HeadingWrapper {...fields} />
        </Container>
      </div>
      <div
        ref={ref}
        className={classNames(
          'flex',
          'flex-col',
          'items-center',
          'mx-auto',
          'space-y-16',
          'relative',
          'z-10',
          'w-full',
          overlapClass
        )}
      >
        <PlaceholderWrapper
          wrapperSize="two-thirds"
          displayName="BCBSAZ-Hero-Overlay"
          name="BCBSAZ-Hero-Health-Sherpa"
          rendering={rendering}
        />
      </div>
    </div>
  );
};

export default HeroHeadline;
