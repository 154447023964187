import { loadSearchActions, loadSearchAnalyticsActions, SearchEngine } from '@coveo/headless';

import { debounce } from 'ts-debounce';

export const debouncedSearch = debounce((engine: SearchEngine, beforeSearch?: () => void) => {
  const { executeSearch } = loadSearchActions(engine);
  const { logSearchFromLink } = loadSearchAnalyticsActions(engine);
  if (beforeSearch) {
    beforeSearch();
  }
  engine.dispatch(executeSearch(logSearchFromLink()));
}, 500);
