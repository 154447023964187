import { Foundation } from '.generated/templates/Foundation.Items.model';
import ModalStandard from 'components/helpers/Modals/ModalStandard/ModalStandard';
import { useState, useEffect } from 'react';
import Button from '../../Button/Button';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isExternalLink } from 'lib/utils/is-external-link';

export type ExternalLinkModalProps = {
  externalLinkModal?: Foundation.Data.Modals.Fields.BaseModal;
};

const ExternalLinkModal = ({ externalLinkModal }: ExternalLinkModalProps): JSX.Element => {
  const context = useSitecoreContext();
  const [showModal, setShowModal] = useState(false);
  const [hrefStateValue, setHrefStateValue] = useState('');

  const handleExternalLinkClick = (e: Event) => {
    if ((e.target as HTMLAnchorElement)?.tagName !== 'A') {
      return;
    }

    const anchor = e.target as HTMLAnchorElement;

    // Prevent infinite loop
    if (anchor.getAttribute('data-click-ignore')) {
      return;
    }

    const href = anchor.getAttribute('href') ?? '';
    const jsIgnoreModal = anchor?.classList.contains('js-ignore-modal');

    const isExternal: boolean | undefined = !!isExternalLink(
      href || '',
      context.sitecoreContext?.internalUrlWhitelist
    );

    if (isExternal && !jsIgnoreModal) {
      // Show Modal
      e.preventDefault();
      setHrefStateValue(href);
      setShowModal(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleExternalLinkClick);

    return () => {
      document.removeEventListener('click', handleExternalLinkClick);
    };
  }, []);

  if (!externalLinkModal) {
    return <></>;
  }

  return (
    <ModalStandard externalTrigger showModal={showModal} handleModal={setShowModal}>
      <div className="w-full mb-8">
        <RichTextA11yWrapper field={externalLinkModal.fields?.content} editable />
      </div>
      <div className="w-full flex flex-row justify-center gap-4 items-center">
        <Button
          field={{
            value: {
              href: hrefStateValue,
              target: '_blank',
              text: externalLinkModal.fields?.buttonText?.value,
            },
          }}
          variant="primary"
          iconPosition="right"
          icon="hover-arrow"
          className="js-ignore-modal"
          onClick={() => {
            setShowModal(false);
          }}
        />

        <Button
          variant="secondary"
          type="button"
          onClick={() => {
            setShowModal(false);
          }}
          aria-label="Cancel"
        >
          {externalLinkModal.fields?.cancelButtonText?.value}
        </Button>
        {externalLinkModal.fields?.disclaimerText?.value && (
          <div className="my-4 text-center text-sm" data-coveo-no-index="true">
            <RichTextA11yWrapper field={externalLinkModal.fields?.disclaimerText} editable />
          </div>
        )}
      </div>
    </ModalStandard>
  );
};

export default ExternalLinkModal;
