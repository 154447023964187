// Global
import { IconSize } from '../ContentMangedIcon/ContentMangedIcon';
import { HeroIconList } from 'temp/heroIcons';
import dynamic from 'next/dynamic';

export type HeroIconNames = HeroIconList;

export interface HeroIconProps {
  className?: string;
  icon?: HeroIconNames;
  mini?: boolean;
  outline?: boolean;
  size: IconSize;
}

export default dynamic(() => import('./HeroIconImpl'));
