// Components
import { ArticleItem } from '../FeaturedArticle/FeaturedArticle';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import useWindowDimensions from 'components/helpers/WindowsSize/WindowSize';
import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { HealthLibraryStory } from 'lib/server-utils/health-library';
import { getStoryImageUrls } from 'lib/utils/article-utils';

interface SpecifiedFeaturedArticleImageProps {
  featuredArticle: ArticleItem | HealthLibraryStory;
  sidebysideState: boolean;
  healthLibraryUrl?: string;
}
const SpecifiedFeaturedArticleImage = ({
  featuredArticle,
  sidebysideState,
  healthLibraryUrl,
}: SpecifiedFeaturedArticleImageProps): JSX.Element => {
  const [screenWidth, setScreenWidth] = useState(1440);
  const { width } = useWindowDimensions();
  useEffect(() => {
    setScreenWidth(width);
  }, [width]);

  const layoutType = sidebysideState && screenWidth > 575 ? 'fill' : 'responsive';
  const article = featuredArticle as ArticleItem;
  const healthLibraryArticle = featuredArticle as HealthLibraryStory;

  const images = healthLibraryArticle ? getStoryImageUrls(healthLibraryArticle) : undefined;

  const ogImgUrl: SizedImageField | null = images
    ? {
        value: {
          src: images.wideImgUrl,
          alt: images.wideImgAlt,
          width: '950px',
          height: '500px',
        },
      }
    : null;

  return (
    <>
      {healthLibraryUrl ? (
        <ImageWrapper
          field={ogImgUrl as SizedImageField}
          layout={layoutType}
          imageClass={classNames('w-full', 'h-full', 'object-cover')}
        />
      ) : (
        <ImageWrapper
          field={article?.fields?.imageFeatured as SizedImageField}
          layout={layoutType}
          imageClass={classNames('w-full', 'h-full', 'object-cover')}
        />
      )}
    </>
  );
};

export default SpecifiedFeaturedArticleImage;
