import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Field } from '@sitecore-jss/sitecore-jss-nextjs';

import ButtonLinkIcon from '../../../helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import RichTextA11yWrapper from '../../../helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type AccordionFAQItemProps = {
  question?: Field<string>;
  answer?: Field<string>;
  onToggle: () => void;
  multiple?: boolean;
  open: boolean;
};

const AccordionFAQItem = ({
  question,
  answer,
  onToggle,
  multiple,
  open,
}: AccordionFAQItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isEE = useExperienceEditor();
  const [isOpen, setIsOpen] = useState(isEE || open);

  const handleAccordionToggle = () => {
    if (multiple) {
      setIsOpen((a) => !a);
    } else {
      onToggle();
    }
  };

  useEffect(() => {
    setIsOpen(isEE || open);
  }, [open]);

  return (
    <li
      className={classNames(
        'bg-gray-light',
        'border',
        'border-gray',
        'last:mb-0',
        'list-none',
        'mb-2',
        'rounded-lg',
        'transition-all',
        'duration-300'
      )}
    >
      <button
        disabled={isEE}
        type="button"
        className={classNames(
          'bg-white',
          'border-gray',
          'flex',
          'items-center',
          'justify-between',
          'p-5',
          'md:p-8',
          'rounded-t-lg',
          'rounded-b-lg',
          'text-left',
          'text-md',
          'md:text-lg',
          'w-full',
          {
            'border-b': isOpen,
            'border-b-0': !isOpen,
            'rounded-t-lg': isOpen,
            'rounded-b-none': isOpen,
          }
        )}
        onClick={() => handleAccordionToggle()}
        aria-expanded={isOpen}
      >
        <span className={classNames('block', 'flex-grow')}>{question && question.value}</span>
        <span
          className={classNames(
            'block',
            'm-0',
            'origin-center',
            'transition-all',
            'transition-200',
            'w-max',
            'text-blue-navy',
            {
              'rotate-180': isOpen,
            }
          )}
        >
          <ButtonLinkIcon icon={'ChevronDownIcon'} iconPosition={'center'} />
        </span>
      </button>
      <div
        ref={ref}
        className={classNames(
          'max-h-0',
          'overflow-hidden',
          'my-0',
          'mx-5',
          'md:mx-8',
          'transition-all',
          'duration-200',
          'origin-bottom',
          {
            'my-5': isOpen,
            'md:my-8': isOpen,
            'duration-200': isOpen,
          }
        )}
        style={{ maxHeight: `${isOpen ? ref?.current?.scrollHeight : 0}px` }}
      >
        <RichTextA11yWrapper field={answer} editable />
      </div>
    </li>
  );
};

export default AccordionFAQItem;
