// Global
import classNames from 'classnames';
import Container from '../Container/Container';

// Local

export type ColumnOptions = '1' | '2' | '3' | '4';

export interface XupProps {
  columns?: ColumnOptions;
  columnClasses?: string;
  children: React.ReactNode | React.ReactNode[];
  cssClass?: string;
}

const Xup = ({ columns, cssClass, children, columnClasses }: XupProps): JSX.Element => {
  const cardsToDisplay = parseInt(columns || '2', 10);

  const gridColClass = classNames(
    columnClasses,
    cssClass,
    'grid-flow-row',
    'sm:grid',
    'mx-auto',
    {
      'md:grid-cols-1': cardsToDisplay === 1,
      'md:grid-cols-2': cardsToDisplay === 2,
      'md:grid-cols-3': cardsToDisplay === 3,
      'md:grid-cols-4': cardsToDisplay === 4,
    },
    'sm:gap-5'
  );

  return (
    <Container dataComponent="helpers/xup">
      <ul className={`${gridColClass}`}>{children}</ul>
    </Container>
  );
};

export default Xup;
