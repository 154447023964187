import classNames from 'classnames';

export type FormInputProps = {
  callBackBlur?: (arg0: any) => void;
  callBackChange?: (arg0: any) => void;
  callBackInput?: (arg0: any) => void;
  errorMessage?: string;
  id?: string;
  inputClassName?: string;
  isInvalid?: boolean;
  name?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  type?: string;
};

const FormInput = ({
  errorMessage,
  label,
  name,
  id,
  inputClassName,
  isInvalid,
  callBackBlur,
  callBackChange,
  callBackInput,
  placeholder,
  type,
  value,
}: FormInputProps) => {
  return (
    <>
      <label className={classNames('text-gray-dark', 'text-sm', 'mb-2', 'block')} htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        onBlur={callBackBlur}
        onChange={callBackChange}
        onInput={callBackInput}
        name={name}
        className={classNames(
          'p-4',
          'border',
          'border-gray',
          'rounded',
          'w-full',
          {
            'border-error-light': isInvalid,
            'text-error-dark': isInvalid,
          },
          inputClassName
        )}
        placeholder={placeholder}
        value={value}
        type={type}
      />
      <span
        className={classNames('text-xs', 'text-error', 'top-full', 'left-0', 'absolute', {
          hidden: !isInvalid,
          block: isInvalid,
        })}
      >
        {errorMessage}
      </span>
    </>
  );
};

export default FormInput;
