// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import EditingHelpText from 'components/helpers/EditingHelpText';
import Xup, { ColumnOptions } from 'components/helpers/Xup/Xup';
import ActionCard from 'components/helpers/cards/ActionCard';
import { getEnum } from 'lib/get-enum';
import chunk from 'lodash/chunk';

export type ActionCardListing = Sitecore.Override.ItemEx &
  Feature.Components.Cards.Fields.ActionCardListing & {
    children: ActionCardType[];
  };

export type ActionCardType = Sitecore.Override.ItemEx &
  Feature.Components.Cards.Fields.ActionCard & {
    children: ActionCardLink[];
  };
export type ActionCardLink = Sitecore.Override.ItemEx &
  Feature.Components.Cards.Fields.ActionCardLink;

export type ActionCardListingProps = Sitecore.Override.ComponentBase & {
  fields: {
    root: ActionCardListing;
  };
};

export type AlignmentOptions = 'vertically-center' | 'top';

const ActionCardListing = ({ fields, rendering }: ActionCardListingProps): JSX.Element => {
  const componentAnchorId = rendering?.uid;
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const columns = getEnum<ColumnOptions>(fields.root.fields.cardsPerRow) || '3';
  const columnsInt = parseInt(columns);
  const cardList: ActionCardType[] = fields.root.children;
  const cardGroups = chunk(cardList, columnsInt);
  const iconAlignment = getEnum<AlignmentOptions>(fields.root.fields.iconAlignment) || 'top';

  const renderList = (arr: ActionCardType[]) =>
    arr.map((i) => {
      const card = i as ActionCardType;
      return (
        <li
          className={classNames(
            'flex',
            'flex-col',
            'flex-grow',
            'h-auto',
            'h-full',
            'items-center',
            'md:pl-9',
            'first:pl-0',
            'shrink-0',
            'py-5',
            'md:py-0'
          )}
          key={i.id}
        >
          <ActionCard
            iconAlignment={iconAlignment}
            {...card}
            links={card.children.map((link) => link)}
          />
        </li>
      );
    });

  return (
    <Container
      id={componentAnchorId}
      dataComponent="authorable/card-listings/actioncardlisting"
      className={classNames('bg-white', 'p-5')}
    >
      <EditingHelpText
        condition={!cardList.length}
        text="General Card Listing | No Cards Selected. "
      />
      <ul className={classNames('divide-y', 'divide-gray')}>
        {/* <ul className={classNames('')}> */}
        {cardGroups.map((group, index) => {
          return (
            <li key={index}>
              <Xup
                columnClasses={classNames(
                  'md:divide-x',
                  'divide-y',
                  'md:divide-y-0',
                  'divide-gray',
                  'py-5',
                  'md:py-10'
                )}
                columns={columns}
              >
                {renderList(group)}
              </Xup>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default ActionCardListing;
