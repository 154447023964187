// Global
import classNames from 'classnames';

// Components
import { SvgIconNames, isSvgIcon } from 'components/helpers/Icons/SvgIcon/SvgIcon';
import { HeroIconNames } from 'components/helpers/Icons/HeroIcon/HeroIcon';
import ContentMangedIcon, {
  IconSize,
  iconSizeClasses,
} from '../ContentMangedIcon/ContentMangedIcon';

export type ButtonIconNames = HeroIconNames & SvgIconNames;

export type ButtonIconPosition = 'center' | 'right' | 'left';

export interface ButtonLinkIconProps {
  iconSize?: IconSize;
  icon?: HeroIconNames | SvgIconNames;
  variant?: string;
  iconPosition: ButtonIconPosition;
  className?: string;
}

const positionClasses = {
  center: 'ml-0 mr-0',
  left: 'mr-1',
  right: 'ml-1 -mr-2',
};

const defaultIconClasses = classNames(
  'content-center',
  'ease-in-out',
  'flex',
  'flex-col',
  'items-center',
  '-my-1'
);

const tertiaryButtonClasses = classNames(
  'bg-btn-bg-tertiary',
  'content-center',
  'ease-in-out',
  'flex',
  'flex-col',
  'items-center',
  'mx-2',
  '-my-1',
  'pl-[2px]',
  'rounded-full'
);
const ButtonLinkIcon = ({
  icon,
  iconPosition,
  iconSize = 'xs',
  variant,
  className,
}: ButtonLinkIconProps): JSX.Element => {
  const isSvgIconFlag = isSvgIcon(icon as string);
  const svgIconClass = className !== undefined && className !== '' ? className : 'text-blue-navy';
  const heroIconClass = className !== undefined && className !== '' ? className : '';
  return (
    <span
      className={classNames(
        variant === 'tertiary' ? tertiaryButtonClasses : defaultIconClasses,
        icon === 'hover-arrow' && iconPosition === 'left' ? 'rotate-180' : '',
        `${positionClasses[iconPosition]} ${iconSizeClasses[iconSize]}`
      )}
    >
      <ContentMangedIcon
        icon={icon}
        size={iconSize}
        mini
        className={isSvgIconFlag ? svgIconClass : heroIconClass}
      />
    </span>
  );
};

export default ButtonLinkIcon;
