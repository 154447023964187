// Global
// Components
import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import { Feature } from '.generated/templates/Feature.Items.model';

export type ScreenmeetProps = Feature.Components.Screenmeet.Fields.Screenmeet;

declare global {
  interface Window {
    Cobrowse: any;
  }
}

const Screenmeet = ({ fields }: ScreenmeetProps) => {
  function handleClick(): void {
    if (window.Cobrowse) {
      window.Cobrowse.modal.openModal();
    }
  }

  return (
    <>
      <Button
        title="Click to start screen-meet session."
        onClick={() => handleClick()}
        className={classNames('')}
        aria-label={fields?.title?.value ?? 'screen meet initiate session button'}
      >
        {fields?.title?.value ?? 'Start Screenmeet'}
      </Button>
    </>
  );
};
export default Screenmeet;
