// Global
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { MouseEvent, TouchEvent, useState } from 'react';
import { useI18n } from 'next-localization';

// Lib
import { Sitecore } from 'lib/types/sitecore';

// Generated
import { Feature } from '.generated/templates/Feature.Items.model';
import { Project } from '.generated/templates/Project.Items.model';

// Components
import Button from 'components/helpers/Button/Button';
import Container from 'components/helpers/Container/Container';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { renderFormattedDate } from 'lib/utils/datetime-utils';

export type FooterLink = Sitecore.Override.ItemEx & Feature.Data.Footer.Fields.FooterLink;

export type FooterProps = Feature.Components.Footer.Fields.Footer;
type Accreditation = Sitecore.Override.ItemEx & Feature.Data.Footer.Fields.FooterAccreditation;

type FooterColumn = Sitecore.Override.ItemEx & Feature.Data.Footer.Fields.FooterColumn;

const Footer = ({ fields }: FooterProps): JSX.Element => {
  const i18n = useI18n();
  const ctx = useSitecoreContext();
  const medicarePage = ctx?.sitecoreContext?.route as Project.Pages.Fields.MedicarePage;

  // Fail out if we don't have any fields
  if (!fields) return <MissingDataSource />;

  /*
   * STATE
   */

  const [activeNavItem, setActiveNavItem] = useState('');

  /*
   * EVENT HANDLERS
   */

  const onClickMenuItem = (evt: MouseEvent | TouchEvent, id: string | undefined) => {
    if (!evt || !id) return;

    const previousTarget = document.getElementById(activeNavItem);
    const previousTargetId = previousTarget?.id;
    const target = evt.target as HTMLElement;
    const targetId = target.id === activeNavItem ? '' : id;

    if (previousTarget) {
      const previousTargetSubnavWrapper = previousTarget?.nextSibling as HTMLElement;

      previousTargetSubnavWrapper.style.maxHeight = '';
    }

    if (previousTargetId === targetId) return;

    const subnavWrapper = target?.nextSibling as HTMLElement;
    const subnav = subnavWrapper?.firstChild as HTMLElement;

    let height = subnav.offsetHeight;
    height += parseInt(window.getComputedStyle(subnav).getPropertyValue('margin-top'));
    height += parseInt(window.getComputedStyle(subnav).getPropertyValue('margin-bottom'));

    if (targetId !== '' && subnavWrapper) subnavWrapper.style.maxHeight = `${height}px`;

    setActiveNavItem(targetId);
  };

  /*
   * RENDERING
   */

  const lastUpdatedDateFormatted = renderFormattedDate(medicarePage?.fields?.lastUpdated?.value);
  return (
    <div
      className={classNames('bg-white', 'border-gray', 'border-solid', 'border-t')}
      data-component="authorable/site/footer"
    >
      <Container>
        <ul
          className={classNames(
            'grid',
            'grid-cols-1',
            'lg:grid-cols-4',
            'pb-12',
            'pt-8',
            'container-content-padding-x'
          )}
        >
          {fields?.footerColumns?.map((col) => {
            const column = col as FooterColumn;
            return (
              <li key={column?.id}>
                {column.fields?.isSocialLinksColumn?.value ? (
                  <>
                    <Button
                      className={classNames(
                        'lg:pointer-events-none',
                        'hidden',
                        'lg:inline-flex',
                        'text-gray-darkest',
                        '!px-0',
                        'py-3.5',
                        'uppercase',
                        'w-full',
                        '[&>span]:grow',
                        '[&>span]:lg:hidden',
                        '[&>span]:items-end',
                        '[&>span]:text-blue'
                      )}
                      icon="ChevronDownIcon"
                      iconSize="xs"
                      iconPosition="right"
                      text={column?.displayName}
                      variant="link"
                    />
                    <ul className={classNames('flex', 'flex-row', 'lg:pt-0', 'pt-9')}>
                      {column?.fields?.Links?.map((x) => {
                        const link = x as FooterLink;

                        const src = link?.fields?.iconImage.value?.src;

                        return (
                          <li className={classNames('h-5', 'mr-7')} key={link?.id}>
                            <a
                              href={link?.fields?.Link?.value?.href}
                              aria-label={link.displayName}
                              target="_blank"
                              title={link.displayName}
                              className={classNames(
                                'block text-center hover:text-blue text-blue-navy'
                              )}
                            >
                              <ImageWrapper
                                field={{
                                  value: {
                                    src: src,
                                    alt: link.displayName,
                                    width: '24px',
                                    height: '24px',
                                    href: link?.fields?.Link?.value?.href,
                                  },
                                }}
                              />
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </>
                ) : (
                  <>
                    <Button
                      className={classNames(
                        'lg:pointer-events-none',
                        'text-gray-darkest',
                        '!px-0',
                        'py-3.5',
                        'uppercase',
                        'w-full',
                        '[&>span]:grow',
                        '[&>span]:h-5',
                        '[&>span]:lg:hidden',
                        '[&>span]:items-end',
                        '[&>span]:pointer-events-none',
                        '[&>span]:text-blue',
                        '[&>span]:w-5',
                        '[&>span>svg]:duration-150',
                        '[&>span>svg]:ease-in-out',
                        '[&>span>svg]:transition-transform',
                        {
                          '[&>span>svg]:duration-150': column?.id === activeNavItem,
                          '[&>span>svg]:ease-in-out': column?.id === activeNavItem,
                          '[&>span>svg]:rotate-180': column?.id === activeNavItem,
                          '[&>span>svg]:transition-transform': column?.id === activeNavItem,
                        }
                      )}
                      icon="ChevronDownIcon"
                      iconSize="xs"
                      iconPosition="right"
                      id={column?.id}
                      onClick={(evt) => onClickMenuItem(evt, column.id)}
                      text={column?.displayName}
                      variant="link"
                    />
                    <div
                      className={classNames(
                        'duration-150',
                        'ease-in-out',
                        'lg:max-h-fit',
                        'lg:mx-0',
                        'max-h-0',
                        '-mx-3',
                        'overflow-hidden',
                        'transition-all'
                      )}
                    >
                      <ul
                        className={classNames(
                          'bg-blue-light',
                          'lg:bg-white',
                          'lg:pt-0',
                          'lg:px-0',
                          'pt-5',
                          'px-3',
                          'rounded-3xl'
                        )}
                      >
                        {column?.fields?.Links?.map((x) => {
                          const link = x as FooterLink;
                          const linkClass = link.fields?.Link?.value?.class;
                          let isScreenMeetLink = false;
                          if (linkClass?.length) {
                            isScreenMeetLink = linkClass.indexOf('azblue-cobrowse-modal') >= 0;
                          }

                          return (
                            <li key={link?.id}>
                              <LinkA11yWrapper
                                className={classNames(
                                  'hover:text-blue',
                                  'inline-block',
                                  'lg:mb-3',
                                  'mb-6',
                                  'text-blue-navy',
                                  { 'azblue-cobrowse-modal': isScreenMeetLink }
                                )}
                                field={link?.fields?.Link}
                              />
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </li>
            );
          })}
        </ul>
      </Container>
      <div className={classNames('bg-blue-navy')}>
        <Container className={classNames('container-content-width', 'container-content-padding-x')}>
          <div
            className={classNames(
              'flex',
              'flex-col',
              'gap-6',
              'lg:items-center',
              'px-0',
              'py-6',
              'text-white',
              {
                'lg:flex-row': !fields.disclaimer?.value?.length,
                'md:py-12': fields.disclaimer?.value?.length,
              }
            )}
          >
            <ul className={classNames('lg:flex', 'lg:flex-row', 'lg:gap-6', 'lg:grow')}>
              {fields?.accreditations?.map((x) => {
                const accreditation = x as Accreditation;
                return (
                  <li
                    className={classNames(
                      'flex',
                      'flex-row',
                      'items-center',
                      'gap-1',
                      'lg:max-w-[232px]',
                      'lg:mb-0',
                      'last:mb-0',
                      'mb-4',
                      'text-xs'
                    )}
                    key={accreditation?.id}
                  >
                    <div className="flex flex-col items-center text-center mt-1">
                      <a href={accreditation?.fields?.link?.value.href}>
                        <ImageWrapper field={accreditation?.fields?.image as SizedImageField} />
                        {/* <img src="https://www.urac.org/wp-content/uploads/2020/12/ACCRED_GOLD_WEB_fnl-min.png" style={{ width: '108px', height: '108px' }} />*/}
                      </a>
                      <Text field={accreditation?.fields?.title} tag="p" className="block" />
                      <p className="block">
                        {`${accreditation?.fields?.datePrefixLabel?.value}
                        ${renderFormattedDate(accreditation?.fields?.date?.value)}`}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
            <Text
              className={classNames('lg:max-w-[380px]', 'lg:text-right', 'text-xs')}
              field={fields.copyright}
              tag="p"
            />
            {fields.disclaimer?.value?.length > 0 && (
              <>
                <RichTextA11yWrapper
                  field={fields.disclaimer}
                  editable={false}
                ></RichTextA11yWrapper>
                <div className={classNames('w-full')}>
                  <div className={classNames('text-left')}>
                    <Text field={medicarePage?.fields?.doiFilingNumber} editable={false} />
                  </div>
                  {/* ======== ADDRESSED alignment in https://dev.azure.com/AZBlue/OneAZBlue/_workitems/edit/351954 ======= */}
                  <div className={classNames('text-right')}>
                    <Text field={medicarePage?.fields?.cmsFilingNumber} editable={false} />
                    {lastUpdatedDateFormatted ? (
                      <>
                        <br />
                        {i18n.t('Last Updated') + ' ' + lastUpdatedDateFormatted}
                      </>
                    ) : null}
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
