import { Project } from '.generated/templates/Project.Items.model';
import { getEnum } from 'lib/get-enum';
import { healthLibraryStoryTypes, HealthLibraryStory } from 'lib/server-utils/health-library';
import { DateTime } from 'luxon';
import { renderArticleFormattedDate } from './datetime-utils';
import { ArticleResult } from 'lib/sitecore/sitemap-fetcher/article-sitemap-service';

export const getArticleMetaData = (
  page: HealthLibraryStory | Project.Pages.Fields.NewsroomArticle | ArticleResult
) => {
  const story = (page as HealthLibraryStory).HIStoryID ? (page as HealthLibraryStory) : null;
  const article = (page as Project.Pages.Fields.NewsroomArticle).fields?.articleTitle
    ? (page as Project.Pages.Fields.NewsroomArticle)
    : null;
  const articleResult = (page as ArticleResult).articleTitle ? (page as ArticleResult) : null;

  if (story) {
    const category = healthLibraryStoryTypes[story.HIStoryTypeID];
    const publishedDate = DateTime.fromISO(story.StartDateTime);
    const updatedDate = DateTime.fromISO(story.LastUpdated);

    const publishedDateFormatted = renderArticleFormattedDate(story.StartDateTime);
    const updatedDateFormatted = renderArticleFormattedDate(story.LastUpdated);

    return {
      category,
      publishedDate,
      updatedDate,
      publishedDateFormatted,
      updatedDateFormatted,
      useSidebarLayout: false,
    };
  } else if (article) {
    const category = getEnum<string>(article.fields?.articleCategory);
    const publishedDate = DateTime.fromISO(article.fields?.publishedDate?.value || '');
    const updatedDate = DateTime.fromISO(article.fields?.lastUpdatedDate?.value || '');

    const publishedDateFormatted = renderArticleFormattedDate(article.fields?.publishedDate?.value);
    const updatedDateFormatted = renderArticleFormattedDate(article.fields?.lastUpdatedDate?.value);

    const useSidebarLayout = article.fields?.useSidebarLayout?.value || false;

    return {
      category,
      publishedDate,
      updatedDate,
      publishedDateFormatted,
      updatedDateFormatted,
      useSidebarLayout,
    };
  } else if (articleResult) {
    const category = articleResult?.articleCategory;
    const publishedDate = DateTime.fromMillis(articleResult.publishedDate);
    const updatedDate = articleResult.lastUpdatedDate
      ? DateTime.fromMillis(articleResult.lastUpdatedDate)
      : undefined;

    const publishedDateFormatted = renderArticleFormattedDate(publishedDate);
    const updatedDateFormatted = renderArticleFormattedDate(updatedDate);

    return {
      category,
      publishedDate,
      updatedDate,
      publishedDateFormatted,
      updatedDateFormatted,
    };
  }
  return {
    category: undefined,
    publishedDate: undefined,
    updatedDate: undefined,
    publishedDateFormatted: undefined,
    updatedDateFormatted: undefined,
    useSidebarLayout: false,
  };
};

export const getStoryImageUrls = (story: HealthLibraryStory) => {
  const site = 'AzBlue';
  const nRClientFilesBase = `https://${site}.netreturns.biz/Client_Files/`;
  const nRClientFilesDefault = nRClientFilesBase + 'Default/HI/System/';
  const nRClientFilesLocalized = nRClientFilesBase + site + '/HI/System/';

  const imgFolder =
    story.IsLocalized === 'true' || !story.IsNationalContent
      ? nRClientFilesLocalized
      : nRClientFilesDefault;

  let imgUrl = '';
  let imgAlt = '';
  if ([1, 2, 3].indexOf(story.HIStoryTypeID) >= 0 && story.SysImageName?.trim()) {
    imgUrl = imgFolder + story.SysImageName;

    imgAlt = story.PrimaryImgAlt;
  }

  const wideImgUrl = story.WideImgSysFileName?.trim() ? imgFolder + story.WideImgSysFileName : '';
  const wideImgAlt = story.WideImgAlt;

  const squareImgUrl = story.SquareImgSysFileName?.trim()
    ? imgFolder + story.SquareImgSysFileName
    : '';
  const squareImgAlt = story.SquareImgAlt;

  return {
    imgUrl,
    imgAlt,
    wideImgUrl,
    wideImgAlt,
    squareImgUrl,
    squareImgAlt,
  };
};
