// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import Link from 'next/link';
import { getEnum } from 'lib/get-enum';
import Button from 'components/helpers/Button/Button';
import {
  ButtonIconNames,
  ButtonIconPosition,
} from 'components/helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';

export type MediaContactProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.MediaContact;

const MediaContact = ({ fields, rendering }: MediaContactProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const iconPrimary = getEnum<ButtonIconNames>(fields?.primaryCtaIcon);
  const iconPositionPrimary = getEnum<ButtonIconPosition>(fields?.primaryCtaIconLocation);
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/mediacontact"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div className="w-full p-6 rounded-lg theme-blue bg-theme-bg text-theme-text">
        <p className="border-b pb-2 border-blue-sky text-base uppercase tracking-[0.06em] font-regular">
          {fields?.titleText?.value}
        </p>
        <div className="contact-block mt-2.5 flex flex-col items-center sm:flex-row sm:items-start">
          {fields.image?.value?.src ? (
            <div className="w-20 h-20 relative flex-none block mr-4 my-6 box-content border-2 border-white  rounded-full overflow-hidden">
              <ImageWrapper field={fields.image as SizedImageField} layout="fill" />
            </div>
          ) : (
            <></>
          )}
          <div className="flex-1 sm:py-3.5">
            <p className="text-xl leading-0 font-bold">{fields?.contactName?.value}</p>
            {fields?.emailAddress ? (
              <p className="text-base leading-4 mb-1">
                <SvgIcon
                  className={classNames('inline-block mr-2 text-btn-bg-primary')}
                  icon="email"
                  viewBox="0 0 32 20"
                  size="xs"
                />
                <Link href={`mailto:${fields?.emailAddress?.value}`}>
                  <a className="btn underline hover:no-underline">{fields?.emailAddress?.value}</a>
                </Link>
              </p>
            ) : (
              <></>
            )}
            {fields?.phoneNumber ? (
              <p className="text-base leading-4 mb-1">
                <SvgIcon
                  className={classNames('inline-block mr-2 text-btn-bg-primary')}
                  icon="phone"
                  viewBox="0 0 20 20"
                  size="xs"
                />
                <Link href={`tel:${fields?.phoneNumber?.value}`}>
                  <a className="btn no-underline">{fields?.phoneNumber?.value}</a>
                </Link>
                {fields?.teletypewriter.value.length > 0 ? (
                  <Link href={`tel:${fields?.teletypewriter?.value}`}>
                    <a className="btn no-underline"> TTY {fields?.teletypewriter?.value}</a>
                  </Link>
                ) : (
                  <></>
                )}
              </p>
            ) : (
              <></>
            )}
            <div className="mt-6">
              {fields?.primaryCta?.value?.text && (
                <Button
                  field={fields.primaryCta}
                  icon={iconPrimary}
                  iconPosition={iconPositionPrimary}
                  variant={getEnum(fields.primaryCtaStyle)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaContact;
