// Global
import classNames from 'classnames';
import { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import ExternalSvgIcon from '../ExternalSvgIcon/ExternalSvgIcon';

export type CircleIconSize = 'xs' | 'sm' | 'md' | 'lg' | 'em';

export interface CircleIconProps {
  field: SizedImageField;
  size: CircleIconSize;
  theme?: string;
  removeFill?: boolean;
}

const sizeClasses: Record<CircleIconSize, string> = {
  xs: 'w-6 h-6',
  sm: 'h-8 w-8',
  md: 'h-16 w-16',
  lg: 'h-32 w-32',
  em: 'h-em w-em',
};
const CircleSizeClasses: Record<CircleIconSize, string> = {
  xs: 'w-10 h-10',
  sm: 'h-16 w-16',
  md: 'h-22 w-22',
  lg: 'h-28 w-28',
  em: 'h-em w-em',
};

const CircleIcon = ({ field, size, theme, removeFill = true }: CircleIconProps): JSX.Element => {
  if (!field) {
    return <></>;
  }

  const iconThemeClasses = classNames(
    'border',
    'rounded-full',
    {
      'bg-blue-peacock': theme === 'theme-blue',
      'text-white': theme === 'theme-blue',
      'bg-white': theme === 'theme-white',
      'text-blue-peacock': theme === 'theme-white',
      'border-blue-peacock': theme === 'theme-white' || theme === 'theme-blue',
    },
    CircleSizeClasses[size]
  );

  return (
    <div
      className={classNames(
        'flex',
        'flex-grow',
        'shrink-0',
        'items-center',
        'justify-center',
        'm-auto',
        'fill-current',
        iconThemeClasses
      )}
    >
      <span
        className={classNames(
          sizeClasses[size],
          'flex',
          'items-center',
          'justify-center',
          'circle-icon'
        )}
      >
        <ExternalSvgIcon field={field} removeFill={removeFill} />
      </span>
    </div>
  );
};

export default CircleIcon;
