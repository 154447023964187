// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { getThemeClasses } from 'lib/get-theme';
import { ChangeEvent, useState } from 'react';
import { useRouter } from 'next/router';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';

export type SearchOverlayProps = Sitecore.Override.ComponentBase &
  Feature.Components.Search.Fields.SearchOverlay;

const SearchOverlay = ({ fields, rendering }: SearchOverlayProps): JSX.Element => {
  const router = useRouter();
  const [searchValue, setSearchValue] = useState('');
  const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';

  const redirectUrl =
    router?.asPath.split('#')[0] === fields?.searchPage?.url ? undefined : fields?.searchPage?.url;

  const audienceArray = fields?.audienceList;

  const handleSubmit = () => {
    window.location.assign(
      `${redirectUrl}#q=${searchValue.trim()}${
        audienceArray?.length
          ? '&f-taxonomy_audience=' + audienceArray?.map((a) => a.name).toString()
          : null
      }`
    );
  };

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target?.value);
  };

  if (!fields) {
    return <></>;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <div
      className={classNames(
        'w-full',
        'container-content-padding-x',
        'container-content-width',
        'anchorIdIndentifier'
      )}
      data-component="authorable/search/searchoverlay"
      id={componentAnchorId}
    >
      <div
        className={classNames(
          'md:px-28',
          'px-5',
          'py-10',
          'rounded-lg',
          getThemeClasses('theme-blue')
        )}
      >
        <div
          className={classNames(
            'flex',
            'flex-col',
            'max-w-5xl',
            'md:items-center',
            'md:justify-center',
            'sm:flex-row',
            'sm:items-start',
            'sm:mx-auto'
          )}
        >
          <div
            className={classNames(
              'flex',
              'flex-col',
              'flex-grow',
              'md:flex-grow-0',
              'items-start',
              'justify-between',
              'mb-6',
              'md:flex-row',
              'md:items-end',
              'md:p-8',
              'lg:w-3/5',
              'p-6',
              'rounded-lg',
              'shrink-0',
              'sm:mb-0',
              getThemeClasses('theme-white')
            )}
          >
            <div
              className={classNames(
                'flex-grow',
                'lg:w-2/3',
                'max-w-sm',
                'mb-6',
                'md:mb-0',
                'md:mr-7',
                'md:w-1/2',
                'shrink-0',
                'w-full'
              )}
            >
              <label
                htmlFor={`${componentAnchorId}-searchLabel`}
                className={classNames('block', 'font-bold', 'mb-3', 'text-lg')}
              >
                {fields?.searchTextLabel.value}
              </label>
              <div className={classNames('relative')}>
                <input
                  id={`${componentAnchorId}-searchLabel`}
                  className={classNames(
                    'py-3',
                    'pl-10',
                    'text-base',
                    'font-regular',
                    'border',
                    'border-gray',
                    'focus:border-blue',
                    'focus:ring-1',
                    'focus:ring-blue',
                    'rounded',
                    'w-full'
                  )}
                  value={searchValue}
                  onChange={(e) => handleInput(e)}
                  onKeyDown={(e) => isEnterKey(e) && handleSubmit()}
                  placeholder={fields?.searchBoxPlaceholder?.value}
                  enterKeyHint="search"
                />
                <div
                  className={classNames(
                    'absolute',
                    'left-2',
                    'text-xl',
                    'top-1/2',
                    '-translate-y-1/2',
                    'w-6'
                  )}
                >
                  <HeroIcon
                    icon={'MagnifyingGlassIcon'}
                    size={'em'}
                    className={classNames('text-teal')}
                  />
                </div>
              </div>
            </div>
            <Button
              text={fields.searchButtonText.value}
              variant="primary"
              onClick={() => handleSubmit()}
              disabled={searchValue === ''}
            />
          </div>
          <div className={classNames('flex', 'items-start', 'sm:ml-4', 'md:ml-9')}>
            <div className={classNames('text-sm')}>
              <HeadingWrapper
                {...fields}
                headingClassName={classNames('!font-bold')}
                headingSizeOverride="sm"
                defaultHeadlinePosition="left"
              />
              <Button field={fields.primaryCta} variant="tertiary" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
