import classNames from 'classnames';
import { useRef, useState } from 'react';
import HeroIcon, { HeroIconNames } from '../Icons/HeroIcon/HeroIcon';

export type DropdownProps = {
  children?: any;
  menuClassName?: string;
  menuItem?: Element[];
  menuItemClassName?: string;
  menuTriggerClassName?: string;
  triggerCloseIcon?: HeroIconNames;
  triggerOpenIcon?: HeroIconNames;
};

const Dropdown = ({
  children,
  menuClassName,
  menuItem,
  menuItemClassName,
  menuTriggerClassName,
  triggerCloseIcon,
  triggerOpenIcon,
}: DropdownProps) => {
  const menuRef = useRef<HTMLUListElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleKeyDownTrigger = (press: KeyboardEvent) => {
    press.key === 'ArrowUp' && setIsOpen(false);
    press.key === 'ArrowDown' && setIsOpen(true);

    if (isOpen && press.key === 'ArrowDown' && menuRef.current) {
      const menu = menuRef.current?.firstElementChild?.firstElementChild as HTMLElement;
      menu.focus();
    }
  };

  const openFunctionClasses = classNames(
    'absolute',
    'bg-white',
    'overflow-hidden',
    'right-0',
    'rounded-lg',
    'shadow-card',
    'px-4',
    'w-max',
    'z-10',
    {
      border: isOpen,
      'border-gray': isOpen,
      'max-h-0': !isOpen,
      'max-h-100': isOpen,
      'py-4': isOpen,
    }
  );

  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={menuTriggerClassName}
        onKeyDown={(e) => handleKeyDownTrigger(e as unknown as KeyboardEvent)}
      >
        More
        <HeroIcon size={'em'} icon={isOpen ? triggerCloseIcon : triggerOpenIcon} />
      </button>
      <ul
        aria-hidden="true"
        role="menu"
        className={classNames(`${openFunctionClasses}`, `${menuClassName}`)}
        tabIndex={0}
        ref={menuRef}
      >
        {menuItem?.map((menuItem) => {
          return (
            <li role="menuitem" key={menuItem.id} className={menuItemClassName}>
              {children(menuItem)}
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Dropdown;
