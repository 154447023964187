import { buildSearchBox } from '@coveo/headless';
import classNames from 'classnames';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { useRouter } from 'next/router';
import { ChangeEventHandler, Ref, forwardRef, useState } from 'react';
import { AzBlueAtomicSearchBoxProps } from '../atomic-wrappers/AzBlueAtomicSearchBox';

interface SearchBoxProps {
  callBack?: () => void;
  fields: AzBlueAtomicSearchBoxProps['fields'];
}

export const AzBlueHeadlessSearchBox = forwardRef(
  ({ callBack, fields }: SearchBoxProps, ref: Ref<HTMLInputElement>) => {
    const coveoEngine = useCoveoEngine();
    const router = useRouter();
    const [searchValue, setSearchValue] = useState('');

    const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';

    const controller = buildSearchBox(coveoEngine, { options: { numberOfSuggestions: 5 } });
    const redirectUrl =
      router?.asPath.split('#')[0] === fields?.searchPage?.url
        ? undefined
        : fields?.searchPage?.url;

    const handleSubmit = () => {
      if (redirectUrl) {
        window.location.assign(`${redirectUrl}#q=${searchValue.trim()}`);
      } else {
        controller.updateText(searchValue);

        controller.submit();
        callBack && callBack();
      }
    };

    const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
      setSearchValue(e.target?.value);
      controller.updateText(e.target?.value);
    };

    return (
      <div
        className={classNames(
          'bg-white',
          'border',
          'border-gray',
          'md-border-0',
          'flex',
          'items-center',
          'h-12',
          'md:h-8',
          'py-[1px]',
          'px-2',
          'overflow-hidden',
          'relative',
          'rounded-lg',
          'w-full'
        )}
      >
        <button
          title="click to search"
          disabled={!searchValue}
          onClick={() => handleSubmit()}
          className={classNames('text-teal', 'absolute')}
          aria-label={fields?.searchBoxPlaceholder?.value}
        >
          <HeroIcon size="xs" icon={'MagnifyingGlassIcon'} />
        </button>
        <input
          ref={ref}
          className={classNames(
            'flex-grow',
            'focus:outline-none',
            'pl-7',
            'rounded-lg',
            'text-gray-dark'
          )}
          value={searchValue}
          onChange={(e) => handleInput(e)}
          onKeyDown={(e) => isEnterKey(e) && searchValue && handleSubmit()}
          placeholder={fields?.searchBoxPlaceholder?.value}
          enterKeyHint="search"
          aria-label={fields?.searchBoxPlaceholder?.value}
        />
        <button
          className={classNames('text-gray-medium')}
          onClick={() => (searchValue ? setSearchValue('') : callBack && callBack())}
          aria-label="Clear Search"
        >
          <HeroIcon size="xxs" icon={'XMarkIcon'} />
        </button>
      </div>
    );
  }
);
