import classNames from 'classnames';
import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export type TabsWrapperProps = {
  childrenList: React.ReactNode[];
  childrenPannels: React.ReactNode[];
  disabled?: boolean;
  domRef?: (node: HTMLElement | undefined) => void;
  selectedTabClassName?: string;
  tabClassNames?: string;
  tabListClassNames?: string;
  tabPannelClassNames?: string;
};

const TabsWrapper = ({
  childrenList,
  childrenPannels,
  disabled,
  domRef,
  selectedTabClassName,
  tabClassNames,
  tabListClassNames,
  tabPannelClassNames,
}: TabsWrapperProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <Tabs
      domRef={domRef}
      selectedIndex={tabIndex}
      onSelect={(index) => setTabIndex(index)}
      disabled={disabled}
    >
      <TabList className={classNames('react-tabs__tab-list', tabListClassNames)}>
        {childrenList.map((tab, index) => {
          return (
            <Tab
              key={index}
              selectedClassName={classNames('react-tabs__tab--selected', selectedTabClassName)}
              className={classNames('react-tabs__tab', tabClassNames)}
            >
              {tab}
            </Tab>
          );
        })}
      </TabList>
      <>
        {childrenPannels.map((tab, index) => {
          return (
            <TabPanel
              key={index}
              selectedClassName="panel-selected"
              className={classNames(
                'transition-opacity',
                'duration-200',
                {
                  'opacity-0': tabIndex !== index,
                  'opacity-100': tabIndex == index,
                },
                tabPannelClassNames
              )}
            >
              {tab}
            </TabPanel>
          );
        })}
      </>
    </Tabs>
  );
};

export default TabsWrapper;
