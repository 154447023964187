// Components
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import Script from 'next/script';

type ScreenmeetScript = {
  script: string;
};
const ScreenmeetScript = (): JSX.Element => {
  const context = useSitecoreContext();
  const screenmeetScript = context.sitecoreContext?.screenmeetScript as ScreenmeetScript;
  const script = screenmeetScript?.script;

  // Fail out if we don't have any fields
  if (!script) {
    return <></>;
  }

  return (
    <>
      {/* Start: screenmeet */}

      {/* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script
        id="screenmeet-sc-script"
        strategy="beforeInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            ${script}
            `,
        }}
      />

      {/* End: screenmeet */}
    </>
  );
};

export default ScreenmeetScript;
