// Components

import { Project } from '.generated/templates/Project.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import useSectionSizeContext from 'lib/state/section-size-context';
import FeaturedArticleImage from './FeaturedArticleImage';
import FeaturedArticleText from './FeaturedArticleText';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { GetStaticComponentProps, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  ArticleResult,
  articleSitemapFetcher,
} from 'lib/sitecore/sitemap-fetcher/article-sitemap-service';
import { guidEquals } from 'lib/string-utils';
import { mediaArticleSitemapFetcher } from 'lib/sitecore/sitemap-fetcher/article-media-sitemap-service';

export type ArticleItem = Sitecore.Override.ItemEx & Project.Pages.Fields.NewsroomArticle;
export type FeaturedArticleProps = Sitecore.Override.ComponentBase & {
  featuredArticle?: ArticleResult;
};

const FeaturedArticle = ({
  fields,
  rendering,
  featuredArticle,
}: FeaturedArticleProps): JSX.Element => {
  // const [sidebysideState, setSidebysideState] = useState(false);
  // Use Section Size Context variable to determine design sidebyside or stacked
  const containerSize = useSectionSizeContext();
  const sidebysideState = containerSize === 'normal' || containerSize === 'wide';
  const context = useSitecoreContext();

  // useEffect(() => {
  //   const sideBySide = containerSize === 'normal' || containerSize === 'wide';
  //   setSidebysideState(sideBySide);
  // }, [containerSize]);

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  // wrapper CSS classes based on sideBySide condition  to determine design sidebyside or stacked
  const wrapperClassNames = sidebysideState
    ? 'flex flex-col md:flex-row md:items-stretch rounded-lg overflow-hidden border-0 md:border border-gray'
    : 'flex flex-col rounded-l-lg overflow-hidden border-0 md:border border-gray';
  const imgSectionClasses = sidebysideState
    ? 'w-full md:w-1/2 relative rounded-lg md:rounded-none overflow-hidden border-gray border md:border-0'
    : 'w-full h-full rounded-lg overflow-hidden';
  const textSectionClasses = sidebysideState
    ? 'w-full bg-white md:w-1/2 md:border-l border-gray p-6 md:p-20'
    : 'w-full bg-white border-0 rounded-lg pt-6 sm:px-7';

  const displayedFeaturedArticle =
    featuredArticle ?? context.sitecoreContext.healthLibraryFeaturedArticle;
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/article/featuredarticle"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText condition={!displayedFeaturedArticle} text="No Featured Article" />

      {displayedFeaturedArticle ? (
        <div className={classNames(wrapperClassNames)}>
          {/* Featured Article Image Section */}
          <div className={classNames(imgSectionClasses)}>
            <FeaturedArticleImage
              featuredArticle={displayedFeaturedArticle}
              sidebysideState={sidebysideState}
            />
          </div>
          {/* Featured Article Text Section */}
          <div className={classNames(textSectionClasses)}>
            <FeaturedArticleText featuredArticle={displayedFeaturedArticle} />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FeaturedArticle;

/**
 * Will be called during SSR
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 * @param {GetStaticComponentProps} context
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, _layoutData) => {
  try {
    let data = await articleSitemapFetcher.fetchArticles(
      [_layoutData.sitecore.context.language ?? 'en'],
      rendering.dataSource
    );
    if (!data.length) {
      data = await mediaArticleSitemapFetcher.fetchArticles(
        [_layoutData.sitecore.context.language ?? 'en'],
        rendering.dataSource
      );
    }
    const selectedFeaturedArticle = (rendering as Project.Pages.Fields.NewsroomFeed).fields
      ?.featuredArticle;

    // Sort by either last updated date or published date, whichever is greater
    data.sort(
      (a, b) =>
        Math.max(a.lastUpdatedDate ?? 0, a.publishedDate) -
        Math.max(b.lastUpdatedDate ?? 0, b.publishedDate)
    );

    // Either use the selected one, or get the most recent one
    const featuredArticle = selectedFeaturedArticle
      ? data.find((x) => guidEquals(x.id, selectedFeaturedArticle.id))
      : data[data.length - 1];

    return {
      ...rendering,
      rendering: rendering,
      featuredArticle: featuredArticle ?? null,
    };
  } catch (e) {
    console.error(e);
    return {
      ...rendering,
      rendering: rendering,
    };
  }
};
