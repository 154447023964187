// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import { DangerousHtml } from 'components/helpers/DangerousHtml/DangerousHtml';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import TabsWrapper from 'components/helpers/TabsWrapper/TabsWrapper';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import React, { useEffect, useState } from 'react';

export type PlanSideBySideComparison = Sitecore.Override.ItemEx &
  Feature.Components.Plans.Fields.PlanSideBySideComparison & {
    children: Plan[];
  };

export type Plan = Sitecore.Override.ItemEx &
  Feature.Data.Plans.Fields.Plan & {
    children: PlanNetwork[];
  };
export type PlanNetwork = Sitecore.Override.ItemEx &
  Feature.Data.Plans.Fields.PlanNetwork & {
    children: ComparisonDetail[];
  };
export type ComparisonDetail = Sitecore.Override.ItemEx &
  Feature.Data.Plans.Fields.ComparisonDetail;

export type PlanSideBySideComparisonProps = Sitecore.Override.ComponentBase & {
  fields: {
    root: PlanSideBySideComparison;
  };
};

const PlanSideBySideComparison = ({
  fields,
  rendering,
}: PlanSideBySideComparisonProps): JSX.Element => {
  const [isOpen, setisOpen] = useState(false);

  const isEE = useExperienceEditor();

  useEffect(() => {
    setisOpen(isEE);
  }, []);

  const getPlanHtml = (p: Plan) => {
    return (
      <>
        <div
          className={classNames(
            'divide-gray',
            'divide-y',
            'md:px-16',
            'px-5',
            'space-y-5',
            'w-full'
          )}
        >
          <div className={classNames('w-full')}>
            <h4 className={classNames('w-full', 'py-4', 'text-center', 'uppercase')}>
              {fields.root.fields.deductibleLabel.value}
            </h4>
            <div className={classNames('flex', 'justify-center', 'w-full')}>
              <span
                className={classNames('flex', 'w-1/2', 'text-center', 'flex-col', 'items-center')}
              >
                <div className={classNames('text-2xl', 'font-bolder', 'text-gray-dark')}>
                  ${(p.fields.deductibleIndividual as Field<string>)?.value}
                </div>
                <h5>{fields.root.fields.individualLabel.value}</h5>
              </span>
              <span
                className={classNames('flex', 'w-1/2', 'text-center', 'flex-col', 'items-center')}
              >
                <div className={classNames('text-2xl', 'font-bolder', 'text-gray-dark')}>
                  ${(p.fields.deductibleFamily as Field<string>)?.value}
                </div>
                <h5>{fields.root.fields.familyLabel.value}</h5>
              </span>
            </div>
          </div>
          <div className={classNames('w-full')}>
            <h4 className={classNames('w-full', 'py-4', 'text-center', 'uppercase')}>
              {fields.root.fields.outOfPocketMaxLabel.value}
            </h4>
            <div className={classNames('flex', 'justify-center', 'w-full')}>
              <span
                className={classNames('flex', 'w-1/2', 'text-center', 'flex-col', 'items-center')}
              >
                <div className={classNames('text-2xl', 'font-bolder', 'text-gray-dark')}>
                  ${(p.fields.maxOutOfPocketIndividual as Field<string>)?.value}
                </div>
                <h5>{fields.root.fields.individualLabel.value}</h5>
              </span>
              <span
                className={classNames('flex', 'w-1/2', 'text-center', 'flex-col', 'items-center')}
              >
                <div className={classNames('text-2xl', 'font-bolder', 'text-gray-dark')}>
                  ${(p.fields.maxOutOfPocketFamily as Field<string>)?.value}
                </div>
                <h5>{fields.root.fields.familyLabel.value}</h5>
              </span>
            </div>
          </div>
        </div>
        <div className={classNames('w-full', 'px-5', 'md:px-8', 'py-6')}>
          <ul
            className={classNames(
              'border-gray',
              'border-t',
              'divide-y',
              'divide-gray',
              'duration-200',
              'overflow-hidden',
              'transition-maxHeight',
              'w-full',
              {
                'border-b': isOpen,
                'max-h-0': !isOpen,
                'max-h-[1000vh]': isOpen,
              }
            )}
          >
            {p.children.map((comparison: any) => {
              return (
                <li
                  className={classNames(
                    'flex',
                    'justify-between',
                    'py-3',
                    'text-gray-dark',
                    'w-full'
                  )}
                  key={comparison.id}
                >
                  <span className={classNames('font-bolder')}>
                    <DangerousHtml html={comparison.fields.label.value} />
                  </span>
                  <span className={classNames('text-right')}>
                    <DangerousHtml html={comparison.fields.value.value} />
                  </span>
                </li>
              );
            })}
          </ul>
          <div
            className={classNames('border-gray', 'flex', 'items-center', 'justify-center', 'pt-5')}
          >
            <Button field={p.fields.viewPlanDetailCTA as LinkField} variant="tertiary" />
          </div>
        </div>
      </>
    );
  };

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/plan/plan-side-by-side-comparison"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      {fields.root.children.length == 2 && (
        <>
          <div>
            <div
              className={classNames(
                'flex',
                'flex-col',
                'gap-6',
                'items-center',
                'md:flex-row',
                'md:items-start',
                'md:justify-center',
                'w-full'
              )}
            >
              {fields.root.children.map((plan) => {
                return (
                  <div
                    className={classNames(
                      'border',
                      'border-gray',
                      'border-t-8',
                      'border-t-blue',
                      'bg-white',
                      'flex',
                      'flex-col',
                      'h-full',
                      'max-w-[506px]',
                      'md:w-1/2',
                      'rounded-lg',
                      'w-full'
                    )}
                    key={plan.id}
                  >
                    <h3 className={classNames('w-full', 'heading-sm', 'text-center', 'my-10')}>
                      {plan.fields.planName.value}
                    </h3>
                    <TabsWrapper
                      tabListClassNames={classNames(
                        '!border-b',
                        '!border-gray',
                        'flex',
                        'gap-1',
                        'justify-center'
                      )}
                      tabClassNames={classNames(
                        'bg-gray-light',
                        'border',
                        '!border-b',
                        '!border-gray',
                        '!px-6',
                        'rounded-t-[4px]'
                      )}
                      selectedTabClassName={classNames(
                        '!border-gray',
                        '!bg-white',
                        'text-blue-navy',
                        '!font-semibold'
                      )}
                      childrenList={plan.children.map((i) => i.displayName)}
                      childrenPannels={plan.children.map((i) => getPlanHtml(i as unknown as Plan))}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div
            className={classNames(
              'flex',
              'items-center',
              'justify-center',
              'md:pt-12',
              'pt-8',
              'w-full'
            )}
          >
            <Button
              disabled={isEE}
              text={
                !isOpen
                  ? fields.root.fields.showMoreDetailsText.value
                  : fields.root.fields.showLessDetailsText.value
              }
              icon={isOpen ? 'ChevronUpIcon' : 'ChevronDownIcon'}
              iconPosition="right"
              iconSize="xs"
              onClick={() => setisOpen(!isOpen)}
              variant="secondary"
              className="md:px-24"
            />
          </div>
        </>
      )}
      {fields.root.children.length != 2 && (
        <EditingHelpText
          block
          condition={true}
          text="PlanSideBySideComparison: Must have exactly two child items."
        />
      )}
    </div>
  );
};

export default PlanSideBySideComparison;
