import { Project } from '.generated/templates/Project.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import {
  AtomicDidYouMean,
  AtomicLayoutSection,
  AtomicNoResults,
  AtomicQueryError,
  AtomicResultList,
  AtomicResultsPerPage,
} from '@coveo/atomic-react';
import { buildQueryExpression } from '@coveo/headless';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import AzBlueHeadlessQuerySummary from 'components/helpers/Coveo/headless/AzBlueHeadlessQuerySummary';
import AzBlueAtomicSearchArticleResults from 'components/helpers/Coveo/result-templates/AzBlueAtomicSearchArticleResults';
import { useEffect } from 'react';
import AzBlueAtomicScrollTarget from 'components/helpers/Coveo/utilities/AzBlueAtomicScrollTarget';
import { withAtomicSearchInterface } from 'components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchInterface';
import AzBlueAtomicNewsFeedContext from 'components/helpers/Coveo/utilities/AzBlueAtomicNewsFeedContext';
import { useI18n } from 'next-localization';
import { AzBlueHeadlessPager } from 'components/helpers/Coveo/headless/AzBlueHeadlessPager';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import AzBlueAtomicSpecifiedArticlesFacets from 'components/helpers/Coveo/facets/AzBlueAtomicSpecifiedArticlesFacets';
import IsDevelopmentOrLower from 'components/helpers/Utility/IsDevelopmentOrLower/IsDevelopmentOrLower';

export type SpecifiedArticleListingProps = Sitecore.Override.ComponentBase &
  Project.Pages.Fields.SpecifiedArticleListing;

export type i18nProps = {
  ReadMore: string;
};

const SpecifiedArticleListing = ({
  fields,
  rendering,
}: SpecifiedArticleListingProps): JSX.Element => {
  if (!fields) {
    return <MissingDataSource />;
  }

  const feed = 'unknown'; // Empty string causes an error, better to show no results

  const currentDomain = new URL(process.env.PUBLIC_URL as string);
  let domainUrl = '';
  if (IsDevelopmentOrLower()) {
    domainUrl = 'https://development.azblue.com';
    console.log('currentDomain', currentDomain);
  } else {
    domainUrl = currentDomain.origin;
  }

  const healthLibraryPath = '/newsroom/health-library/';
  const healthLibraryList = fields?.healthLibraryArticles?.value.split(/\r?\n/);

  const healthLibraryListUrls = healthLibraryList?.map(
    (x) => domainUrl + healthLibraryPath + x.trim()
  );

  const feedArticlesURLs = fields?.feedArticles?.map(
    (x) => domainUrl + (x.url?.length ? x.url : '')
  );

  const newsRoomFeeds = fields?.newsRoomFeeds.map((x) => x.displayName ?? '');

  const i18n = useI18n();

  const i18nProps = {
    ReadMore: i18n.t('Read More'),
  };

  let messageExpression = '';

  const feedArticlesUrlsCoveo = feedArticlesURLs?.slice() as string[];
  let newsFeedsCoveo = newsRoomFeeds?.slice() as string[];
  if (newsFeedsCoveo.length == 0) {
    newsFeedsCoveo = ['unknown'];
  }
  const healthLibraryUrlsCoveo = healthLibraryListUrls?.slice() as string[];

  const expression = buildQueryExpression()
    //adds news_feeds into the search
    .addStringField({
      negate: false,
      field: 'news_feed',
      operator: 'contains',
      values: newsFeedsCoveo,
    })
    //adds or clause
    .joinUsing('or')
    //adds all selected sitecore articles URIs into the search
    .addStringField({
      negate: false,
      field: 'uri',
      operator: 'contains',
      values: feedArticlesUrlsCoveo,
    })
    .joinUsing('or')
    //adds all healthLibrary Article GuiD's URIs into the search
    .addStringField({
      negate: false,
      field: 'uri',
      operator: 'contains',
      values: healthLibraryUrlsCoveo,
    });
  messageExpression = expression.toQuerySyntax();

  if (IsDevelopmentOrLower()) console.log('messageExpression', messageExpression);

  useEffect(() => {
    const styleResultList = document.createElement('style');
    const shadowResultList = document.querySelector('atomic-result-list');
    styleResultList.textContent = `
     [part="result-list"] {
       display: flex !important;
       gap: 20px !important;
       grid-auto-flow: row !important;
       grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
       width: 100% !important;
     }
     .result-component{
       width: auto !important;
     }
     .list-root.display-list.density-normal [part~="outline"]::before {
       display: none !important;
     }
     @media (min-width: 769px) {
       [part="result-list"]{
         display: grid !important;
       }
     }
   `;
    shadowResultList?.shadowRoot?.appendChild(styleResultList);
  }, []);
  const componentAnchorId = rendering?.uid;

  return (
    <Container
      dataComponent="authorable/article/specifiedArticleListing"
      id={componentAnchorId}
      className="anchorIdIndentifier article-list"
    >
      <AzBlueAtomicScrollTarget />
      <AzBlueAtomicNewsFeedContext feed={feed} additionalQuery={messageExpression} />
      <AzBlueAtomicSpecifiedArticlesFacets fields={fields} rendering={rendering} />
      <AtomicLayoutSection section="results">
        <AzBlueHeadlessQuerySummary />
        <AtomicResultList
          // Coveo Implementation of grid is not great and breaks surrounding newsroom styles
          display="list"
          imageSize="large"
          template={(result) => {
            return AzBlueAtomicSearchArticleResults(result, i18nProps);
          }}
        />
        <div className={classNames('hidden')}>
          <AtomicResultsPerPage choicesDisplayed="6" initialChoice={6} />
        </div>
        <AzBlueHeadlessPager />
        <AtomicQueryError />
        <AtomicNoResults />
        <AtomicDidYouMean />
      </AtomicLayoutSection>
    </Container>
  );
};
export default withAtomicSearchInterface(SpecifiedArticleListing);
