import classNames from 'classnames';
import { AzBlueAtomicSearchBoxProps } from 'components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchBox';
import { AzBlueHeadlessSearchBox } from 'components/helpers/Coveo/headless/AzBlueHeadlessSearch';
import { Ref, forwardRef } from 'react';

export interface MobileGlobalHeaderSearchBarProps {
  callBack: () => void;
  fields: AzBlueAtomicSearchBoxProps['fields'];
}

const MobileGlobalHeaderSearchBar = forwardRef(
  ({ callBack, fields }: MobileGlobalHeaderSearchBarProps, ref: Ref<HTMLInputElement>) => {
    return (
      <>
        <div
          data-component="authorable/search/MobileGlobalHeaderSearchBar"
          className={classNames(
            'text-center',
            'mobile-global-header-search-bar',
            'justify-center',
            'header-navList-menuItem align-middle duration-200 ease-in-out inline-flex items-center px-5',
            'w-full'
          )}
        >
          <div
            className={classNames(
              'mobile-global-header-search-box-div',
              'transition-maxHeight',
              'duration-200',
              'w-full'
            )}
          >
            <ul>
              <li>
                <AzBlueHeadlessSearchBox callBack={callBack} ref={ref} fields={fields} />
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
);

export default MobileGlobalHeaderSearchBar;
