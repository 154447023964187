import classNames from 'classnames';
import ImageWrapper, { SizedImageField } from '../ImageWrapper/ImageWrapper';
import { useRef, useState } from 'react';
import HeroIcon from '../Icons/HeroIcon/HeroIcon';
import Button from '../Button/Button';
import LinkA11yWrapper from '../LinkA11yWrapper/LinkA11yWrapper';
import { useClickAway } from 'lib/utils/component-util';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Feature } from '.generated/templates/Feature.Items.model';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';
import { AzBlueHeadlessSearchBoxForPopUp } from '../Coveo/headless/AzBlueHeadlessSearchForPopUp';

export type PopupCTAItem = Sitecore.Override.ItemEx &
  Feature.Data.Popups.Fields.PopupCta & {
    children: (Sitecore.Override.ItemEx & Feature.Data.Popups.Fields.PopupCtaLink)[];
  };

export type PopUpCTAProps = { popupCta: PopupCTAItem };
const PopUpCTA = ({ popupCta }: PopUpCTAProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenAnimate, setIsOpenAnimate] = useState(false);

  const setIsOpenHelper = (newValue: boolean) => {
    setIsOpen(newValue);
    setTimeout(() => setIsOpenAnimate(newValue), 0);
  };
  const modalClickAway = () => {
    setIsOpenHelper(false);
  };
  const popupRef = useRef<HTMLDivElement>(null);

  useClickAway([popupRef], modalClickAway);

  const primaryLinkList = popupCta?.children.filter((item) => {
    return item.fields.usePrimaryStyle.value;
  });

  const searchItem = popupCta?.children.filter((item) => {
    return !item.fields.usePrimaryStyle.value && item.displayName === 'Search';
  });

  const secondaryLinkList = popupCta?.children.filter((item) => {
    return !item.fields.usePrimaryStyle.value && item.displayName !== 'Search';
  });

  return (
    <div
      data-coveo-no-index="true"
      className={classNames('bottom-9', 'fixed', 'right-0', 'md:right-12', 'z-40', {
        'before:fixed': isOpenAnimate,
        'before:w-full': isOpenAnimate,
        'before:h-full': isOpenAnimate,
        'before:bg-gray-dark': isOpenAnimate,
        'before:opacity-60': isOpenAnimate,
        'before:top-0': isOpenAnimate,
        'before:left-0': isOpenAnimate,
        'before:-z-1': isOpenAnimate,
      })}
      data-component="helpers/popupcta"
    >
      <ConditionalRender condition={isOpen}>
        <div
          className={classNames(
            'absolute',
            'bottom-0',
            'duration-200',
            'md:min-w-[380px]',
            'origin-bottom-right',
            'right-0',
            'shadow-card',
            'md:w-full',
            'w-screen',
            'w-max',
            'overflow-hidden',
            'z-50',
            'px-5',
            'scale-0',
            {
              '!scale-100': isOpenAnimate,
              'transition-none': !isOpenAnimate,
              'transition-transform': isOpenAnimate,
            }
          )}
          ref={popupRef}
        >
          <button
            className={classNames(
              'bg-blue-navy',
              'cursor-pointer',
              'flex',
              'items-center',
              'justify-between',
              'p-5',
              'rounded-t-md',
              'text-white',
              'w-full'
            )}
            onClick={() => setIsOpenHelper(false)}
          >
            <span className={classNames('heading-xs')}>
              {popupCta?.fields?.popupTitleText.value}
            </span>
            <HeroIcon size="xs" icon={'XMarkIcon'} />
          </button>
          <div className={classNames('px-5', 'bg-white', 'rounded-b-md')}>
            <ul>
              {searchItem && searchItem?.length > 0 && (
                <li
                  key={searchItem[0]?.id}
                  className={classNames(
                    'border-b',
                    'border-gray',
                    'flex',
                    'gap-4',
                    'py-5',
                    'relative'
                  )}
                >
                  <AzBlueHeadlessSearchBoxForPopUp item={searchItem[0]} />
                </li>
              )}
              {primaryLinkList?.map((item) => {
                return (
                  <li
                    key={item.id}
                    className={classNames(
                      'border-b',
                      'border-gray',
                      'flex',
                      'gap-4',
                      'py-5',
                      'relative'
                    )}
                  >
                    <ImageWrapper
                      imageClass=""
                      removeFill
                      field={
                        {
                          ...item?.fields.iconImage,
                          value: {
                            ...item?.fields.iconImage.value,
                            width: '36px',
                            height: '36px',
                          },
                        } as SizedImageField
                      }
                    />
                    <div
                      className={classNames(
                        'flex',
                        'flex-grow',
                        'items-center',
                        'justify-between',
                        'shrink-0',
                        'text-left',
                        '[&>span]:w-full',
                        '[&>span]:block'
                      )}
                    >
                      <Button
                        field={item.fields.Link}
                        className={classNames(
                          '!justify-start',
                          'stretched-link',
                          '!static',
                          '!w-full',
                          '[&>span]:ml-auto',
                          '[&>span]:!mr-0'
                        )}
                        variant="tertiary"
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
            <ul className={classNames('py-4')}>
              {secondaryLinkList?.map((item) => {
                return (
                  <li key={item.id} className={classNames('flex', 'gap-4', 'py-2', 'relative')}>
                    <ImageWrapper
                      imageClass=""
                      removeFill
                      field={
                        {
                          ...item?.fields.iconImage,
                          value: {
                            ...item?.fields.iconImage.value,
                            width: '24px',
                            height: '24px',
                          },
                        } as SizedImageField
                      }
                    />
                    <LinkA11yWrapper
                      className={classNames('text-blue', 'stretched-link')}
                      field={item.fields.Link}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </ConditionalRender>
      <button
        className={classNames(
          'bg-btn-bg-primary',
          'duration-500',
          'flex',
          'flex-col',
          'items-center',
          'justify-center',
          'h-[85px]',
          'hover:bg-btn-bg-primary-hover',
          'overflow-visible',
          'p-3',
          'rounded-full',
          'shadow-xl',
          'text-blue-navy',
          'w-[85px]',
          {
            'transition-none': !isOpenAnimate,
            'transition-opacity': isOpenAnimate,
            'opacity-0': isOpenAnimate,
          }
        )}
        onClick={() => setIsOpenHelper(true)}
        aria-label={popupCta?.fields.iconText.value || popupCta?.fields?.popupTitleText.value}
      >
        <ImageWrapper
          imageClass=""
          removeFill
          field={
            {
              ...popupCta?.fields.iconImage,
              value: {
                ...popupCta?.fields.iconImage.value,
                width: popupCta?.fields.iconText.value ? '34px' : '60px',
                height: popupCta?.fields.iconText.value ? '34px' : '60px',
              },
            } as SizedImageField
          }
        />
        {popupCta?.fields.iconText.value}
      </button>
    </div>
  );
};

export default PopUpCTA;
