// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
// import { useState } from 'react';
//type FindDoctorProps = Sitecore.Override.ComponentBase
export type PriorAuthLookupProps = Sitecore.Override.ComponentBase &
  Feature.Components.PriorAuthLookup.Fields.PriorAuthLookup;
export type ResultMessages = Feature.Data.Priorauth.ResultMessages.Fields.ResultMessages;
//Feature.Components.PriorAuthLookup.Fields

const PriorAuthLookup = ({ fields, rendering }: PriorAuthLookupProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  // const [selection, setSelection] = useState();
  const resultMessages = fields.defaultResults as unknown as ResultMessages;
  console.log('this is a prior auth component', fields);
  console.log('this is a prior auth result messages', resultMessages);

  const componentAnchorId = rendering?.uid;

  function updateSelection(value: any): void {
    console.log('updateSelection called', value);
  }

  return (
    // the main container that has the theme
    <Container
      theme="theme-blue--light"
      dataComponent="authorable/priorauthlookup"
      className={classNames('')}
      id={componentAnchorId}
      fullWidth={true}
    >
      {/* the main element that controls the padding for all content */}
      <div
        className={classNames(
          'priorauth-content-body',
          'container-content-padding-x',
          'md:space-y-12',
          'space-y-8'
        )}
      >
        {/* the heading section of the form */}
        <section className={classNames('priorauth-heading')}>
          <h2 className={classNames('text-5xl leading-[70px] font-light')}>
            {fields?.priorAuthTitle?.value}
          </h2>
        </section>

        <section className={classNames('priorauth-options-section')}>
          <label htmlFor={`${componentAnchorId}-selectLabel`}>{fields?.optionsLabel?.value}</label>

          <FormSelectList
            id={`${componentAnchorId}-selectLabel`}
            name={fields?.optionsLabel?.value}
            placeholder={undefined}
            options={[]}
            // {fields.dropdownOptions.map((option) => {
            //   return {
            //     value: (option.fields.optionText as Field<string>).value,
            //     label: (option.fields.optionText as Field<string>).value,
            //   };
            // })}
            callBackChange={(e) => updateSelection(e.value)}
          />
        </section>

        {/* the form section */}
        <div
          className={classNames(
            'priorauth-form-shell',
            'rounded-lg bg-white shadow-card min-h-[650px]'
          )}
        >
          <div className="border-b border-gray border-l border-r  md:p-8 rounded-b-lg p-5 text-gray-dark border-t rounded-t-lg min-h-[650px]">
            <div
              className={classNames(
                'priorauth-form-body',
                'md:grid',
                'grid-cols-2',
                'md:gap-5',
                'lg:gap-12',
                'space-y-6',
                'md:space-y-0'
              )}
            >
              <div
                className={classNames('mb-10', 'md:mb-0', 'space-y-6', 'divide-solid', 'divide-x')}
              >
                PriorAuthLookup Form Component
              </div>

              {/* <div className="h-px bg-gray-300 md:hidden"></div>
              <div className="hidden md:block w-px bg-gray-300"></div> */}
              <div>Prior Auth Default:Results Component</div>
            </div>
          </div>
        </div>

        <div className={classNames('priorauth-footer', 'pb-6')}>
          <RichTextA11yWrapper field={fields?.footerText}></RichTextA11yWrapper>
        </div>
      </div>
    </Container>
  );
};

export default PriorAuthLookup;
