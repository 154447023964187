// Components
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { landingPageName } from 'lib/utils/landing-page-utils';
import NextLink from 'next/link';

export interface BreadcrumbProps {
  fields?: {
    breadcrumb: Sitecore.Override.ItemEx[];
  };
}

const Breadcrumb = ({ fields }: BreadcrumbProps): JSX.Element => {
  const context = useSitecoreContext();
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const hide: boolean = fields?.breadcrumb.length < 2;

  return (
    <>
      {!hide && (
        <div
          data-coveo-no-index
          data-component="authorable/site/breadcrumb"
          className={classNames(
            'border-gray-light',
            'border-y',
            'hidden',
            'py-4',
            'px-12',
            'sm:block',
            'w-full'
          )}
        >
          <ul className={classNames('flex', 'items-center')}>
            {fields?.breadcrumb.map((i, index) => {
              if (i.displayName === '$') {
                i.displayName = context.sitecoreContext.healthLibraryArticle?.Headline;
              }
              if (index === 1 && i.displayName === landingPageName) {
                return null;
              }
              return (
                <li key={i.id} className={classNames('flex', 'items-center', 'mr-3', 'text-xs')}>
                  {index === fields?.breadcrumb.length - 1 ? (
                    <div
                      title={i.displayName}
                      className={classNames('max-w-[208px]', 'truncate', 'hover:overflow-visible')}
                    >
                      {i.displayName}
                    </div>
                  ) : (
                    <>
                      <NextLink href={i.url ?? ''}>
                        <a
                          href={i.url}
                          title={i.displayName}
                          className={classNames('max-w-[208px]', 'truncate')}
                        >
                          {i.displayName}
                        </a>
                      </NextLink>
                      <HeroIcon
                        size={'em'}
                        icon={'ChevronRightIcon'}
                        className={classNames('text-gray-medium', 'ml-3')}
                      />
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

export default Breadcrumb;
