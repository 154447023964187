// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import Container from 'components/helpers/Container/Container';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import AccordionFAQItem from 'components/authorable/faqs/AccordionFAQList/AccordionFAQItem';
import classNames from 'classnames';
import { HeadingPositionProps } from 'components/helpers/Heading/Heading';
import { getEnum, getEnumValueOverRide } from 'lib/get-enum';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import CtaGroup, { CtaAlignment } from 'components/helpers/CtaGroup/CtaGroup';
import { useState } from 'react';

export type AccordionFAQListProps = Sitecore.Override.ComponentBase &
  Feature.Components.Faqs.Fields.AccordionFaqList;

const AccordionFAQList = ({ fields, rendering }: AccordionFAQListProps): JSX.Element => {
  const [open, setOpen] = useState<number | undefined>(-1);
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const headlinePosition = getEnum<HeadingPositionProps>(fields.headlinePosition);
  const isMultiple = fields.allowMultiple.value;
  const componentAnchorId = rendering?.uid;

  return (
    <Container
      dataComponent="authorable/faqs/accordionfaqlist"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div
        className={classNames('flex', 'flex-col', {
          'md:flex-row': headlinePosition === 'left',
          'md:justify-between': headlinePosition === 'left',
        })}
      >
        <div
          className={classNames('flex', 'flex-col', {
            'md:mr-8': headlinePosition === 'left',
            'items-start': headlinePosition === 'left',
            'items-center': headlinePosition !== 'left',
            'md:mb-8': headlinePosition !== 'left',
            'mb-4': headlinePosition !== 'left',
            'md:mb-0': headlinePosition === 'left',
            'mb-5': headlinePosition === 'left',
          })}
        >
          <HeadingWrapper {...fields} />
          <CtaGroup
            className={classNames('mt-8')}
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${headlinePosition as CtaAlignment}`)}
          />
        </div>
        <ul
          className={classNames({
            'md:w-2/3': headlinePosition === 'left',
            'md:flex-grow': headlinePosition === 'left',
            'md:shrink-0': headlinePosition === 'left',
          })}
        >
          {fields?.selectedFaqs.map((i, index) => {
            const faqItem = i as Sitecore.Override.ItemEx & Feature.Data.Faqs.Fields.Faq;
            return (
              <AccordionFAQItem
                key={faqItem.id}
                open={open === index}
                onToggle={() => setOpen((o) => (o === index ? undefined : index))}
                multiple={isMultiple}
                question={faqItem.fields?.questionText}
                answer={faqItem.fields?.answerText}
              />
            );
          })}
        </ul>
      </div>
    </Container>
  );
};

export default AccordionFAQList;
