// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { GetStaticComponentProps } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { getThemeClasses, Theme } from 'lib/get-theme';
import CountUp from 'react-countup';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';

export type CountupTimerProps = Sitecore.Override.ComponentBase &
  Feature.Components.CountupTimers.Fields.CountupTimer & {
    total_submissions: number;
  };

const CountupTimer = ({ fields, rendering, ...props }: CountupTimerProps): JSX.Element => {
  if (!fields) {
    return <MissingDataSource />;
  }

  /***Enumhandler/Fields from Sitecore***/
  const componentAnchorId = rendering?.uid;
  const themeValue = fields?.backgroundColor?.value as Theme;
  const isNoImage = fields?.iconImage?.value?.asset === null;
  const isBlueLight = fields?.backgroundColor?.value === 'theme-blue--light';
  const isPeacock = fields?.backgroundColor?.value === 'theme-blue--peacock';
  const isStacked = fields?.displayOption?.value === 'Stacked';
  const src = fields?.iconImage?.value?.src;

  return (
    <div
      data-component="authorable/general/countuptimer"
      className={classNames('anchorIdIndentifier', getThemeClasses(themeValue), 'text-theme-text', {
        'bg-blue': isPeacock,
      })}
      id={componentAnchorId}
    >
      <div
        className={classNames(
          'md:grid',
          'md:gap-7',
          'lg:gap-10',
          'items-center',
          'py-6',
          {
            'lg:py-10': isPeacock,
          },
          {
            'pl-4': !isPeacock,
          }
        )}
      >
        <div
          className={classNames('flex', 'mb-1', 'md:mb-0', {
            'justify-center': isStacked,
          })}
        >
          <div className={classNames('flex-row')}>
            <div className={classNames('flex', 'items-start', 'mb-1', 'md:mb-0')}>
              <div
                className={classNames('flex', 'flex-row', 'pr-4', 'ml-1', { hidden: isStacked })}
              >
                <ImageWrapper
                  field={{
                    value: {
                      src: src,
                      width: '71px',
                      height: '77px',
                    },
                  }}
                />
              </div>

              <div
                className={classNames(
                  'flex',
                  'flex-row',
                  { 'border-l-[1px]': !isNoImage },
                  'border-[#0099d8]',
                  {
                    'border-[#0b3b60]': isBlueLight,
                  },
                  { 'pl-4': !isStacked },
                  { 'pl-0': isNoImage },
                  'flex-col',
                  'text-[56px]',
                  'h-20',
                  'leading-[42px]',
                  { 'border-l-[0]': isStacked },
                  {
                    'items-center': isStacked,
                  }
                )}
              >
                <CountUp
                  start={0}
                  duration={6}
                  end={props?.total_submissions}
                  className={classNames(
                    'flex',
                    'flex-row',
                    'font-light',
                    { 'text-blue-peacock': !isPeacock },
                    {
                      '!text-blue-navy': isBlueLight,
                    }
                  )}
                />
                <div
                  className={classNames(
                    'flex-col',
                    'whitespace-nowrap',
                    'text-base',
                    'leading-[56px]'
                  )}
                >
                  {fields?.pledge?.value}
                </div>
              </div>
            </div>
            <div
              className={classNames(
                { hidden: isNoImage },
                'flex-col',
                'text-center',
                'text-base',
                { 'border-t-[1px]': !isNoImage },
                'border-[#0099d8]',
                { 'border-[#ffffff]': isPeacock },
                'mt-3',
                'pt-4',
                { 'border-none': !isStacked }
              )}
            ></div>
            <div
              className={classNames('flex', 'flex-row', 'justify-center', { hidden: !isStacked })}
            >
              <ImageWrapper
                field={{
                  value: {
                    src: src,
                    width: '71px',
                    height: '77px',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * Will be called during SSR
 * @param {ComponentRendering} rendering
 * @param {LayoutServiceData} layoutData
 * @param {GetStaticComponentProps} context
 */
export const getStaticProps: GetStaticComponentProps = async (rendering, _layoutData) => {
  const formid = (rendering?.fields as CountupTimerProps['fields'])?.formID?.value;
  const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${process.env.FORMSTACK_API_KEY}`,
    },
  };
  const Apiurl = `https://www.formstack.com/api/v2/form/${formid}/count`;
  const total_submissions = await fetch(Apiurl, options)
    .then((response) => response.json())
    .then((response) => response.total_submissions)
    .catch((err) => console.error(err));
  return {
    total_submissions,
  };
};
export default CountupTimer;
