import ModalStandard from 'components/helpers/Modals/ModalStandard/ModalStandard';
import { useState, useEffect } from 'react';
import Button from '../../Button/Button';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { useCookies } from 'react-cookie';

export type BlockingModalProps = Feature.Data.Modals.Fields.BlockingModal;

const BlockingModal = ({ fields }: BlockingModalProps): JSX.Element => {
  const [showModal, setShowModal] = useState(true);
  const [cookies, setCookie] = useCookies(['disclaimer-acceptance']);

  if (!fields) {
    return <></>;
  }
  const handleAccept = () => {
    setShowModal(false);
    setCookie('disclaimer-acceptance', encodeURIComponent('User Acepted'));
    cookies['disclaimer-acceptance'];
  };

  useEffect(() => {
    const app = document.getElementById('__next');
    showModal ? app?.classList.add('blur-sm') : app?.classList.remove('blur-sm');
    showModal
      ? app?.classList.add('overflow-x-hidden')
      : app?.classList.remove('overflow-x-hidden');
  }, [showModal]);

  return (
    <ModalStandard backdrop="static" externalTrigger showModal={showModal} showCloseButton={false}>
      <div className="w-full mb-8 heading-md">
        <RichTextA11yWrapper field={fields?.title} editable />
      </div>
      <div className="w-full flex flex-col items-center">
        {fields?.disclaimerText?.value && (
          <div className="my-4">
            <RichTextA11yWrapper field={fields?.disclaimerText} editable />
          </div>
        )}
      </div>
      <div className={classNames('flex', 'justify-center', 'mt-4', 'items-center', 'gap-2')}>
        <Button text={fields.acceptButtonText.value} variant="primary" onClick={handleAccept} />
        <Button
          text={fields.declineButtonText.value}
          variant="secondary"
          onClick={() => {
            window.history.length > 1 ? window.history.back() : window.location.assign('/');
          }}
        />
      </div>
    </ModalStandard>
  );
};

export default BlockingModal;
