// Global
import classNames from 'classnames';

// Components
import SvgIcon, { SvgIconNames, isSvgIcon } from 'components/helpers/Icons/SvgIcon/SvgIcon';
import HeroIcon, { HeroIconNames } from 'components/helpers/Icons/HeroIcon/HeroIcon';

export type IconName = HeroIconNames | SvgIconNames | 'loading';

export type IconSize = 'xxxs' | 'xxs' | 'xs' | 'smr' | 'sm' | 'md' | 'lg' | 'em';

export interface ContentMangedIconProps {
  size?: IconSize;
  icon?: IconName;
  mini?: boolean;
  outline?: boolean;
  className?: string;
}

export const iconSizeClasses: Record<IconSize, string> = {
  xxxs: 'h-3 w-3',
  xxs: 'h-4 w-4',
  xs: 'h-6 w-6',
  sm: 'h-8 w-8',
  smr: 'h-8 w-8',
  md: 'h-16 w-16',
  lg: 'h-32 w-32',
  em: 'h-em w-em',
};
export const iconSizes: Record<IconSize, string> = {
  xxxs: '0.75rem',
  xxs: '1rem',
  xs: '1.5rem',
  smr: '1.75rem',
  sm: '2rem',
  md: '4rem',
  lg: '8rem',
  em: '1em',
};

const ContentMangedIcon = ({
  icon,
  size = 'xs',
  mini,
  outline,
  className,
}: ContentMangedIconProps): JSX.Element => {
  // We have a special loading icon
  const isLoading = icon === 'loading';
  const isSvgIconFlag = isSvgIcon(icon as string);
  const svgIconClass = className !== undefined && className !== '' ? className : 'text-blue-navy';
  const heroIconClass = className !== undefined && className !== '' ? className : '';
  return (
    <>
      {isLoading ? (
        <div
          className={classNames(
            'animate-spin',
            iconSizeClasses[size],
            'border-2',
            'rounded-full',
            'border-b-transparent'
          )}
        >
          <span className="sr-only">Loading...</span>
        </div>
      ) : isSvgIconFlag ? (
        <SvgIcon
          icon={icon as SvgIconNames}
          size="xs"
          viewBox={'0 0 16 16'}
          className={classNames('overflow-visible', svgIconClass)}
        />
      ) : (
        <HeroIcon
          icon={icon as HeroIconNames}
          mini={mini}
          outline={outline}
          size={size}
          className={heroIconClass}
        />
      )}
    </>
  );
};

export default ContentMangedIcon;
