import classNames from 'classnames';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
// Components
import Container from 'components/helpers/Container/Container';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
//data models
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type InfographicCardListingProps = Sitecore.Override.ComponentBase & {
  fields?: {
    selectedCards: (Sitecore.Override.ItemEx & Feature.Data.Cards.Fields.InfographicCard)[];
  };
};

const InfographicCardListing = ({
  fields,
  rendering,
}: InfographicCardListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/card-listings/infographiccardlisting"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      {fields.selectedCards.length ? (
        <div
          className={classNames(
            'w-full h-full',
            'flex flex-1 flex-col md:flex-row md:flex-nowrap justify-center items-center md:items-stretch'
          )}
        >
          {fields.selectedCards.map((infoCard) => {
            return (
              <div
                key={infoCard.id}
                className={classNames(
                  'w-full h-auto max-w-sm py-12 first:pt-0 md:py-0 md:px-10',
                  'border-blue-peacock border-b border-r-0 md:border-b-0 md:border-r last:border-0',
                  'flex flex-col'
                )}
              >
                <div
                  className={classNames(
                    'w-full h-[70px] mb-5 md:mb-4 flex flex-row flex-nowrap items-end',
                    'text-blue-peacock text-4xl md:text-2xl lg:text-4xl font-light',
                    'whitespace-nowrap'
                  )}
                >
                  <span className={classNames('w-[70px] h-full flex items-center mr-4')}>
                    <ImageWrapper
                      field={
                        {
                          ...infoCard?.fields?.iconImage,
                          value: {
                            ...infoCard?.fields?.iconImage?.value,
                            width: '70px',
                            height: '70px',
                          },
                        } as SizedImageField
                      }
                      skipExternalSvg
                      layout="intrinsic"
                    />
                  </span>
                  <span
                    className={classNames(
                      'w-full h-[70px] flex flex-row flex-nowrap items-center',
                      'text-blue-peacock text-4xl md:text-2xl lg:text-4xl font-light',
                      'whitespace-nowrap'
                    )}
                  >
                    <Text field={infoCard.fields.headlineText} />
                  </span>
                </div>
                <div className={classNames('w-full')}>
                  <div className={classNames('text-base font-regular text-gray-darkest')}>
                    <RichTextA11yWrapper field={infoCard.fields.descriptionText} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default InfographicCardListing;
