// Components
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { LargeMediaPromoProps } from './LargeMediaPromo';
import classNames from 'classnames';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';

const LargeMediaPromoText = ({ fields, rendering }: LargeMediaPromoProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <div className="w-full md:max-w-[30rem] mx-auto md:mx-0 my-2 md:my-36">
      <HeadingWrapper
        {...fields}
        defaultHeadlinePosition="left"
        eyebrowClassName={classNames(
          'bg-blue-sky',
          'font-bolder',
          'max-w-max',
          'mb-4',
          'px-2',
          'py-0.5',
          'rounded-full',
          'text-blue-navy',
          'text-xs'
        )}
      />
      <PlaceholderWrapper
        wrapperSize="half"
        name="BCBSAZ-Additional-Content"
        rendering={rendering}
      />
      <div className="my-2 md:my-8">
        <CtaGroup {...fields} />
      </div>
    </div>
  );
};

export default LargeMediaPromoText;
