// Components
const ScreenMeetAllowMultiDomain = (): JSX.Element => {
  return (
    <>
      <iframe
        id="cbSessionCarrier"
        width="1"
        height="1"
        style={{ display: 'none' }}
        referrerPolicy="origin"
        src="https://edge.screenmeet.com/v3/cobrowse/multidomain"
      ></iframe>
    </>
  );
};

export default ScreenMeetAllowMultiDomain;
