// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { LayoutTwoColumnShell } from 'components/authorable/layout/LayoutComponents/LayoutTwoColumn/LayoutTwoColumn';
import Container from 'components/helpers/Container/Container';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import ModalStandard from 'components/helpers/Modals/ModalStandard/ModalStandard';
import VideoItem from 'components/helpers/VideoItem/VideoItem';
import { getEnum } from 'lib/get-enum';
import { useState } from 'react';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import CtaGroup from 'components/helpers/CtaGroup/CtaGroup';

export type MediaPromoPlacement = 'right' | 'left';

export type MediaPromoProps = Sitecore.Override.ComponentBase &
  Feature.Components.Promo.Fields.MediaPromo;

const MediaPromo = ({ fields, rendering }: MediaPromoProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const mediaPlacement = getEnum<MediaPromoPlacement>(fields?.mediaPlacement);
  const mediaLeft: string | undefined = 'left';

  const youTubeId = fields?.youtubeUrl.value && fields.youtubeUrl.value?.match(/[\w\-]{11,}/)?.[0];

  const promoContent = () => {
    return (
      <>
        {/* MAY NEED TO ADD DESCRIPTION CLASS TO <HEADINGWRAPPER /> AFTER https://dev.azure.com/AZBlue/OneAZBlue/_workitems/edit/338354 */}
        <HeadingWrapper
          {...fields}
          headlinePosition={{ name: '', fields: { Value: { value: 'left' } } }}
        />
        <PlaceholderWrapper
          wrapperSize="half"
          name="BCBSAZ-Additional-Content"
          rendering={rendering}
        />
        <CtaGroup className={classNames('mt-8')} {...fields} />
      </>
    );
  };

  const promoVideoThumbnail = () => {
    // Image takes precedence over video, no thumbnail
    if (fields?.image?.value?.src) {
      return null;
    }
    // Manual thumbnail takes precedence
    if (fields.videoThumbnail.value?.src) {
      return (
        <ImageWrapper
          imageClass={classNames('static', '!z-10', 'h-full', 'left-0', 'top-0', 'w-full')}
          field={fields.videoThumbnail as SizedImageField}
          layout="responsive"
        />
      );
    }
    // YouTube can auto populate thumbnail
    if (fields.youtubeUrl.value) {
      return (
        <ImageWrapper
          imageClass={classNames('static', '!z-10', 'h-full', 'left-0', 'top-0', 'w-full')}
          field={{
            value: {
              src: `https://img.youtube.com/vi/${youTubeId}/0.jpg`,
              alt: 'youtube-thumbnail',
              height: 'auto',
              width: '100%',
            },
          }}
        />
      );
    }
    // Vimeo can no longer access automatic thumbnail without an API account
    if (fields.vimeoUrl.value) {
      return <EditingHelpText condition={true} text="Must specify thumbnail for Vimeo videos" />;
    }
    return null;
  };
  const promoMedia = () => {
    return (
      <>
        {fields?.image?.value?.src ? (
          <div
            className={classNames('overflow-hidden', 'max-w-max', 'flex', 'rounded-lg', {
              'mb-8': mediaPlacement === mediaLeft,
              'md:mb-0': mediaPlacement === mediaLeft,
            })}
          >
            <ImageWrapper field={fields.image as SizedImageField} />
          </div>
        ) : (
          <>
            <div className={classNames('relative', 'rounded-lg', 'overflow-hidden')}>
              {promoVideoThumbnail()}
              <button
                className={classNames('absolute', 'top-0', 'bottom-0', 'left-0', 'right-0', 'z-10')}
                onClick={() => setShowModal(true)}
                role="presentation"
                aria-label={`Play video: ${
                  fields.videoTitle.value.length > 0
                    ? fields.videoTitle.value
                    : 'video title not set'
                }`}
              >
                <span
                  className={classNames(
                    'absolute',
                    'top-0',
                    'bottom-0',
                    'left-0',
                    'right-0',
                    'bg-black',
                    'opacity-20'
                  )}
                />
                <span
                  className={classNames(
                    'absolute',
                    '-translate-y-1/2',
                    '-translate-x-1/2',
                    'w-12',
                    'md:w-16',
                    'h-12',
                    'md:h-16',
                    'top-1/2',
                    'left-1/2',
                    'text-white',
                    'rounded-full'
                  )}
                >
                  <SvgIcon
                    icon="play-button"
                    size="md"
                    viewBox="0 0 64 64"
                    className={classNames('block')}
                  />
                </span>
              </button>
            </div>
            <ModalStandard externalTrigger showModal={showModal} handleModal={setShowModal}>
              <VideoItem
                title={fields.videoTitle.value}
                videoSrc={
                  fields?.youtubeUrl.value ? fields?.youtubeUrl.value : fields?.vimeoUrl.value
                }
              />
            </ModalStandard>
          </>
        )}
      </>
    );
  };

  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/promo/mediapromo"
      className={classNames('theme-blue-light', 'anchorIdIndentifier')}
      id={componentAnchorId}
    >
      <Container>
        <LayoutTwoColumnShell
          left={mediaPlacement === mediaLeft ? promoMedia() : promoContent()}
          right={mediaPlacement === mediaLeft ? promoContent() : promoMedia()}
          className={classNames('md:items-center')}
        />
      </Container>
    </div>
  );
};

export default MediaPromo;
