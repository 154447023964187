import classNames from 'classnames';
import { MouseEventHandler } from 'react';

export type HeaderLanguageToggleProps = {
  onClick: MouseEventHandler<HTMLDivElement>;
  isNavOpen: boolean;
};

const HeaderHamburger = ({ onClick, isNavOpen }: HeaderLanguageToggleProps): JSX.Element => {
  return (
    <div
      className={classNames('pr-5', 'md:hidden', { isActive: isNavOpen })}
      id="header-button-hamburger"
      onClick={onClick}
      role="button"
      aria-label="Menu"
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  );
};

export default HeaderHamburger;
