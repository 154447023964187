// Global
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';

// Local
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';

export type LayoutFourColumnProps = Sitecore.Override.ComponentBase;

export type LayoutFourColumnShellProps = {
  left: JSX.Element;
  middleLeft: JSX.Element;
  middleRight: JSX.Element;
  right: JSX.Element;
  dataComponent?: string;
};

export const LayoutFourColumnShell = ({
  left,
  middleLeft,
  middleRight,
  right,
  dataComponent,
}: LayoutFourColumnShellProps): JSX.Element => {
  return (
    <div
      className={classNames('md:grid', 'grid-cols-4', 'md:gap-5', 'lg:gap-7')}
      data-component={dataComponent}
    >
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{left}</div>
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{middleLeft}</div>
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{middleRight}</div>
      <div className={classNames('col-span-1', 'mb-0', 'space-y-6')}>{right}</div>
    </div>
  );
};

const LayoutFourColumn = ({ rendering }: LayoutFourColumnProps): JSX.Element => {
  return (
    <LayoutFourColumnShell
      dataComponent="authorable/layout/layout-components/layout-four-column"
      left={
        <PlaceholderWrapper
          wrapperSize="quarter"
          name="BCBSAZ-One-Quarter-Left"
          rendering={rendering}
        />
      }
      middleLeft={
        <PlaceholderWrapper
          wrapperSize="quarter"
          name="BCBSAZ-One-Quarter-Middle-Left"
          rendering={rendering}
        />
      }
      middleRight={
        <PlaceholderWrapper
          wrapperSize="quarter"
          name="BCBSAZ-One-Quarter-Middle-Right"
          rendering={rendering}
        />
      }
      right={
        <PlaceholderWrapper
          wrapperSize="quarter"
          name="BCBSAZ-One-Quarter-Right"
          rendering={rendering}
        />
      }
    />
  );
};

export default LayoutFourColumn;
