import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { buildSearchStatus, buildFacet } from '@coveo/headless';
import classNames from 'classnames';
import FormSelectList from 'components/helpers/FormElements/FormSelectList/FormSelectList';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { getEnum, getEnumObject } from 'lib/get-enum';
import { useState, useEffect } from 'react';

const AzBlueFacet = ({ facetItem }: { facetItem: Sitecore.Override.ItemEx }): JSX.Element => {
  const coveoEngine = useCoveoEngine();
  const searchStatus = buildSearchStatus(coveoEngine);
  const controller = buildFacet(coveoEngine, {
    options: {
      field: getEnum(facetItem) || '',
      facetId: facetItem.id,
      numberOfValues: 1000,
    },
  });

  const [state, setState] = useState(controller.state);
  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  const selectedValue = state.values.find((val) => val.state === 'selected');

  useEffect(() => {
    if (!state.isLoading && selectedValue && searchStatus.state.firstSearchExecuted) {
      document.querySelector('#scroll-target')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [state, selectedValue, searchStatus]);

  if (!state.values.length) {
    return <></>;
  }

  return (
    <FormSelectList
      className={classNames('w-48', 'relative')}
      isClearable
      placeholder={getEnumObject(facetItem)?.name}
      name={facetItem.id}
      value={
        selectedValue
          ? {
              value: selectedValue.value,
              label: `${selectedValue.value} (${selectedValue.numberOfResults})`,
              facet: selectedValue,
            }
          : null
      }
      callBackChange={(obj) => {
        controller.deselectAll();
        obj && controller.toggleSelect(obj.facet);
      }}
      options={state.values.map((facet) => ({
        value: facet.value,
        label: `${facet.value} (${facet.numberOfResults})`,
        facet: facet,
      }))}
    />
  );
};

export default AzBlueFacet;
