// Global
import React, { useEffect, useState } from 'react';
import { Field, RichText, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { RichTextProps } from '@sitecore-jss/sitecore-jss-nextjs/types/components/RichText';

// Local
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import { DangerousHtml } from '../DangerousHtml/DangerousHtml';
import { isExternalLink } from 'lib/utils/is-external-link';
import { removeLandingFromUrl } from 'lib/utils/landing-page-utils';
import { isEdgeSitecoreCdnLink } from 'lib/utils/is-edge-sitecore-cdn';

const srOnlySpan = '<span class="sr-only"> (Opens in a new tab)</span>';

export type RichTextA11yWrapperProps = RichTextProps & {
  /**
   * Specify to execute external scripts.  Be sure the source is trusted
   */
  executeExternalScripts?: boolean;
  /**
   * Specify to not use the Sitecore Rich Text component.
   * It does processing which requires access to the route that is not prsent when processing from Coveo  */
  skipSitecoreComponent?: boolean;
  /**
   * A list of rules to do regex replacement on <a> href value
   */
  linkUrlReplace?: { regex: RegExp; replacement: string }[];
  /**
   * A list of rules to do regex replacement on <iframe> src value
   */
  iFrameUrlReplace?: { regex: RegExp; replacement: string }[];
  /**
   * A list of rules to do regex replacement on <img> src value
   */
  imageUrlReplace?: { regex: RegExp; replacement: string }[];
  /**
   * A list of rules to do regex replacement on all element classes
   */
  classNameReplace?: { regex: RegExp; replacement: string }[];
  /**
   * A list of rules to do regex replacement on text (not attributes)
   */
  textReplace?: { regex: RegExp; replacement: string }[];
  /**
   * If specified, adds a `useEffect` with a dependency on the RTE content
   */
  onContentChangeUseEffect?: React.EffectCallback;
};

export const getTextReplaceBold = (word: string) => ({
  regex: new RegExp(`\\b(${word}\\w*\\b)`, 'gi'),
  replacement: '<b>$&</b>',
});

/**
 * Recursively gets all child TEXT nodes
 * @param node
 * @returns
 */
function textNodesUnder(node: Node | null) {
  const all: (Node | null)[] = [];
  for (node = node?.firstChild ?? null; node; node = node.nextSibling) {
    if (node.nodeType === Node.TEXT_NODE) {
      all.push(node);
    } else {
      all.push(...textNodesUnder(node));
    }
  }
  return all;
}
const RichTextA11yWrapper = ({
  field,
  editable = true,
  executeExternalScripts,
  skipSitecoreComponent,
  linkUrlReplace,
  iFrameUrlReplace,
  imageUrlReplace,
  classNameReplace,
  textReplace,
  onContentChangeUseEffect: onContentChange,
  ...props
}: RichTextA11yWrapperProps): JSX.Element => {
  /**
   * Hook for experience editor
   */
  const isExperienceEditor = useExperienceEditor();
  const context = useSitecoreContext();

  //If we're replacing iframe url, render blank instead of loading wrong iframe
  const hasIFrameAndIFrameReplace =
    iFrameUrlReplace?.length && (field?.value?.indexOf('<iframe') ?? -1) > -1;

  const initialValue = hasIFrameAndIFrameReplace ? '' : field?.value ?? '';

  const [content, setContent] = useState(initialValue);
  const updatedField: Field<string> = {
    value: content || '',
  };

  if (onContentChange) useEffect(onContentChange, [content]);

  // Run this client-side because we don't have access to the document server-side
  useEffect(() => {
    const template = document.createElement('template');
    template.innerHTML = field?.value || '';

    if (textReplace) {
      const nodes = textNodesUnder(template.content);
      nodes.forEach((elem) => {
        if (elem) {
          // Create a new span because text nodes cannot contain html
          // Note, we create this outside the loop otherwise multiple replacements would override each other
          const replacementNode = document.createElement('span');
          // Since `elem` is a text node, it only has `textContent`
          replacementNode.innerHTML = elem.textContent ?? '';

          textReplace.forEach(({ regex, replacement }) => {
            // Here we're replacing the `innerHTML` because we want to preserve any HTML that's been added
            // in previous replacements
            replacementNode.innerHTML =
              replacementNode.innerHTML?.replace(regex, replacement) ?? '';
          });
          // Since we can't update a text node with html content, replace it with our span
          if (elem.parentNode) {
            elem.parentNode.insertBefore(replacementNode, elem);
            elem.parentNode.removeChild(elem);
          }
        }
      });
    }

    if (classNameReplace) {
      const elements = [...template.content.querySelectorAll('[class]')];
      elements.forEach((elem) => {
        classNameReplace.forEach(({ regex, replacement }) => {
          elem.setAttribute('class', elem.getAttribute('class')?.replace(regex, replacement) ?? '');
        });
      });
    }

    if (iFrameUrlReplace) {
      const iframes = [...template.content.querySelectorAll('iframe')];
      iframes.forEach((iframe) => {
        iFrameUrlReplace.forEach(({ regex, replacement }) => {
          iframe.setAttribute('src', iframe.getAttribute('src')?.replace(regex, replacement) ?? '');
        });
      });
    }

    if (imageUrlReplace) {
      const images = [...template.content.querySelectorAll('img')];
      images.forEach((img) => {
        imageUrlReplace.forEach(({ regex, replacement }) => {
          img.setAttribute('src', img.getAttribute('src')?.replace(regex, replacement) ?? '');
        });
      });
    }

    const links = [...template.content.querySelectorAll('a')];

    links.forEach((a) => {
      // Remove landing regardless of any additional link replacement
      a.setAttribute('href', removeLandingFromUrl(a.getAttribute('href')) ?? '');

      //adds class identifier to know this is a link from the rte
      a.classList.add('rte-link');

      (linkUrlReplace ?? []).forEach(({ regex, replacement }) => {
        a.setAttribute('href', a.getAttribute('href')?.replace(regex, replacement) ?? '');
      });
    });
    // Find all links either either have target="_blank" or appear to be external due to starting with "http"
    const externalLinks = links.filter((a) => {
      const href = a.getAttribute('href');

      return isExternalLink(href || '', context.sitecoreContext?.internalUrlWhitelist);
    });
    // Update each external link
    externalLinks.forEach((a) => {
      // Set to open in new tab
      a.setAttribute('target', '_blank');
      // Add Screen Reader text and new tab icon
      // eslint-disable-next-line no-param-reassign
      a.innerHTML = `${a.innerHTML}${srOnlySpan} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" class="inline ml-2 -mt-1 h-em w-em"><path fill-rule="evenodd" d="M8.25 3.75H19.5a.75.75 0 01.75.75v11.25a.75.75 0 01-1.5 0V6.31L5.03 20.03a.75.75 0 01-1.06-1.06L17.69 5.25H8.25a.75.75 0 010-1.5z" clip-rule="evenodd"></path></svg>`;
      // a.innerHTML = `${a.innerHTML}${srOnlySpan}${ReactDOMServer.renderToStaticMarkup(newTabIcon)}`;
    });

    //Update each link to sitecore edge
    const scLinks = links.filter((a) => {
      const href = a.getAttribute('href');

      return isEdgeSitecoreCdnLink(href || '');
    });
    //sets no follow to prevent google from following and indexing the edge cdn page
    //https://developers.google.com/search/docs/crawling-indexing/qualify-outbound-links#:~:text=site%20and%20platform.-,rel%3D%22nofollow%22,txt%20disallow%20rule.
    scLinks.forEach((a) => {
      a.setAttribute('rel', 'nofollow');
    });

    // Update the content
    setContent(template.innerHTML);
  }, [field?.value]);

  // If there's no text, the only render it if it is editable and we are editing.
  // Otherwise don't render anything
  if (!field?.value) {
    if (isExperienceEditor && editable) {
      return <RichText field={field} {...props} editable={editable} className="rte" />;
    }
    return <></>;
  }

  // Just a pass through if it's EE.
  if (isExperienceEditor) {
    return <RichText field={field} {...props} editable={editable} className="rte" />;
  }

  if (executeExternalScripts) {
    return <DangerousHtml html={content} {...props} className="rte" />;
  }

  if (skipSitecoreComponent) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: field.value?.replaceAll(
            '<a',
            '<a class="font-medium hover:underline" style="color:#2273ba"'
          ),
        }}
        {...props}
      />
    );
  }

  /**
   * If not EE, use the updated field value
   */

  return <RichText field={updatedField} {...props} className="rte" />;
};

export default RichTextA11yWrapper;
