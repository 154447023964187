'use client';

import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import HeaderDropdown from '../Header/HeaderDropdown';
import { useState, useEffect } from 'react';
import Button from '../Button/Button';

export type LanguageDescriptor = Feature.Data.LanguageSwitcher.Fields.LanguageNavigatorItem &
  Sitecore.Override.ItemEx;

export type LanguageSwitcherProps = {
  variant: 'mobile' | 'desktop';
  locales: Sitecore.Override.ItemEx[];
  enabled: Field<boolean>;
  isMobile: boolean;
};

const LanguageSwitcher = ({
  enabled,
  locales,
  variant,
  isMobile,
}: LanguageSwitcherProps): JSX.Element => {
  if (enabled && locales?.length == 0) {
    return <></>;
  }
  const languages = locales as LanguageDescriptor[];
  const useSitecore = useSitecoreContext();
  const contextLocale = useSitecore.sitecoreContext.language;
  const selectedLocale = languages
    ?.filter((i) => i.name === contextLocale)
    ?.map((x) => x.fields.languageDisplayName.value)[0];

  const [selectedOption, setSelectedOption] = useState(selectedLocale);

  useEffect(() => {
    setSelectedOption(selectedOption);
  }, [selectedOption]);

  const switchLocale = (locale: string, localeDisplayName: string) => {
    const { location } = window;
    let newUrl = location.href;
    if (locale !== contextLocale) {
      if (
        location.pathname.match(/\/es\/|\/zh-TW\//) &&
        !location.pathname.includes(`/${locale}`)
      ) {
        newUrl = location.href.replace(
          /\/es\/|\/zh-TW\//,
          `${locale == 'en' ? '/' : '/' + locale + '/'}`
        );
      } else {
        if (location.pathname == '/' || location.pathname.includes(`/${contextLocale}`)) {
          newUrl = `${location.origin}${locale == 'en' ? '' : '/' + locale}`;
        } else {
          newUrl = `${location.origin}${locale == 'en' ? '' : '/' + locale}${location.pathname}${
            location.search
          }${location.hash}`.replace(/\/$/, '');
        }
      }
      window.open(newUrl, '_self');
    }
    window.Cobrowse.modal.setLocale(locale);
    setSelectedOption(localeDisplayName);
  };

  const additionalListClasses =
    variant === 'desktop'
      ? classNames('bottom-full', 'md:bottom-auto', 'md:mt-1', 'mb-10', 'md:mb-0')
      : classNames('-mt-1', 'text-white', 'text-sm');

  const dropdownStyles =
    variant === 'desktop'
      ? classNames('hidden', 'md:block', 'mt-[10px]')
      : classNames('block', 'md:hidden');

  const triggerStyles =
    variant === 'mobile' ? classNames('text-sm', 'text-white', 'py-3', 'pl-2') : '';
  return (
    <>
      {isMobile ? (
        <div className="flex space-x-4">
          <li>
            <Button
              className={classNames('header-navList-menuItem', 'cursor-default')}
              icon="GlobeAmericasIcon"
              iconSize="xs"
              type="button"
              variant="link"
            />
          </li>
          {languages?.map((lang: LanguageDescriptor) => (
            <div
              className={classNames(
                'ddl-lang-li',
                lang?.fields?.languageDisplayName?.value === selectedOption ? 'font-bold' : ''
              )}
              key={lang.id}
              onClick={() =>
                switchLocale(
                  lang?.fields?.languageCode?.value,
                  lang?.fields?.languageDisplayName?.value
                )
              }
            >
              <span>
                <button className="ddl-lang-btn">{lang?.fields?.languageDisplayName?.value}</button>
              </span>
            </div>
          ))}
        </div>
      ) : (
        <>
          <div className="flex space-x-4">
            <Button
              className={classNames('header-navList-menuItem', 'cursor-default')}
              icon="GlobeAmericasIcon"
              iconSize="xs"
              type="button"
              variant="link"
            />
            <HeaderDropdown
              className={dropdownStyles}
              listStyles={classNames(
                'ddl-lang-ul',
                'absolute',
                'bg-white',
                'w-full',
                'py-4',
                'shadow-card',
                'rounded-lg',
                'border',
                'border-gray',
                additionalListClasses
              )}
              triggerStyles={classNames(
                'ddl-lang-btn',
                'font-semibold',
                'hover:bg-btn-bg-secondary-hover',
                'hover:text-btn-text-secondary-hover',
                'text-btn-text-secondary',
                'px-8',
                'py-3',
                triggerStyles
              )}
              label={selectedOption}
            >
              {languages?.map((lang: LanguageDescriptor) => (
                <>
                  <li
                    className={classNames(
                      'ddl-lang-li',
                      lang?.fields?.languageDisplayName?.value === selectedOption ? 'hidden' : ''
                    )}
                    key={lang.id}
                    onClick={() =>
                      switchLocale(
                        lang?.fields?.languageCode?.value,
                        lang?.fields?.languageDisplayName?.value
                      )
                    }
                  >
                    <span>
                      <button className="ddl-lang-btn">
                        {lang?.fields?.languageDisplayName?.value}
                      </button>
                    </span>
                  </li>
                </>
              ))}
            </HeaderDropdown>
          </div>
        </>
      )}
    </>
  );
};

export default LanguageSwitcher;
