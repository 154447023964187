import { buildPager } from '@coveo/headless';
import classNames from 'classnames';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { useEffect, useState } from 'react';

export const AzBlueHeadlessPager = () => {
  const coveoEngine = useCoveoEngine();
  const controller = buildPager(coveoEngine);
  const [state, setState] = useState(controller.state);

  useEffect(() => controller.subscribe(() => setState(controller.state)), []);

  const activePageClass = classNames('bg-blue-navy', 'hover:bg-blue', 'text-white');

  const nextPrevClass = classNames(
    'border',
    'rounded-full',
    'border-transparent',
    'hover:border-blue-navy',
    'text-blue',
    'p-1',
    'w-10',
    'h-10',
    'transition'
  );

  if (state.maxPage <= 1) {
    return <></>;
  }

  return (
    <nav
      aria-label="Pagination"
      className={classNames(
        'py-8',
        'flex',
        'flex-wrap',
        'items-center',
        'justify-center',
        'w-full',
        'space-x-2'
      )}
    >
      <button
        className={classNames(nextPrevClass, { 'cursor-not-allowed': !state.hasPreviousPage })}
        aria-label="Previous"
        disabled={!state.hasPreviousPage}
        onClick={() => controller.previousPage()}
      >
        <HeroIcon icon="ChevronLeftIcon" size={'smr'} className={classNames('inline-block')} />
      </button>
      {state.currentPages.map((page) => (
        <button
          key={page}
          className={classNames(
            controller.isCurrentPage(page) ? activePageClass : '',
            'border',
            'rounded-full',
            'border-transparent',
            'hover:border-blue-navy',

            'transition',
            'p-1',
            'w-10',
            'h-10'
          )}
          disabled={controller.isCurrentPage(page)}
          onClick={() => controller.selectPage(page)}
          aria-label={`Page ${page}`}
          aria-current="page"
        >
          {page}
        </button>
      ))}
      <button
        className={classNames(nextPrevClass, { 'cursor-not-allowed': !state.hasNextPage })}
        aria-label="Next"
        disabled={!state.hasNextPage}
        onClick={() => controller.nextPage()}
      >
        <HeroIcon icon="ChevronRightIcon" size={'smr'} className={classNames('inline-block')} />
      </button>
    </nav>
  );
};
