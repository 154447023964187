import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import { withAtomicSearchInterface } from 'components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchInterface';
import { AzBlueHeadlessSearchPageBar } from 'components/helpers/Coveo/headless/AzBlueHeadlessSearchPageBar';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import Heading from 'components/helpers/Heading/Heading';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { getThemeClasses } from 'lib/get-theme';

export type SearchBarProps = Sitecore.Override.ComponentBase &
  Feature.Components.Search.Fields.SearchBar;

const SearchBar = ({ fields, rendering }: SearchBarProps): JSX.Element => {
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/search/searchBar"
      className={classNames(
        getThemeClasses('theme-blue--light'),
        'text-center',
        'py-20',
        'container-content-padding-x',
        'anchorIdIndentifier'
      )}
      id={componentAnchorId}
    >
      <Heading headingLevel="h2" headingText={fields.headline} center />

      {fields.description.value.length > 0 ? (
        <div className="flex justify-center">
          <div className="max-w-[767px] md:text-lg pb-8" style={{ color: '#2D2926' }}>
            <RichTextA11yWrapper field={fields.description}></RichTextA11yWrapper>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={classNames('!block', 'max-w-[832px]', 'mx-auto', 'px-8')}>
        <AzBlueHeadlessSearchPageBar fields={fields} />
      </div>
    </Container>
  );
};

export default withAtomicSearchInterface(SearchBar);
