export default function IsDevelopmentOrLower() {
  const currentDomain = new URL(process.env.PUBLIC_URL as string);
  let IsDevelopmentOrLower = false;
  const developmentOrLower = [
    'localhost',
    'azblue.localhost',
    'www.azblue.localhost',
    'development.azblue.com',
    'cm.development.azblue.com',
  ];
  if (
    developmentOrLower.some((x) => {
      return currentDomain.hostname == x;
    })
  ) {
    IsDevelopmentOrLower = true;
  }

  return IsDevelopmentOrLower;
}
