// Lib
import { HeaderBasicProps } from 'components/authorable/site/HeaderBasic/HeaderBasic';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { NavigationMenuLink } from '../header-types';
import HeaderLink from '../HeaderLink';
import classNames from 'classnames';

const MainNavMicrosite = ({ fields }: HeaderBasicProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <nav
      className={classNames('header-navList', 'md:px-12', 'md:flex', 'md:items-center')}
      id="navigation-primary"
      role="navigation"
    >
      <ul
        className={classNames(
          { 'mt-10': !fields.hideUtility.value },
          { 'md:mt-0': !fields.hideUtility.value },
          'md:gap-12',
          'md:flex',
          'md:items-center'
        )}
      >
        {fields?.mainNavLinks?.map((navItem: NavigationMenuLink) => (
          <>
            {navItem.fields?.Link?.value?.href && (
              <li key={navItem.id}>
                <HeaderLink link={navItem?.fields?.Link} />
              </li>
            )}
          </>
        ))}
      </ul>
    </nav>
  );
};

export default MainNavMicrosite;
