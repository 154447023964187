import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import { AzBlueAtomicSearchBoxProps } from 'components/helpers/Coveo/atomic-wrappers/AzBlueAtomicSearchBox';
import { AzBlueHeadlessSearchBox } from 'components/helpers/Coveo/headless/AzBlueHeadlessSearch';

import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { useClickAway } from 'lib/utils/component-util';

import { useRef, useState } from 'react';

export type GlobalHeaderSearchBarProps = {
  fields: AzBlueAtomicSearchBoxProps['fields'] & {
    searchText?: Field<string>;
  };
};

const GlobalHeaderSearchBar = ({ fields }: GlobalHeaderSearchBarProps) => {
  const [hideSearchBar, setHideSearchBar] = useState(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const desktopSearchRef = useRef(null);
  const onDesktopClickAway = () => {
    setHideSearchBar(true);
  };
  useClickAway([desktopSearchRef], onDesktopClickAway);

  if (!fields) {
    return <MissingDataSource />;
  }

  const hideShowSearch = (event: React.MouseEvent | React.TouchEvent) => {
    if (!event) return;
    setHideSearchBar(!hideSearchBar);
    inputRef.current?.focus();
  };

  const closeSearch = () => {
    setHideSearchBar(!hideSearchBar);
    inputRef.current?.blur();
  };

  return (
    <div
      data-component="authorable/search/GlobalHeaderSearchBar"
      className={classNames(
        'text-right',
        'global-header-search-bar',
        'relative',
        'min-w-[200px]',
        'min-h-[20px]'
      )}
      ref={desktopSearchRef}
    >
      <Button
        className={classNames('header-navList-menuItem', 'md:inline-block', 'inline-block', '!p-0')}
        icon="MagnifyingGlassIcon"
        iconSize="xxs"
        iconPosition="left"
        size="small"
        text={fields?.searchText?.value}
        type="button"
        variant="link"
        onClick={hideShowSearch}
      />
      <div
        className={classNames(
          'absolute',
          'duration-200 ease-in-out ',
          'global-header-search-box',
          'inline-block',
          '-mt-1',
          'origin-right',
          'overflow-hidden',
          'right-0',
          'transition-transform',
          'w-full',
          {
            'scale-x-0': hideSearchBar,
            'transition-transform': !hideSearchBar,
          }
        )}
      >
        <AzBlueHeadlessSearchBox fields={fields} ref={inputRef} callBack={closeSearch} />
      </div>
    </div>
  );
};

export default GlobalHeaderSearchBar;
