import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ComponentRendering } from '@sitecore-jss/sitecore-jss-nextjs';

import ButtonLinkIcon from '../../../helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import RichTextA11yWrapper from '../../../helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Theme, getThemeClasses } from 'lib/get-theme';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import MediaComponent, { MediaComponentProps } from '../MediaComponent/MediaComponent';
import { TableProps, Table } from '../../table/TableComponent/TableComponent';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Feature } from '.generated/templates/Feature.Items.model';
import DocumentListing, {
  ListingFormProps,
} from 'components/authorable/table/DocumentListing/DocumentListing';
import RelativeLinkListing, {
  RelativeLinkListingProps,
} from 'components/authorable/table/RelativeLinkListing/RelativeLinkListing';
import { guidEquals } from 'lib/string-utils';
import { TemplatesIds } from 'lib/sitecore/template-ids';

export type AccordionItemContentProps = Sitecore.Override.ItemEx &
  Feature.Components.Accordion.Fields.AccordionItem;
export type AccordionItemStateProps = {
  onToggle: () => void;
  multiple?: boolean;
  open: boolean;
  variant: Theme;
};
export type AccordionItemProps = AccordionItemContentProps & AccordionItemStateProps;

const AccordionItem = ({ fields, onToggle, multiple, open, variant }: AccordionItemProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const isEE = useExperienceEditor();
  const [isOpen, setIsOpen] = useState(isEE || open);

  const handleAccordionToggle = () => {
    if (multiple) {
      setIsOpen((a) => !a);
    } else {
      onToggle();
    }
  };

  useEffect(() => {
    setIsOpen(isEE || open);
  }, [open]);

  return (
    <li
      className={classNames(
        'last:mb-0',
        'list-none',
        'mb-2',
        'rounded-3xl',
        'transition-all',
        'duration-300',
        {
          border: !variant || variant === 'theme-white',
          'border-gray': !variant || variant === 'theme-white',
          'bg-gray-light': !variant || variant === 'theme-white',
        }
      )}
    >
      <button
        disabled={isEE}
        type="button"
        className={classNames(
          'flex',
          'items-center',
          'justify-between',
          'p-5',
          'md:p-6',
          'rounded-t-3xl',
          'rounded-b-3xl',
          'text-left',
          'text-md',
          'md:text-lg',
          'w-full',
          getThemeClasses(variant ? variant : 'theme-white'),
          {
            'rounded-t-3xl': isOpen,
            '!rounded-b-none': isOpen,
          }
        )}
        onClick={() => handleAccordionToggle()}
        aria-expanded={isOpen}
      >
        <span className={classNames('block', 'flex-grow')}>
          <RichTextA11yWrapper field={fields.panelHeading} editable />
        </span>
        <span
          className={classNames(
            'block',
            'm-0',
            'origin-center',
            'transition-all',
            'transition-200',
            'w-max',
            {
              'text-blue-navy': variant === 'theme-blue--light' || variant === 'theme-white',
              'text-white': variant === 'theme-blue',
              'rotate-180': isOpen,
            }
          )}
        >
          <ButtonLinkIcon icon={'ChevronDownIcon'} iconPosition={'center'} />
        </span>
      </button>
      <div
        ref={ref}
        className={classNames(
          'overflow-hidden',
          'my-0',
          'mx-5',
          'md:mx-8',
          'transition-all',
          'duration-200',
          'origin-bottom',
          'relative',
          {
            'my-5': isOpen,
            'md:my-8': isOpen,
            'duration-200': isOpen,
          }
        )}
        style={{
          maxHeight: `${isOpen ? ref?.current?.scrollHeight : 0}px`,
        }}
      >
        <div className={classNames('md:space-y-12', 'space-y-8')}>
          <RichTextA11yWrapper field={fields.mainContent} editable />

          {fields.tableComponent?.map((x) => {
            if (guidEquals(x.templateId, TemplatesIds.TableComponent)) {
              return (
                <Table {...(x as Sitecore.Override.ItemEx & TableProps)} uid={x.id} key={x.id} />
              );
            }
            if (guidEquals(x.templateId, TemplatesIds.DocumentLinkListing)) {
              return (
                <DocumentListing
                  {...(x as ListingFormProps)}
                  rendering={{ componentName: 'AccordionItem' } as ComponentRendering}
                  key={x.id}
                />
              );
            }
            if (guidEquals(x.templateId, TemplatesIds.RelativeLinkListing)) {
              return (
                <RelativeLinkListing
                  {...(x as Sitecore.Override.ItemEx & RelativeLinkListingProps)}
                  key={x.id}
                />
              );
            }
            return null;
          })}
          {fields.imageComponent ? (
            <MediaComponent {...(fields.imageComponent as unknown as MediaComponentProps)} />
          ) : null}

          <RichTextA11yWrapper field={fields.disclaimer} editable />
        </div>
      </div>
    </li>
  );
};

export default AccordionItem;
