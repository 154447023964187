import { Foundation } from '.generated/templates/Foundation.Items.model';
import ModalStandard from 'components/helpers/Modals/ModalStandard/ModalStandard';
import { useState, useEffect } from 'react';
import Button from '../../Button/Button';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';

export type FindADoctorModalProps = {
  href: string;
  externalLinkModal: Foundation.Data.Modals.Fields.BaseModal;
  handleModal?: (value: React.SetStateAction<boolean>) => void;
  showModal?: boolean;
};

const FindADoctorModal = ({
  href,
  externalLinkModal,
  showModal = false,
  handleModal,
}: FindADoctorModalProps): JSX.Element => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    showModal !== show && setShow(showModal);
  }, [showModal]);

  useEffect(() => {
    handleModal && handleModal(show);
  }, [show]);

  if (!externalLinkModal) {
    return <></>;
  }

  return (
    <ModalStandard externalTrigger showModal={show} handleModal={setShow}>
      <div className=" w-full mb-8">
        <RichTextA11yWrapper field={externalLinkModal.fields?.content} editable />
      </div>
      <div className="w-full flex flex-col items-center">
        <Button
          field={{
            value: {
              href: href,
              target: '_blank',
              text: externalLinkModal.fields?.buttonText?.value,
            },
          }}
          variant="primary"
          iconPosition="right"
          icon="hover-arrow"
          className="js-ignore-modal"
          onClick={() => {
            setShow(false);
          }}
        />
        {externalLinkModal.fields?.disclaimerText?.value && (
          <div className="my-4 text-center text-sm">
            <RichTextA11yWrapper field={externalLinkModal.fields?.disclaimerText} editable />
          </div>
        )}
      </div>
    </ModalStandard>
  );
};

export default FindADoctorModal;
