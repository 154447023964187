import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { useEffect, useState } from 'react';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';

export type ToggleViewsItemProps = Sitecore.Override.ItemEx &
  Feature.Components.Toggles.ToggleViews.Fields.ToggleViewItem;

export type ToggleViewsProps = Sitecore.Override.ComponentBase & {
  fields?: {
    root?: Feature.Components.Toggles.ToggleViews.Fields.ToggleViews & {
      children?: ToggleViewsItemProps[];
    };
  };
};

const ToggleViews = ({ fields, rendering }: ToggleViewsProps) => {
  const componentAnchorId = rendering?.uid;

  const allToggles = fields?.root?.children ?? [];
  const validToggles = allToggles.slice(0, 5);
  const isEE = useExperienceEditor();
  const [activeTabId, setActiveTabId] = useState(validToggles[0]?.id);

  const updateTab = (newTabId: string | undefined) => {
    setActiveTabId(newTabId);
    window.location.hash = newTabId ?? '';
  };
  useEffect(() => {
    if (window.location.hash) {
      setActiveTabId(window.location.hash.replace(/^\#/, ''));
    } else {
      window.location.hash = activeTabId ?? '';
    }
  }, []);

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <div
      className="w-full relative anchorIdIndentifier md:space-y-12 space-y-8"
      data-component="authorable/toggles/toggleviews"
      id={componentAnchorId}
    >
      <EditingHelpText
        condition={!validToggles.length}
        text="Toggle View | No tabs added. Add child items to add tabs"
      />

      <div
        className={classNames(
          'w-full sm:w-fit',
          'mx-auto',
          'flex flex-col sm:flex-row text-center items-center',
          'space-x-0 sm:space-x-2',
          'space-y-2 sm:space-y-0'
        )}
      >
        <Text
          tag="div"
          field={fields?.root?.fields?.label}
          editable={true}
          className={classNames('w-full sm:w-fit', 'text-base leading-4 font-bold text-gray-dark')}
        />

        <div
          className={classNames(
            'w-full sm:w-fit',
            'p-0 sm:p-0.5',
            'bg-transparent sm:bg-gray-light',
            'sm:border border-gray rounded-full'
          )}
        >
          {validToggles.length ? (
            <ul
              className={classNames(
                'w-full sm:w-fit',
                'flex flex-col sm:flex-row',
                'space-y-2 sm:space-x-2 sm:space-y-0'
              )}
            >
              {validToggles.map((item) => {
                const activeButtonClass =
                  activeTabId === item.id
                    ? 'bg-blue-navy text-white'
                    : 'bg-gray-light sm:bg-transparent text-gray-dark';

                return (
                  <li
                    className={classNames('text-base font-bold')}
                    key={item.id}
                    onClick={() => updateTab(item.id)}
                  >
                    <div
                      className={classNames(
                        'w-full h-full sm:w-fit',
                        'block px-6 py-3',
                        'rounded-full',
                        'border border-gray rounded-[100px] sm:border-0',
                        activeButtonClass,
                        'no-underline',
                        'cursor-pointer',
                        'hover:bg-blue-navy hover:text-white'
                      )}
                    >
                      <Text field={item.fields.toggleText} />
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </div>
      </div>

      <EditingHelpText
        condition={allToggles.length > 5}
        text="Toggle View | More than 5 toggles added. Only the first 5 will display"
      />

      <EditingHelpText
        condition={true}
        text="NOTE: Re-ordering toggle elements will not re-order the content.  The placeholders are based on order and are not tied to a specific toggle item.  Take care when re-ordering toggles."
      />
      {validToggles.map((item, index) => {
        // Placeholder with numbers seem to have issues, so using text
        const map = ['One', 'Two', 'Three', 'Four', 'Five'];
        return (
          <div
            key={item.id}
            className={classNames('mx-auto', 'relative', {
              hidden: !isEE && activeTabId !== item.id,
            })}
          >
            <PlaceholderWrapper
              wrapperSize="normal"
              name={`BCBSAZ-Toggle-${map[index]}`}
              rendering={rendering}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ToggleViews;
