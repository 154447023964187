// Components

import { Project } from '.generated/templates/Project.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import Container from 'components/helpers/Container/Container';
import classNames from 'classnames';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import { useI18n } from 'next-localization';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { getArticleMetaData } from 'lib/utils/article-utils';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/ConditionalWrapper';
import { LayoutWideLeftShell } from 'components/authorable/layout/LayoutComponents/LayoutWideLeft/LayoutWideLeft';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import VideoItem from 'components/helpers/VideoItem/VideoItem';
import IsDevelopmentOrLower from 'components/helpers/Utility/IsDevelopmentOrLower/IsDevelopmentOrLower';

export type ArticleHeroProps = Sitecore.Override.ComponentBase;

const ArticleHero = ({ rendering }: ArticleHeroProps): JSX.Element => {
  const i18n = useI18n();
  const context = useSitecoreContext();
  const article = context.sitecoreContext.route as Project.Pages.Fields.NewsroomArticle;
  const {
    category,
    publishedDate,
    updatedDate,
    publishedDateFormatted,
    updatedDateFormatted,
    useSidebarLayout,
  } = getArticleMetaData(article);
  const componentAnchorId = rendering?.uid;
  const notDisclaimerTextBrOnly = article?.fields?.disclaimerText?.value != '<br />';

  //log only in development or lower envs
  if (IsDevelopmentOrLower()) {
    console.log('article?.fields?.disclaimerText?.value', article?.fields?.disclaimerText?.value);
    if ('<br />' == article?.fields?.disclaimerText?.value) {
      console.log('breakline only found');
    }
    console.log('disclaimerTextBrOnly', notDisclaimerTextBrOnly);
  }

  return (
    <Container
      dataComponent="authorable/article/articlehero"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <div className={classNames('pt-9')}>
        {category && (
          <div
            className={classNames(
              'text-blue-navy',
              'text-sm',
              'flex',
              'gap-2',
              'items-center',
              'mb-3',
              'md:mb-5'
            )}
          >
            <SvgIcon size={'em'} icon="large-bullet" viewBox="0 0 12 13" />
            {category}
          </div>
        )}
        <h1 className={classNames('text-2xl', 'font-light', 'leading-11', 'mb-3')}>
          <Text field={article?.fields?.articleTitle} />
        </h1>
        <div className={classNames('font-light', 'text-gray-dark', 'mb-3')}>
          {article?.fields?.articleAuthor?.value ? i18n.t('By:') : ''}{' '}
          <Text field={article?.fields?.articleAuthor} />
        </div>
        <div
          className={classNames(
            'text-gray-dark',
            'text-sm',
            'flex',
            'gap-2',
            'mb-5',
            'items-center'
          )}
        >
          <span>{publishedDateFormatted}</span>
          {updatedDate && updatedDate > publishedDate ? (
            <>
              <span>|</span>
              <span className={classNames('italic')}> Updated:{` ${updatedDateFormatted}`}</span>
            </>
          ) : (
            ''
          )}
        </div>

        <ConditionalWrapper
          condition={useSidebarLayout || false}
          wrapper={(children) => (
            <>
              <LayoutWideLeftShell
                dataComponent="authorable/layout/layout-components/layout-wide-left"
                left={children}
                right={
                  <PlaceholderWrapper
                    wrapperSize="third"
                    name="BCBSAZ-One-Third-Right"
                    rendering={rendering}
                  />
                }
              />
            </>
          )}
        >
          <>
            <div className={classNames('w-full', 'p-0', 'mb-8', 'text-center')}>
              <ImageWrapper field={article?.fields?.imageFeatured as SizedImageField} />
            </div>
            <div className={classNames('p-0', 'mb-8')}>
              <RichTextA11yWrapper field={article?.fields?.articleBody} editable />
            </div>

            {/*only show video if we have a value in either youtube or vimeo */}
            {(article?.fields?.youtubeUrl?.value || article?.fields?.vimeoUrl?.value) && (
              <div className={classNames('p-0', 'mb-8')}>
                <VideoItem
                  title={'Video'}
                  videoSrc={
                    article?.fields?.youtubeUrl?.value
                      ? article?.fields?.youtubeUrl.value
                      : article?.fields?.vimeoUrl?.value
                  }
                />
              </div>
            )}

            {/*only show disclaimerText if we have disclaimer value and is not </br> */}
            {article?.fields?.disclaimerText?.value && notDisclaimerTextBrOnly && (
              <div className={classNames('p-0', 'mb-8', 'bg-[#efefef]', 'tracking-[0.4px]')}>
                <div className={classNames('m-0', 'p-5')}>
                  <div className={classNames('m-0', 'py-1')}>
                    <p>Disclaimer:</p>
                  </div>
                  <div className={classNames('m-0', 'p-0')}>
                    <RichTextA11yWrapper field={article?.fields?.disclaimerText} editable />
                  </div>
                </div>
              </div>
            )}
          </>
        </ConditionalWrapper>
      </div>
    </Container>
  );
};

export default ArticleHero;
