// Global
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';

// Local
import Button from 'components/helpers/Button/Button';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import Heading from 'components/helpers/Heading/Heading';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import SvgIcon from 'components/helpers/Icons/SvgIcon/SvgIcon';
import { getThemeClasses } from 'lib/get-theme';
import useUtmContext from 'lib/state/utm-context';
import { useState } from 'react';

export type HealthSherpaProps = Sitecore.Override.ComponentBase &
  Feature.Components.HealthSherpa.Fields.HealthSherpa;

const HealthSherpa = ({ fields, rendering }: HealthSherpaProps): JSX.Element => {
  const [validZip, setValidZip] = useState(false);

  const sitecoreContext = useSitecoreContext();

  const utm = useUtmContext();

  const language = sitecoreContext.sitecoreContext.language;

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  // EVENT HANDLERS
  const handleZip = (e: { target: { value: string } }) => {
    (!/^-?\d*[.]?\d*$/.test(e.target.value) || e.target.value.length > 5) &&
      (e.target.value = e.target.value.replace(/.$/, ''));
    e.target.value.length === 5 ? setValidZip(true) : setValidZip(false);
  };

  const componentAnchorId = rendering?.uid;

  const formAction = validZip
    ? fields.promoShopPlansButtonValid.value.href
    : fields.promoShopPlansButtonInvalid.value.href;

  return (
    <div className={classNames('w-full', 'container-content-padding-x', 'container-content-width')}>
      <div
        className={classNames(
          'md:py-16',
          'md:px-28',
          'px-5',
          'py-12',
          'rounded-lg',
          'anchorIdIndentifier',
          getThemeClasses('theme-blue')
        )}
        data-component="authorable/promo/health-sherpa"
        id={componentAnchorId}
      >
        <Heading
          headingText={fields?.promoHeadingText}
          headingClassName={classNames('md:text-center', 'md:mx-auto')}
          headingLevel="h3"
          headingSize="md"
          description={fields.promoDescriptionText}
          descriptionClassName={classNames('mb-8', 'md:text-center')}
        />
        <div
          className={classNames(
            'flex',
            'flex-col',
            'max-w-5xl',
            'md:items-center',
            'sm:flex-row',
            'sm:items-start',
            'sm:mx-auto'
          )}
        >
          <form
            className={classNames(
              'flex',
              'flex-col',
              'flex-grow',
              'md:flex-grow-0',
              'items-start',
              'justify-between',
              'mb-6',
              'md:flex-row',
              'md:items-end',
              'md:p-8',
              'lg:w-3/5',
              'p-6',
              'rounded-lg',
              'shrink-0',
              'sm:mb-0',
              getThemeClasses('theme-white')
            )}
            action={formAction}
            method="get"
            target="_blank"
          >
            <div
              className={classNames(
                'flex-grow',
                'lg:w-2/3',
                'max-w-sm',
                'mb-6',
                'md:mb-0',
                'md:mr-2',
                'md:w-1/2',
                'shrink-0',
                'w-full'
              )}
            >
              <label
                htmlFor={`${componentAnchorId}-zipcodeLabel`}
                className={classNames('block', 'font-bold', 'text-lg')}
              >
                {fields.promoZipcodeText.value}
              </label>
              <div className={classNames('relative')}>
                <input
                  id={`${componentAnchorId}-zipcodeLabel`}
                  aria-label="5-digit Zipcode code"
                  className={classNames(
                    'appearance-none',
                    'block',
                    'text-base',
                    'font-regular',
                    'border',
                    'border-gray',
                    'focus:border-blue',
                    'focus:ring-1',
                    'focus:ring-blue',
                    'peer',
                    'pl-10',
                    'py-3',
                    'rounded-lg',
                    'w-full'
                  )}
                  name={`${validZip ? 'zip_code' : ''}`}
                  type="text"
                  placeholder={fields.promoZipcodePlaceholderText.value}
                  onChange={(e) => {
                    handleZip(e);
                  }}
                />
                {Object.keys(utm).map((name) => {
                  if (!name) {
                    return null;
                  }
                  const value = utm[name];
                  return <input key={name} name={name} value={value} type="hidden" />;
                })}
                <ConditionalRender condition={language === 'es'}>
                  <input name="ljs" value="es-MX" type="hidden" />
                </ConditionalRender>
                <div
                  className={classNames(
                    'absolute',
                    'left-2',
                    'text-xl',
                    'top-1/2',
                    '-translate-y-1/2',
                    'w-6'
                  )}
                >
                  <HeroIcon icon={'MapPinIcon'} size={'em'} className={classNames('text-teal')} />
                </div>
              </div>
            </div>
            <Button
              text={
                validZip
                  ? fields.promoShopPlansButtonValid.value.text
                  : fields.promoShopPlansButtonInvalid.value.text
              }
              variant="primary"
              type="submit"
            />
          </form>
          <div className={classNames('flex', 'items-start', 'sm:ml-4')}>
            <div className={classNames('w-7', 'h-7', 'mr-4')}>
              <SvgIcon className={classNames('block')} icon="call-us" size="md" />
            </div>
            <div className={classNames('text-sm', 'lg:w-1/2')}>
              <div
                className={classNames('font-bolder')}
                dangerouslySetInnerHTML={{ __html: fields.promoInfoHeadingText.value }}
              />
              <div dangerouslySetInnerHTML={{ __html: fields.promoInfoDescriptionText.value }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HealthSherpa;
