// Components

import { Feature } from '.generated/templates/Feature.Items.model';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type ContentBlockProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.ContentBlock;

const ContentBlock = ({ fields, rendering }: ContentBlockProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <div
      data-component="authorable/general/contentblock"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <RichTextA11yWrapper field={fields.richText} editable />
    </div>
  );
};

export default ContentBlock;
