// Global
import { Placeholder } from '@sitecore-jss/sitecore-jss-nextjs';
import { PlaceholderComponentProps } from '@sitecore-jss/sitecore-jss-react/types/components/Placeholder';
import classnames from 'classnames';
// Lib
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import { SectionSizeContext, SectionSize } from 'lib/state/section-size-context';
import React, { useContext } from 'react';
import ContentMangedIcon from '../Icons/ContentMangedIcon/ContentMangedIcon';
import { HeroIconNames } from '../Icons/HeroIcon/HeroIcon';

/**
 * Adds a visual area for authors to click when placeholder is empty
 * in Experience Editor
 */

export type PlaceholderWrapperComponentProps = PlaceholderComponentProps & {
  /**
   * Used if this is a placeholder we don't want to display to content author since it's not edible, e.g. Header/Footer.
   */
  hideHelperText?: boolean;
  /**
   * Use if we want to rename a placeholder but don't want to break existing content.
   * When not specified, the "name" will be used for display in Experience Editor.
   */
  displayName?: string;
  /**
   * The size of the container that this placeholder is in.  This will be accessible to components
   * that may need to alter appearance based on container size.
   */
  wrapperSize: SectionSize;
};

type PlaceholderMessageProps = {
  icon: HeroIconNames;
  message: string;
};

const PlaceholderMessage = ({ icon, message }: PlaceholderMessageProps): JSX.Element => {
  const placeholderWrapperClasses = classnames('text-sm', 'text-center', 'p-1', 'font-bold');

  const placeholderNameClasses = classnames(
    'bg-theme-text',
    'text-theme-bg',
    'inline-block',
    'px-1.5',
    'py-0.5',
    'leading-tight',
    'mx-2',
    'opacity-80'
  );

  return (
    <div className={placeholderWrapperClasses}>
      Placeholder:
      <span className={placeholderNameClasses}>{message}</span>
      <ContentMangedIcon className={classnames('inline-block')} icon={icon} size="em" />
    </div>
  );
};

export const PlaceholderContext = React.createContext<string>('');

export const usePlaceholderContext = () => {
  const context = useContext(PlaceholderContext);

  return context;
};

const PlaceholderWrapper = ({
  name,
  hideHelperText,
  displayName,
  wrapperSize,
  ...props
}: PlaceholderWrapperComponentProps): JSX.Element => {
  const showHelper = !hideHelperText && useExperienceEditor();

  const ph = (
    <Placeholder
      name={name}
      {...props}
      render={(components) => {
        return components.map((component) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const componentOrSitecore = component as any;
          if (componentOrSitecore?.props?.type === 'text/sitecore') {
            return component;
          }

          return (
            <React.Fragment key={componentOrSitecore?.key}>
              <SectionSizeContext.Provider value={wrapperSize}>
                <PlaceholderContext.Provider value={name}>{component}</PlaceholderContext.Provider>
              </SectionSizeContext.Provider>
            </React.Fragment>
          );
        });
      }}
    />
  );

  return showHelper ? (
    <div>
      {showHelper ? (
        <PlaceholderMessage message={displayName || name} icon="ArrowDownIcon" />
      ) : (
        <></>
      )}
      {ph}
      {showHelper ? <PlaceholderMessage message={displayName || name} icon="ArrowUpIcon" /> : <></>}
    </div>
  ) : (
    ph
  );
};

export default PlaceholderWrapper;
