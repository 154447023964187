// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import ImageWrapper, { resizeHeight } from 'components/helpers/ImageWrapper/ImageWrapper';
import NextLink from 'next/link';

export type LogoHeaderProps = Sitecore.Override.ComponentBase &
  Feature.Components.Header.Fields.LogoHeader;

const LogoHeader = ({ fields }: LogoHeaderProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  // fields: {
  //   logo: ImageField;
  //   LogoLink: LinkField;
  // };

  return (
    <Container
      dataComponent="authorable/site/logoheader"
      className={classNames('border-b border-b-gray', 'flex', 'justify-center', 'py-4')}
    >
      <div className={classNames('')}>
        <NextLink href={fields.logoLink?.value}>
          <a
            href={fields.logoLink?.value.href}
            aria-label={fields.logoLink?.value?.text}
            title={fields.logoLink?.value?.title}
            className="block text-center"
          >
            <span className={classNames('inline-block', 'px-5', 'md:hidden')}>
              <ImageWrapper
                priority
                skipExternalSvg
                imageClass={classNames('h-[64px]', 'w-auto')}
                editable={false}
                field={resizeHeight(fields.logo, 64)}
              />
            </span>
            <span className={classNames('hidden', 'md:inline-block')}>
              <ImageWrapper
                priority
                skipExternalSvg
                imageClass={classNames('h-[50px]', 'w-auto')}
                editable={false}
                field={resizeHeight(fields.logo, 50)}
              />
            </span>
          </a>
        </NextLink>
      </div>
    </Container>
  );
};

export default LogoHeader;
