// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Field, RichTextField } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import React from 'react';

export type TableProps = Feature.Components.Table.Fields.TableComponent & {
  uid?: string;
};

export const Table = (props: TableProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!props.fields) {
    return <></>;
  }

  const headingArr: Field<string>[] = [];

  ({
    column1Heading: headingArr[0],
    column2Heading: headingArr[1],
    column3Heading: headingArr[2],
    column4Heading: headingArr[3],
    column5Heading: headingArr[4],
  } = props.fields);

  const columnCount = headingArr?.filter((p) => p?.value).length;

  const isColumnCountValid = columnCount <= 5 && columnCount > 0;

  const NumberOfColumnsToDisplay = props.fields.numberOfColumnsToDisplay?.value
    ? SetNumberOfColumnsToDisplay(props.fields.numberOfColumnsToDisplay.value)
    : isColumnCountValid
    ? columnCount
    : 5;

  const HideColumnHeaders = props.fields.hideColumnHeaders
    ? props.fields.hideColumnHeaders.value
    : false;

  function SetNumberOfColumnsToDisplay(numberOfColumnsToDisplay: number) {
    let result = 5;
    const isValidNumberOfCols = numberOfColumnsToDisplay <= 5 && numberOfColumnsToDisplay > 0;
    if (isValidNumberOfCols) {
      result = numberOfColumnsToDisplay;
      return result;
    } else return result;
  }

  return (
    <Container dataComponent="authorable/table/table-component/table">
      <div>
        <HeadingWrapper
          {...props.fields}
          defaultHeadlinePosition="left"
          headingClassName={classNames(
            'md:border-b-0',
            'border-b-2',
            'border-blue-navy',
            'pb-6',
            'md:pb-0'
          )}
        />
        <table className={classNames('table-fixed', 'border-spacing-y-4', 'w-full')}>
          <thead>
            <tr
              className={classNames(
                'align-top',
                { 'md:border-b-2': !HideColumnHeaders },
                { 'md:border-blue-navy': !HideColumnHeaders },
                'hidden',
                'md:table-row'
              )}
            >
              {headingArr?.slice(0, NumberOfColumnsToDisplay).map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    {
                      <th
                        className={classNames(
                          'font-regular',
                          'py-3',
                          'uppercase',
                          'text-left',
                          {
                            'sr-only': HideColumnHeaders,
                          },
                          {
                            hidden: !i?.value,
                            'md:table-cell': !i?.value,
                          }
                        )}
                        scope="col"
                        id={`ColumnHeader-${props.uid}-${index + 1}`}
                      >
                        {i?.value}
                      </th>
                    }
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {props.fields?.tableRows?.map((i) => {
              const row = i as Sitecore.Override.ItemEx &
                Feature.Data.Table.Fields.TableComponentRow;

              // We can't use `Object?.values(row?.fields);` because the order isn't guaranteed
              const rowColumns = [
                row.fields.column1Body,
                row.fields.column2Body,
                row.fields.column3Body,
                row.fields.column4Body,
                row.fields.column5Body,
              ];

              return (
                <tr
                  className={classNames(
                    'align-top',
                    'grid',
                    'border-b',
                    'border-gray',
                    'last:border-b-0',
                    'md:table-row'
                  )}
                  key={row?.id}
                >
                  {rowColumns?.slice(0, NumberOfColumnsToDisplay)?.map((x, index) => {
                    const column = x as Field<string>;
                    return (
                      <td
                        key={index}
                        headers={`ColumnHeader-${props.uid}-${index + 1}`}
                        className={classNames(
                          'py-3',
                          {
                            hidden: !column?.value,
                            'md:table-cell': !column?.value,
                          },
                          'md:pr-6'
                        )}
                      >
                        <RichTextA11yWrapper field={x as RichTextField} editable />
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Container>
  );
};

const TableComponent = (props: TableProps & Sitecore.Override.ComponentBase): JSX.Element => {
  const componentAnchorId = props.rendering?.uid;
  return (
    <div data-component="authorable/table/table-component" id={componentAnchorId}>
      <Table fields={props.fields} uid={componentAnchorId}></Table>
    </div>
  );
};

export default TableComponent;
