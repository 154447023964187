/**
 * Note: use the `normalizeGuid`, `guidEquals`, or `guidIsOneOf` function to compare guids to prevent format
 * differences from mattering
 */
export const TemplatesIds = {
  MediaArticle: 'a9364a5a-1dd9-468a-9dbf-7a0afcbb874b',
  NewsArticle: '{1D18A32D-692B-4C03-BD1F-1DD50091E3CE}',
  HealthLibraryFeed: '{FD5B8DC2-2498-4D52-A597-6C28906161A0}',
  HealthLibraryWildcard: '{47A28A59-A907-472F-8176-839DE72C8D74}',
  NoHeaderPage: '{B31D26C2-2C21-490A-9D9A-8F3F3CD978C8}',
  RelativeLinkFolder: '50cb1dce-0501-43a2-a3ab-66a901e5131a',
  TableComponent: '{04005B50-0FC5-4103-822D-6707E3D8614E}',
  DocumentLinkListing: '{BE23C3A9-3B41-428B-86EE-B26E33B75130}',
  RelativeLinkListing: '{171770BD-A316-4F7E-BEC6-2B9C5D1FCF3C}',
};

export const ItemIds = {
  AzBlueIndexedMedia: '{543F1417-326D-5534-BC7F-D39C396E5E92}',
  MedicalPoliciesMedia: '{7917483F-A63D-4B06-9BF2-2C405FF6B63E}',
};
