/* eslint-disable @next/next/no-page-custom-font */
// Global
import { LayoutServiceData } from '@sitecore-jss/sitecore-jss-nextjs';

import classNames from 'classnames';
// Lib
import { getThemeClasses } from 'lib/get-theme';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import FavIcon from 'components/helpers/HeadHelpers/FavIcon/FavIcon';
import GTMScripts from 'components/helpers/HeadHelpers/GTMScripts/GTMScripts';
import PageMetaData from 'components/helpers/HeadHelpers/PageMetaData/PageMetaData';
import CookieSetter from 'components/helpers/CookieSetter/CookieSetter';
import HrefLang from 'components/helpers/HeadHelpers/HrefLang/HrefLang';
import { Project } from '.generated/templates/Project.Items.model';
import ConditionalWrapper from 'components/helpers/ConditionalWrapper/ConditionalWrapper';
import ExternalMediaRedirect from 'components/helpers/HeadHelpers/ExternalMediaRedirect/ExternalMediaRedirect';
import ExternalLinkModal from 'components/helpers/Modals/ExternalLinks/ExternalLinkModal';
import BlockingModal from 'components/helpers/Modals/BlockingModal/BlockingModal';
import { Cookies } from 'react-cookie';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { guidEquals } from 'lib/string-utils';
import { TemplatesIds } from 'lib/sitecore/template-ids';
import Button from 'components/helpers/Button/Button';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { useI18n } from 'next-localization';
import PopUpCTA from 'components/helpers/PopUpCTA/PopUpCTA';
import UtmParams from 'components/helpers/Utility/Utm/UtmParams';
import QualtricsCustoms from 'components/helpers/Utility/Qualtrics/QualtricsCustoms';
import ScreenMeetAllowMultiDomain from 'components/helpers/Utility/ScreenMeet/ScreenMeetAllowMultiDomain';
import ScreenmeetScript from 'components/helpers/HeadHelpers/Screenmeet/ScreenmeetScript';
import ScreenMeetModalLinkListener from 'components/helpers/Utility/ScreenMeet/ScreenMeetModalLinkListener';

interface LayoutProps {
  layoutData: LayoutServiceData;
}

const PageLayout = ({ layoutData }: LayoutProps): JSX.Element => {
  const { route } = layoutData.sitecore;
  const [loaded, setLoaded] = useState(false);
  const cookies = new Cookies();
  const accepted = cookies.get('disclaimer-acceptance');
  const router = useRouter();
  const i18n = useI18n();
  const page = route as Project.Pages.Fields.Page;
  // Fail out if we don't have any page data.
  if (!route) {
    return <></>;
  }
  const mainContent = useRef<HTMLElement>(null);

  const themeClasses = getThemeClasses('theme-white');
  const externalLinkModal = layoutData.sitecore.context?.externalLinkModal;
  const blockingModal = layoutData.sitecore.context?.blockingModal;
  useEffect(() => {
    // This code finds #id from url and if the same Anchor ID exist in page then scrolls down to that anchor ID
    const sectionId = router.asPath.includes('#') ? router.asPath.split('#')[1] : null;
    if (sectionId !== null && document.getElementById(sectionId) !== null) {
      const anchorposTop = document?.getElementById(sectionId)?.getBoundingClientRect().top || 0;
      const topCount =
        window.scrollY + anchorposTop - document.querySelectorAll('header')[0].offsetHeight;
      setTimeout(() => {
        window.scrollTo(0, topCount);
      }, 0);
      document?.getElementById(sectionId)?.scrollIntoView();
    }
  }, [router.asPath]);
  useEffect(() => {
    setLoaded(true);
  }, []);

  const sitecoreContext = layoutData.sitecore.context;
  const language = sitecoreContext.language;
  const healthLibraryArticle = sitecoreContext.healthLibraryArticle;
  const newsFeed = sitecoreContext.newsFeed;
  return (
    <>
      <GTMScripts />
      <ScreenmeetScript />

      {/* adds screenmeet click listner for class azblue-cobrowse-modal */}
      <ScreenMeetModalLinkListener />
      <ExternalMediaRedirect />
      <PageMetaData
        route={route}
        language={language}
        healthLibraryArticle={healthLibraryArticle}
        newsFeed={newsFeed}
      />
      <FavIcon />
      <HrefLang />
      <UtmParams />
      <QualtricsCustoms />

      <ConditionalRender
        condition={layoutData.sitecore.context.itemPath?.startsWith('/member') ?? false}
      >
        <CookieSetter
          cookieName="visited-member"
          cookieValue={'true'}
          // If we're past the year 300 and this code is still in use we have bigger problems
          // Note: browsers can limit this to even shorter.  In Chrome it's 400 days.
          // In Brave browser for cookies set in JS it's 7 days
          expiration={new Date(3000, 1, 1)}
        />
      </ConditionalRender>
      {/* Prevent transitions from happening on page load */}
      <div className={classNames('flex', 'flex-col', 'min-h-screen', { 'pre-load': !loaded })}>
        <Button
          className={classNames(
            '!bg-white',
            'absolute',
            'z-50',
            'left-1/3',
            '-translate-y-full',
            'opacity-0',
            'focus:translate-y-1',
            'focus:opacity-100'
          )}
          text={i18n.t('Skip To Main content')}
          data-coveo-no-index="true"
          variant="secondary"
          onClick={() => mainContent.current?.focus()}
          size="small"
        />

        <header className={classNames(themeClasses, 'sticky', 'top-0', 'z-40')}>
          <PlaceholderWrapper
            wrapperSize="wide"
            rendering={route}
            name="BCBSAZ-Header"
            hideHelperText={!guidEquals(route.templateId, TemplatesIds.NoHeaderPage)}
          />
        </header>
        <main ref={mainContent} tabIndex={-1} className={classNames(themeClasses, 'flex-grow')}>
          {/* Site main content */}
          <PlaceholderWrapper
            wrapperSize="wide"
            rendering={route}
            name="BCBSAZ-AlertMessage"
            hideHelperText={!guidEquals(route.templateId, TemplatesIds.NoHeaderPage)}
          />
          <PlaceholderWrapper
            wrapperSize="wide"
            rendering={route}
            name="BCBSAZ-Breadcrumb"
            hideHelperText={!guidEquals(route.templateId, TemplatesIds.NoHeaderPage)}
          />
          <ConditionalWrapper
            condition={!!page.fields?.useNarrowLayout?.value}
            wrapper={(children: JSX.Element) => (
              <div
                className={classNames(
                  'max-w-[1040px]',
                  'mx-auto',
                  'narrow-container',
                  'container-content-padding-x'
                )}
              >
                {children}
              </div>
            )}
          >
            <>
              <PlaceholderWrapper wrapperSize="wide" rendering={route} name="BCBSAZ-Hero" />

              <PlaceholderWrapper wrapperSize="wide" rendering={route} name="BCBSAZ-Body" />
              <PlaceholderWrapper
                wrapperSize="wide"
                rendering={route}
                name="BCBSAZ-Pre-Footer"
                hideHelperText
              />
            </>
          </ConditionalWrapper>
        </main>

        <footer data-coveo-no-index="true">
          <PlaceholderWrapper
            wrapperSize="wide"
            rendering={route}
            name="BCBSAZ-Footer"
            hideHelperText
          />
        </footer>
      </div>

      {externalLinkModal ? <ExternalLinkModal externalLinkModal={externalLinkModal} /> : <></>}
      {blockingModal && !accepted ? <BlockingModal fields={blockingModal.fields} /> : null}
      {layoutData.sitecore.context?.popupCta && (
        <PopUpCTA popupCta={layoutData.sitecore.context.popupCta} />
      )}

      {/* component to allow screen meet to multi domain */}
      <ScreenMeetAllowMultiDomain />
    </>
  );
};

export default PageLayout;
