// Lib
import { useRouter } from 'next/router';
import classNames from 'classnames';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { LinkField } from '@sitecore-jss/sitecore-jss-react';
import HeroIcon from '../Icons/HeroIcon/HeroIcon';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';

export type HeaderLinkProps = {
  link: LinkField;
  className?: string;
  chevron?: boolean;
};

const HeaderLink = ({ link, className, chevron }: HeaderLinkProps): JSX.Element => {
  const route = useRouter();
  // Fail out if we don't have any fields
  if (!link) {
    return <></>;
  }

  return (
    <LinkA11yWrapper
      className={classNames('header-navList-menuItem', className, {
        'current-item': route?.asPath === link.value?.href,
      })}
      field={link}
    >
      <ConditionalRender condition={!!chevron}>
        <span className={classNames('ml-5')}>
          <HeroIcon icon={'ChevronRightIcon'} size={'em'} />
        </span>
      </ConditionalRender>
    </LinkA11yWrapper>
  );
};

export default HeaderLink;
