import { AtomicSearchInterface } from '@coveo/atomic-react';
import { buildContext, buildSearchStatus, buildUrlManager } from '@coveo/headless';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { createNewCoveoEngine, useCoveoEngine } from 'lib/coveo/coveo';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import { getDisplayName } from 'next/dist/shared/lib/utils';
import dynamic from 'next/dynamic';
import React, { useContext, useEffect } from 'react';
import { debouncedSearch } from '../headless/actions/headless-search';
import { useRouter } from 'next/router';

type AzBlueAtomicSearchInterfaceProps = React.PropsWithChildren;

const AzBlueAtomicSearchInterfaceWrapper = ({ children }: AzBlueAtomicSearchInterfaceProps) => {
  const sitecoreContext = useSitecoreContext();

  const router = useRouter();
  const coveoEngine = useCoveoEngine();
  const coveoContext = buildContext(coveoEngine);
  const searchStatus = buildSearchStatus(coveoEngine);
  const urlManager = buildUrlManager(coveoEngine, {
    // Setting it to empty because window hash is not available server-side
    initialState: { fragment: '' },
  });

  const onHashChange = () => {
    const fragment = window.location.hash.slice(1);

    urlManager.synchronize(fragment);
  };

  useEffect(() => {
    coveoContext.set({
      ...coveoContext.state.values,
      language: sitecoreContext.sitecoreContext.language ?? 'en',
    });
    if (searchStatus.state.firstSearchExecuted) {
      debouncedSearch(coveoEngine);
    }
  }, [sitecoreContext.sitecoreContext.language]);

  useEffect(() => {
    router.events.on('routeChangeStart', onRouteChangeStart);

    const urlUnSub = urlManager.subscribe(() => {
      const hash = `#${urlManager.state.fragment}`;

      if (!searchStatus.state.firstSearchExecuted) {
        history.replaceState(null, document.title, hash);
        return;
      }

      history.pushState(null, document.title, hash);
    });

    window.addEventListener('hashchange', onHashChange);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      urlUnSub();
      window.removeEventListener('hashchange', onHashChange);
    };
  }, []);

  // Get the fragment as it is before other manipulation happens
  let fragment = '';
  if (typeof window !== 'undefined') {
    fragment = window.location.hash.slice(1);
  }

  return (
    <AtomicSearchInterface
      engine={coveoEngine}
      reflectStateInUrl={false}
      onReady={async () => {
        // Synchronize before doing the search
        await debouncedSearch(coveoEngine, () => urlManager.synchronize(fragment));
      }}
      language={sitecoreContext.sitecoreContext?.language || 'en'}
      languageAssetsPath="/lang"
      scrollContainer="#scroll-target"
      fieldsToInclude={[
        'image_url',
        'article_category',
        'source_outlet',
        'question',
        'answer',
        'published_date',
        'updated_date',
        'searchtype',
        'hasform',
      ]}
    >
      {children}
    </AtomicSearchInterface>
  );
};

const DynamicAzBlueAtomicSearchInterfaceWrapper = dynamic(
  () => Promise.resolve(AzBlueAtomicSearchInterfaceWrapper),
  {
    ssr: false,
  }
);

const AtomicWrapperContext = React.createContext<boolean>(false);

function onRouteChangeStart() {
  // Clear out any existing context data.  Fixes: https://dev.azure.com/AZBlue/OneAZBlue/_workitems/edit/386529
  // If someone went to the newroom, the existing newsfeed context would still apply even though it may not be applicable for the page

  createNewCoveoEngine();
}

export function withAtomicSearchInterface<P>(Component: React.ComponentType<P>) {
  // Not sure why the {} is needed by TypeScript complains without it
  const WrappedComponent: React.FC<P & {}> = (props: P & {}) => {
    const isEE = useExperienceEditor();
    // Prevent nested wrappers
    const hasAtomicWrapper = useContext(AtomicWrapperContext);
    if (hasAtomicWrapper) {
      return <Component {...props} />;
    } else if (isEE) {
      return (
        <AzBlueAtomicSearchInterfaceWrapper>
          <AtomicWrapperContext.Provider value={true}>
            <Component {...props} />
          </AtomicWrapperContext.Provider>
        </AzBlueAtomicSearchInterfaceWrapper>
      );
    } else {
      return (
        <DynamicAzBlueAtomicSearchInterfaceWrapper>
          <AtomicWrapperContext.Provider value={true}>
            <Component {...props} />
          </AtomicWrapperContext.Provider>
        </DynamicAzBlueAtomicSearchInterfaceWrapper>
      );
    }
  };
  WrappedComponent.displayName = `withAtomicSearchInterface(${getDisplayName(WrappedComponent)})`;
  return WrappedComponent;
}
