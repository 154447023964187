// Global
import { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { HTMLAttributes, useEffect, useRef } from 'react';

export interface ExternalSvgIconProps extends HTMLAttributes<HTMLSpanElement> {
  field: SizedImageField;
  svgClassName?: string;
  removeFill?: boolean;
}

const ExternalSvgIcon = ({
  field,
  svgClassName,
  removeFill = true,
  ...props
}: ExternalSvgIconProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const removeFillCallback = () => {
    // Remove all the fill attributes so we can style them.
    if (removeFill) {
      svgRef.current?.querySelectorAll('[fill]').forEach((x) => x.removeAttribute('fill'));
    }
  };

  useEffect(() => {
    // Setting the 'data-src' in `useEffect` so we don't get a React Hydration error.
    // This was only happening on production builds on low bandwidth (mobile lighthouse testing)
    // but it was cause a Hydration error which was causing a rerender which lowered performance on mobile
    field.value?.src &&
      svgRef?.current &&
      svgRef.current.setAttribute('data-src', field.value?.src);

    svgRef?.current && svgRef.current.addEventListener('iconload', removeFillCallback);
    return () => {
      svgRef?.current && svgRef.current.removeEventListener('iconload', removeFillCallback);
    };
  }, [svgRef?.current]);

  if (!field) {
    return <></>;
  }

  const { src: _src, ...svgProps } = { ...field.value };
  return (
    <span {...props}>
      {/* If this isn't working, make sure
        There is `import 'external-svg-loader';` in your `_app.tsx` and NPM library is included */}
      <svg
        {...svgProps}
        className={svgClassName}
        ref={svgRef}
        data-cache="disabled"
        data-loading="lazy"
        // We are setting the data-src in useEffect, see comment above
        // data-src={field.value?.src}
        fill={removeFill ? 'currentColor' : undefined}
      />
    </span>
  );
};

export default ExternalSvgIcon;
