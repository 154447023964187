import React, { useContext } from 'react';

export const UtmContext = React.createContext<Record<string, string>>({});

const useUtmContext = () => {
  const context = useContext(UtmContext);

  return context;
};

export default useUtmContext;
