// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import EditingHelpText from 'components/helpers/EditingHelpText';
import Heading from 'components/helpers/Heading/Heading';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { TemplatesIds } from 'lib/sitecore/template-ids';
import { guidEquals } from 'lib/string-utils';
import { isExternalLinkForNewTab } from 'lib/utils/is-external-link-for-new-tab';

export interface RelativeLinkListingProps {
  fields: {
    root?: RelativeLinkFolderItem;
  };
}

//Rules for links
//PDFs open in a new tab
//Links to AZBlue.com open in the same tab
//Links to external sites like azbluefacts.com or azbluefoundation.org are in a new tab
export type RelativeLinkItem = {
  id: string;
  linkText: string;
  url: string;
};

export type RelativeLinkFolderItem = Sitecore.Override.ItemEx &
  Feature.Data.RelativeLinks.Fields.RelativeLinkFolder & {
    refLinkLists?: {
      name: string;
      description: string;
      links: RelativeLinkItem[];
    };
    children: Sitecore.Override.ItemEx[];
  };

const RelativeLinkListing = ({ fields }: RelativeLinkListingProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const GetHtml = (folderItem: RelativeLinkFolderItem) => {
    return folderItem.children.map((element) => {
      const docItem = element as RelativeLinkFolderItem;
      return (
        <tr key={docItem?.id} className={classNames()}>
          <td className={classNames('py-5', 'align-top', 'space-y-1')}>
            <div className={classNames('font-bolder')}>
              {docItem?.refLinkLists?.name || docItem?.displayName}
            </div>
            <div>{docItem?.refLinkLists?.description}</div>
          </td>
          <td className={classNames('py-5', 'space-y-1')}>
            {docItem?.refLinkLists?.links.map((e) => {
              const is_ExternalLinkForNewTab: boolean | undefined = isExternalLinkForNewTab(e?.url);
              const isNewTab = is_ExternalLinkForNewTab ? '_blank' : '_self';
              return (
                <div key={e.id}>
                  <LinkA11yWrapper
                    field={{
                      value: {
                        href: e?.url,
                        text: e.linkText,
                        target: isNewTab,
                      },
                    }}
                  />
                </div>
              );
            })}
          </td>
        </tr>
      );
    });
  };

  const GetFolderHtml = (folderItem: RelativeLinkFolderItem | undefined) => {
    if (!folderItem) {
      return null;
    }
    return (
      <>
        <Heading
          headingLevel="h2"
          headingSize="md"
          description={folderItem.fields?.description}
          headingText={folderItem.fields?.title}
          descriptionClassName={classNames('mb-6')}
        />
        <table className={classNames('table-fixed', 'w-full', 'text-left')}>
          <thead className={classNames('uppercase', 'text-gray-dark')}>
            <tr className={classNames('border-b-2', 'tracking-wider', 'border-blue-navy')}>
              <th scope="col" className={classNames('font-regular', 'py-3')}>
                <Text field={folderItem.fields?.column1Label} />
              </th>
              <th scope="col" className={classNames('font-regular', 'py-3')}>
                <Text field={folderItem.fields?.column2Label} />
              </th>
            </tr>
          </thead>
          <tbody className={classNames('divide-y', 'divide-gray')}>{GetHtml(folderItem)}</tbody>
        </table>
      </>
    );
  };

  const HasFolderChildren = (folderItem: RelativeLinkFolderItem | undefined) => {
    let returnVal = false;
    folderItem?.children.forEach((element) => {
      const scElement = element as Sitecore.Override.ItemEx;
      if (guidEquals(scElement?.templateId, TemplatesIds.RelativeLinkFolder)) {
        returnVal = true;
      }
    });
    return returnVal;
  };

  const hasFolderChildrenBool = HasFolderChildren(fields?.root);

  return (
    <Container dataComponent="authorable/table/relativelinklisting">
      <EditingHelpText
        condition={!fields.root}
        text="Relative Link Listing | No links folder selected . "
      />
      <EditingHelpText
        condition={!fields.root?.children?.length}
        text="Relative Link Listing | No links in folder. "
      />
      {!hasFolderChildrenBool ? GetFolderHtml(fields?.root) : null}

      {hasFolderChildrenBool
        ? fields?.root?.children.map((e) => {
            return !guidEquals(e?.templateId, TemplatesIds.RelativeLinkFolder) ? (
              <></>
            ) : (
              <div key={e.id}>{GetFolderHtml(e as RelativeLinkFolderItem)}</div>
            );
          })
        : null}
    </Container>
  );
};

export default RelativeLinkListing;
