// Global
import { LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import classNames from 'classnames';

// Lib
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import { useState } from 'react';

// Local
import { HeroIconNames } from 'components/helpers/Icons/HeroIcon/HeroIcon';
import ButtonLinkIcon, { ButtonIconPosition } from '../Icons/ButtonLinkIcon/ButtonLinkIcon';
import { SvgIconNames } from 'components/helpers/Icons/SvgIcon/SvgIcon';
import ModalStandard from '../Modals/ModalStandard/ModalStandard';
import VideoItem from '../VideoItem/VideoItem';
import LinkA11yWrapper from '../LinkA11yWrapper/LinkA11yWrapper';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';
import { IconSize } from '../Icons/ContentMangedIcon/ContentMangedIcon';

type ButtonSize = 'large' | 'small';
type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'link';

export interface ButtonProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  disabled?: boolean;
  field?: LinkField;
  icon?: HeroIconNames | SvgIconNames;
  iconPosition?: ButtonIconPosition;
  hasModal?: boolean;
  iconSize?: IconSize;
  id?: string;
  onClick?: (event: React.MouseEvent | React.TouchEvent) => void;
  size?: ButtonSize | undefined;
  srOnlyText?: string | undefined;
  text?: string;
  type?: 'button' | 'submit';
  variant?: ButtonVariant | undefined;
  ignoreExtLinkModal?: boolean;
}

const buttonBaseClasses = classNames(
  'appearance-none',
  'duration-200',
  'ease-in-out',
  'align-middle',
  'inline-flex',
  'items-center',
  'justify-center',
  'leading-none',
  'rounded-full',
  'text-sm',
  'transition-colors',
  'whitespace-pre-line',
  'sm:whitespace-pre'
);
const anchorBaseClasses = classNames(
  'align-middle',
  'duration-200',
  'ease-in-out',
  'font-normal',
  'lg:font-semibold',
  'leading-tight',
  'inline-flex',
  'items-center',
  'transition-colors'
);

const buttonClasses: Record<ButtonVariant, string> = {
  primary: classNames(
    buttonBaseClasses,
    'bg-btn-bg-primary',
    'font-bolder',
    'hover:bg-btn-bg-primary-hover',
    'hover:text-btn-text-primary',
    'hover:ring-btn-border-primary-hover',
    'ring-1',
    'ring-btn-border-primary',
    'text-btn-text-primary',
    'text-base',
    'sm:w-auto',
    'w-full'
  ),
  secondary: classNames(
    buttonBaseClasses,
    'bg-btn-bg-secondary',
    'font-bolder',
    'hover:bg-btn-bg-secondary-hover',
    'hover:ring-2',
    'hover:ring-btn-border-secondary-hover',
    'hover:text-btn-text-secondary-hover',
    'ring-1',
    'ring-btn-border-secondary',
    'text-btn-text-secondary',
    'text-base',
    'sm:w-auto',
    'w-full'
  ),
  tertiary: classNames(
    buttonBaseClasses,
    'font-bolder',
    'hover-arrow-icon',
    'hover:underline-offset-2',
    'hover:underline',
    '!px-0',
    '!py-1',
    'ring-0',
    'text-btn-text-tertiary',
    'text-base'
  ),
  link: classNames(
    anchorBaseClasses,
    'hover:bg-transparent',
    'hover:text-btn-link-text-hover',
    'hover:underline',
    'text-btn-link-text',
    '!font-normal'
  ),
};

const disabledButtonClasses: Record<ButtonVariant, string> = {
  primary: classNames(
    buttonBaseClasses,
    'bg-btn-disabled-bg-primary',
    'font-bolder',
    'ring-2',
    'ring-btn-disabled-border-primary',
    'text-base',
    'text-btn-disabled-text-primary',
    'pointer-events-none'
  ),
  secondary: classNames(
    buttonBaseClasses,
    'bg-btn-disabled-bg-secondary',
    'font-bolder',
    'ring-btn-disabled-border-secondary',
    'text-base',
    'text-btn-disabled-text-secondary',
    'pointer-events-none'
  ),
  tertiary: classNames(
    'align-middle',
    'font-bolder',
    'pointer-events-none',
    'text-base',
    'text-btn-disabled-text-tertiary',
    'pointer-events-none'
  ),
  link: classNames(
    'align-middle',
    'font-semibold',
    'text-base',
    'text-btn-disabled-text-tertiary',
    'pointer-events-none'
  ),
};

const buttonSize: Record<ButtonSize, string> = {
  large: 'px-10 py-4',
  small: 'px-8 py-3',
};

export const CTA = ({
  className,
  disabled,
  field,
  icon,
  iconPosition = 'right',
  iconSize,
  id,
  onClick,
  size = 'large',
  srOnlyText,
  text,
  type = 'button',
  variant = 'primary',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasModal,
  ignoreExtLinkModal,
  ...props
}: ButtonProps): JSX.Element => {
  const buttonIcon = variant === 'tertiary' ? 'hover-arrow' : icon;

  /*
  State
  Convnience Methods
  */
  const configuredClasses = classNames(
    {
      'hover-arrow-icon': buttonIcon === 'hover-arrow',
    },
    !disabled
      ? `${buttonClasses[variant]} ${variant !== 'link' && buttonSize[size]}`
      : `${disabledButtonClasses[variant]} ${buttonSize[size]}`,
    {
      'js-ignore-modal': ignoreExtLinkModal,
    },
    className
  );
  const leftContent = () => {
    return (
      <ConditionalRender
        condition={!!buttonIcon && (iconPosition === 'left' || iconPosition === 'center')}
      >
        <ButtonLinkIcon
          icon={buttonIcon}
          iconPosition={iconPosition as ButtonIconPosition}
          iconSize={iconSize}
          variant={variant}
        />
      </ConditionalRender>
    );
  };

  const rightContent = () => {
    return (
      <>
        {srOnlyText ? <span className="sr-only">{srOnlyText}</span> : ''}
        <ConditionalRender condition={!!buttonIcon && iconPosition === 'right'}>
          <ButtonLinkIcon
            icon={buttonIcon}
            iconPosition={iconPosition as ButtonIconPosition}
            iconSize={iconSize}
            variant={variant}
          />
        </ConditionalRender>
      </>
    );
  };

  const ctaText = () => {
    if (field) {
      return field?.value.text;
    }
    if (iconPosition === 'center') {
      return null;
    }
    return <>{text ?? props.children}</>;
  };

  const renderCtaContent = () => {
    return (
      <>
        {leftContent()} {ctaText()} {rightContent()}
      </>
    );
  };

  const isEE = useExperienceEditor();

  /*
  Rendering
  */
  return (
    <>
      {!!field ? (
        isEE ? (
          <>
            <LinkA11yWrapper {...props} className={classNames()} field={field as LinkField} />
          </>
        ) : (
          <>
            {buttonIcon || variant === 'tertiary' ? (
              <LinkA11yWrapper
                {...props}
                onClick={onClick}
                className={classNames(configuredClasses)}
                field={field as LinkField}
                showLinkTextWithChildrenPresent={false}
                hideText={true}
                suppressNewTabIcon
              >
                {renderCtaContent()}
              </LinkA11yWrapper>
            ) : (
              <LinkA11yWrapper
                {...props}
                onClick={onClick}
                linkClass={classNames(configuredClasses)}
                field={field as LinkField}
                showLinkTextWithChildrenPresent={false}
                hideText={!!text}
              >
                {text}
              </LinkA11yWrapper>
            )}
          </>
        )
      ) : (
        <button
          {...props}
          className={classNames(configuredClasses)}
          id={id}
          disabled={disabled}
          onClick={onClick}
          type={type}
        >
          {renderCtaContent()}
        </button>
      )}
    </>
  );
};

const Button = ({ ...props }: ButtonProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  const { field, variant, hasModal } = props;

  if (field && !field?.value) {
    console.warn('button field is not expected type', field);
    return <></>;
  }
  return (
    <>
      {hasModal ? (
        <>
          <CTA {...props} onClick={() => setShowModal(true)} />
          <ModalStandard
            triggerStyles={variant}
            triggerText={field?.value.text}
            externalTrigger
            showModal={showModal}
            handleModal={setShowModal}
          >
            <VideoItem videoSrc={field?.value.href} title={field?.value.title} />
          </ModalStandard>
        </>
      ) : (
        <CTA {...props} />
      )}
    </>
  );
};

export default Button;
