// Global
// Components
import classNames from 'classnames';
import Heading from 'components/helpers/Heading/Heading';
import {
  ActionCardType,
  ActionCardLink,
  AlignmentOptions,
} from 'components/authorable/cardListings/ActionCardListing/ActionCardListing';
import LinkA11yWrapper from '../LinkA11yWrapper/LinkA11yWrapper';
import RichTextA11yWrapper from '../RichTextA11yWrapper/RichTextA11yWrapper';
import React from 'react';
import { getEnum } from 'lib/get-enum';
import HeroIcon, { HeroIconNames } from '../Icons/HeroIcon/HeroIcon';
import ButtonLinkIcon from '../Icons/ButtonLinkIcon/ButtonLinkIcon';
import { isExternalLink } from 'lib/utils/is-external-link';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';

interface ExtendedCardProps {
  links?: ActionCardLink[];
  iconAlignment?: AlignmentOptions;
}

export type ActionCardProps = ActionCardType & ExtendedCardProps;

const ActionCard = ({ fields, links, iconAlignment }: ActionCardProps): JSX.Element => {
  const context = useSitecoreContext();

  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }

  const renderLinks = (item: ActionCardLink) => {
    const linkIcon = getEnum<HeroIconNames>(item?.fields.icon);
    const isExternal: boolean | undefined = isExternalLink(
      item?.fields.link.value.href,
      context.sitecoreContext?.internalUrlWhitelist
    );
    return (
      <>
        <span
          className={classNames('flex', {
            'items-center': iconAlignment === 'vertically-center',
            'items-top': iconAlignment === 'top',
          })}
        >
          <HeroIcon size="xs" outline icon={linkIcon} />
        </span>
        {item?.fields.link?.value.href && (
          <span className={classNames('flex-grow', 'shrink-0')}>
            <LinkA11yWrapper
              className={classNames('flex', 'hover-arrow-icon', 'items-center')}
              field={item.fields.link}
            >
              <ConditionalRender condition={!isExternal}>
                <ButtonLinkIcon iconPosition={'center'} icon="hover-arrow" className="ml-5" />
              </ConditionalRender>
            </LinkA11yWrapper>
          </span>
        )}
        {item?.fields.description?.value && (
          <RichTextA11yWrapper field={item?.fields.description} />
        )}
      </>
    );
  };

  return (
    <div className={classNames('flex', 'flex-col', 'h-full', 'w-full')}>
      <div className={classNames('flex', 'flex-col', 'flex-grow', 'shrink-0', 'text-gray-darkest')}>
        <Heading
          headingText={fields?.title}
          headingClassName={classNames('mb-2', 'font-regular', 'text-gray-darkest')}
          headingLevel="h4"
          headingSize="xxs"
          description={fields?.description}
          descriptionClassName={classNames('mb-8', 'text-base')}
          headingIsRichText
        />
        <ul className={classNames('flex', 'flex-col', 'gap-2')}>
          {links?.map((link) => (
            <li
              key={link.id}
              className={classNames(
                'flex',
                'gap-4',
                '[&>.rte>a]:!font-regular',
                'text-gray-darkest'
              )}
            >
              {renderLinks(link)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ActionCard;
