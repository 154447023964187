export const isExternalLink = (
  linkHref: string | undefined,
  internalUrlArray: string[] | undefined
) => {
  if (linkHref?.startsWith(process.env.PUBLIC_URL as string)) {
    return false;
  }
  if (!linkHref?.startsWith('http')) {
    return false;
  }
  if (!internalUrlArray?.length) {
    return true;
  }
  return (
    linkHref?.startsWith('http') &&
    internalUrlArray?.every((domain) => linkHref.indexOf(domain) === -1)
  );
};
