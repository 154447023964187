// Local
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import { MainNavigationMenu } from '../header-types';
import classNames from 'classnames';
import HeaderMainNavigationMenu from './HeaderMainNavigationMenu';
import { HeaderProps } from 'components/authorable/site/Header/Header';

export type MainNavGlobalProps = HeaderProps;

const MainNavGlobal = ({ fields }: MainNavGlobalProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  return (
    <nav
      className={classNames('header-navList', 'md:px-7', 'md:flex', 'md:items-center')}
      id="navigation-primary"
      role="navigation"
    >
      <ul
        className={classNames(
          { 'mt-10': !fields.hideUtility.value && !fields.isMobile },
          { 'md:mt-0': !fields.hideUtility.value && !fields.isMobile },
          'md:gap-12',
          'md:flex',
          'md:items-center'
        )}
      >
        {fields?.mainNavSections?.map((navItem: MainNavigationMenu) => (
          <li key={navItem.id}>
            <HeaderMainNavigationMenu item={navItem} closeText={fields?.closeMenuText?.value} />
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MainNavGlobal;
