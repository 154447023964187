import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import LinkA11yWrapper from 'components/helpers/LinkA11yWrapper/LinkA11yWrapper';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ConditionalRender from '../ConditionalWrapper/ConditionalRender';
import { NavigationMenuLink, HeaderMenuSectionProps } from './header-types';

import HeaderLink from './HeaderLink';

const HeaderMenuSection = ({ sectionLink, closeText, variant }: HeaderMenuSectionProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const route = useRouter();

  const onMouseDown = useCallback(
    (e: Event) => {
      if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setIsOpen(false);
      }
    },
    [containerRef, setIsOpen]
  );

  useEffect(() => {
    window.addEventListener('resize', () => setIsOpen(false));
    window.addEventListener('mousedown', onMouseDown);

    return () => {
      window.removeEventListener('resize', () => setIsOpen(false));
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  if (!sectionLink) {
    return <></>;
  }
  const validLinks = sectionLink.fields.sectionLinks.filter(
    (x: NavigationMenuLink) => x.fields.Link.value.href
  );
  return (
    <div ref={containerRef}>
      <ConditionalRender condition={variant === 'mobile'}>
        <Button
          className={classNames('header-navList-menuItem', {
            '!text-blue': route?.asPath === sectionLink.fields?.headingLink?.value.href,
          })}
          icon={'ChevronRightIcon'}
          iconSize="xs"
          iconPosition="right"
          id={sectionLink.id}
          onClick={() => setIsOpen(true)}
          size="small"
          text={
            sectionLink.fields?.headingLink?.value.href
              ? sectionLink.fields?.headingLink?.value.text
              : sectionLink.fields.heading.value
          }
          type="button"
          variant="link"
        />
      </ConditionalRender>
      <ConditionalRender condition={variant === 'desktop'}>
        {sectionLink?.fields?.headingLink?.value.href ? (
          <HeaderLink
            link={sectionLink.fields.headingLink}
            chevron
            className={classNames(
              'flex',
              'items-center',
              'justify-between',
              '!pointer-events-auto',
              '!text-lg'
            )}
          />
        ) : (
          <span className="header-navList-menuItem">{sectionLink?.fields.heading.value}</span>
        )}
      </ConditionalRender>

      <div
        className={classNames('header-navList-wingNav', 'transition-transform', 'duration-200', {
          'w-0': !isOpen,
          'w-full': isOpen,
          'transition-transform': isOpen,
          '-translate-x-full': isOpen,
          'z-10': isOpen,
        })}
      >
        <ul>
          {/* <ConditionalRender condition={!!validLinks.length}>
            Removed from conditional logic to always show close button when links are available.
            https://dev.azure.com/AZBlue/OneAZBlue/_workitems/edit/361880
          </ConditionalRender> */}
          <li>
            <Button
              className={classNames(
                'border-b',
                'border-gray',
                'border-solid',
                'header-navList-closeButton',
                'text-lg'
              )}
              icon="ChevronLeftIcon"
              iconSize="xs"
              iconPosition="left"
              onClick={() => setIsOpen(false)}
              size="large"
              text={closeText}
              variant="link"
            />
          </li>
          <ConditionalRender
            condition={variant === 'mobile' && !!sectionLink.fields.headingLink?.value?.href}
          >
            <li key="mobile-key" className={classNames('border-b', 'border-gray', 'font-semibold')}>
              <LinkA11yWrapper
                className={classNames('header-navList-menuItem')}
                field={sectionLink.fields.headingLink}
              />
            </li>
          </ConditionalRender>
          {validLinks.map((sectionLink) => {
            return (
              <li key={sectionLink.id}>
                <HeaderLink link={sectionLink.fields.Link} />
              </li>
            );
          })}
          <ConditionalRender condition={!!sectionLink.fields.allLink.value.href}>
            <li className={classNames('border-t', 'border-gray', 'mt-3', 'pt-3')}>
              <LinkA11yWrapper
                className="header-navList-menuItem"
                field={sectionLink.fields.allLink}
              />
            </li>
          </ConditionalRender>
        </ul>
      </div>
    </div>
  );
};

export default HeaderMenuSection;
