import { Foundation } from '.generated/templates/Foundation.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { buildSearchBox } from '@coveo/headless';
import classNames from 'classnames';
import HeroIcon from 'components/helpers/Icons/HeroIcon/HeroIcon';
import { useCoveoEngine } from 'lib/coveo/coveo';
import { ChangeEventHandler, useState, useRef } from 'react';

interface SearchBoxForPopUpProps {
  item: Sitecore.Override.ItemEx & Foundation.Data.Links.Fields.BaseLink;
}

export const AzBlueHeadlessSearchBoxForPopUp = ({ item }: SearchBoxForPopUpProps) => {
  const redirectUrl = item.fields?.Link?.value?.href;
  const name = item.fields?.Link?.value?.text;

  const inputRef = useRef<HTMLInputElement>(null);

  const coveoEngine = useCoveoEngine();
  const [searchValue, setSearchValue] = useState('');

  const isEnterKey = (e: React.KeyboardEvent<HTMLInputElement>) => e.key === 'Enter';

  const controller = buildSearchBox(coveoEngine, { options: { numberOfSuggestions: 5 } });

  const handleSubmit = () => {
    console.log('searchValue===>', searchValue);
    if (redirectUrl) {
      window.location.assign(`${redirectUrl}#q=${searchValue.trim()}`);
    } else {
      controller.updateText(searchValue);
      controller.submit();
    }
  };

  const handleInput: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSearchValue(e.target?.value);
    controller.updateText(e.target?.value);
  };

  return (
    <div
      className={classNames(
        'bg-white',
        'border',
        'border-gray',
        'md-border-0',
        'flex',
        'items-center',
        'h-12',
        'md:h-8',
        'py-[1px]',
        'px-2',
        'overflow-hidden',
        'relative',
        'rounded-lg',
        'w-full'
      )}
    >
      <button
        title="click to search"
        disabled={!searchValue}
        onClick={() => handleSubmit()}
        className={classNames('text-teal', 'absolute')}
        aria-label={name}
      >
        <HeroIcon size="xs" icon={'MagnifyingGlassIcon'} />
      </button>
      <input
        ref={inputRef}
        className={classNames(
          'flex-grow',
          'focus:outline-none',
          'pl-7',
          'rounded-lg',
          'text-gray-dark'
        )}
        value={searchValue}
        onChange={(e) => handleInput(e)}
        onKeyDown={(e) => isEnterKey(e) && searchValue && handleSubmit()}
        placeholder={name}
        enterKeyHint="search"
        aria-label={name}
        autoFocus={true}
      />
      <button
        className={classNames('text-gray-medium')}
        onClick={() => {
          searchValue && setSearchValue('');
          inputRef.current?.focus();
        }}
        aria-label="Clear Search"
      >
        <HeroIcon size="xxs" icon={'XMarkIcon'} />
      </button>
    </div>
  );
};
