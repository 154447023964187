// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import classNames from 'classnames';
import { MissingDataSource } from 'components/helpers/EditingHelpText';
import useWindowDimensions from 'components/helpers/WindowsSize/WindowSize';
import { getEnum } from 'lib/get-enum';
import LargeTestimonialImage from './LargeTestimonialImage';
import LargeTestimonial from './LargeTestimonial';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';

export type TestimonialWithImageProps = Sitecore.Override.ComponentBase &
  Feature.Components.Testimonials.Fields.TestimonialWithImage;
export type TestimonialWithLargeImageProps =
  Feature.Components.Testimonials.Fields.TestimonialWithImage;
export type TestimonialLayout = 'left' | 'right';
export type TestimonialTextPosition = 'left' | 'center' | 'right';

const TestimonialWithImage = ({ fields, rendering }: TestimonialWithImageProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const _testimonialLayout = getEnum<TestimonialLayout>(fields?.testimonialLayout);

  const { width } = useWindowDimensions();

  const componentAnchorId = rendering?.uid;

  return (
    <div
      data-component="authorable/general/testimonialwithimage"
      className={classNames('testimonial-with-image anchorIdIndentifier')}
      id={componentAnchorId}
    >
      <div
        className={classNames('flex', 'gap-3', 'items-center', {
          'flex-row': _testimonialLayout === 'left' && width > 960,
          'flex-row-reverse': _testimonialLayout !== 'left' && width > 960,
          'justify-center': width < 960,
        })}
      >
        <div
          className={classNames({
            'md:relative left-20': _testimonialLayout === 'left' && width > 960,
            'md:relative right-20': _testimonialLayout !== 'left' && width > 960,
          })}
        >
          <LargeTestimonialImage fields={fields} />
        </div>

        <div
          className={classNames(
            {
              'rounded-tl-3xl rounded-tr-3xl rounded-br-3xl': _testimonialLayout === 'left',
              'rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl': _testimonialLayout !== 'left',
            },
            {
              'md:relative right-20 ': _testimonialLayout === 'left' && width > 960,
              'md:relative left-20': _testimonialLayout !== 'left' && width > 960,
            },
            'bg-blue-navy',
            'text-white',
            'z-10'
          )}
        >
          <LargeTestimonial fields={fields} />
        </div>
      </div>
    </div>
  );
};

export default TestimonialWithImage;
