import classNames from 'classnames';
import Button from 'components/helpers/Button/Button';
import ConditionalRender from 'components/helpers/ConditionalWrapper/ConditionalRender';
import { HeaderPhoneProps } from '../header-types';

const HeaderPhone = ({ phoneNumber, phoneNumberTTY, variant }: HeaderPhoneProps): JSX.Element => {
  // Fail out if we don't have any fields
  if (!phoneNumber) {
    return <></>;
  }

  const outerClassName =
    variant === 'desktop'
      ? classNames('inline-flex', 'items-center', 'flex-start')
      : classNames('flex', 'flex-row', 'items-center', 'text-sm');

  const phoneClassName =
    variant === 'desktop'
      ? classNames('header-navList-menuItem', 'phone', 'pr-1')
      : classNames(
          'text-theme-text',
          'px-0 pr-1',
          'hover:text-theme-text',
          'hover:bg-transparent',
          'hover:underline',
          '[&>span]:text-teal'
        );

  const ttyClassName =
    variant === 'desktop'
      ? classNames('header-navList-menuItem', 'tty', 'pl-1')
      : classNames(
          'text-theme-text',
          'px-0',
          'pl-1',
          'hover:text-theme-text',
          'hover:bg-transparent',
          'hover:underline'
        );

  return (
    <span className={outerClassName}>
      <Button
        icon="PhoneIcon"
        iconSize="xxs"
        iconPosition="left"
        size="small"
        className={phoneClassName}
        field={{
          value: {
            href: `tel:${phoneNumber}`,
            text: phoneNumber,
          },
        }}
        type="button"
        variant="link"
      />
      <ConditionalRender condition={!!phoneNumberTTY}>
        <Button
          className={ttyClassName}
          field={{
            value: {
              href: `tel:${phoneNumberTTY}`,
              text: `TTY ${phoneNumberTTY}`,
            },
          }}
          type="button"
          variant="link"
        />
      </ConditionalRender>
    </span>
  );
};

export default HeaderPhone;
