import { AxiosDataFetcher } from '@sitecore-jss/sitecore-jss';

export interface GetStory {
  Story: HealthLibraryStory[];
  Keywords: Keyword[];
}

export interface GetStoriesForTopic {
  Topic: HealthLibraryTopic[];
  Featured: HealthLibraryStory[];
  Basic: HealthLibraryStory[];
  Related: HealthLibraryStory[];
  Keywords: Keyword[];
}
export interface Keyword {
  KeywordID: string;
  Word: string;
}

export interface HealthLibraryTopic {
  TopicID: string;
  TopicName: string;
  NotForMobile: boolean;
}

export interface HealthLibraryStoryBasic {
  HIStoryID: string;
  AssociatedTopics: string;
  StoryKey: number;
  Headline: string;
  IsLocalized: string;
  StartDateTime: string;
  RealDateTime: string;
  Teaser: string;
  SysImageName: string;
  WideImageName: string;
  SquareImageName: string;
  WideImgSysFileName: string;
  SquareImgSysFileName: string;
  SocialMediaStatusText: string;
  NotForMobile: boolean;
  IsActive: boolean;
  IsHMOContent: boolean;
  IsArchived: boolean;
  IsDeleted: boolean;
  IsNationalContent: boolean;
  IsSpanishContent: boolean;
  HIStoryTypeID: number;
  TypeDesc: string;
  PrimaryImgAlt: string;
  WideImgAlt: string;
  SquareImgAlt: string;
  ReadingLevel: number;
}

export interface HealthLibraryStory {
  IsLinkReview: number;
  LinkName: string;
  LinkUrl: string;
  HIStoryID: string;
  StoryKey: number;
  IsNationalContent: boolean;
  StartDateTime: string;
  TickleDate: string;
  TickleQueue: string;
  Headline: string;
  IsLocalized: string;
  IsActive: boolean;
  IsHMOContent: boolean;
  HIStoryTypeID: number;
  HITickleStyleID: number;
  Teaser: string;
  Summary: string;
  Body: string;
  Notes: string;
  LocalizationNotes: string;
  ArchiveDate: string;
  IsArchived: boolean;
  IsDeleted: boolean;
  SysImageName: string;
  ClientImageName: string;
  IsENewsOnly: boolean;
  DirectLinkUrl: string;
  ThumbImgSysName: string;
  ThumbImgOrigName: string;
  WideImgSysFileName: string;
  WideImgOrigFileName: string;
  SquareImgSysFileName: string;
  SquareImgOrigFileName: string;
  SocialMediaStatusText: string;
  NotForMobile: boolean;
  LastUpdated: string;
  IsSpanishContent: boolean;
  PrimaryImgAlt: string;
  WideImgAlt: string;
  SquareImgAlt: string;
  ReadingLevel: number;
}

export const healthLibraryStoryTypes: {
  [name: number]: string;
} = {
  1: '',
  2: 'Focus Story',
  3: 'Breaking Story',
  4: 'Link Review',
  5: 'Health Tool', // Unused
  6: 'Infographic',
  7: 'Recipe',
  8: 'Health Tip',
  9: 'Assessment',
  10: 'Calculator',
  11: 'Quiz',
  12: 'Slideshow',
  13: 'Video',
};

export const getHealthLibaryStory = async (
  id: string,
  redirectLimit = 3
): Promise<GetStory | undefined> => {
  const site = process.env.HEALTH_LIBRARY_SITE;
  const password = process.env.HEALTH_LIBRARY_PASSWORD;

  const url = `https://api.netreturns.biz/v1/HealthLibrary/${site}/GetStory/?nRApiPassword=${password}&id=${id}`;

  try {
    const result = await new AxiosDataFetcher().fetch<GetStory>(url);

    const story = result.data.Story[0];

    if (
      redirectLimit > 0 &&
      story.DirectLinkUrl?.trim() &&
      story.DirectLinkUrl.indexOf(story.HIStoryID) < 0
    ) {
      const [_base, storyId] = story.DirectLinkUrl.split('StoryID=');

      if (storyId) return getHealthLibaryStory(storyId, redirectLimit - 1);
    }
    return result.data;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getHealthLibaryTopics = async (): Promise<HealthLibraryTopic[] | undefined> => {
  const site = process.env.HEALTH_LIBRARY_SITE;
  const password = process.env.HEALTH_LIBRARY_PASSWORD;

  const url = `https://api.netreturns.biz/v1/HealthLibrary/${site}/GetTopics/?nRApiPassword=${password}`;

  try {
    const result = await new AxiosDataFetcher().fetch<HealthLibraryTopic[]>(url);

    return result.data;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getHealthLibraryStoriesForTopic = async (
  topicId: string
): Promise<GetStoriesForTopic | undefined> => {
  const site = process.env.HEALTH_LIBRARY_SITE;
  const password = process.env.HEALTH_LIBRARY_PASSWORD;

  const url = `https://api.netreturns.biz/v1/HealthLibrary/${site}/GetStoriesForTopic/?nRApiPassword=${password}&id=${topicId}`;

  try {
    const result = await new AxiosDataFetcher().fetch<GetStoriesForTopic>(url);

    return result.data;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getAllHealthLibraryStories = async (): Promise<HealthLibraryStoryBasic[]> => {
  return (await getHealthLibraryStories()) ?? [];
  const types = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  const result = await Promise.all(
    types.map(async (i) => {
      return getHealthLibraryStories(900, i);
    })
  );

  const flattened = result
    .filter((x) => x)
    .flat()
    .filter((x) => x);

  const arrayUniqueByKey = [...new Map(flattened.map((item) => [item?.HIStoryID, item])).values()];
  return arrayUniqueByKey as HealthLibraryStoryBasic[];
};

export const getHealthLibraryStories = async (
  limit = 0,
  storyType: number | '' = ''
): Promise<HealthLibraryStoryBasic[] | undefined> => {
  const site = process.env.HEALTH_LIBRARY_SITE;
  const password = process.env.HEALTH_LIBRARY_PASSWORD;

  const url = `https://api.netreturns.biz/v1/HealthLibrary/${site}/GetStories/?nRApiPassword=${password}&limit=${limit}&storyType=${storyType}&includeDirectLinkStories=false`;

  try {
    const result = await new AxiosDataFetcher().fetch<HealthLibraryStoryBasic[]>(url);

    if (result.data.length === limit) {
      console.warn(
        'not all health library items returned for story type:',
        healthLibraryStoryTypes[storyType || 0]
      );
    }
    return result.data;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
