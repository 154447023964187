// Components
import { useEffect } from 'react';

const ScreenMeetModalLinkListener = (): JSX.Element => {
  const handleClick = (e: Event) => {
    const screenMeetLinkClass = 'azblue-cobrowse-modal';

    const clickElement = e.target as HTMLElement;

    let isClickScreenMeetLinkClass = false;
    if (clickElement) {
      isClickScreenMeetLinkClass = clickElement.classList.contains(screenMeetLinkClass);
    }

    if (isClickScreenMeetLinkClass) {
      e.preventDefault();
      window.Cobrowse.modal.openModal();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  //functional component only
  return <></>;
};

export default ScreenMeetModalLinkListener;
