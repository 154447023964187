import classNames from 'classnames';
import { Feature } from '.generated/templates/Feature.Items.model';
import ImageWrapper from 'components/helpers/ImageWrapper/ImageWrapper';
import RichTextA11yWrapper from 'components/helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import EditingHelpText from 'components/helpers/EditingHelpText';
import CtaGroup from '../CtaGroup/CtaGroup';

export type SearchNoResultProps = Feature.Data.Search.Fields.SearchNoResult & {
  hasResults: boolean;
  whiteTheme?: boolean;
  smallVariant?: boolean;
};

const NoResults = ({
  fields,
  hasResults,
  whiteTheme,
  smallVariant,
}: SearchNoResultProps): JSX.Element => {
  if (!fields) {
    return <EditingHelpText condition={true} text="No item select for no results text" />;
  }

  if (hasResults) {
    return <></>;
  }

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'items-center',
        'justify-center',
        'rounded-lg',
        'text-center',
        'w-full',
        {
          'bg-blue-light': !whiteTheme,
          'bg-white': whiteTheme,
          border: whiteTheme,
          'border-gray': whiteTheme,
          'md:h-[707px]': !smallVariant,
          'md:p-30': !smallVariant,
          'p-10': !smallVariant,
          'md:p-12': smallVariant,
          'p-5': smallVariant,
        }
      )}
    >
      <span className={classNames('mb-5', 'text-blue-navy', 'fill-current', 'w-fit')}>
        <ImageWrapper
          imageClass={classNames('w-fit')}
          field={{
            value: { ...fields?.icon.value, width: '60px', height: '60px' },
          }}
        />
      </span>
      <div className={classNames('mb-6', 'md:mb-8')}>
        <RichTextA11yWrapper field={fields?.content} />
      </div>
      <CtaGroup {...fields} />
      {/* {(isEE || fields.primaryCta.value.text) && (
        <Button
          field={fields.primaryCta}
          icon={getEnum<ButtonIconNames>(fields.primaryCtaIcon)}
          iconPosition={getEnum<ButtonIconPosition>(fields.primaryCtaIconLocation)}
          variant={getEnum(fields.primaryCtaStyle)}
        />
      )} */}
    </div>
  );
};

export default NoResults;
