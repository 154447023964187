/* eslint-disable @typescript-eslint/no-explicit-any */

export const deepSearchFound = (root: any, predicate: (obj: any) => boolean) => {
  return deepSearch(root, predicate, true).length > 0;
};

export const deepSearch = (root: any, predicate: (obj: any) => boolean, stopAtFirst = false) => {
  const processedObjects: any[] = [];
  const matchingObjects: any[] = [];
  (function find(obj) {
    if (predicate(obj) === true) {
      matchingObjects.push(obj);
      if (stopAtFirst) {
        return;
      }
    }
    for (const key of Object.keys(obj as object)) {
      const o: any = (obj as any)[key] as any;
      if (o && typeof o === 'object') {
        if (!processedObjects.find((obj) => obj === o)) {
          processedObjects.push(o);
          find(o);
        }
      }
    }
  })(root);
  return matchingObjects;
};
