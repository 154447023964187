// Global
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';

// Local
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';

export type LayoutThreeColumnProps = Sitecore.Override.ComponentBase;

export type LayoutThreeColumnShellProps = {
  left: JSX.Element;
  middle: JSX.Element;
  right: JSX.Element;
  dataComponent?: string;
};

export const LayoutThreeColumnShell = ({
  left,
  middle,
  right,
  dataComponent,
}: LayoutThreeColumnShellProps): JSX.Element => {
  return (
    <div
      className={classNames('md:grid', 'grid-cols-3', 'md:gap-5', 'lg:gap-7')}
      data-component={dataComponent}
    >
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{left}</div>
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{middle}</div>
      <div className={classNames('col-span-1', 'mb-0', 'space-y-6')}>{right}</div>
    </div>
  );
};

const LayoutThreeColumn = ({ rendering }: LayoutThreeColumnProps): JSX.Element => {
  return (
    <LayoutThreeColumnShell
      dataComponent="authorable/layout/layout-components/layout-three-column"
      left={
        <PlaceholderWrapper
          wrapperSize="third"
          name="BCBSAZ-One-Third-Left"
          rendering={rendering}
        />
      }
      middle={
        <PlaceholderWrapper
          wrapperSize="third"
          name="BCBSAZ-One-Third-Middle"
          rendering={rendering}
        />
      }
      right={
        <PlaceholderWrapper
          wrapperSize="third"
          name="BCBSAZ-One-Third-Right"
          rendering={rendering}
        />
      }
    />
  );
};

export default LayoutThreeColumn;
