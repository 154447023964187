import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';
import { useEffect, useRef, useState } from 'react';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import Container from 'components/helpers/Container/Container';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';

export type TabViewsItemProps = Sitecore.Override.ItemEx &
  Feature.Components.Toggles.TabViews.Fields.TabViewItem;

export type TabViewsProps = Sitecore.Override.ComponentBase & {
  fields?: {
    root?: Feature.Components.Toggles.TabViews.Fields.TabViews & {
      children?: TabViewsItemProps[];
    };
  };
};
const TabViews = ({ fields, rendering }: TabViewsProps) => {
  const componentAnchorId = rendering?.uid;

  const allTabs = fields?.root?.children ?? [];
  const validTabs = allTabs.slice(0, 5);
  const isEE = useExperienceEditor();
  const [activeTabId, setActiveTabId] = useState<string>();

  // Fail out if we don't have any fields
  if (!fields) {
    return <MissingDataSource />;
  }

  const updateTab = (newTabId: string | undefined) => {
    setActiveTabId(newTabId);
    window.location.hash = newTabId ?? '';
  };
  const activeScrollList = useRef<HTMLUListElement>(null);
  const activeScrollItem = useRef<HTMLLIElement>(null);

  useEffect(() => {
    setActiveTabId(window.location.hash.replace(/^\#/, '') || validTabs[0]?.id);
    console.log(activeScrollList.current?.scrollLeft);
  }, []);

  useEffect(() => {
    if (activeScrollList.current != undefined) {
      activeScrollList.current.scrollLeft = activeScrollItem.current?.offsetLeft || 0;
    }
    activeTabId ? (window.location.hash = activeTabId) : null;
  }, [activeTabId]);

  return (
    <div
      data-coveo-no-index
      className={classNames(
        'relative',
        'overflow-x-hidden',
        'md:overflow-x-visible',
        'anchorIdIndentifier'
      )}
      data-component="authorable/toggles/tabviews"
      id={componentAnchorId}
    >
      <Container>
        <EditingHelpText
          condition={!validTabs.length}
          text="Tabs View | No tabs added. Add child items to add tabs"
        />

        {validTabs.length ? (
          <ul
            ref={activeScrollList}
            className={classNames(
              'border-gray-medium',
              'md:border-b',
              'md:overflow-x-visible',
              'md:pl-3',
              'md:space-x-2',
              'overflow-x-scroll',
              'grid grid-flow-col',
              'hide-scroll-bar',
              'space-x-2'
            )}
          >
            {validTabs.map((item) => {
              const isActive = activeTabId === item.id;

              return (
                <li
                  ref={isActive ? activeScrollItem : undefined}
                  key={item.id}
                  role="button"
                  onClick={() => updateTab(item.id)}
                  className={classNames(
                    'block',
                    'border-t',
                    'border-l',
                    'border-r',
                    'group',
                    'rounded-t-md',
                    'transition-all',
                    'whitespace-pre',
                    {
                      'border-gray-medium': isActive,
                      'border-gray': !isActive,
                      'bg-gray-light': !isActive,
                      'bg-white': isActive,
                      'font-bolder': isActive,
                    }
                  )}
                >
                  <div
                    className={classNames(
                      'hover:!no-underline',
                      'h-full',
                      'flex',
                      'justify-center',
                      'items-center',
                      'px-4',

                      'py-3',
                      'space-x-3',
                      'cursor-pointer',
                      'rounded-t-md',
                      'transition-all',
                      {
                        'shadow-underline': isActive,
                        'text-blue': isActive,
                        'text-gray-darkest': !isActive,
                      }
                    )}
                  >
                    <ImageWrapper
                      imageClass={classNames('inline-block', {
                        grayscale: !isActive,
                      })}
                      field={
                        {
                          ...item.fields.iconImage,
                          value: {
                            ...item.fields.iconImage.value,
                            width: '40px',
                            height: '40px',
                          },
                        } as SizedImageField
                      }
                    />
                    <Text
                      field={item.fields.tabText}
                      tag="span"
                      className={classNames('group-hover:underline')}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        ) : null}

        <EditingHelpText
          condition={allTabs.length > 5}
          text="Tab View | More than 5 tabs added. Only the first 5 will display"
        />

        <EditingHelpText
          condition={true}
          text="NOTE: Re-ordering tab elements will not re-order the content.  The placeholders are based on order and are not tied to a specific tab item.  Take care when re-ordering tabs."
        />
      </Container>
      {validTabs.map((item, index) => {
        // Placeholder with numbers seem to have issues, so using text
        const map = ['One', 'Two', 'Three', 'Four', 'Five'];
        return (
          <div
            key={item.id}
            className={classNames('mx-auto', 'relative', {
              hidden: !isEE && activeTabId !== item.id,
            })}
          >
            <PlaceholderWrapper
              wrapperSize="normal"
              name={`BCBSAZ-Toggle-${map[index]}`}
              displayName={`BCBSAZ-Tab-${map[index]}`}
              rendering={rendering}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TabViews;
