// Global
// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Heading from 'components/helpers/Heading/Heading';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import { getEnumValueOverRide } from 'lib/get-enum';
import CtaGroup, { CtaAlignment } from '../CtaGroup/CtaGroup';
import { ColumnOptions } from '../Xup/Xup';
import { getThemeClasses, Theme } from 'lib/get-theme';
import { useState } from 'react';
import ModalStandard from 'components/helpers/Modals/ModalStandard/ModalStandard';
import VideoItem from 'components/helpers/VideoItem/VideoItem';
import SvgIcon from '../Icons/SvgIcon/SvgIcon';
interface ExtendedCardProps {
  alignment?: string;
  columns: ColumnOptions | undefined;
  theme: Theme | undefined;
}

export type ImageCardProps = Sitecore.Override.ItemEx &
  Feature.Data.Cards.Fields.CardWithImage &
  ExtendedCardProps;

const ImageCard = ({ fields, alignment, theme, columns }: ImageCardProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  const contentAlign = alignment?.toLowerCase();

  const isVideo = fields?.youtubeUrl?.value || fields?.vimeoUrl?.value;

  return (
    <div
      className={classNames(
        'flex',
        'flex-col',
        'h-full',
        'overflow-hidden',
        'border-b',
        'border-gray',
        'border-t',
        'rounded-t-lg',
        'border-l',
        'border-r',
        'rounded-b-lg',
        'w-full'
      )}
    >
      <div
        className={classNames('relative', 'w-full', 'overflow-hidden', {
          'h-[270px]': Number(columns) >= 3,
          'h-[400px]': Number(columns) === 2,
          'h-[269px]': Number(columns) === 1,
        })}
      >
        {isVideo ? (
          <>
            <div className="cursor-pointer">
              <ImageWrapper
                field={fields?.videoThumbnail as SizedImageField}
                layout="fill"
                imageClass={classNames('w-full', 'md:h-full', 'object-cover')}
              />
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <button
                  className={classNames(
                    'absolute',
                    'top-0',
                    'bottom-0',
                    'left-0',
                    'right-0',
                    'z-10'
                  )}
                  onClick={() => setShowModal(true)}
                  role="presentation"
                  aria-label={`Play video: ${
                    fields?.videoTitle?.value?.length > 0
                      ? fields?.videoTitle?.value
                      : 'video title not set'
                  }`}
                >
                  <span
                    className={classNames(
                      'absolute',
                      'top-0',
                      'bottom-0',
                      'left-0',
                      'right-0',
                      'bg-black',
                      'opacity-20'
                    )}
                  />
                  <span
                    className={classNames(
                      'absolute',
                      '-translate-y-1/2',
                      '-translate-x-1/2',
                      'w-12',
                      'md:w-16',
                      'h-12',
                      'md:h-16',
                      'top-1/2',
                      'left-1/2',
                      'text-white',
                      'rounded-full'
                    )}
                  >
                    <SvgIcon
                      icon="play-button"
                      size="md"
                      viewBox="0 0 64 64"
                      className={classNames('block')}
                    />
                  </span>
                </button>
              </div>
            </div>
            <ModalStandard externalTrigger showModal={showModal} handleModal={setShowModal}>
              <VideoItem
                title={fields?.videoTitle?.value}
                videoSrc={
                  fields?.youtubeUrl?.value ? fields?.youtubeUrl?.value : fields?.vimeoUrl?.value
                }
              />
            </ModalStandard>
          </>
        ) : (
          <ImageWrapper
            field={fields.image as SizedImageField}
            layout="fill"
            imageClass={classNames('w-full', 'md:h-full', 'object-cover')}
          />
        )}
      </div>
      <div
        className={classNames(
          'border-gray',
          'border-t',
          'flex',
          'flex-col',
          'text-gray-dark',
          'flex-grow',
          'overflow-hidden',
          'p-5',
          'md:p-8',
          'rounded-b-lg',
          'shadow-card',
          'shrink-0',
          {
            'items-center': contentAlign === 'center',
            'text-center': contentAlign === 'center',
          },
          getThemeClasses(theme),
          '!text-theme-text'
        )}
      >
        <Heading
          headingText={fields?.headline}
          headingClassName={classNames(
            'mb-2',
            'font-regular',
            'text-gray-darkest',
            getThemeClasses(theme),
            '!text-theme-text'
          )}
          headingLevel="h3"
          headingSize="sm"
          description={fields?.description}
          descriptionClassName={classNames('mb-8', 'text-base')}
          eyebrow={fields?.eyebrowText}
        />
        <div className={classNames('mt-auto')}>
          <CtaGroup
            {...fields}
            ctaAlignment={getEnumValueOverRide<CtaAlignment>(`${contentAlign as CtaAlignment}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
