// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';
import Container from 'components/helpers/Container/Container';
import AccordionItem from './AccordionItem';
import { getEnum } from 'lib/get-enum';
import React, { useState } from 'react';
import EditingHelpText from 'components/helpers/EditingHelpText';
import { Theme } from 'lib/get-theme';

export type AccordionProps = Sitecore.Override.ComponentBase & {
  fields?: {
    root?: Feature.Components.Accordion.Fields.Accordion & {
      children?: (Sitecore.Override.ItemEx & Feature.Components.Accordion.Fields.AccordionItem)[];
    };
  };
};

const Accordion = ({ fields, rendering }: AccordionProps): JSX.Element => {
  const [open, setOpen] = useState<number | undefined>(-1);

  // Fail out if we don't have any fields
  if (!fields) {
    return <></>;
  }
  const componentAnchorId = rendering?.uid;
  return (
    <Container
      dataComponent="authorable/general/accordion"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <EditingHelpText
        condition={!fields?.root?.children?.length}
        text={`Accordion | Use Insert button to add accordion items. `}
      />
      <div className={classNames('flex', 'flex-col')}>
        <ul>
          {fields?.root?.children?.map((accItem, index) => {
            return (
              <React.Fragment key={accItem.id}>
                <AccordionItem
                  open={open === index}
                  onToggle={() => setOpen((o) => (o === index ? undefined : index))}
                  multiple={fields.root?.fields?.allowMultiple.value}
                  variant={
                    getEnum<Theme>(fields.root?.fields?.accordionBackgroundColor) ??
                    'theme-blue--light'
                  }
                  {...accItem}
                />
              </React.Fragment>
            );
          })}
        </ul>
      </div>
    </Container>
  );
};

export default Accordion;
