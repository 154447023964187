// Components
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Feature } from '.generated/templates/Feature.Items.model';
import { DangerousHtml } from 'components/helpers/DangerousHtml/DangerousHtml';
import { getEnum } from 'lib/get-enum';

export type PageScriptsProps = Sitecore.Override.ComponentBase &
  Feature.Components.General.Fields.PageScripts;

export type ScriptType = 'dangeroushtml' | 'plainhtml';

const PageScripts = ({ fields, rendering }: PageScriptsProps): JSX.Element => {
  const script = fields?.javaScript.value;
  // Fail out if we don't have any page scripts selected.
  if (!script) {
    return <></>;
  }
  const scriptType = getEnum<ScriptType>(fields?.useScriptType);

  function GetScript() {
    switch (scriptType) {
      case 'dangeroushtml':
        return <DangerousHtml html={script as string} />;
      case 'plainhtml':
      default:
        return <div dangerouslySetInnerHTML={{ __html: `${script}` }} />;
    }
  }

  return (
    <div
      data-component="authorable/general/pagescripts"
      id={rendering?.uid}
      className="azblue-pagescripts"
    >
      {GetScript()}
    </div>
  );
};

export default PageScripts;
