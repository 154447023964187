// Global
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import classNames from 'classnames';

// Local
import PlaceholderWrapper from 'components/helpers/PlaceholderWrapper/PlaceholderWrapper';

export type LayoutWideRightProps = Sitecore.Override.ComponentBase;

export type LayoutWideRightShellProps = {
  left: JSX.Element;
  right: JSX.Element;
  dataComponent?: string;
};

export const LayoutWideRightShell = ({
  left,
  right,
  dataComponent,
}: LayoutWideRightShellProps): JSX.Element => {
  return (
    <div
      className={classNames('md:grid', 'grid-cols-3', 'md:gap-5', 'lg:gap-7')}
      data-component={dataComponent}
    >
      <div className={classNames('col-span-1', 'mb-10', 'md:mb-0', 'space-y-6')}>{left}</div>
      <div className={classNames('col-span-2', 'space-y-6')}>{right}</div>
    </div>
  );
};

const LayoutWideRight = ({ rendering }: LayoutWideRightProps): JSX.Element => {
  return (
    <LayoutWideRightShell
      dataComponent="authorable/layout/layout-components/layout-wide-right"
      left={
        <PlaceholderWrapper
          wrapperSize="third"
          name="BCBSAZ-One-Third-Left"
          rendering={rendering}
        />
      }
      right={
        <PlaceholderWrapper
          wrapperSize="two-thirds"
          name="BCBSAZ-Two-Thirds"
          rendering={rendering}
        />
      }
    />
  );
};

export default LayoutWideRight;
