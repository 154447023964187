// Components
import { Feature } from '.generated/templates/Feature.Items.model';
import Container from 'components/helpers/Container/Container';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { getEnum } from 'lib/get-enum';
import EditingHelpText, { MissingDataSource } from 'components/helpers/EditingHelpText';
import classNames from 'classnames';
import { Sitecore } from '.generated/templates/_.Sitecore.Override';
import { Theme } from 'lib/get-theme';
import {
  ButtonIconNames,
  ButtonIconPosition,
} from 'components/helpers/Icons/ButtonLinkIcon/ButtonLinkIcon';
import Button from 'components/helpers/Button/Button';
import React from 'react';
import CircleIcon from 'components/helpers/Icons/CircleIcon/CircleIcon';
import useExperienceEditor from 'lib/sitecore/use-experience-editor';
import ImageWrapper, { SizedImageField } from 'components/helpers/ImageWrapper/ImageWrapper';
import HeadingWrapper from 'components/helpers/HeadingWrapper/HeadingWrapper';

export type MedicarePlanOptionsProps = Sitecore.Override.ComponentBase &
  Feature.Components.MedicarePlan.Fields.MedicarePlanOptions;

const MedicarePlanOptions = ({ fields, rendering }: MedicarePlanOptionsProps): JSX.Element => {
  if (!fields) {
    return <MissingDataSource />;
  }

  const isEE = useExperienceEditor();
  if (!isEE && !fields.planOptionDetails.length) {
    return <></>;
  }
  const componentAnchorId = rendering?.uid;
  const stackMobile = fields?.mobileColumn?.value;

  return (
    <Container
      dataComponent="authorable/general/medicareplanoptions"
      id={componentAnchorId}
      className="anchorIdIndentifier"
    >
      <HeadingWrapper {...fields} />
      <EditingHelpText
        condition={!fields.planOptionDetails.length}
        text="Medicare Plan Options | No Plans Selected. "
      />
      <table className={classNames('w-full', 'mt-7', 'md:mt-12')} role="presentation">
        {/* Commented out for now, until we find a proper fix.
        <thead>
          <tr className={classNames('sr-only')}>
            <th scope="col">Medicare Plan</th>
            <th scope="col">Medicare Parts</th>
            <th scope="col">Learn More</th>
          </tr>
        </thead> */}

        <tbody className={classNames('border-gray', 'border-t')}>
          {fields?.planOptionDetails.map((i) => {
            const planDetail = i as Sitecore.Override.ItemEx &
              Feature.Data.MedicarePlan.Fields.PlanDetails;
            return (
              <tr
                className={classNames(
                  'border-gray',
                  'border-b',
                  'flex',
                  'flex-col',
                  'md:table-row',
                  'py-7'
                )}
                key={i.id}
              >
                <td className={classNames('max-w-[400px]')}>
                  <div
                    dangerouslySetInnerHTML={{ __html: planDetail.fields?.heading.value }}
                    className={classNames('flex', 'text-md', 'md:text-lg')}
                  />
                  <div className={classNames('flex', 'text-sm')}>
                    <Text field={planDetail.fields?.shortDescription} />
                  </div>
                </td>
                <td
                  className={classNames(
                    'flex',
                    'flex-wrap',
                    'md:flex-nowrap',
                    'items-center',
                    'py-6'
                  )}
                >
                  {planDetail.fields?.planIcons.map((pi) => {
                    const planIcon = pi as Sitecore.Override.ItemEx &
                      Feature.Data.MedicarePlan.Fields.PlanIcon;

                    const themeValue = getEnum<Theme>(planIcon.fields?.backgroundStyle);

                    return (
                      <div
                        className={classNames(
                          'flex',
                          'flex-col',
                          'odd:items-start',
                          { 'even:items-center': stackMobile === true },
                          { 'even:mt-6': stackMobile === true },
                          { 'w-full': stackMobile === true },
                          { 'even:lg:even:w-10': stackMobile === true },
                          'mr-3',
                          'md:mr-6'
                        )}
                        key={pi.id}
                      >
                        {themeValue ? (
                          <CircleIcon
                            field={planIcon.fields.iconImage as SizedImageField}
                            size={'sm'}
                            theme={themeValue}
                          />
                        ) : (
                          <div
                            className={classNames(
                              'flex',
                              'items-center',
                              'justify-center',
                              'w-8',
                              'h-8',
                              '-mt-6',
                              '-mx-3',
                              'fill-current',
                              'bg-none',
                              'text-gold',
                              'border-0'
                            )}
                          >
                            <ImageWrapper
                              field={planIcon.fields.iconImage as SizedImageField}
                              removeFill
                            />
                          </div>
                        )}
                        <div
                          className={classNames('flex', 'text-sm', 'top-full', {
                            'lg:min-h-[100px]': stackMobile === true,
                          })}
                        >
                          <Text field={planIcon.fields?.label} />
                        </div>
                      </div>
                    );
                  })}
                </td>
                <td>
                  {planDetail.fields?.primaryCta.value.text && (
                    <div className={classNames('flex', 'items-center', 'w-max', 'justify-end')}>
                      <Button
                        field={planDetail.fields?.primaryCta}
                        icon={getEnum<ButtonIconNames>(planDetail.fields?.primaryCtaIcon)}
                        iconPosition={getEnum<ButtonIconPosition>(
                          planDetail.fields?.primaryCtaIconLocation
                        )}
                        variant={getEnum(planDetail.fields.primaryCtaStyle)}
                      />
                    </div>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Container>
  );
};

export default MedicarePlanOptions;
